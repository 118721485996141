import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { roles } from '../../utils/roles';
import { getPermissions } from '../../lib/accessControl';
import { abortController } from '../../utils/abortController';
import withAuth from '../../lib/withAuth';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import MaterialReactDataTable from '../../components/MaterialReactDataTable';
import Backdrop from '@mui/material/Backdrop';
import TextField from '@mui/material/TextField';
import Alert from '../../components/Alert';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import Loader from '../../components/Loader';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import axios from 'axios';

const ApproveDocuments = ({ logOut = () => {} }) => 
{
    const { pathname } = useLocation();
    const debounce = require("lodash.debounce");

    /* AUTHENTICATION VARIABLES */
    const currentUser = useUser();

    /* AUTHORIZATION VARAIBLES */
    const { isWritable = false } = getPermissions(currentUser?.role, pathname);
    const businessUnits = getPermissions(currentUser?.role, null, "businessUnits");

    /* SESSION EXPIRY VARIABLES */
    const triggerSessionExpire = useSessionExpire();

    /* API VARIABLES */
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    /* DATATABLE VARIABLES */
    const [documentData, setDocumentData] = useState([]);
    const [toggleDisplayData, setToggleDisplayData] = useState(false);
    const tableRef = useRef();

    /* DATATABLE ROW DATA VARIABLES */
    const [rowData, setRowData] = useState({});
    const [rowComment, setRowComment] = useState("");
    const [rowCommentData, setRowCommentData] = useState({});

    /* DATATABLE GLOBAL FILTER VARIABLES */
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    /* TAB VARIABLES */
    const [selectedTab, setSelectedTab] = useState(0);
    const [isTabChanged, setIsTabChanged] = useState(false);

    /* MODAL VARIABLES */
    const [showCommentModal, setShowCommentModal] = useState(false);
    const [showDisableModal, setShowDisableModal] = useState(false);

    useEffect(() =>
    {
        const fetchData = async () => 
        {
            setIsLoading(true);
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");
            setRowData({});
    
            await axios({
                method: "post",
                url: "/api/GetDocumentData",
                data: {
                    selected_deal_type: selectedTab ? businessUnits[selectedTab - 1]?.id : "All",
                    filter_value: globalFilterValue
                },
                signal: abortController.signal
            })
            .then((response) => 
            {
                setIsLoading(false);
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const documentData = data?.document_data || {};        
                    setDocumentData(documentData);
                }
                else
                {
                    setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => 
            {
                console.log("Get Document Data Api: ", error);
                setIsLoading(false);

                if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
                {
                    return;
                }
                
                const status = error?.response?.status;

                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }
        
        if (businessUnits?.length > 0)
        {
            fetchData();
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [businessUnits, selectedTab, toggleDisplayData]);

    const handleTabChange = (tab) =>
    {
        setIsTabChanged(selectedTab !== tab);
        setSelectedTab(tab);
    }

    const handleViewDocument = async (rowData) =>
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        const {
            deal_pfi_id: Deal_PFI_Id,
            tranche_id: Tranche_Id,
            coo_id: COO_ID,
            external_file_id: External_File_Id,
            revision_number: Revision_Number,
            isApproved: is_approved_document,
            "Document Type": Document_Type,
            is_external_document: isExternalDocument
        } = rowData || {};
        
        const documentRowData = {
            pathname: pathname,
            Deal_PFI_Id,
            Tranche_Id,
            COO_ID,
            External_File_Id,
            Revision_Number,
            Document_Type: isExternalDocument ? "External Document" : Document_Type,
            is_approved_document,
            view_document: true
        };        
    
        await axios({
            method: "post",
            url: "/api/ViewDocument",
            data: documentRowData,
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
        
            if (status === 200) 
            {
                window.open(data, "_blank", "noopener, noreferrer");
            }
            else if (status === 202)
            {
                setAlertMessage(data?.data);
                setAlertType("info");
                setShowAlert(true);
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("View Document Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
        
            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleAddOrEditComment = (rowData) => 
    {
        const {
            deal_pfi_id: Deal_PFI_Id,
            tranche_id: Tranche_Id,
            coo_id: COO_ID,
            external_file_id: External_File_Id,
            revision_number: Revision_Number,
            "Document Type": Document_Type,
            Comments,
            is_external_document: isExternalDocument
        } = rowData || {};
        
        const rowCommentData = {
            Deal_PFI_Id,
            Tranche_Id,
            COO_ID,
            External_File_Id,
            Revision_Number,
            Document_Type: isExternalDocument ? "External Document" : Document_Type,
            Comments
        };

        setRowComment(rowCommentData?.Comments || "");
        setRowCommentData(rowCommentData);
        setShowCommentModal(true);
    }
     
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const handleCommentChange = useCallback(debounce((comment) => 
    {
        setRowComment(comment);
    }, 300), []);

    const handleSaveComment = async () => 
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        const updatedRowCommentData = {
            ...rowCommentData,
            Comments: rowComment
        };
    
        await axios({
            method: "post",
            url: "/api/SetDocumentComment",
            data: updatedRowCommentData,
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status } = response;
        
            if (status === 200) 
            {
                setShowCommentModal(false);
                setToggleDisplayData(!toggleDisplayData);
            } 
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Set Document Comment Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
            
            const status = error?.response?.status;
        
            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleRecordStatusChangeWrapper = (rowData) =>
    {
        setRowData(rowData);

        if (rowData?.isApproved || rowData?.isDeleted)
        {
            setShowDisableModal(true);
        }
        else
        {
            handleRecordStatusChange(rowData);
        }
    }
    
    const handleRecordStatusChange = useCallback(async (rowData) => 
    {
        setShowDisableModal(false);
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
    
        const {
            deal_pfi_id: Deal_PFI_Id,
            tranche_id: Tranche_Id,
            coo_id: COO_ID,
            external_file_id: External_File_Id,
            revision_number: Revision_Number,
            "Document Type": Document_Type,
            "Document Name": Document_Name,
            "Reviewed By": Reviewed_By,
            isApproved,
            isDeleted: Access_Status,
            is_external_document: isExternalDocument
        } = rowData || {};
        
        const documentRowData = {
            Deal_PFI_Id,
            Tranche_Id,
            COO_ID,
            External_File_Id,
            Revision_Number,
            Document_Type: isExternalDocument ? "External Document" : Document_Type,
            Document_Name,
            Document_Status: isApproved ? 0 : 1,
            Access_Status,
            Reviewed_By
        };        

        await axios({
            method: "post",
            url: "/api/SetDocumentStatus",
            data: documentRowData,
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status } = response;
        
            if (status === 200) 
            {
                setToggleDisplayData(!toggleDisplayData);
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Set Document Status Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
            
            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [rowData]);

    const handleGlobalFilter = async (table, rowData) =>
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
        
        await axios({
            method: "post",
            url: "/api/FilterTableData",
            data: { 
                pathname: pathname,
                table_data: rowData,
                filter_value: globalFilterValue
            },
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
    
            if (status === 200) 
            {
                table?.resetRowSelection();
                tableRef?.current?.setFilteredData(data || []);
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Filter Table Data Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
            
            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleBulkViewOrDownload = async (selectedRows, actionType) => 
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        const documentRowData = selectedRows?.map(({ 
            deal_pfi_id: Deal_PFI_Id, 
            tranche_id: Tranche_Id, 
            coo_id: COO_ID, 
            external_file_id: External_File_Id, 
            revision_number: Revision_Number, 
            isApproved: is_approved_document, 
            "Document Type": Document_Type, 
            is_external_document: isExternalDocument 
        }) => ({
            Deal_PFI_Id,
            Tranche_Id,
            COO_ID,
            External_File_Id,
            Revision_Number,
            Document_Type: isExternalDocument ? "External Document" : Document_Type,
            is_approved_document,
            view_document: actionType === "view"
        }));
    
        await axios({
            method: "post",
            url: "/api/ViewDocuments",
            data: documentRowData,
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
        
            if (status === 200) 
            {
                const { file_urls: fileUrls = [], unavailable_files: unavailableFiles = [] } = data;
                
                fileUrls.forEach((url) => 
                {
                    window.open(url, "_blank", "noopener, noreferrer");
                });

                if (unavailableFiles.length > 0)
                {
                    setAlertMessage(`No file is available for the documents: ${unavailableFiles.join(", ")}`);
                    setAlertType("info");
                    setShowAlert(true);
                }
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("View Documents Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
        
            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }
    
    const handleBulkApproval = async (selectedRows) => 
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        const documentRowData = selectedRows?.map(({ 
            deal_pfi_id: Deal_PFI_Id, 
            tranche_id: Tranche_Id, 
            coo_id: COO_ID, 
            external_file_id: External_File_Id, 
            revision_number: Revision_Number, 
            "Document Type": Document_Type, 
            "Document Name": Document_Name,
            isApproved, 
            is_external_document: isExternalDocument 
        }) => ({
            Deal_PFI_Id,
            Tranche_Id,
            COO_ID,
            External_File_Id,
            Revision_Number,
            Document_Type: isExternalDocument ? "External Document" : Document_Type,
            Document_Name,
            Reviewed_By: currentUser?.id,
            Document_Status: isApproved ? 0 : 1
        }));

        await axios({
            method: "post",
            url: "/api/ApproveDocuments",
            data: documentRowData,
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status } = response;
        
            if (status === 200) 
            {
                setToggleDisplayData(!toggleDisplayData);
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Approve Documents Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
        
            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }    

    const handleCloseModal = () =>
    {
        setShowCommentModal(false);
        setShowDisableModal(false);
        setRowComment("");
    }
      
    return (
        <div className = "content-container d-flex flex-column container approve-document">
            <Alert
                show = {showAlert}
                message = {alertMessage}
                type = {alertType}
                setShow = {setShowAlert}
            />

            <h4 className = "page-heading m-0">
                Approve Documents
            </h4>

            <div className = {`${businessUnits?.length > 0 ? 'show-tabs' : 'hide-tabs'}`}>
                {businessUnits?.length > 0 && (
                    <Box className = "mb-1" display = "flex" justifyContent = "center" width = "100%">
                        <Tabs
                            value = {selectedTab}
                            variant = "scrollable"
                            scrollButtons = "auto"
                            onChange = {(event, newValue) => handleTabChange(newValue)}
                            sx = {{ 
                                minHeight: '38px !important',
                                marginBottom: '0.5em',
                                backgroundColor: '#eef2ff',
                                '& .MuiTab-root': {
                                    minHeight: '38px !important'
                                }
                            }}
                        >
                            <Tab key = "All" label = "All" />
                            {businessUnits?.map((businessUnit) => (
                                <Tab 
                                    key = {businessUnit.id} 
                                    label = {businessUnit.value === "SBLC" ? "Polymers - SBLC" : businessUnit.value} 
                                />
                            ))}
                        </Tabs>
                    </Box>
                )}

                <Backdrop
                    sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open = {isLoading}
                >
                    <Loader/>
                </Backdrop>
                
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                    <MaterialReactDataTable
                        ref = {tableRef}
                        title = "Approve Documents"
                        isWritable = {isWritable}
                        isTabChanged = {isTabChanged}
                        isBusinessUnitSelected = {businessUnits?.length > 0}
                        allowBulkActions = {true}
                        currentUserId = {currentUser?.id}
                        globalFilterValue = {globalFilterValue}
                        tableData = {documentData}
                        setIsLoading = {setIsLoading}
                        setShowAlert = {setShowAlert}
                        setAlertMessage = {setAlertMessage}
                        setAlertType = {setAlertType}
                        setIsTabChanged = {setIsTabChanged}
                        setGlobalFilterValue = {setGlobalFilterValue}
                        handleGlobalFilter = {handleGlobalFilter}
                        handleAddOrEditComment = {handleAddOrEditComment}
                        handleViewDocument = {handleViewDocument}
                        handleRecordStatusChange = {handleRecordStatusChangeWrapper}
                        handleBulkViewOrDownload = {handleBulkViewOrDownload}
                        handleBulkApproval = {handleBulkApproval}
                    />
                </LocalizationProvider>
            </div>
        
            <Modal show = {showDisableModal} onHide = {handleCloseModal} centered = {true}>
                <Modal.Header closeButton = {true}>
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <span>
                        {rowData?.isDeleted ? 'Deleting' : 'Disapproving'} the document will remove it from the list of {rowData?.isDeleted ? 'accessible' : 'approved'} documents.
                    </span>
                </Modal.Body>
                <Modal.Footer className = "gap-2">
                    <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant = "contained" size = "small" onClick = {() => handleRecordStatusChange(rowData)}>
                        {rowData?.isDeleted ? 'Delete' : 'Disapprove'}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show = {showCommentModal} onHide = {handleCloseModal} centered = {true}>
                <Modal.Header closeButton = {true}>
                    <Modal.Title>{rowCommentData?.Comments ? "Update Comment" : "Add Comment"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <TextField
                        label = "Write a Comment"
                        multiline
                        fullWidth
                        rows = {3}
                        defaultValue = {rowComment}
                        onChange = {(event) => handleCommentChange(event.target.value)}
                    />
                </Modal.Body>
                <Modal.Footer className = "gap-2">
                    <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
                        Cancel
                    </Button>
                    <Button variant = "contained" size = "small" onClick = {handleSaveComment}>
                        {rowCommentData?.Comments ? "Update" : "Add"}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default withAuth(ApproveDocuments)([
    roles[2], 
    roles[3], 
    roles[4], 
    roles[5],
    roles[6],  
    roles[7], 
    roles[8], 
    roles[10], 
    roles[11], 
    roles[12], 
    roles[13], 
    roles[14],
    roles[15],  
    roles[16], 
    roles[17],
    roles[18],  
    roles[19]
]);