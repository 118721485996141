import { useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';

const CountdownTimer = ({ label = "", targetDate = null, timeLeft = {}, setTimeLeft = () => {} }) => 
{
    useEffect(() => 
    {
        const timer = setInterval(() => 
        {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [targetDate]);

    const calculateTimeLeft = () => 
    {
        const difference = +new Date(targetDate) - +new Date();
        
        if (difference <= 0) 
        {
            return {};
        }

        return {
            "Day(s)": Math.floor(difference / (1000 * 60 * 60 * 24)),
            "Hour(s)": Math.floor((difference / (1000 * 60 * 60)) % 24),
            "Min(s)": Math.floor((difference / 1000 / 60) % 60),
            "Sec(s)": Math.floor((difference / 1000) % 60)
        };
    }

    const timerComponents = Object.keys(timeLeft).map((interval) => 
    {
        const value = timeLeft[interval];

        return value ? (
            <Chip 
                key = {interval}
                color ="primary"
                label = {`${interval}: ${value}`}
                sx = {{
                    borderRadius: '5px',
                    fontWeight: 'bold'
                }}
            />
        ) : null;
    });

    return (
        <>
            {timerComponents.length > 0 && (
                <Stack 
                    direction = "row" 
                    alignItems = "center" 
                    spacing = {0.5}
                >
                    <small>{label + " Time Left:"}</small> &nbsp;
                    {timerComponents}
                </Stack>
            )}
        </>
    );
}

export default CountdownTimer;