import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import routeControl from './routeControl';

const withAuth = (Component) => (allowedRoles) => (props) => 
{
  const navigate = useNavigate();
  const location = useLocation();

  /* AUTHORIZATION VARIABLES */
  const isAuthenticated = useSelector(state => state.user.login);
  const role = useSelector(state => state.user.role);

  /* ROUTE VARIABLES */
  const [isValidRoute, setIsValidRoute] = useState(false);

  useEffect(() => 
  {
    const currentPath = location.pathname;
  
    const isPathValid = () => 
    {
      const roleRoutes = routeControl[role] || {};
  
      // Check for an exact match
      if (Object.values(roleRoutes).includes(currentPath)) 
      {
        return true;
      }
  
      // Check against dynamic routes (only those containing ":")
      return Object.values(roleRoutes).some(route =>
        route.includes(":") && new RegExp(`^${route.replace(/:[^/]+/g, "([^/]+)")}$`).test(currentPath)
      );
    };    

    // Prevent authenticated users from accessing login-related routes
    if (isAuthenticated && routeControl.login[currentPath]) 
    {
      setIsValidRoute(false);
    
      const defaultRoute = routeControl[role]?.default;
      navigate(defaultRoute);
    }
    // If user is authenticated and on the wrong path then redirect user to the default route of the user's role
    else if (isAuthenticated && (!allowedRoles.includes(role) || !isPathValid())) 
    {
      setIsValidRoute(false);
    
      const defaultRoute = routeControl[role]?.default;
      navigate(defaultRoute);
    }
    // If user is not authenticated and on the wrong path then redirect user to the login page
    else if (!isAuthenticated && !routeControl.login[currentPath]) 
    {
      setIsValidRoute(false);
      
      const loginDefaultRoute = routeControl.login?.default;
      navigate(loginDefaultRoute);
    }
    // If top two conditions not met then route user to pathname
    else 
    {
      setIsValidRoute(true);
    }
  
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [location.pathname, isAuthenticated, role]);

  return isValidRoute ? <Component {...props} /> : null;
}

export default withAuth;