import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { abortController } from '../../utils/abortController';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import DealSBLC from './DealSBLC';
import TextField from '@mui/material/TextField';
import AddBoxIcon from '@mui/icons-material/AddBox';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Grid from '@mui/material/Grid2';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import IconButton from '@mui/material/IconButton';
import CustomTextMask from '../../components/CustomTextMask';
import dayjs from 'dayjs';
import axios from 'axios';

const DealHeader = forwardRef(({ isWritable = false, isListDataLoading = false, isFertilizersAndPetroChemicalsDeal = false, isFoodsOrPolymersDeal = false, isFoodsPolymersOrSBLCDeal = false, isSBLCDeal = false, isExistingDealHeader = false, isExistingSBLC = false, isFormSavedOrDeleted = false, selectedBusinessUnit = null, selectedPFI = null, selectedPFIToCopy = null, formData = {}, sellingLegFormData = {}, sblcInitialFormData = {}, sblcFormData = {}, expandedFormSections = [], setIsExistingDealHeader = () => {}, setIsExistingSBLC = () => {}, setIsFormUpdated = () => {}, setIsSBLCFormUpdated = () => {}, setIsFormSavedOrDeleted = () => {}, setFormData = () => {}, setSellingLegFormData = () => {}, setSBLCFormData = () => {}, setExpandedFormSections = () => {}, setWarningDialogText = () => {}, setShowWarningDialog = () => {}, setShowDeleteModal = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, logOut = () => {} }, ref) => 
{
  const { pathname } = useLocation();

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();

  /* LIST VARIABLES */
  const [productData, setProductData] = useState([]);
  const [products, setProducts] = useState([]);
  const [productGrades, setProductGrades] = useState([]);
  const [customerCounterParties, setCustomerCounterParties] = useState([]);
  const [supplierCounterParties, setSupplierCounterParties] = useState([]);
  const orderStatus = useMemo(() => ["In-Progress", "Cancelled", "Closed"], []);
  const transactionTypes = useMemo(() => ["Addition", "Subtraction"], []);
  
  /* FORM VARIABLES */
  const formRef = useRef(null);

  /* INPUT REFERENCE VARIABLES */
  const freightInputRef = useRef(null);
  const fobValueInputRef = useRef(null);
  
  /* ACCORDIAN VARIABLES */
  const [isProductAccordionExpanded, setIsProductAccordionExpanded] = useState(null);
  const [isPFIAccordionExpanded, setIsPFIAccordionExpanded] = useState(null);
  const [isProductGradeAccordionExpanded, setIsProductGradeAccordionExpanded] = useState(null);
  const productAccordionRefs = useRef([]);
  const pfiAccordionRefs = useRef([]);
  const productGradeAccordionRefs = useRef([]);

  useEffect(() => 
  {
    const fetchLists = async () => 
    {
      axios({
        method: "post",
        url: "/api/GetProducts",
        data: {
          pathname: pathname,
          Business_Unit_Id: selectedBusinessUnit
        },
        signal: abortController.signal
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const productData = data?.product_data?.rows || [];
          setProductData(productData);
        }
      })
      .catch((error) => 
      {
        console.log("Get Products Api: ", error);
        
        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });

      axios({
        method: "post",
        url: "/api/GetProductGradeList",
        data: { 
          pathname: pathname,
          Business_Unit_Id: selectedBusinessUnit
        },
        signal: abortController.signal
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const productGrades = data?.product_grades || [];
          setProductGrades(productGrades);
        }
      })
      .catch((error) => 
      {
        console.log("Get Product Grades Api: ", error);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });

      axios({
        method: "post",
        url: "/api/GetProductList",
        data: { 
          pathname: pathname,
          Business_Unit_Id: selectedBusinessUnit
        },
        signal: abortController.signal
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const products = data?.product || [];
          setProducts(products);
        }
      })
      .catch((error) => 
      {
        console.log("Get Filtered Products Api: ", error);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });

      axios({
        method: "post",
        url: "/api/GetCounterPartyList",
        data: { 
          pathname: pathname,
          Business_Unit_Id: selectedBusinessUnit,
          Deal_PFI_Id: selectedPFI
        },
        signal: abortController.signal
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const { customer_vendors: customerCounterParties = [], supplier_vendors: supplierCounterParties = [] } = data || {};
          
          setCustomerCounterParties(customerCounterParties);
          setSupplierCounterParties(supplierCounterParties);
        }
      })
      .catch((error) => 
      {
        console.log("Get Filtered Sellers/Counter Parties Api: ", error);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    fetchLists();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() =>
  {
    setIsProductAccordionExpanded((formData?.products?.length ?? 0) - 1);
  }, [formData?.products]);

  useEffect(() => 
  {
    if (isSBLCDeal)
    {
      // Check if the 'sblcFormData' is empty
      const isEmpty = typeof sblcFormData === "object" && !sblcFormData?.Deal_PFI_Id;
    
      // Set existing deal sblc flags
      if (!isEmpty && !selectedPFIToCopy) 
      {
        setIsExistingSBLC(true);
      }
      else
      {
        setIsExistingSBLC(false);
      }
    
      if (!isFormSavedOrDeleted && !selectedPFIToCopy)
      {
        const isSBLCFormUpdated = checkIfFormUpdated(sblcFormData, sblcInitialFormData);
        setIsSBLCFormUpdated(isSBLCFormUpdated);
      }
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isSBLCDeal, sblcFormData, selectedPFIToCopy]);

  useImperativeHandle(ref, () => ({
    isValid,
    handleSubmit: handleSubmit,
    handleDelete: handleDelete
  }));

  const checkIfFormUpdated = (formData, initialFormData, dateKeys = []) => 
  {
    // Check non-date fields
    const isNonDateUpdated = Object.keys(initialFormData).some((key) => 
    {
      const currentValue = formData[key];
      const initialValue = initialFormData[key];
    
      return JSON.stringify(currentValue) !== JSON.stringify(initialValue);
    });

    // Check date fields separately
    const isDateUpdated = dateKeys.some((dateKey) => 
    {
      const currentDate = formData[dateKey] ? dayjs(formData[dateKey]) : formData[dateKey];
      const initialDate = initialFormData[dateKey] ? dayjs(initialFormData[dateKey]) : initialFormData[dateKey];
    
      return JSON.stringify(currentDate) !== JSON.stringify(initialDate);
    });

    return isNonDateUpdated || isDateUpdated;
  }

  const convertToDayJsObject = (date, datetime) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : datetime ? dayjs(datetime, "MM/DD/YYYY hh:mm A") : null;
    return convertedDate;
  }

  const shouldDisableDate = (date, type) => 
  {
    if (!formData?.Booked_for_Month) 
    {
      return false;
    }

    const selectedDate = new Date(date);
    const bookedDate = new Date(formData?.Booked_for_Month);

    return (
      selectedDate.getFullYear() !== bookedDate.getFullYear() ||
      (type === "Month" && selectedDate.getMonth() !== bookedDate.getMonth())
    );
  }

  const isValid = () => 
  {
    return formRef.current?.reportValidity();
  }

  const handleFormSectionAccordionChange = (section) => (event, isExpanded) => 
  {
    setExpandedFormSections(isExpanded ? [...expandedFormSections, section] : expandedFormSections.filter(id => id !== section));
  }
  
  const handleAccordionChange = (index) => (event, isExpanded) => 
  {
    setIsProductAccordionExpanded(isExpanded ? index : null);
  }

  const handleChange = (event, field, value, isDate = false) => 
  {
    let newValue = value;

    if (isDate && newValue) 
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }

    if (["Freight", "FOB_Value", "Value"].includes(field))
    {
      // Remove any non-numeric characters except the decimal point
      newValue = value.replace(/[^0-9.]/g, "");

      // Ensure the value has at most 2 decimal places
      if (newValue.includes(".")) 
      {
        const [integerPart, decimalPart] = newValue.split(".");
        newValue = `${integerPart}.${decimalPart.substring(0, 2)}`;
      }
    }

    const getSanitizedValue = (value) => typeof value === "string" ? (value.trim() === "" ? null : value) : value ?? null;
    const updates = { [field]: getSanitizedValue(newValue) };
  
    if (field === "Customer_Address")
    {
      setSellingLegFormData((previousSellingLegFormData) => ({ 
        ...previousSellingLegFormData, 
        Customer_Address: updates.Customer_Address || null
      }));
    }
    else
    {
      if (field === "Booked_for_Month") 
      {
        updates.Order_Date = null;
      } 
      else if (field === "Deal_Close_Date") 
      {
        updates.Order_Status = orderStatus.find((status) => status === "Closed") || null;
      } 
      else if (field === "Freight" || field === "FOB_Value") 
      {
        freightInputRef?.current?.setCustomValidity("");
        fobValueInputRef?.current?.setCustomValidity("");
    
        const freight = field === "Freight" ? parseInt(newValue || 0) : parseInt(formData["Freight"] || 0);
        const fobValue = field === "FOB_Value" ? parseInt(newValue || 0) : parseInt(formData["FOB_Value"] || 0);
        const calculatedTotalAmount = freight + fobValue;
        const actualTotalAmount = formData["Total_Amount"] || 0;
    
        if (calculatedTotalAmount !== actualTotalAmount) 
        {
          event?.target?.setCustomValidity(
            `The calculated total is ${calculatedTotalAmount > actualTotalAmount ? "more" : "less"} than the actual total amount.`
          );
        }
      }
    
      setFormData((previousFormData) => ({ ...previousFormData, ...updates }));
    }
  }

  const handleProductChange = (index, field, value) => 
  {
    let formattedValue = value;

    if (["Quantity", "Buying_Price", "Selling_Price"].includes(field)) 
    {
      // Remove any non-numeric characters except the decimal point
      formattedValue = value.replace(/[^0-9.]/g, "");

      if (formattedValue.includes(".")) 
      {
        const [integerPart, decimalPart] = formattedValue.split(".");

        if (field === "Quantity") 
        {
          // Ensure the value has at most 3 decimal places
          formattedValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
        } 
        else 
        {
          // Ensure the value has at most 2 decimal places
          formattedValue = `${integerPart}.${decimalPart.substring(0, 2)}`;
        }
      }
    }

    const data = { ...formData };
    const newProducts = [...data.products];
    const updatedProduct = { ...newProducts[index] };

    // Get old quantity & price before update
    const oldQuantity = parseInt(newProducts[index]?.Quantity || 0);
    const oldPrice = parseInt(newProducts[index]?.Selling_Price || 0);
    const oldAmount = oldQuantity * oldPrice;

    updatedProduct[field] = formattedValue;    
    newProducts[index] = updatedProduct;

    // Get new quantity & price after update
    const newQuantity = parseInt(updatedProduct?.Quantity || 0);
    const newPrice = parseInt(updatedProduct?.Selling_Price || 0);
    const newAmount = newQuantity * newPrice;

    // Adjust total by subtracting old and adding new
    const totalAmount = (data["Total_Amount"] || 0) - oldAmount + newAmount;

    setFormData((previousFormData) => ({
      ...previousFormData,
      products: newProducts,
      Total_Amount: totalAmount,
      ...(["Quantity", "Selling_Price"].includes(field) ? { Freight: null, FOB_Value: null } : {})
    }));
  }

  const handleAddProduct = (event) => 
  {
    event.stopPropagation();

    const products = [...formData.products || []];
    const isAnyProductFieldFilled =
      products.length > 0
        ? products.every((product) =>
            Object.values(product).some((value) =>
              Array.isArray(value) ? value.length > 0 : Boolean(value)
            )
          )
        : true;

    if (isAnyProductFieldFilled) 
    {
      const newProductData = {
        Product_Id: null,
        Unit_of_Measurement: "",
        Product_Grade_Id: null,
        Product_Specification: "",
        Product_Description: "",
        Quantity: "",
        Buying_Price: "",
        Selling_Price: "",
        HS_Code: ""
      };
      const updatedProducts = [...products, newProductData];

      setFormData((previousFormData) => ({
        ...previousFormData,
        products: updatedProducts,
        Freight: null,
        FOB_Value: null
      }));
    } 
    else 
    {
      setWarningDialogText("Please fill in at least one field in the current product details before adding a new one.");
      setShowWarningDialog(true);
    }
  }

  const handleDeleteProduct = (event, index) => 
  {
    event.stopPropagation();

    setFormData((previousFormData) => 
    {
      const productToDelete = previousFormData.products[index];  
      const deletedAmount = (parseInt(productToDelete?.Quantity || 0) * parseInt(productToDelete?.Selling_Price || 0)) || 0;

      return {
        ...previousFormData,
        products: previousFormData.products.filter((_, i) => i !== index),
        Total_Amount: (previousFormData.Total_Amount || 0) - deletedAmount,
        Freight: null,
        FOB_Value: null
      };
    });
  }

  const handleSubmit = async (event) => 
  {
    event?.preventDefault();

    if (!isValid()) 
    {
      return false;
    }

    return new Promise((resolve, reject) => 
    {
      setShowWarningDialog(false);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
      
      let updatedFormData = {
        ...formData,
        Business_Unit_Id: selectedBusinessUnit,
        Deal_PFI_Id: selectedPFI,
        User_Id: currentUser?.id
      };
  
      updatedFormData = Object.fromEntries(Object.entries(updatedFormData).map(([key, value]) => [
        key,
        typeof value === "object" && value?.hasOwnProperty("value") ? value.id : value
      ]));
  
      const apiUrl = isExistingDealHeader
        ? isFertilizersAndPetroChemicalsDeal
          ? "/api/UpdateOrderDetailsFertilizersAndPetroChemicals"
          : isFoodsPolymersOrSBLCDeal
            ? "/api/UpdateOrderDetailsFoodsPolymersAndSBLC"
            : ""
        : isFertilizersAndPetroChemicalsDeal
          ? "/api/AddOrderDetailsFertilizersAndPetroChemicals"
          : isFoodsPolymersOrSBLCDeal
            ? "/api/AddOrderDetailsFoodsPolymersAndSBLC"
            : "";
      let isSBLCSaved = Promise.resolve();
  
      if (isSBLCDeal) 
      {
        let updatedSBLCFormData = {
          ...sblcFormData,
          Deal_PFI_Id: selectedPFI,
          User_Id: currentUser?.id
        };
        const sblcApiUrl = isExistingSBLC ? "/api/UpdateDealSBLCProductGrades" : "/api/AddDealSBLCProductGrades";
  
        const transformObject = (obj, seen = new Set()) => 
        {
          if (seen.has(obj)) return obj; // Return the object if it has already been processed
          if (typeof obj !== "object" || obj === null) return obj; // Base case for non-objects
  
          seen.add(obj); // Add the current object to the seen set
  
          if (Array.isArray(obj)) 
          {
            return obj.map(item =>
              typeof item === "object" && item !== null
                ? item.hasOwnProperty("value")
                  ? item.id
                  : transformObject(item, seen) // Recursively transform nested arrays
                : item
            );
          }
  
          return Object.fromEntries(
            Object.entries(obj).map(([key, value]) => [
              key,
              typeof value === "object" && value !== null
                ? Array.isArray(value)
                  ? transformObject(value, seen)  // Handle arrays
                  : value.hasOwnProperty("value")
                    ? value.id
                    : transformObject(value, seen)  // Recursively transform nested objects
                : value
            ])
          );
        }
  
        updatedSBLCFormData = transformObject(updatedSBLCFormData);

        isSBLCSaved = axios({
          method: "post",
          url: sblcApiUrl,
          data: updatedSBLCFormData,
          signal: abortController.signal
        })
        .then((response) => 
        {
          const { status, data } = response;

          if (status === 400) 
          {
            setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
            reject(data?.error);
          } 
          else if (status !== 200)
          {
            setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
            reject(false);
          }
        })
        .catch((error) => 
        {
          console.log("Add/Update Deal SBLC Product Grade Data Api: ", error);

          if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
          {
            return;
          }

          const status = error?.response?.status;

          if (status === 403) 
          {
            triggerSessionExpire();
          } 
          else 
          {
            setAlertMessage(
              status === 401
                ? "Unauthorized access. You do not have the required permissions to perform this action."
                : status === 429
                ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                : "An error occurred while processing your request. Please try again later or contact the site administrator."
            );
            setAlertType("error");
            setShowAlert(true);

            if (status === 429) 
            {
              setTimeout(logOut, 3000);
            }            
          }

          reject(error);
        });
      }
  
      isSBLCSaved
      .then(() =>
        axios.post(apiUrl, updatedFormData, { signal: abortController.signal })
        .then((response) => 
        {
          const { status, data } = response;

          if (status === 200) 
          {
            setIsFormUpdated(false);
            setIsFormSavedOrDeleted(true);
            resolve(true);
          } 
          else 
          {
            const errorMessage = status === 400 
              ? (data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.")
              : "An error occurred while processing your request. Please try again later or contact the site administrator.";

            setAlertMessage(errorMessage);
            setAlertType("error");
            setShowAlert(true);
            reject(errorMessage);
          }
        }))
      .catch((error) => 
      {
        console.log("Add/Update Deal Details Api: ", error);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401 ? "Unauthorized access. You do not have the required permissions." :
            status === 429 ? "Request limit exceeded. Your account is temporarily disabled." :
            "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);

          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
        
        reject(error);
      });
    });
  }
  
  const handleDelete = async () => 
  {
    return new Promise((resolve, reject) => 
    {
      setShowDeleteModal(false);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      let isSBLCDeleted = Promise.resolve();

      if (isSBLCDeal)
      {
        isSBLCDeleted = axios({
          method: "post",
          url: "/api/DeleteDealSBLCProductGrades",
          data: { 
            Deal_PFI_Id: selectedPFI,
            SBLC_Id: sblcFormData?.SBLC_Id?.id,
            User_Id: currentUser?.id
          },
          signal: abortController.signal
        })
        .then((response) => 
        {
          const { status, data } = response;

          if (status === 200) 
          {                      
            setIsExistingSBLC(false);
            setIsSBLCFormUpdated(false);
            setIsPFIAccordionExpanded(null);
            setIsProductGradeAccordionExpanded(null);
          } 
          else if (status === 400)
          {
            setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
            reject(false);
          }
          else 
          {
            setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
            reject(false);
          }
        })
        .catch((error) => 
        {
          console.log("Delete Buying Leg SBLC Api: ", error);
          
          if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
          {
            return;
          }

          const status = error?.response?.status;

          if (status === 403) 
          {
            triggerSessionExpire();
          } 
          else 
          {
            setAlertMessage(
              status === 401
                ? "Unauthorized access. You do not have the required permissions to perform this action."
                : status === 429
                ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                : "An error occurred while processing your request. Please try again later or contact the site administrator."
            );
            setAlertType("error");
            setShowAlert(true);
        
            if (status === 429) 
            {
              setTimeout(logOut, 3000);
            }
          }

          reject(false);
        });
      }

      isSBLCDeleted
      .then(() =>
        axios.post("/api/DeleteOrderDetails", { 
          Business_Unit_Id: selectedBusinessUnit,
          Deal_PFI_Id: selectedPFI,
          User_Id: currentUser?.id
        }, {
          signal: abortController.signal
        })
        .then((response) => 
        {
          const { status, data } = response;

          if (status === 200) 
          {
            formRef?.current?.reset();

            setIsExistingDealHeader(false);
            setIsFormUpdated(false);
            setIsFormSavedOrDeleted(true);
            resolve(true);
          } 
          else if (status === 400)
          {
            setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
            reject(false);
          }
          else 
          {
            setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
            reject(false);
          }
        }))
      .catch((error) => 
      {
        console.log("Delete Deal Header Data Api: ", error);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }

        reject(false);
      });
    });
  }

  const formSectionsHTML = [
    {
      id: 1,
      columns: 12,
      title: "Deal Information",
      content: (
        <Grid
          container
          spacing = {2}
          className = "align-items-start autocomplete"
        >
          <Grid size = {{ xs: 3 }}>
            <VirtualizedAutocomplete
              isMultiple = {false}
              isObject = {false}
              isWritable = {isWritable}
              filterOn = "Deal Status"
              options = {orderStatus}
              selectedOptions = {formData?.Order_Status || null}
              handleSelectChange = {(filterOn, newValue) => handleChange(null, "Order_Status", newValue)}
            />
          </Grid>

          <Grid size = {{ xs: 3 }}>
            <VirtualizedAutocomplete
              isLoading = {isListDataLoading}
              isMultiple = {false}
              isObject = {true}
              isWritable = {isWritable}
              filterOn = "Customer Name *"
              options = {customerCounterParties}
              selectedOptions = {formData?.Buyer_Id || null}
              handleSelectChange = {(filterOn, newValue) => handleChange(null, "Buyer_Id", newValue)}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              label = "Customer Address *"
              id = "Customer_Address"
              value = {sellingLegFormData?.Customer_Address || ""}
              onChange = {(event) => handleChange(event, "Customer_Address", event.target.value)}  
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}                
            />
          </Grid>

          <Grid size = {{ xs: 3 }}>
            <VirtualizedAutocomplete
              isLoading = {isListDataLoading}
              isMultiple = {false}
              isObject = {true}
              isWritable = {isWritable}
              filterOn = "Supplier Name"
              options = {supplierCounterParties}
              selectedOptions = {formData?.Seller_Id || null}
              handleSelectChange = {(filterOn, newValue) => handleChange(null, "Seller_Id", newValue)}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                className = "date-picker w-100"
                label = "Deal Open Month"
                openTo = "month"
                views = {["month", "year"]}
                inputFormat = "MMM-YY"
                disableMaskedInput
                readOnly = {!isWritable}
                value = {convertToDayJsObject(formData?.Booked_for_Month)}
                onChange = {(newDate) => handleChange(null, "Booked_for_Month", newDate)}
                onAccept = {(newDate) => handleChange(null, "Booked_for_Month", newDate)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
          
          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Deal Open Date"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Order_Date)}
                onChange = {(newDate) => handleChange(null, "Order_Date", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Order_Date", newDate, true)}
                shouldDisableMonth = {(month) => shouldDisableDate(month, "Month")}
                shouldDisableYear = {(year) => shouldDisableDate(year, "Year")}
                InputProps = {{
                  required: formData?.Order_Status?.value === "Closed"
                }}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Deal Close Date"
                className = "date-picker w-100"
                disableMaskedInput
                readOnly = {!isWritable}
                minDate = {convertToDayJsObject(formData?.Order_Date)}
                value = {convertToDayJsObject(formData?.Deal_Close_Date)}
                onChange = {(newDate) => handleChange(null, "Deal_Close_Date", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Deal_Close_Date", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDateTimePicker
                inputFormat = "MM/DD/YYYY hh:mm A"
                label = "Deal Sheet Approval Date & Time"
                className = "datetime-picker w-100"
                disableMaskedInput
                readOnly = {!isWritable}
                value = {formData?.Deal_Sheet_Approval_Date ? dayjs(formData?.Deal_Sheet_Approval_Date) : null}
                onChange = {(newDateTime) => handleChange(null, "Deal_Sheet_Approval_Date", newDateTime)}
                onAccept = {(newDateTime) => handleChange(null, "Deal_Sheet_Approval_Date", newDateTime)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Shipment Month & Date"
                className = "date-picker w-100"
                disableMaskedInput
                readOnly = {!isWritable}
                value = {convertToDayJsObject(formData?.Shipment_Month_And_Date)}
                onChange = {(newDate) => handleChange(null, "Shipment_Month_And_Date", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Shipment_Month_And_Date", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          {formData?.Order_Status === "Cancelled" &&
            <Grid size = {{ xs: 4 }} className = "form-text-field">
                <TextField
                  label = "Deal Cancelled Reason"
                  id = "Deal_Cancelled_Reason"
                  multiline
                  rows = {3}
                  value = {formData?.Deal_Cancelled_Reason || ""}
                  slotProps = {{ 
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                  onChange = {(event) => handleChange(event, "Deal_Cancelled_Reason", event.target.value)}
                />
            </Grid>
          }
        </Grid>  
      )
    },
    {
      id: 2,
      columns: 6,
      title: `${isSBLCDeal ? "SBLC" : "Product"} Details`,
      content: (
        <>
          {isSBLCDeal ? (
              <DealSBLC 
                isWritable = {isWritable}
                isListDataLoading = {isListDataLoading}
                isPFIAccordionExpanded = {isPFIAccordionExpanded}
                isProductGradeAccordionExpanded = {isProductGradeAccordionExpanded}
                selectedTab = "Deal Header"
                sblcFormData = {sblcFormData}
                pfiAccordionRefs = {pfiAccordionRefs}
                productGradeAccordionRefs = {productGradeAccordionRefs}
                setIsPFIAccordionExpanded = {setIsPFIAccordionExpanded}
                setIsProductGradeAccordionExpanded = {setIsProductGradeAccordionExpanded}
                setFormData = {setFormData}
                setSBLCFormData = {setSBLCFormData}
                setShowAlert = {setShowAlert}
                setAlertMessage = {setAlertMessage}
                setAlertType = {setAlertType}
              />
          ) : formData?.products?.map((product, index) => {
            const productValue = product?.Product_Id?.value || `Product ${index + 1}`;

            return (
              <Accordion
                ref = {(element) => (productAccordionRefs.current[index] = element)}
                key = {index}
                expanded = {isProductAccordionExpanded === index}
                onChange = {handleAccordionChange(index)}
              >
                <AccordionSummary
                  id = {`product-header-${index}`}
                  aria-controls = {`product-content-${index}`}
                  expandIcon = {<ExpandMoreIcon />}
                  slot = "div"
                >
                  <small className = "page-heading fw-bold item">
                    {productValue}
                  </small>
                  
                  <IconButton
                    color = "error"
                    size = "small"
                    className = "pt-0"
                    disabled = {!isWritable}
                    onClick = {(event) => handleDeleteProduct(event, index)}
                  >
                    <DeleteIcon fontSize = "inherit" />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    spacing = {2}
                    className = "align-items-start autocomplete"
                  >
                    <Grid size = {{ xs: 6 }}>
                      <VirtualizedAutocomplete
                        isLoading = {isListDataLoading}
                        isMultiple = {false}
                        isObject = {true}
                        isWritable = {isWritable}
                        isRequired = {true}
                        filterOn = "Product"
                        options = {products}
                        selectedOptions = {product.Product_Id || null}
                        handleSelectChange = {(filterOn, newValue) => handleProductChange(index, "Product_Id", newValue)}
                      />
                    </Grid>

                    <Grid size = {{ xs: 6 }} className = "form-text-field">
                      <TextField
                        label = "Unit of Measurement *"
                        id = "Unit_of_Measurement"
                        disabled
                        value = {product?.Product_Id 
                          ? productData?.find((_product) => _product?.id === product?.Product_Id?.id)?.Unit_of_Measurement || "" 
                          : ""
                        }
                        slotProps = {{
                          inputLabel: {
                            shrink:
                              product?.Product_Id &&
                              productData?.some((_product) => _product.id === product.Product_Id.id && _product.Unit_of_Measurement)
                          }
                        }}
                      />
                    </Grid>

                    {isFoodsOrPolymersDeal && (
                      <Grid size = {{ xs: 6 }}>
                        <VirtualizedAutocomplete
                          isLoading = {isListDataLoading}
                          isMultiple = {false}
                          isObject = {true}
                          isWritable = {isWritable}
                          filterOn = "Product Grade"
                          options = {productGrades}
                          selectedOptions = {product.Product_Grade_Id || null}
                          handleSelectChange = {(filterOn, newValue) => handleProductChange(index, "Product_Grade_Id", newValue)}
                        />
                      </Grid>
                    )}

                    <Grid size = {{ xs: 6 }} className = "form-text-field">
                      <TextField
                        label = "Quantity *"
                        id = "Quantity"
                        type = "number"
                        value = {product.Quantity || ""}
                        onChange = {(event) => handleProductChange(index, "Quantity", event.target.value)}
                        slotProps = {{
                          input: { 
                            readOnly: !isWritable,
                            min: 0, 
                            max: 2147483647, 
                            step: "0.001" 
                          } 
                        }}
                        onKeyDown = {(event) => 
                        {
                          if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                          {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Grid>

                    <Grid size = {{ xs: 6 }} className = "form-text-field">
                      <TextField
                        label = "Selling Price *"
                        id = "Selling_Price"
                        type = "number"
                        value = {product.Selling_Price || ""}
                        onChange = {(event) => handleProductChange(index, "Selling_Price", event.target.value)}
                        slotProps = {{
                          input: { 
                            readOnly: !isWritable,
                            min: 0, 
                            max: 2147483647, 
                            step: "0.01" 
                          } 
                        }}
                        onKeyDown = {(event) => 
                        {
                          if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                          {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Grid>

                    <Grid size = {{ xs: 6 }} className = "form-text-field">
                      <TextField
                        label = "Buying Price *"
                        id = "Buying_Price"
                        type = "number"
                        value = {product.Buying_Price || ""}
                        onChange = {(event) => handleProductChange(index, "Buying_Price", event.target.value)}
                        slotProps = {{
                          input: { 
                            readOnly: !isWritable,
                            min: 0, 
                            max: 2147483647, 
                            step: "0.01" 
                          } 
                        }}
                        onKeyDown = {(event) => 
                        {
                          if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                          {
                            event.preventDefault();
                          }
                        }}
                      />
                    </Grid>

                    <Grid size = {{ xs: 6 }} className = "form-text-field">
                      <TextField
                        label = "HS Code *"
                        id = "HS_Code"
                        name = "HS_Code"
                        value = {product?.HS_Code ? product?.HS_Code?.toString() : ""}
                        onChange = {(event) => handleProductChange(index, "HS_Code", event.target.value)}
                        slotProps = {{
                          input: { 
                            readOnly: !isWritable,
                            inputComponent: CustomTextMask 
                          } 
                        }}
                      />
                    </Grid>

                    {isFoodsOrPolymersDeal && (
                      <Grid size = {{ xs: 12 }} className = "form-text-field">
                        <TextField
                          label = "Specification"
                          id = "Product_Specification"
                          multiline
                          rows = {3}
                          value = {product.Product_Specification || ""}
                          slotProps = {{ 
                            input: {
                              readOnly: !isWritable
                            }
                          }}
                          onChange = {(event) => handleProductChange(index, "Product_Specification", event.target.value)}
                        />
                      </Grid>
                    )}

                    <Grid size = {{ xs: 12 }} className = "form-text-field">
                      <TextField
                        label = "Description *"
                        id = "Product_Description"
                        multiline
                        rows = {3}
                        value = {product.Product_Description || ""}
                        slotProps = {{ 
                          input: {
                            readOnly: !isWritable
                          }
                        }}
                        onChange = {(event) => handleProductChange(index, "Product_Description", event.target.value)}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </>
      )
    },
    {
      id: 3,
      columns: 6,
      title: "PFI Freight And Discount Adjustments",
      content: (
        <Grid
          container
          spacing = {2}
          className = "w-100 align-items-start"
        >
          <Grid size = {{ xs: 6 }} className = "form-text-field">
            <TextField
              label = "Total Amount"
              id = "Total_Amount"
              type = "number"
              value = {formData?.Total_Amount || ""}
              disabled
              slotProps = {{
                inputLabel: { shrink: formData?.Total_Amount }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 6 }} className = "form-text-field">
            <TextField
              inputRef = {freightInputRef}
              label = "Freight"
              id = "Freight"
              type = "number"
              value = {formData?.Freight || ""}
              slotProps = {{ 
                input: {
                  readOnly: !isWritable
                }
              }}
              onChange = {(event) => handleChange(event, "Freight", event.target.value)}
            />
          </Grid>

          <Grid size = {{ xs: 6 }} className = "form-text-field">
            <TextField
              inputRef = {fobValueInputRef}
              label = "FOB Value"
              id = "FOB_Value"
              value = {formData?.FOB_Value || ""}
              slotProps = {{ 
                input: {
                  readOnly: !isWritable
                }
              }}
              onChange = {(event) => handleChange(event, "FOB_Value", event.target.value)}
            />
          </Grid>
          
          <Grid size = {{ xs: 6 }} className = "form-text-field">
            <TextField
              label = "Label"
              id = "Label"
              value = {formData?.Label || ""}
              slotProps = {{ 
                input: {
                  readOnly: !isWritable
                }
              }}
              onChange = {(event) => handleChange(event, "Label", event.target.value)}
            />
          </Grid>

          <Grid size = {{ xs: 6 }} className = "form-text-field">
            <TextField
              label = "Value"
              id = "Value"
              type = "number"
              value = {formData?.Value || ""}
              slotProps = {{ 
                input: {
                  readOnly: !isWritable
                }
              }}
              onChange = {(event) => handleChange(event, "Value", event.target.value)}
            />
          </Grid>

          <Grid size = {{ xs: 6 }}>
            <VirtualizedAutocomplete
              isMultiple = {false}
              isObject = {false}
              isWritable = {isWritable}
              filterOn = "Transaction Type"
              options = {transactionTypes}
              selectedOptions = {formData?.Transaction_Type || null}
              handleSelectChange = {(filterOn, newValue) => handleChange(null, "Transaction_Type", newValue)}
            />
          </Grid>
        </Grid>
      )
    }
  ];

  return (
    <form
      ref = {formRef}
      className = "deal-form mt-1"
      onSubmit = {handleSubmit}
    >
      <Grid
        container
        spacing = {1}
        className = "w-100 align-items-start autocomplete gap-1"
      >
        {formSectionsHTML.map((section, index) => (
          <Grid 
            key = {index}
            size = {{ xs: section?.columns }}
          >
            <Accordion
              key = {section.id}
              expanded = {expandedFormSections.includes(section.id)}
              onChange = {handleFormSectionAccordionChange(section.id)}
            >
              <AccordionSummary
                id = "panel-header"
                aria-controls = "panel-content"
                expandIcon = {<ExpandMoreIcon/>}
                slot = "div"
              >
                <small className = "page-heading title">
                  {section.title}
                </small>

                {(section.title === "Product Details" && expandedFormSections.includes(section.id)) && (
                  <IconButton
                    color = "primary"
                    disabled = {!isWritable}
                    onClick = {handleAddProduct}
                  >
                    <AddBoxIcon 
                      fontSize = "large"
                      sx = {{ color: '#0C969C' }}
                    />
                  </IconButton>
                )}
              </AccordionSummary>
              <AccordionDetails>
                {section.content}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}  
      </Grid>
    </form>
  );
});

export default memo(DealHeader);