import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { allowedRoutesByRole } from '../../utils/routes';
import { getPermissions } from '../../lib/accessControl';
import { roles } from '../../utils/roles';
import { abortController } from '../../utils/abortController';
import { FaTableCells } from 'react-icons/fa6';
import { FaClipboardList, FaUserCog } from 'react-icons/fa';
import { AiOutlineAudit } from 'react-icons/ai';
import { RiPagesLine, RiUserSearchFill } from 'react-icons/ri';
import { IoDocumentsSharp } from 'react-icons/io5'
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import Grid from '@mui/material/Grid2';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import Alert from '../../components/Alert';
import MetricTile from '../../components/MetricTile';
import axios from 'axios';

const LandingPage = ({ logOut = () => {} }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const { pathname } = useLocation();
    const navigate = useNavigate();

    /* SESSION EXPIRY VARIABLES */
    const triggerSessionExpire = useSessionExpire();

    /* AUTHENTICATION VARIABLES */
    const currentUser = useUser();

    /* AUTHORIZATION VARAIBLES */
    const businessUnits = getPermissions(currentUser?.role, null, "businessUnits");

    /* ROUTE VARIABLES */
    const getAllowedRoutes = (role) => allowedRoutesByRole[role] || [];
    const allowedRoutes = useMemo(() => getAllowedRoutes(currentUser?.role) || [], [currentUser?.role]);

    /* BUSINESS UNIT VARIABLES */
    const polymerBusinessUnit = businessUnits.find(businessUnit => businessUnit.value === "Polymers") || null;
    const sblcBusinessUnit = businessUnits.find(businessUnit => businessUnit.value === "SBLC") || null;

    /* MENU VARIABLES */
    const [keyMetrics, setKeyMetrics] = useState({
        newDeals: null,
        closedDeals: null,
        totalUsers: null,
        newUsers: null,
        kycTotalRequests: null,
        kycApprovedRequests: null,
        pendingDocuments: null,
        approvedDocuments: null
    });
    const menuConfig = useMemo(() => 
    {
        const hasPolymers = Boolean(polymerBusinessUnit);
        const hasSBLC = Boolean(sblcBusinessUnit);
    
        return [
            {
                key: "deals",
                label: "Deals",
                caption: "(This Month)",
                color: "#075E62",
                icon: <FaClipboardList className = "metric-icon" />,
                subMenu: [
                    ...businessUnits
                        .filter(businessUnit => !["Polymers", "SBLC"].includes(businessUnit.value))
                        .map(businessUnit => ({ path: `/deals/${businessUnit.value.toLowerCase()}`, label: businessUnit.value, businessUnit: businessUnit })),
                    hasPolymers && hasSBLC && {
                        key: "polymerDeals",
                        label: "Polymers",
                        subMenu: [
                            { path: "/deals/polymers", label: "Polymers", businessUnit: polymerBusinessUnit },
                            { path: "/deals/sblc", label: "Polymers - SBLC", businessUnit: sblcBusinessUnit }
                        ]
                    }
                ].filter(Boolean),
                keyMetrics: [
                    { label: "Created", value: keyMetrics.newDeals },
                    { label: "Closed", value: keyMetrics.closedDeals }
                ]
            },
            {
                key: "entities",
                label: "Entities",
                caption: "(Active)",
                color: "#0A7478",
                icon: <FaTableCells className = "metric-icon" />,
                subMenu: [
                    { path: "/entities/banks", label: "Banks" },
                    { path: "/entities/counter-parties", label: "Counter Parties" },
                    { path: "/entities/deals", label: "Deals" },
                    { path: "/entities/deal-pfis", label: "Deal PFIs" },
                    { path: "/entities/dropdowns", label: "Dropdowns" },
                    { path: "/entities/file-types", label: "File Types" },
                    { path: "/entities/products", label: "Products" },
                    { path: "/entities/sblc", label: "SBLC" },
                    { path: "/entities/tenors", label: "Tenors" },
                    { path: "/entities/traders", label: "Traders" }
                ],
                keyMetrics: [
                    { label: "Counter Parties", value: keyMetrics.counterParties },
                    { label: "Products", value: keyMetrics.products }
                ]
            },
            {
                key: "reports",
                label: "Reports",
                color: "#0C969C",
                icon: <RiPagesLine className = "metric-icon" />,
                subMenu: [
                    { path: "/reports/deal-report", label: "Deal Report" },
                    { path: "/reports/finance-report", label: "Finance Report" },
                    { path: "/reports/morning-report", label: "Morning Report" }
                ],
                keyMetrics: [
                    { label: "Viewed", value: keyMetrics.reportsViewed },
                    { label: "Downloaded", value: keyMetrics.reportsDownloaded }
                ]
            },
            {
                key: "kyc",
                label: "KYC",
                caption: "(This Month)",
                color: "#10ADB4",
                icon: <RiUserSearchFill className = "metric-icon" />,
                subMenu: [
                    { path: "/kyc/new-kyc", label: "New KYC" },
                    { path: "/kyc/requests", label: "Requests" },
                    { path: "/kyc/counter-party-list", label: "Counter Party List" }
                ],
                keyMetrics: [
                    { label: "Requested", value: keyMetrics.kycTotalRequests },
                    { label: "Approved", value: keyMetrics.kycApprovedRequests }
                ]
            },
            {
                key: "audit",
                label: "Audit Trail",
                color: "#13C2C9",
                path: "/mass-update",
                icon: <AiOutlineAudit className = "metric-icon" />,
                subMenu: [
                    { path: "/audit-trail/finance", label: "Finance" },
                    { path: "/audit-trail/user", label: "User" }
                ]
            },
            {
                key: "documents",
                label: "Approve Documents",
                color: "#2F80B2",
                path: "/approve-documents",
                icon: <IoDocumentsSharp className = "metric-icon" />,
                keyMetrics: [
                    { label: "Approved", value: keyMetrics.approvedDocuments },
                    { label: "Pending", value: keyMetrics.pendingDocuments }
                ]
            },
            {
                key: "massUpdate",
                label: "Mass Update",
                color: "#16D7DE",
                path: "/mass-update",
                icon: <DynamicFeedIcon className = "metric-icon" />
            },
            {
                key: "user-management",
                label: "User Management",
                caption: "(This Month)",
                color: "#10ADB4",
                path: "/user-management",
                icon: <ManageAccountsIcon className = "metric-icon" />,
                keyMetrics: [
                    { label: "Total Users", value: keyMetrics.totalUsers },
                    { label: "New Users", value: keyMetrics.newUsers }
                ]
            },
            {
                key: "account",
                label: "My Profile",
                color: "#043F42",
                icon: <FaUserCog className = "metric-icon" />,
                subMenu: [{ path: "/account/edit-profile", label: "Edit Profile" }]
            }
        ];
    }, [businessUnits, polymerBusinessUnit, sblcBusinessUnit, keyMetrics]);
    const filteredMenuConfig = menuConfig.map(section => 
    {
        if (section.subMenu) 
        {
            const allowedSubRoutes = section.subMenu.filter(item => allowedRoutes.includes(item.path));
            return allowedSubRoutes.length > 0 ? { ...section, subMenu: allowedSubRoutes } : { ...section, disabled: true };
        }

        return allowedRoutes.includes(section.path) ? section : { ...section, disabled: true };
    }).filter(Boolean);
    
    useEffect(() => 
    {
        const fetchKeyMetrics = async () => 
        {
            setIsLoading(true);
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");

            const activeKeyMetrics = filteredMenuConfig
                ?.filter(({ key, disabled }) => key && !disabled)
                .map(({ key }) => key);
            
            await axios({
                method: "post",
                url: "/api/GetKeyMetrics",
                data: { 
                    pathname: pathname, 
                    activeKeyMetrics: activeKeyMetrics 
                },
                signal: abortController.signal
            })
            .then((response) => 
            {
                setIsLoading(false);
                const { status, data = {} } = response;

                if (status === 200) 
                {
                    setKeyMetrics((keyMetrics) => ({ ...keyMetrics, ...data }));
                }
            })
            .catch((error) => 
            {
                setIsLoading(false);
                console.log("Get Key Metrics Api: ", error);

                if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
                {
                    return;
                }

                const status = error?.response?.status;
                
                if (status === 403) 
                {
                    triggerSessionExpire();
                }
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }

        fetchKeyMetrics();

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    const handleNavigation = (menuItem) => 
    {
        navigate(
            menuItem.key === "deals" && [roles[13], roles[14], roles[15]].includes(currentUser?.role) 
                ? "/deals/polymers" 
                : menuItem.subMenu?.[0]?.path ?? menuItem.path
          );          
    }    

    return (
        <div className = "landing-page-background">
            <div className = "landing-page-container">
                <Alert 
                    show = {showAlert}
                    message = {alertMessage}
                    type = {alertType}
                    setShow = {setShowAlert}
                />

                <div className = "h-100 d-flex flex-column justify-content-center">
                    <Grid container sx = {{ padding: 3, justifyContent: 'center', alignItems: 'center', gap: '12px' }}>
                        {filteredMenuConfig.map((menuItem, index) => (
                            <MetricTile 
                                key = {index} 
                                isLoading = {isLoading}
                                menuItem = {menuItem} 
                                handleNavigation = {handleNavigation} 
                            />
                        ))}
                    </Grid>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;