import { useState, useEffect, useMemo } from 'react';
import { Sidebar } from 'react-pro-sidebar';
import { useLocation, useNavigate } from 'react-router-dom';
import { AiOutlineMenuFold, AiOutlineMenuUnfold, AiOutlineAudit } from 'react-icons/ai';
import { FaTableCells } from 'react-icons/fa6';
import { FaClipboardList, FaUserCog } from 'react-icons/fa';
import { RiPagesLine, RiUserSearchFill } from 'react-icons/ri';
import { IoDocumentsSharp } from 'react-icons/io5'
import { allowedRoutesByRole } from '../utils/routes';
import useTab from '../hooks/useTab';
import MenuList from '../components/MenuList';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import FullLogo from '../images/logo-full.png';
import Logo from '../images/logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/sidebar.css';

const SideBar = ({ currentUser, component, businessUnits = [], logOut = () => {} }) => 
{
  const [matches, setMatches] = useState(window.matchMedia("(max-width: 992px)").matches);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  /* AUTHORIZATION VARIABLES */
  const getAllowedRoutes = (role) => allowedRoutesByRole[role] || allowedRoutesByRole.default;
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  const allowedRoutes = useMemo(() => getAllowedRoutes(currentUser?.role), [currentUser?.role]);

  /* TAB VARIABLES */
  const { setActiveTab, setSelectedDeal, setSelectedPFI } = useTab();

  /* MOBILE VIEW VARIABLES */
  const [collapsed, setCollapsed] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] = useState(pathname);

  /* BUSINESS UNIT VARIABLES */
  const polymerBusinessUnit = businessUnits.find(businessUnit => businessUnit.value === "Polymers") || null;
  const sblcBusinessUnit = businessUnits.find(businessUnit => businessUnit.value === "SBLC") || null;
  
  /* MENU VARIABLES */
  const menuConfig = useMemo(() => 
  {
    const hasPolymers = Boolean(polymerBusinessUnit);
    const hasSBLC = Boolean(sblcBusinessUnit);
  
    return [
      {
        key: "deals",
        label: "Deals",
        icon: <FaClipboardList />,
        subMenu: [
          ...businessUnits
            .filter(businessUnit => !["Polymers", "SBLC"].includes(businessUnit.value))
            .map(businessUnit => ({
              path: `/deals/${businessUnit.value.toLowerCase()}`,
              label: businessUnit.value,
              businessUnit
            })),
          hasPolymers && hasSBLC && {
            key: "polymerDeals",
            label: "Polymers",
            subMenu: [
              { path: "/deals/polymers", label: "Polymers", businessUnit: polymerBusinessUnit },
              { path: "/deals/sblc", label: "Polymers - SBLC", businessUnit: sblcBusinessUnit }
            ]
          }
        ].filter(Boolean) // Remove `false` or `undefined` values
      },
      {
        key: "entities",
        label: "Entities",
        icon: <FaTableCells />,
        subMenu: [
          { path: "/entities/banks", label: "Banks" },
          { path: "/entities/counter-parties", label: "Counter Parties" },
          { path: "/entities/deals", label: "Deals" },
          { path: "/entities/deal-pfis", label: "Deal PFIs" },
          { path: "/entities/dropdowns", label: "Dropdowns" },
          { path: "/entities/file-types", label: "File Types" },
          { path: "/entities/products", label: "Products" },
          { path: "/entities/sblc", label: "SBLC" },
          { path: "/entities/tenors", label: "Tenors" },
          { path: "/entities/traders", label: "Traders" }
        ]
      },
      {
        key: "reports",
        label: "Reports",
        icon: <RiPagesLine />,
        subMenu: [
          { path: "/reports/deal-report", label: "Deal Report" },
          { path: "/reports/finance-report", label: "Finance Report" },
          { path: "/reports/morning-report", label: "Morning Report" }
        ]
      },
      {
        key: "kyc",
        label: "KYC",
        icon: <RiUserSearchFill />,
        subMenu: [
          { path: "/kyc/new-kyc", label: "New KYC" },
          { path: "/kyc/requests", label: "Requests" },
          { path: "/kyc/counter-party-list", label: "Counter Party List" }
        ]
      },
      {
        key: "audit",
        label: "Audit Trail",
        icon: <AiOutlineAudit />,
        subMenu: [
          { path: "/audit-trail/finance", label: "Finance" },
          { path: "/audit-trail/user", label: "User" }
        ]
      },
      {
        key: "documents",
        label: "Approve Documents",
        path: "/approve-documents",
        icon: <IoDocumentsSharp />
      },
      {
        key: "massUpdate",
        label: "Mass Update",
        path: "/mass-update",
        icon: <DynamicFeedIcon sx = {{ fontSize: collapsed ? '20px' : '17px' }} />
      },
      {
        key: "user-management",
        label: "User Management",
        path: "/user-management",
        icon: <ManageAccountsIcon sx = {{ fontSize: collapsed ? '20px' : '17px' }} />
      },
      {
        key: "account",
        label: "My Profile",
        icon: <FaUserCog />,
        subMenu: [{ path: "/account/edit-profile", label: "Edit Profile" }]
      }
    ];
  }, [businessUnits, polymerBusinessUnit, sblcBusinessUnit, collapsed]);
    
  /* SUB-MENU VARIABLES */
  const initialSubMenuState = {
    deals: pathname.includes("deals") && !pathname.includes("entities"),
    polymerDeals: pathname.includes("deals/polymers") || pathname.includes("deals/sblc"),
    entities: pathname.includes("entities"),
    reports: pathname.includes("reports"),
    kyc: pathname.includes("kyc"),
    audit: pathname.includes("audit-trail"),
    account: pathname.includes("account")
  };
  const [subMenuState, setSubMenuState] = useState(initialSubMenuState);

  useEffect(() => 
  {
    window
      .matchMedia("(max-width: 992px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() =>
  {
    setCollapsed(pathname === "/");
  }, [pathname]);

  useEffect(() => 
  {
    if (businessUnits.length > 0 && (pathname.includes("deals") && !pathname.includes("entities"))) 
    {
      const tokenizedPathname = pathname.split("/");
      const selectedBusinessUnit = tokenizedPathname.length !== 3
      ? tokenizedPathname.length === 4
        ? businessUnits.find(({ value }) => value.toLowerCase() === `${tokenizedPathname?.[2]?.toLowerCase()}/${tokenizedPathname?.[3]?.toLowerCase()}`)
        : businessUnits.find(businessUnit => businessUnit.value !== "Miscellaneous")
      : businessUnits.find(({ value }) => value.toLowerCase() === tokenizedPathname?.[2]?.toLowerCase());

      setActiveTab("Deals");
      setSelectedDeal(null);
      navigate(`/deals/${selectedBusinessUnit?.value?.toLowerCase()}`, {
        state: { selectedBusinessUnit }
      });
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [businessUnits, pathname]);

  useEffect(() => 
  {
    const timeout = setTimeout(() => 
    {
      setSubMenuState((previousSubMenuState) => ({
        deals: previousSubMenuState.deals && (selectedMenuItem.includes("deals") && !selectedMenuItem.includes("entities")),
        polymerDeals: previousSubMenuState.polymerDeals && (selectedMenuItem.includes("deals/polymers") || selectedMenuItem.includes("deals/sblc")),
        entities: previousSubMenuState.entities && selectedMenuItem.includes("entities"),
        reports: previousSubMenuState.reports && selectedMenuItem.includes("reports"),
        kyc: previousSubMenuState.kyc && selectedMenuItem.includes("kyc"),
        audit: previousSubMenuState.audit && selectedMenuItem.includes("audit-trail"),
        account: previousSubMenuState.account && selectedMenuItem.includes("account")
      }));
    }, 10000);
  
    return () => clearTimeout(timeout);
  }, [selectedMenuItem, subMenuState]);
  
  const handleMenuClick = (menuItem, state = {}, isNotSubMenu = false) => 
  {
    setSelectedMenuItem(menuItem);
    setActiveTab("Deals");
    setSelectedDeal(null);
    setSelectedPFI(null);
    navigate(menuItem, { state });
  
    if (isNotSubMenu) 
    {
      setSubMenuState({
        deals: false,
        polymerDeals: false,
        entities: false,
        reports: false,
        kyc: false,
        audit: false,
        account: false
      });
    }
  }

  const handleSubMenuClick = (menu) => 
  {
    setSubMenuState((previousSubMenuState) => ({
      deals: menu === "polymerDeals" ? true : menu === "deals" ? !previousSubMenuState.deals : false,
      polymerDeals: menu === "polymerDeals" ? !previousSubMenuState.polymerDeals : false,
      entities: menu === "entities" ? !previousSubMenuState.entities : false,
      reports: menu === "reports" ? !previousSubMenuState.reports : false,
      kyc: menu === "kyc" ? !previousSubMenuState.kyc : false,
      audit: menu === "audit" ? !previousSubMenuState.audit : false,
      account: menu === "account" ? !previousSubMenuState.account : false
    }));
  }

  const handleLogout = () =>
  {
    setActiveTab("Deals");
    setSelectedDeal(null);
    logOut();
  }

  return (
    <div className = "d-flex">
      <Sidebar collapsed = {matches || collapsed}>
        <div className = {`d-flex justify-content-between align-items-center mt-2 ${(matches || collapsed) && "flex-column-reverse"}`}>
          <div className = "logo-section">
            <img
              src = {collapsed ? Logo : FullLogo}
              alt = "Engro"
              className = "logo"
              onClick = {() => navigate("/")}
            />
          </div>

          {!matches && (
            <button
                className = "btn text-white menu-button m-0 d-flex align-items-center"
                onClick = {() => setCollapsed(!collapsed)}
              >
                {(collapsed) ? (
                  <AiOutlineMenuUnfold className = "header-icon" />
                ) : (
                  <AiOutlineMenuFold className = "header-icon" />
                )}
            </button>
          )}
        </div>

        <div className = "menu-list-container">
          <MenuList
            currentUser = {currentUser}
            collapsed = {matches || collapsed}
            menuConfig = {menuConfig}
            allowedRoutes = {allowedRoutes}
            pathname = {pathname}
            subMenuState = {subMenuState}
            handleMenuClick = {handleMenuClick}
            handleSubMenuClick = {handleSubMenuClick}
            handleLogout = {handleLogout}
          />
        </div>
      </Sidebar>

      <div className = {`content-section ${collapsed ? "collapsed" : ""}`}>
        {component ? component : ""}
      </div>
    </div>
  );
}

export default SideBar;