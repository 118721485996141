import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { roles } from '../../utils/roles';
import { getPermissions } from '../../lib/accessControl';
import { abortController } from '../../utils/abortController';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import withAuth from '../../lib/withAuth';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import FileUploader from '../../components/FileUploader';
import Backdrop from '@mui/material/Backdrop';
import Alert from '../../components/Alert';
import Loader from '../../components/Loader';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Form from '../../components/Form';
import axios from 'axios';
import 'react-phone-number-input/style.css';

const NewKYC = ({ logOut = () => {} }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const { pathname } = useLocation();

    /* AUTHENTICATION VARIABLES */
    const currentUser = useUser();

    /* AUTHORIZATION VARIABLES */
    const { isWritable = false } = getPermissions(currentUser?.role, pathname);
    const { isWritable: isDocumentWritable = false } = getPermissions(currentUser?.role, null, "documents");

    /* SESSION EXPIRY VARIABLES */
    const triggerSessionExpire = useSessionExpire();

    /* LIST VARIABLES */
    const [dropdowns, setDropdowns] = useState({});

    /* COUNTER PARTY VARIABLES */
    const [isCounterPartyDataLoading, setIsCounterPartyDataLoading] = useState(false);
    const [counterPartyFields, setCounterPartyFields] = useState([]);
    const [counterPartyData, setCounterPartyData] = useState([]);
    const [counterParties, setCounterParties] = useState([]);
    const [toggleCounterPartyData, setToggleCounterPartyData] = useState(false);

    /* FORM VARIABLES */
    const dataSchema = {
        Registered_Company_Name: null,
        Registered_Business_Address: "",
        Country_of_Incorporation: "",
        Key_Contact_Person_Name: "",
        Key_Contact_Person_Designation: "",
        Key_Contact_Person_Mobile: "",
        Key_Contact_Person_Email: "",
        Business_Partner_Additional_Info: "",
        Business_Relation_Nature: [],
        Documentation_Exceptions: "",
        Advance_Payment_To_Counterparty: ""
    };
    const [formData, setFormData] = useState(dataSchema);
    const formRef = useRef(null);
    const submitButtonRef = useRef(null);

    /* FILE VARIABLES */
    const [areFilesLoading, setAreFilesLoading] = useState(false);
    const [kycFileTypes, setKYCFileTypes] = useState([]);
    const [kycFiles, setKYCFiles] = useState([]);
    const [uploadedKYCFiles, setUploadedKYCFiles] = useState([]);

    /* MODAL VARIABLES */
    const [showAddCounterPartyModal, setShowAddCounterPartyModal] = useState(false);

    useEffect(() =>
    {
        const fetchLists = () =>
        {
            axios({
                method: "post",
                url: "/api/GetExternalKYCFileTypeList",
                data: { pathname },
                signal: abortController.signal
            })
            .then((response) => 
            {
                const { status, data } = response;
        
                if (status === 200) 
                {
                    setKYCFileTypes(data?.external_file_types || []);  
                }
            })
            .catch((error) => 
            {
                console.log("Get External File Type List Api: ", error);

                if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
                {
                    return;
                }

                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });

            axios({
                method: "post",
                url: "/api/GetBusinessUnitList",
                data: { pathname },
                signal: abortController.signal
            })
            .then((response) => 
            {
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const businessUnits = data?.business_units || [];
                    setDropdowns((previousDropdowns) => ({
                        ...previousDropdowns,
                        Business_Unit: businessUnits
                    }));
                }
            })
            .catch((error) => 
            {
                console.log("Get Business Units Api: ", error);

                if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
                {
                    return;
                }

                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }

        fetchLists();

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() =>
    {
        const fetchCounterPartyLists = async () =>
        {
            setIsCounterPartyDataLoading(true);

            await axios({
                method: "post",
                url: "/api/GetVendorsKYC",
                data: { pathname },
                signal: abortController.signal
            })
            .then((response) => 
            {
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const counterPartyData = data?.vendor_data || [];
                    const { rows: rowData = [], columns = [], data_types: dataTypes = {} } = counterPartyData;
                    const columnData = columns.filter((column) => !(["id", "isDeleted"]).includes(column)) || [];
                    const tempColumns = [];

                    for (let i = 0; i < columnData.length; i++) 
                    {
                        if (dataTypes[columnData[i]].includes("object") || dataTypes[columnData[i]].includes("date")) 
                        {
                            tempColumns.push({
                                field: columnData[i],
                                type: 'string'
                            });
                        } 
                        else if (dataTypes[columnData[i]] !== "object") 
                        {
                            tempColumns.push({
                                field: columnData[i],
                                type: 'number'
                            });
                        }
                    }      

                    setCounterPartyFields(tempColumns);
                    setCounterPartyData(rowData);
                } 
                else 
                {
                    setIsCounterPartyDataLoading(false);
                    setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => 
            {
                console.log("Get Counter Party Data Api: ", error);
                setIsCounterPartyDataLoading(false);

                if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
                {
                    return;
                }

                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });

            await axios({
                method: "post",
                url: "/api/GetVendorList",
                data: { pathname },
                signal: abortController.signal
            })
            .then((response) => 
            {
                setIsCounterPartyDataLoading(false);
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const counterParties = data?.vendors || [];
                    setCounterParties(counterParties);
                }
            })
            .catch((error) => 
            {
                console.log("Get Filtered Sellers/Counter Parties Api: ", error);
                setIsCounterPartyDataLoading(false);

                if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
                {
                    return;
                }
                
                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }

        fetchCounterPartyLists();
        
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [toggleCounterPartyData]);

    const handleChange = (field, value) =>
    {
        setFormData((previousFormData) => ({
            ...previousFormData,
            [field]: typeof newValue === "string" ? value.trim() === "" ? null : value : value ? value : null
        }));
    }

    const handleFileUpload = async (id) => 
    {
        setAreFilesLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
      
        const fileFormData = new FormData();
      
        uploadedKYCFiles.forEach((file, index) => 
        {
            const fileObj = {
                DealPFIId: id,
                fileName: file.name,
                docType: "KYC_Documentation_And_Requests",
                fileExtension: file.name.split(".").pop(),
                fileType: file.docType
            };
            
            fileFormData.append(`file${index}`, JSON.stringify(fileObj));
            fileFormData.append(`file${index}`, file);
        });    
    
        fileFormData.append("pathname", pathname);

        await axios({
            method: "post",
            url: "/api/UploadDocument",
            data: fileFormData,
            signal: abortController.signal
        })
        .then((response) => 
        {
            setAreFilesLoading(false);
            const { status, data } = response;
      
            if (status === 200)
            {
                formRef?.current?.reset();
                setFormData((previousFormData) => ({
                    ...previousFormData,
                    Registered_Company_Name: null,
                    Registered_Business_Address: "",
                    Country_of_Incorporation: "",
                    Key_Contact_Person_Name: "",
                    Key_Contact_Person_Designation: "",
                    Key_Contact_Person_Mobile: "",
                    Key_Contact_Person_Email: "",
                    Business_Partner_Additional_Info: "",
                    Business_Relation_Nature: [],
                    Documentation_Exceptions: "",
                    Advance_Payment_To_Counterparty: ""
                }));
                setUploadedKYCFiles([]);
                setAlertMessage("KYC request form submitted successfully.");
                setAlertType("success");
                setShowAlert(true);
            }
            else if (status === 202)
            {
                setAlertMessage(data?.data || "An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);        
            }
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Upload KYC Documents Api: ", error);
            setAreFilesLoading(false);
            
            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }

            const status = error?.response?.status;
      
            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleAddCounterParty = async (newCounterParty) =>
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
    
        await axios({
            method: "post",
            url: "/api/AddVendor",
            data: {
                pathname: pathname,
                vendor_data: newCounterParty
            },
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
        
            if (status === 200) 
            {
                handleCloseModal();
                setAlertMessage("New counter party added successfully.");
                setAlertType("success");
                setShowAlert(true);
                setTimeout(() => setToggleCounterPartyData(!toggleCounterPartyData), 2000);
            } 
            else if (status === 202)
            {
                setAlertMessage(data?.data);
                setAlertType("error");
                setShowAlert(true);
            }
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Add Counter Party Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
            
            const status = error?.response?.status;
        
            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleSubmit = async (event) =>
    {
        event.preventDefault();

        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
    
        let updatedFormData = { 
            ...formData,
            User_Id: currentUser?.id,
            pathname: pathname
        };
    
        updatedFormData = Object.fromEntries(Object.entries(updatedFormData).map(([key, value]) => [
            key,
            typeof value === "object" && value?.hasOwnProperty("value") ? value.id : value
        ]));
        
        await axios({
            method: "post",
            url: "/api/AddKYC",
            data: updatedFormData,
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
        
            if (status === 200) 
            {
                if (uploadedKYCFiles.length > 0) 
                {
                    handleFileUpload(data?.id);
                } 
                else 
                {
                    formRef?.current?.reset();
                    setFormData((previousFormData) => ({
                        ...previousFormData,
                        Registered_Company_Name: null,
                        Registered_Business_Address: "",
                        Country_of_Incorporation: "",
                        Key_Contact_Person_Name: "",
                        Key_Contact_Person_Designation: "",
                        Key_Contact_Person_Mobile: "",
                        Key_Contact_Person_Email: "",
                        Business_Partner_Additional_Info: "",
                        Business_Relation_Nature: [],
                        Documentation_Exceptions: "",
                        Advance_Payment_To_Counterparty: ""
                    }));
                    setUploadedKYCFiles([]);
                    setAlertMessage("KYC request form submitted successfully.");
                    setAlertType("success");
                    setShowAlert(true);
                }
            } 
            else if (status === 202)
            {
                setAlertMessage(data?.data);
                setAlertType("error");
                setShowAlert(true);
            }
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Add KYC Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
            
            const status = error?.response?.status;
        
            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleCloseModal = () =>
    {
        setShowAddCounterPartyModal(false);
    }

    return (
        <div className = "content-container d-flex flex-column container kyc">
            <Alert 
                show = {showAlert}
                message = {alertMessage}
                type = {alertType}
                setShow = {setShowAlert}
            />

            <Backdrop
                sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open = {isLoading}
            >
                <Loader/>
            </Backdrop>

            <h4 className = "page-heading m-0">
                New KYC
            </h4>

            <form ref = {formRef} className = "kyc-form" onSubmit = {handleSubmit}>
                <div>
                    <div className = "d-flex align-items-center justify-content-between mb-3">
                        <h6 className = "mb-0">Company Information</h6>

                        <button
                            type = "button"
                            variant = "contained"
                            className = "btn btn-secondary"
                                disabled = {!isWritable || isCounterPartyDataLoading} 
                                onClick = {() => setShowAddCounterPartyModal(true)}
                            >
                            Add New Counter Party
                        </button>
                    </div>

                    <Grid
                        container
                        spacing = {2}
                        className = "align-items-start autocomplete mb-3"
                    >
                        <Grid size = {{ xs: 4 }}>
                            <VirtualizedAutocomplete
                                isLoading = {isCounterPartyDataLoading}
                                isMultiple = {false}
                                isObject = {true}
                                isRequired = {true}
                                isWritable = {isWritable}
                                filterOn = "Registered Name (Company)"
                                options = {counterParties}
                                selectedOptions = {formData?.Registered_Company_Name || null}
                                handleSelectChange = {(filterOn, newValue) => handleChange("Registered_Company_Name", newValue)}
                            />
                        </Grid>

                        <Grid size = {{ xs: 4 }}>
                            <TextField
                                label = "Registered Business Address"
                                id = "Registered_Business_Address"
                                value = {counterPartyData?.find((counterParty) => counterParty.id === formData?.Registered_Company_Name?.id)?.Address || ""}
                                disabled
                                slotProps = {{
                                    inputLabel: {
                                        shrink: counterPartyData?.some((counterParty) => counterParty.id === formData?.Registered_Company_Name?.id)
                                    }
                                }}
                            />
                        </Grid>

                        <Grid size = {{ xs: 4 }}>
                            <TextField
                                label = "Country of Incorporation"
                                id = "Country_of_Incorporation"
                                value = {counterPartyData?.find((counterParty) => counterParty.id === formData?.Registered_Company_Name?.id)?.Country || ""}
                                disabled
                                slotProps = {{
                                    inputLabel: {
                                        shrink: counterPartyData?.some((counterParty) => counterParty.id === formData?.Registered_Company_Name?.id)
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                    <h6>Business Relationship Details</h6>
                    <Grid
                        container
                        spacing = {2}
                        className = "align-items-start autocomplete mb-3"
                    >
                        <Grid size = {{ xs: 4 }}>
                            <VirtualizedAutocomplete
                                isMultiple = {true}
                                isObject = {false}
                                isWritable = {isWritable}
                                isRequired = {true}
                                filterOn = "Nature of Business Relation"
                                options = {["Customer", "Supplier"]}
                                selectedOptions = {formData?.Business_Relation_Nature || []}
                                handleSelectChange = {(filterOn, newValue) => handleChange("Business_Relation_Nature", newValue)}
                            />
                        </Grid>
                    </Grid>

                    <h6>Documentation & Requests</h6>
                    <Grid
                        container
                        spacing = {1}
                        className = "align-items-start row-gap-2 mb-3"
                    >
                        <Grid size = {{ xs: 4 }}>
                            <TextField
                                label = "Documentation Exceptions Being Requested"
                                id = "Documentation_Exceptions"
                                value = {formData.Documentation_Exceptions || ""}
                                onChange = {(event) => handleChange("Documentation_Exceptions", event.target.value)}
                                slotProps = {{ 
                                    input: {
                                        readOnly: !isWritable
                                    }
                                }}
                            />
                        </Grid>

                        <Grid size = {{ xs: 12 }} marginTop = {1}>
                            <FileUploader
                                isWritable = {isDocumentWritable}
                                isLoading = {areFilesLoading}
                                isMultiple = {true}
                                isKYC = {true}
                                pathname = {pathname}
                                uploadText = "Please upload customer response email to our KYC request."
                                files = {kycFiles}
                                uploadedFiles = {uploadedKYCFiles}
                                allowFileTypeSelection = {true}
                                kycFileTypes = {kycFileTypes}
                                setIsLoading = {setAreFilesLoading}
                                setShowAlert = {setShowAlert}
                                setAlertMessage = {setAlertMessage}
                                setAlertType = {setAlertType}
                                setFiles = {setKYCFiles}
                                setUploadedFiles = {setUploadedKYCFiles}
                                logOut = {logOut}
                            />
                        </Grid>
                    </Grid>

                    <h6>Other Information</h6>
                    <Grid
                        container
                        spacing = {2}
                        className = "align-items-start mb-3"
                    >
                        <Grid size = {{ xs: 4 }}>
                            <TextField
                                label = "Are we paying any advance to counterparty?"
                                id = "Advance_Payment_To_Counterparty"
                                value = {formData.Advance_Payment_To_Counterparty || ""}
                                onChange = {(event) => handleChange("Advance_Payment_To_Counterparty", event.target.value)}
                                slotProps = {{ 
                                    input: {
                                        readOnly: !isWritable
                                    }
                                }}
                            />
                        </Grid>

                        <Grid size = {{ xs: 12 }}>
                            <TextField
                                label = "Any other important information regarding business partner?"
                                id = "Business_Partner_Additional_Info"
                                multiline
                                rows = {3}
                                value = {formData.Business_Partner_Additional_Info || ""}
                                onChange = {(event) => handleChange("Business_Partner_Additional_Info", event.target.value)}
                                slotProps = {{ 
                                    input: {
                                        readOnly: !isWritable
                                    }
                                }}
                            />
                        </Grid>
                    </Grid>

                    <button ref = {submitButtonRef} type = "submit" className = "hidden"></button>
                </div>
            </form>
            
            <div className = "submit-buttons pt-3">
                <button
                    type = "button"
                    variant = "contained"
                    disabled = {!isWritable || isLoading}
                    className = "btn btn-secondary custom-btn"
                    onClick = {() => submitButtonRef?.current?.click()}
                >
                    Submit
                </button>
            </div>

            {showAddCounterPartyModal && (
                <Form
                    show = {showAddCounterPartyModal}
                    title = "Counter Party"
                    pathname = {pathname}
                    columns = {counterPartyFields}
                    selectedRow = {{}}
                    dropdowns = {dropdowns}
                    rowType = "Add"
                    setIsLoading = {setIsLoading}
                    setShowAlert = {setShowAlert}
                    setAlertMessage = {setAlertMessage}
                    setAlertType = {setAlertType}
                    handleSubmitAddedRow = {handleAddCounterParty}
                    handleClose = {handleCloseModal}
                    logOut = {logOut}
                />
            )}
        </div>
    );
}

export default withAuth(NewKYC)([
    roles[4], 
    roles[5], 
    roles[6], 
    roles[7], 
    roles[10], 
    roles[11], 
    roles[12], 
    roles[13], 
    roles[14],
    roles[15], 
    roles[16], 
    roles[17],
    roles[18]
]);