import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { roles } from '../../utils/roles';
import { getPermissions } from '../../lib/accessControl';
import { abortController } from '../../utils/abortController';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import MUIDataTable from '../../components/MUIDataTable';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from '../../components/Alert';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Loader from '../../components/Loader';
import withAuth from '../../lib/withAuth';
import axios from 'axios';

const DealPFI = ({ logOut = () => {} }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const { pathname } = useLocation();

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* AUTHORIZATION VARIABLES */
  const { isWritable = false } = getPermissions(currentUser?.role, pathname);

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();
  
  /* DATATABLE VARIABLES */
  const [columnData, setColumnData] = useState([]);
  const [columnDataTypes, setColumnDataTypes] = useState({});
  const [columnWidths, setColumnWidths] = useState({});
  const [rows, setRows] = useState([]);
  const [toggleDisplayData, setToggleDisplayData] = useState(false);

  /* DATATABLE ROW VARIABLES */
  const [rowId, setRowId] = useState(null);
  const [rowStatus, setRowStatus] = useState(null);
  const [dealPFIDisabledReason, setDealPFIDisabledReason] = useState(null);

  /* MODAL VARIABLES */
  const [showDisableModal, setShowDisableModal] = useState(false);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
      setRowId(null);
      setRowStatus(null);

      await axios({
        method: "get",
        url: "/api/GetDealPFIs",
        signal: abortController.signal
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;
      
        if (status === 200) 
        {
          const dealPFIData = data?.deal_pfi_data || {};
          const { rows: rowData = [], columns = [], data_types: dataTypes = {}, max_column_lengths: widths = {} } = dealPFIData;
          const columnData = columns.filter((column) => !(column === "id" || column === "isDeleted")) || [];

          setColumnData(columnData);
          setColumnDataTypes(dataTypes);
          setColumnWidths(widths);
          setRows(rowData);
        } 
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      { 
        console.log("Update Deal PFI Api: ", error);
        setIsLoading(false);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    fetchData();
    
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [toggleDisplayData]);

  const handleRecordStatusChangeWrapper = (id, status, flag) => 
  {
    setRowId(id);
    setRowStatus(status);
  
    if (status === 1) 
    {
        setShowDisableModal(true);
    } 
    else 
    {
        handleRecordStatusChange(id, status);
    }
  }

  const handleRecordStatusChange = useCallback(async (id, status) => 
  {
    setShowDisableModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/api/SetDealPFIStatus",
      data: {
        id: rowId || id,
        status: rowStatus || status,
        Deal_PFI_Disabled_Reason: (rowStatus || status) === 1 ? dealPFIDisabledReason : null
      },
      signal: abortController.signal
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setToggleDisplayData(!toggleDisplayData);
        setDealPFIDisabledReason(null)
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Set Deal PFI Status Api: ", error);
      setIsLoading(false);

      if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
      {
        return;
      }
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rowId, rowStatus, dealPFIDisabledReason]);

  const handleCloseModal = () => 
  {
    setShowDisableModal(false);
  }

  return (
    <div className = "content-container d-flex flex-column container entities">
      <Alert
        show = {showAlert}
        message = {alertMessage}
        type = {alertType}
        setShow = {setShowAlert}
      />

      <h4 className = "page-heading m-0">
        Deal PFIs
      </h4>

      <div>
        <Backdrop
          sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open = {isLoading}
          >
          <Loader/>
        </Backdrop>

        <MUIDataTable
          title = "Deal PFI"
          isWritable = {isWritable}
          pathname = {pathname}
          columnData = {columnData}
          columnDataTypes = {columnDataTypes}
          columnWidths = {columnWidths}
          rows = {rows}
          files = {[]}
          isEditable = {false}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          handleRecordStatusChange = {handleRecordStatusChangeWrapper}
          logOut = {logOut}
        />
      </div>

      <Modal show = {showDisableModal} onHide = {handleCloseModal} centered = {true}>
        <Modal.Header closeButton = {true}>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>Disabling the PFI will remove it from the list of available PFIs.</span>
          <Grid container spacing = {2} className = "align-items-start autocomplete mt-3">
            <Grid size = {{ xs: 12 }} className = "form-text-field">
              <TextField
                label = "Deal Cancelled Reason"
                id = "deal_cancelled_reason"
                multiline
                rows = {3}
                value = {dealPFIDisabledReason || ""}
                onChange = {(event) => setDealPFIDisabledReason(event.target.value)}
                slotProps = {{ 
                  input: { readOnly: !isWritable }
                }}
              />
            </Grid>
          </Grid>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button
            variant = "contained"
            size = "small"
            onClick = {handleRecordStatusChange}
          >
            Disable
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default withAuth(DealPFI)([
  roles[4], 
  roles[5], 
  roles[6], 
  roles[7], 
  roles[8], 
  roles[10], 
  roles[11], 
  roles[12], 
  roles[13], 
  roles[14],
  roles[15], 
  roles[16], 
  roles[17], 
  roles[18], 
  roles[19]
]);