import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { roles } from '../../utils/roles';
import useSessionExpire from '../../hooks/useSessionExpire';
import Button from 'react-bootstrap/Button';
import Box from '@mui/material/Box';
import Alert from 'react-bootstrap/Alert';
import Logo from '../../images/logo-full.png';
import withAuth from '../../lib/withAuth';
import axios from 'axios';
import '../../styles/authentication.css';

const SetNewPassword = ({ currentUser, logOut = () => {} }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [alertError, setAlertError] = useState(null);
    const navigate = useNavigate();

    /* SESSION EXPIRY VARIABLES */
    const triggerSessionExpire = useSessionExpire();

    /* PASSWORD VARIABLES */
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const handleToggleShowPassword = (passwordType) => 
    {
        if (passwordType === "Current Password")
        {
            setShowCurrentPassword(previousShowCurrentPassword => !previousShowCurrentPassword);
        }
        else if (passwordType === "New Password")
        {
            setShowNewPassword(previousShowNewPassword => !previousShowNewPassword);
        }
        else if (passwordType === "Confirm Password")
        {
            setShowConfirmPassword(previousShowConfirmPassword => !previousShowConfirmPassword);
        }
    }

    const handleChange = (event, passwordType) => 
    {
        if (passwordType === "Current Password")
        {
            setCurrentPassword(event.target.value);
        }
        else if (passwordType === "New Password")
        {
            setNewPassword(event.target.value);
        }
        else if (passwordType === "Confirm Password")
        {
            if (typeof event.target.value !== "undefined" && event.target.value !== "") 
            {
                if (newPassword !== event.target.value) 
                {
                    event.target.setCustomValidity("Passwords do not match.");
                } 
                else 
                {
                    event.target.setCustomValidity("");
                }
            
                setConfirmPassword(event.target.value);
            }
        }
    }

    const handleSubmit = async (event) => 
    {
        event.preventDefault();

        setIsLoading(true);
        setAlertError(null);

        await axios({
            method: "post",
            url: "/api/SetNewPassword",
            data: {
                currentPassword: currentPassword,
                newPassword: confirmPassword,
                userID: currentUser?.id
            }
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;

            if (status === 200) 
            {
                navigate("/");
            } 
            else if (status === 202)
            {
                setAlertError(data?.data);
            }
            else
            {
                setAlertError("An error occurred while processing your request. Please try again later or contact the site administrator.");
            }
        })
        .catch((error) => 
        {
            console.log("Set New Password Api: ", error);
            setIsLoading(false);

            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertError(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    return (
        <div className = "login-background">
            <Box className = "login-container">
                <div>
                    <img src = {Logo} alt = "Engro" className = "login-logo" />
                    {alertError && <Alert variant = "danger">{alertError}</Alert>}

                    <h6>Set New Password</h6>
                
                    <div className = "login-form change-password">
                        <form 
                            className = "d-flex flex-column gap-3"
                            onSubmit = {handleSubmit}
                        >
                            <div className = "form-group password-container">
                                <input
                                    type = {showCurrentPassword ? "text" : "password"}
                                    id = "currentPassword"
                                    name = "currentPassword"
                                    className = "form-control"
                                    placeholder = "Enter Current Password"
                                    onChange = {(event) => handleChange(event, "Current Password")}
                                    required
                                />

                                {showCurrentPassword ? (
                                    <AiOutlineEyeInvisible className = "show-hide-password" title = "Hide Password" onClick = {() => handleToggleShowPassword("Current Password")} />
                                ) : (
                                    <AiOutlineEye className = "show-hide-password" title = "Show Password" onClick = {() => handleToggleShowPassword("Current Password")} />
                                )}
                            </div>

                            <div className = "form-group password-container">
                                <input
                                    type = {showNewPassword ? "text" : "password"}
                                    id = "password"
                                    name = "password"
                                    className = "form-control"
                                    placeholder = "Enter New Password"
                                    onChange = {(event) => handleChange(event, "New Password")}
                                    required
                                />

                                {showNewPassword ? (
                                    <AiOutlineEyeInvisible className = "show-hide-password" title = "Hide Password" onClick = {() => handleToggleShowPassword("New Password")} />
                                ) : (
                                    <AiOutlineEye className = "show-hide-password" title = "Show Password" onClick = {() => handleToggleShowPassword("New Password")} />
                                )}
                            </div>

                            <div className = "form-group password-container">
                                <input
                                    type = {showConfirmPassword ? "text" : "password"}
                                    id = "confirmPassword"
                                    name = "confirmPassword"
                                    className = "form-control"
                                    placeholder = "Enter Confirm Password"
                                    onChange = {(event) => handleChange(event, "Confirm Password")}
                                    required
                                />

                                {showConfirmPassword ? (
                                    <AiOutlineEyeInvisible className = "show-hide-password" title = "Hide Password" onClick = {() => handleToggleShowPassword("Confirm Password")} />
                                ) : (
                                    <AiOutlineEye className = "show-hide-password" title = "Show Password" onClick = {() => handleToggleShowPassword("Confirm Password")} />
                                )}
                            </div>

                            <Button
                                type = "submit"
                                disabled = {isLoading}
                                className = "login-button btn-secondary"
                            >
                                {isLoading ? (
                                    <div className = "spinner-border text-info" role = "status" />
                                ) : (
                                    "Save"
                                )}
                            </Button>
                        </form>
                    </div>
                </div>
            </Box>
        </div>
    );
}

export default withAuth(SetNewPassword)([
    roles[1], 
    roles[2], 
    roles[3], 
    roles[4], 
    roles[5], 
    roles[6], 
    roles[7], 
    roles[8], 
    roles[9], 
    roles[10], 
    roles[11], 
    roles[12], 
    roles[13], 
    roles[14], 
    roles[15], 
    roles[16], 
    roles[17], 
    roles[18], 
    roles[19]
]);