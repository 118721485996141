import { forwardRef, useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { abortController } from '../utils/abortController';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import axios from 'axios';

const ReportDownloader = forwardRef(({ isBusinessUnitRequired = false, apiMethod = "", api = "", apiData = {}, buttonType = "button", buttonText = "", fileName = "", isDisabled = false, isValidateForm = false, toggleDocumentList = false, setToggleDocumentList = () => {}, handleFormValidation = () => {}, handleResetModal = () => {}, setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, triggerSessionExpire = () => {}, logOut = () => {} }, ref) => 
{
  /* ERROR VARIABLES */
  const [missingRequiredFields, setMissingRequiredFields] = useState([]);

  /* MODAL VARIABLES */
  const [showRequiredFieldsModal, setShowRequiredFieldsModal] = useState(false);

  const handleDownloadWrapper = (event) => 
  {
    const allowDownload = isBusinessUnitRequired ? apiData?.businessUnit : true;

    if (allowDownload)
    {
      event.preventDefault();

      if (isValidateForm) 
      {
        if (!handleFormValidation()) 
        {
          return;
        }
      }

      handleDownload();
    }
  }
  
  const handleDownload = async () => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: apiMethod,
      url: api,
      responseType: "blob",
      timeout: 240000,
      data: apiData,
      signal: abortController.signal
    })
    .then(async (response) => 
    {
      setIsLoading(false);
      const { status, data, headers } = response;
    
      if (status === 200) 
      {
        const blob = new Blob([data], { type: data.type });
        const blobURL = window.URL.createObjectURL(blob);
    
        // Extract the filename from the Content-Disposition header
        const contentDisposition = headers['content-disposition'];
        let headerFileName = "";

        if (contentDisposition) 
        {
          const match = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
        
          if (match != null && match[1]) 
          {
            headerFileName = match[1].replace(/['"]/g, '').trim();
          }
        }
    
        const anchor = document.createElement("a");
        anchor.download = headerFileName || fileName;
        anchor.href = blobURL;
        anchor.dataset.downloadurl = [data.type, anchor.download, anchor.href].join(":");
        anchor.click();
    
        setTimeout(() => 
        {
          URL.revokeObjectURL(blobURL);
        }, 100);

        setToggleDocumentList(!toggleDocumentList);
        handleCloseModal();
      } 
      else if (status === 202) 
      {
        const reader = new FileReader();
        const errorData = await new Promise((resolve) => 
        {
          reader.onload = () => resolve(reader.result);
          reader.readAsText(data);
        });
        const error = JSON.parse(errorData)?.data;

        if (typeof(error) === "object")
        {
          setMissingRequiredFields(error);
          setShowRequiredFieldsModal(true);
        }
        else if (typeof(error) === "string")
        {
          const isBusinessUnitRequired = JSON.parse(errorData)?.is_report;

          setAlertMessage(error);
          setAlertType(isBusinessUnitRequired ? "info" : "error");
          setShowAlert(true);
        }
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Download Report Api: ", error);
      setIsLoading(false);
      
      if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
      {
        return;
      }

      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleCloseModal = () => 
  {
    setShowRequiredFieldsModal(false);
    handleResetModal();
  }

  return (
    <>
      <button  
        ref = {ref}
        type = {buttonType} 
        className = {`btn csv_button ${isDisabled ? 'disabled' : ''} ${ref ? 'hidden' : ''}`} 
        onClick = {handleDownloadWrapper}
      >
        <FiDownload />
        {buttonText}
      </button>

      <Modal show = {showRequiredFieldsModal} onHide = {handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Missing Required Fields</Modal.Title>
        </Modal.Header>
        <Modal.Body className = "missing-required-fields-container">
          <div className = "missing-required-fields grid-container">
            <div className = "grid-header">
              <span>Missing Fields</span>
              <span>Tab</span>
              <span>Section</span>
            </div>

            {missingRequiredFields.map((missingRequiredField, index) => (
              <div key = {index} className = "grid-row">
                <span>{missingRequiredField.missing_field}</span>
                <span>{missingRequiredField.tab}</span>
                <span>{missingRequiredField.section}</span>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default ReportDownloader;