import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { roles } from '../../utils/roles';
import { getPermissions } from '../../lib/accessControl';
import withAuth from '../../lib/withAuth';
import useUser from '../../hooks/useUser';
import useTab from '../../hooks/useTab';
import Alert from '../../components/Alert';
import Backdrop from '@mui/material/Backdrop';
import Deals from './Deals';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PreShipment from './PreShipment';
import PostShipment from './PostShipment';
import Loader from '../../components/Loader';

const BusinessUnit = ({ logOut = () => {} }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const location = useLocation();
  const { selectedBusinessUnit } = location.state || {};
  const { isFertilizersAndPetroChemicalsDeal, isFoodsOrPolymersDeal, isFoodsPolymersOrSBLCDeal, isSBLCDeal } = useMemo(() => 
  {
    const id = selectedBusinessUnit?.id;
    
    return {
      isFertilizersAndPetroChemicalsDeal: [2, 3, 5].includes(id),
      isFoodsOrPolymersDeal: [1, 4].includes(id),
      isFoodsPolymersOrSBLCDeal: [1, 4, 6].includes(id),
      isSBLCDeal: id === 6
    };

  }, [selectedBusinessUnit]);
  const debounce = require('lodash.debounce');

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* AUTHORIZATION VARIABLES */
  const { isWritable = false } = getPermissions(currentUser?.role, location?.pathname);
  
  /* TAB VARIABLES */
  const { 
    activeTab, 
    selectedTab,
    selectedDeal, 
    selectedPFI,
    selectedTranche,
    setActiveTab, 
    setSelectedTab,
    setSelectedDeal, 
    setSelectedPFI,
    setSelectedTranche
  } = useTab();

  /* DATA VARIABLES */
  const [isUpdated, setIsUpdated] = useState(false);

  /* DATA FETCH VARIABLES */
  const [isDocumentToBeGenerated, setIsDocumentToBeGenerated] = useState(false);

  /* DIALOG VARIABLES */
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  /* REFERENCE VARIABLES */
  const preShipmentRef = useRef(null);
  const postShipmentRef = useRef(null);
  const debouncedHandleTabChange = useRef(null);

  useEffect(() => 
  {
    debouncedHandleTabChange.current = debounce((tab) => 
    {
      if (isUpdated)
      {
        setSelectedTab(tab);
        setShowConfirmationDialog(true);
      } 
      else 
      {
        handleConfirmTabChange(tab);
      }
    }, 200);
  
    return () => {
      debouncedHandleTabChange.current.cancel();
    };

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isUpdated]);  

  const handleConfirmTabChange = (tab) =>
  {
    if (tab === "Deals") 
    {
      setSelectedDeal(null);
      setSelectedPFI(null);
    }

    setActiveTab(() => tab);
    setSelectedTab((previousSelectedTab) => (previousSelectedTab === tab ? previousSelectedTab : null));
    setIsUpdated(false);
    setIsDocumentToBeGenerated(false);
  }

  const handleTabChange = useCallback((tab) => 
  {
    if (tab === "Deals" || selectedPFI) 
    {
      debouncedHandleTabChange.current(tab);
    }
  }, [selectedPFI]);

  const handleSaveChanges = async () =>
  {
    setShowConfirmationDialog(false);
  
    try 
    {
      switch (activeTab) 
      {
        case "Pre-Shipment":
          if (preShipmentRef.current) 
          {
            await preShipmentRef.current.handleSubmit();
          }
          break;
        case "Post-Shipment":
          if (postShipmentRef.current) 
          {
            await postShipmentRef.current.handleSubmit();
          }
          break;
        default:
          break;
      }
  
      if (!isDocumentToBeGenerated)
      {
        handleConfirmTabChange(selectedTab);
      }
    } 
    catch (error) 
    {
      console.error("Save changes api failed due to unknown reasons.");
    }
  }  

  const handleDiscardChanges = () => 
  {
    setShowConfirmationDialog(false);
    handleConfirmTabChange(selectedTab);
  }

  const handleCloseDialog = () =>
  {
    setShowConfirmationDialog(false);
  }

  return (
    <div className = "business-unit container position-relative">
      <Alert
        show = {showAlert}
        message = {alertMessage}
        type = {alertType}
        setShow = {setShowAlert}
      />

      <Dialog open = {showConfirmationDialog} onClose = {handleCloseDialog}>
        <DialogContent>
          <DialogContentText className = "text-center">
            Save changes before proceeding?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <button
            type = "button"
            variant = "contained"
            className = "btn btn-secondary custom-btn"
            onClick = {handleSaveChanges}
          >
            Save
          </button>

          {isDocumentToBeGenerated ? (
            <button
              type = "button"
              variant = "contained"
              className = "btn bg-secondary custom-btn"
              onClick = {handleCloseDialog}
            >
              Cancel
            </button>
          ) : (
            <button
              type = "button"
              variant = "contained"
              className = "btn btn-danger custom-btn"
              onClick = {handleDiscardChanges}
            >
              Discard
            </button>
          )}
        </DialogActions>
      </Dialog>

      <div>
        <Backdrop
          sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open = {isLoading}
        >
          <Loader/>
        </Backdrop>

        <Tab.Container activeKey = {activeTab} defaultActiveKey = "Deals">
          <Nav variant = "tabs" className = "deal-tabs w-100 d-flex align-items-center justify-content-between">
            <Nav.Item>
              {(selectedDeal && activeTab !== "Deals") && (
                <Nav.Link><b>{selectedDeal.value}{selectedPFI ? selectedPFI.value : ""}</b></Nav.Link>
              )}
            </Nav.Item>

            <Nav.Item className = "d-flex align-items-center">
              <Nav.Item>
                <Nav.Link 
                  eventKey = "Deals" 
                  disabled = {isLoading && activeTab !== "Deals"}
                  onClick = {() => handleTabChange("Deals")}
                >
                  Deals
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link 
                  eventKey = "Pre-Shipment" 
                  disabled = {isLoading && activeTab !== "Pre-Shipment"}
                  onClick = {() => handleTabChange("Pre-Shipment")}
                >
                  Pre-Shipment
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link 
                  eventKey = "Post-Shipment" 
                  disabled = {isLoading && activeTab !== "Post-Shipment"}
                  onClick = {() => handleTabChange("Post-Shipment")}
                >
                  Post-Shipment
                </Nav.Link>
              </Nav.Item>
            </Nav.Item>
          </Nav>
        </Tab.Container>

        <div className = {`content-container p-0 d-flex flex-column deals ${selectedDeal ? "deal-selected" : ""}`}>
          {(activeTab === "Deals") ? (
            <Deals
              isWritable = {isWritable}
              isLoading = {isLoading}
              selectedBusinessUnit = {selectedBusinessUnit?.id}
              setActiveTab = {setActiveTab}
              setSelectedDeal = {setSelectedDeal}
              setSelectedPFI = {setSelectedPFI}
              setIsLoading = {setIsLoading}
              setShowAlert = {setShowAlert}
              setAlertMessage = {setAlertMessage}
              setAlertType = {setAlertType}
              logOut = {logOut}
            />
          ) : (activeTab === "Pre-Shipment") ? (
            <PreShipment
              ref = {preShipmentRef}
              isWritable = {isWritable}
              isLoading = {isLoading}
              isFertilizersAndPetroChemicalsDeal = {isFertilizersAndPetroChemicalsDeal}
              isFoodsOrPolymersDeal = {isFoodsOrPolymersDeal}
              isFoodsPolymersOrSBLCDeal = {isFoodsPolymersOrSBLCDeal}
              isSBLCDeal = {isSBLCDeal}
              selectedBusinessUnit = {selectedBusinessUnit?.id}
              selectedDeal = {selectedDeal?.id}
              selectedPFI = {selectedPFI?.id}
              setIsUpdated = {setIsUpdated}
              setActiveTab = {setActiveTab}
              setIsLoading = {setIsLoading}
              setShowAlert = {setShowAlert}
              setAlertMessage = {setAlertMessage}
              setAlertType = {setAlertType}
              logOut = {logOut}
            />
          ) : (activeTab === "Post-Shipment") && (
            <PostShipment
              ref = {postShipmentRef}
              isWritable = {isWritable}
              isLoading = {isLoading}
              isFoodsOrPolymersDeal = {isFoodsOrPolymersDeal}
              isFoodsPolymersOrSBLCDeal = {isFoodsPolymersOrSBLCDeal}
              isSBLCDeal = {isSBLCDeal}
              isUpdated = {isUpdated}
              selectedBusinessUnit = {selectedBusinessUnit?.id}
              selectedPFI = {selectedPFI?.id}
              selectedTranche = {selectedTranche}
              setIsUpdated = {setIsUpdated}
              setIsDocumentToBeGenerated = {setIsDocumentToBeGenerated}
              setSelectedTranche = {setSelectedTranche}
              setShowConfirmationDialog = {setShowConfirmationDialog}
              setIsLoading = {setIsLoading}
              setShowAlert = {setShowAlert}
              setAlertMessage = {setAlertMessage}
              setAlertType = {setAlertType}
              logOut = {logOut}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default withAuth(BusinessUnit)([
  roles[2], 
  roles[3], 
  roles[4], 
  roles[5], 
  roles[6], 
  roles[7], 
  roles[8], 
  roles[10], 
  roles[11], 
  roles[12], 
  roles[13], 
  roles[14], 
  roles[15], 
  roles[16], 
  roles[17], 
  roles[18], 
  roles[19]
]);