import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchPreShipmentLists } from '../../store/slices/dealDropdownOptions'; 
import { formatDateFields } from '../../utils/dateUtils';
import useSessionExpire from '../../hooks/useSessionExpire';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DealHeader from './DealHeader';
import BuyingLeg from './BuyingLeg';
import SellingLeg from './SellingLeg';
import Modal from 'react-bootstrap/Modal';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import dayjs from 'dayjs';
import axios from 'axios';

const PreShipment = forwardRef(({ isWritable = false, isLoading = false, isFertilizersAndPetroChemicalsDeal = false, isFoodsOrPolymersDeal = false, isFoodsPolymersOrSBLCDeal = false, isSBLCDeal = false, selectedBusinessUnit = null, selectedDeal = null, selectedPFI = null, setIsUpdated = () => {}, setSelectedTab = () => {}, setActiveTab = () => {}, setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, logOut = () => {} }, ref) => 
{
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();

  /* LIST VARIABLES */
  const preShipmentListData = useSelector((state) => state.dealDropdownOptions.preShipment);
  const [isPreShipmentListDataLoading, setIsPreShipmentListDataLoading] = useState(false);

  /* AUTO-POPULATE DATA VARIABLES */
  const [pfisForCopy, setPFIsForCopy] = useState([]);
  const [selectedPFIToCopy, setSelectedPFIToCopy] = useState(null);

  /* FORM VARIABLES */
  const [expandedFormSections, setExpandedFormSections] = useState([1, 2, 3, 4, 5]);

  /* Deal Header Variables */
  const dealHeaderDataSchema = {
    Deal_PFI_Id: null,
    Order_Status: "",
    Buyer_Id: null,
    Buying_Payment_Terms_Id: null,
    Buying_Tenor_Id: null,
    Selling_Payment_Terms_Id: null,
    Selling_Tenor_Id: null,
    Seller_Id: null,
    Booked_for_Month: null,
    Order_Date: null,
    Deal_Close_Date: null,
    Deal_Sheet_Approval_Date: null,
    Shipment_Month_And_Date: null,
    Deal_Cancelled_Reason: "",
    Total_Amount: 0,
    Freight: null,
    FOB_Value: null,
    Label: "",
    Value: null,
    Transaction_Type: null,
    tranche_product_exists: false,
    products: []
  };
  const [isExistingDealHeader, setIsExistingDealHeader] = useState(false);
  const [isDealHeaderFormUpdated, setIsDealHeaderFormUpdated] = useState(false);
  const [isDealHeaderFormSavedOrDeleted, setIsDealHeaderFormSavedOrDeleted] = useState(false);
  const [dealHeaderInitialFormData, setDealHeaderInitialFormData] = useState(structuredClone(dealHeaderDataSchema));
  const [dealHeaderFormData, setDealHeaderFormData] = useState(structuredClone(dealHeaderDataSchema));
  
  /* Buying Leg Variables */
  const buyingLegDataSchema = {
    Deal_PFI_Id: null,
    Trader_Id: null,
    FZE_Bank_Id: null,
    Buying_Tenor_Id: null,
    Supplier_Bank_Id: null,
    LC_Confirming_Bank_Id: null,
    Advise_Through_Bank: "",
    Buying_Currency_Id: null,
    Latest_Shipment_Date: null,
    LC_Expiry_Date: null,
    Port_of_Loading_Id: null,
    Supplier_PFI_No: "",
    Supplier_PFI_Date: null,
    Supplier_CI_No: "",
    Supplier_CI_Date: null,
    PO_No: "",
    PO_Date: null,
    Incoterm_Id: null,
    Remaining_Balance_Payment_Days: null,
    Payment_Instrument_Status: null,
    LC_No: "",
    LC_Issue_Date: null,
    Document_Presentation_Days: null,
    Special_Requirement: ""
  };
  const [isExistingBuyingLeg, setIsExistingBuyingLeg] = useState(false);
  const [isBuyingLegFormUpdated, setIsBuyingLegFormUpdated] = useState(false);
  const [isBuyingLegFormSavedOrDeleted, setIsBuyingLegFormSavedOrDeleted] = useState(false);
  const [buyingLegInitialFormData, setBuyingLegInitialFormData] = useState(structuredClone(buyingLegDataSchema));
  const [buyingLegFormData, setBuyingLegFormData] = useState(structuredClone(buyingLegDataSchema));
  
  /* Selling Leg Variables */
  const sellingLegDataSchema = {
    Deal_PFI_Id: null,
    Trader_Id: null,
    Selling_Currency_Id: null,
    Incoterm_Id: null,
    Drawee_Bank: "",
    Customer_Bank_Id: null,
    Customer_Bank_Name: "",
    LC_Confirming_Bank_Id: null,
    Customer_Address: "",
    Port_of_Discharge_Id: null,
    PFI_Issuance_Date: null,
    Validity_Days: null,
    PFI_Expiry_Date: null,
    Last_Shipment_Date: null,
    Remaining_Balance_Payment_Days: null,
    Payment_Instrument_Status: null,
    LC_No: "",
    Issuance_Date: null,
    LC_Expiry_Date: null,
    Document_Presentation_Days: null,
    Terms_and_Conditions: "",
    Country_Of_Origin: "",
    Packaging_Type_Id: null
  };
  const [isExistingSellingLeg, setIsExistingSellingLeg] = useState(false);
  const [isSellingLegFormUpdated, setIsSellingLegFormUpdated] = useState(false);
  const [isSellingLegFormSavedOrDeleted, setIsSellingLegFormSavedOrDeleted] = useState(false);
  const [sellingLegInitialFormData, setSellingLegInitialFormData] = useState(structuredClone(sellingLegDataSchema));
  const [sellingLegFormData, setSellingLegFormData] = useState(structuredClone(sellingLegDataSchema));

  /* SBLC Variables */
  const sblcProductGradeDataSchema = {
    Deal_PFI_Id: null,
    Tranche_Id: null,
    SBLC_Id: null,
    PFIs: [],
    PFI_Id: []
  };
  const [isExistingSBLC, setIsExistingSBLC] = useState(false);
  const [isSBLCFormUpdated, setIsSBLCFormUpdated] = useState(false);
  const [sblcInitialFormData, setSBLCInitialFormData] = useState(structuredClone(sblcProductGradeDataSchema));
  const [sblcFormData, setSBLCFormData] = useState(structuredClone(sblcProductGradeDataSchema));

  /* DIALOG VARIABLES */
  const [warningDialogText, setWarningDialogText] = useState(null);
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  /* MODAL VARIABLES */
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  /* REFERENCE VARIABLES */
  const dealHeaderRef = useRef(null);
  const buyingLegRef = useRef(null);
  const sellingLegRef = useRef(null);

  useEffect(() => 
  {
    const getPreShipmentDealData = async (selectedPFIId) => 
    {  
      try 
      {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
    
        const dealHeaderApiEndPoint = isFertilizersAndPetroChemicalsDeal ? "/api/GetOrderDetailsFertilizersAndPetroChemicals" : isFoodsPolymersOrSBLCDeal ? "/api/GetOrderDetailsFoodsPolymersAndSBLC" : null;  

        const promises = [
          dealHeaderApiEndPoint && axios.post(dealHeaderApiEndPoint, {
            Business_Unit_Id: selectedBusinessUnit,
            Deal_PFI_Id: selectedPFIId,
            is_sblc_deal: selectedBusinessUnit === 6
          }),
          axios.post("/api/GetBuyingLeg", { Business_Unit_Id: selectedBusinessUnit, Deal_PFI_Id: selectedPFIId }),
          axios.post("/api/GetSellingLeg", { Business_Unit_Id: selectedBusinessUnit, Deal_PFI_Id: selectedPFIId })
        ];
  
        if (isSBLCDeal) 
        {
          promises.push(axios.post("/api/GetDealSBLCProductGrades", { Deal_PFI_Id: selectedPFIId }));
        }
  
        const [rawDealHeaderData, rawBuyingLegData, rawSellingLegData, rawSBLCProductGradeData] = await Promise.all(promises);
        const isEmpty = (obj) => !obj || Object.keys(obj).length === 0;

        const dealHeaderData = isEmpty(rawDealHeaderData?.data) ? dealHeaderDataSchema : rawDealHeaderData?.data;
        const buyingLegData = isEmpty(rawBuyingLegData?.data) ? buyingLegDataSchema : rawBuyingLegData?.data;
        const sellingLegData = isEmpty(rawSellingLegData?.data) ? sellingLegDataSchema : rawSellingLegData?.data;
        const sblcProductGradeData = isEmpty(rawSBLCProductGradeData?.data) ? sblcProductGradeDataSchema : rawSBLCProductGradeData?.data;        
        const formattedDealHeaderData = formatDateFields(dealHeaderData, [
          "Booked_for_Month", 
          "Order_Date", 
          "Deal_Close_Date", 
          "Deal_Sheet_Approval_Date", 
          "Shipment_Month_And_Date"
        ]);
        const formattedBuyingLegData = formatDateFields(buyingLegData, [
          "PO_Date", 
          "Latest_Shipment_Date", 
          "LC_Expiry_Date", 
          "LC_Issue_Date", 
          "Supplier_CI_Date", 
          "Supplier_PFI_Date"
        ]);
        const formattedSellingLegData = formatDateFields(sellingLegData, [
          "Last_Shipment_Date",
          "PFI_Issuance_Date",
          "PFI_Expiry_Date",  
          "Issuance_Date",
          "LC_Expiry_Date"
        ]);

        setDealHeaderInitialFormData(structuredClone(formattedDealHeaderData));
        setDealHeaderFormData(structuredClone(formattedDealHeaderData));
        setBuyingLegInitialFormData(structuredClone(formattedBuyingLegData));
        setBuyingLegFormData(structuredClone(formattedBuyingLegData));
        setSellingLegInitialFormData(structuredClone(formattedSellingLegData));
        setSellingLegFormData(structuredClone(formattedSellingLegData));
        setSBLCInitialFormData(structuredClone(sblcProductGradeData));
        setSBLCFormData(structuredClone(sblcProductGradeData));
      } 
      catch (error) 
      {
        console.log("Get Pre-Shipment Deal Data Api: ", error);
        handleApiError(error);
      }
      finally
      {
        setIsLoading(false);
      }
    };
  
    const selectedPFIId = selectedPFIToCopy?.id || selectedPFI;
    
    if (selectedPFIId) 
    {
      getPreShipmentDealData(selectedPFIId);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, selectedPFIToCopy]);
  
  useEffect(() => 
  {
    const fetchPreShipmentListData = async () => 
    {
      setIsPreShipmentListDataLoading(true);

      try 
      {
        await dispatch(fetchPreShipmentLists({ pathname, businessUnitId: selectedBusinessUnit })).unwrap();
      } 
      catch (error) 
      {
        console.error("Failed to fetch pre-shipment lists:", error);
      }
      finally 
      {
        setIsPreShipmentListDataLoading(false);
      }
    }
    
    if (selectedBusinessUnit)
    {
      fetchPreShipmentListData();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedBusinessUnit]);

  useEffect(() =>
  {
    const filteredPFIs = preShipmentListData?.pfis?.filter(pfi => pfi.deal_no === selectedDeal && pfi.id !== selectedPFI) || [];
    setPFIsForCopy(filteredPFIs);
  }, [selectedDeal, selectedPFI, preShipmentListData?.pfis]);

  useEffect(() =>
  {
    setIsUpdated(isDealHeaderFormUpdated || isBuyingLegFormUpdated || isSellingLegFormUpdated || isSBLCFormUpdated);
    
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isDealHeaderFormUpdated, isBuyingLegFormUpdated, isSellingLegFormUpdated, isSBLCFormUpdated]);

  useEffect(() => 
  {
    // Check if the 'dealHeaderFormData' is empty
    const isEmpty = typeof dealHeaderFormData === "object" && !dealHeaderFormData?.Deal_PFI_Id;
    
    if (!isEmpty && !selectedPFIToCopy) 
    {
      setIsExistingDealHeader(true);
    }
    else
    {
      setIsExistingDealHeader(false);
    }

    if (!isDealHeaderFormSavedOrDeleted && !selectedPFIToCopy)
    {
      const isDealHeaderFormUpdated = checkIfFormUpdated(
        dealHeaderFormData,
        dealHeaderInitialFormData,
        ["Booked_for_Month", "Order_Date", "Deal_Close_Date", "Deal_Sheet_Approval_Date", "Shipment_Month_And_Date"]
      );
      setIsDealHeaderFormUpdated(isDealHeaderFormUpdated);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dealHeaderFormData, selectedPFIToCopy]);    

  useEffect(() => 
  {
    // Check if the 'buyingLegFormData' is empty
    const isEmpty = typeof buyingLegFormData === "object" && !buyingLegFormData?.Deal_PFI_Id;
  
    if (!isEmpty && !selectedPFIToCopy) 
    {
      setIsExistingBuyingLeg(true);
    }
    else
    {
      setIsExistingBuyingLeg(false);
    }

    if (!isBuyingLegFormSavedOrDeleted && !selectedPFIToCopy)
    {
      const isBuyingLegFormUpdated = checkIfFormUpdated(buyingLegFormData, buyingLegInitialFormData);
      setIsBuyingLegFormUpdated(isBuyingLegFormUpdated);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [buyingLegFormData, selectedPFIToCopy]);

  useEffect(() => 
  {
    // Check if the 'sellingLegFormData' is empty
    const isEmpty = typeof sellingLegFormData === "object" && !sellingLegFormData?.Deal_PFI_Id;
  
    if (!isEmpty && !selectedPFIToCopy) 
    {
      setIsExistingSellingLeg(true);
    }
    else
    {
      setIsExistingSellingLeg(false);
    }

    if (!isSellingLegFormSavedOrDeleted && !selectedPFIToCopy)
    {
      const isSellingLegFormUpdated = checkIfFormUpdated(
        sellingLegFormData,
        sellingLegInitialFormData,
        ["PFI_Issuance_Date", "PFI_Expiry_Date", "Last_Shipment_Date", "Issuance_Date"]
      );
      setIsSellingLegFormUpdated(isSellingLegFormUpdated);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [sellingLegFormData, selectedPFIToCopy]);

  useImperativeHandle(ref, () => (
  {
    handleSubmit: async () => 
    {
      setIsLoading(true);
      setShowWarningDialog(false);
    
      try
      {
        const validationResults = await Promise.all([
          dealHeaderRef.current?.isValid() ?? false,
          buyingLegRef.current?.isValid() ?? false,
          sellingLegRef.current?.isValid() ?? false
        ]);
  
        if (validationResults.includes(false)) 
        {
          return;
        }
  
        const submissionResults = await Promise.all([
          dealHeaderRef.current?.handleSubmit(),
          buyingLegRef.current?.handleSubmit(),
          sellingLegRef.current?.handleSubmit()
        ]);
  
        if (submissionResults.every(result => result === true)) 
        {
          setActiveTab("Post-Shipment");
          setSelectedTab(null);
        }
      }
      catch
      {
        console.log("Add/Update Pre-Shipment data api failed due to unknown reasons.");
      }
      finally 
      {
        setIsLoading(false);
        setSelectedPFIToCopy(null);
      }
    }    
  }));

  const checkIfFormUpdated = (formData, initialFormData, dateKeys = []) => 
  {
    // Check non-date fields
    const isNonDateUpdated = Object.keys(initialFormData).some((key) => 
    {
      const currentValue = formData[key];
      const initialValue = initialFormData[key];
    
      return JSON.stringify(currentValue) !== JSON.stringify(initialValue);
    });

    // Check date fields separately
    const isDateUpdated = dateKeys.some((dateKey) => 
    {
      const currentDate = formData[dateKey] ? dayjs(formData[dateKey]) : formData[dateKey];
      const initialDate = initialFormData[dateKey] ? dayjs(initialFormData[dateKey]) : initialFormData[dateKey];
    
      return JSON.stringify(currentDate) !== JSON.stringify(initialDate);
    });

    return isNonDateUpdated || isDateUpdated;
  } 

  const handleApiError = (error) => 
  {
    const status = error?.response?.status;
    const data = error?.response?.data;

    switch (status) 
    {
      case 400:
        setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
        break;
      case 403:
        triggerSessionExpire();
        break;
      case 401:
        setAlertMessage("Unauthorized access. You do not have the required permissions to perform this action.");
        setAlertType("error");
        setShowAlert(true);
        break;
      case 429:
        setAlertMessage("Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
        setTimeout(logOut, 3000);
        break;
      default:
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
        break;
    }
  }

  const handleExpandAll = () => 
  {
    setExpandedFormSections([1, 2, 3, 4, 5]);
  }

  const handleCollapseAll = () => 
  {
    setExpandedFormSections([]);
  }

  const handleSubmitWrapper = () =>
  {
    if (isSBLCDeal && isExistingSBLC && isSBLCFormUpdated && dealHeaderFormData?.tranche_product_exists) 
    {
      setWarningDialogText("Updating the SBLC will remove it from Shipment Details. Do you want to continue?");
      setShowWarningDialog(true);
    } 
    else 
    {
      ref?.current?.handleSubmit();
    }        
  }

  const handleDelete = async () =>
  {
    try
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      await Promise.all([
        dealHeaderRef.current?.handleDelete(),
        buyingLegRef.current?.handleDelete(),
        sellingLegRef.current?.handleDelete()
      ]);

      setDealHeaderInitialFormData(structuredClone(dealHeaderDataSchema));
      setDealHeaderFormData(structuredClone(dealHeaderDataSchema));
      setBuyingLegInitialFormData(structuredClone(buyingLegDataSchema));
      setBuyingLegFormData(structuredClone(buyingLegDataSchema));
      setSellingLegInitialFormData(structuredClone(sellingLegDataSchema));
      setSellingLegFormData(structuredClone(sellingLegDataSchema));
      setSBLCInitialFormData(structuredClone(sblcProductGradeDataSchema));
      setSBLCFormData(structuredClone(sblcProductGradeDataSchema));
      setAlertMessage("Pre-shipment data deleted successfully.");
      setAlertType("success");
      setShowAlert(true);
    }
    catch
    {
      console.log("Delete Pre-Shipment data api failed due to unknown reasons.");
    }
    finally
    {
      setIsLoading(false);
    }
  }

  const handleCloseDialog = () => 
  {
    setShowWarningDialog(false);
  }

  const handleCloseModal = () => 
  {
    setShowDeleteModal(false);
  }
      
  return (
    <Grid
      container
      spacing = {2}
      className = "input-fields align-items-start justify-content-between autocomplete"
    >
      <div className = "w-100 d-flex justify-content-between align-items-center mt-2 flex-wrap row-gap-3">
        <div className = "d-flex align-items-center gap-2 form-section-button">
          <Button 
            variant = "outlined" 
            size = "small" 
            startIcon = {<UnfoldMoreIcon />}
            disabled = {expandedFormSections.length === 5}
            onClick = {handleExpandAll}
          >
            Expand All
          </Button>

          <Button 
            variant = "outlined" 
            size = "small" 
            startIcon = {<UnfoldLessIcon />}
            disabled = {expandedFormSections.length === 0}
            onClick = {handleCollapseAll}
          >
            Collapse All
          </Button>
        </div>

        <div className = "copy-pfi autocomplete flex-wrap">
          <VirtualizedAutocomplete
            isMultiple = {false}
            isObject = {true}
            isWritable = {isWritable}
            filterOn = "Select PFI to Auto Populate"
            options = {pfisForCopy}
            selectedOptions = {selectedPFIToCopy}
            handleSelectChange = {(filterOn, newValue) => setSelectedPFIToCopy(newValue)}
          />
        </div>
      </div>

      <Grid size = {{ xs: 12 }}>
        <DealHeader
          ref = {dealHeaderRef}
          isWritable = {isWritable}
          isListDataLoading = {isPreShipmentListDataLoading}
          isFertilizersAndPetroChemicalsDeal = {isFertilizersAndPetroChemicalsDeal}
          isFoodsOrPolymersDeal = {isFoodsOrPolymersDeal}
          isFoodsPolymersOrSBLCDeal = {isFoodsPolymersOrSBLCDeal}
          isSBLCDeal = {isSBLCDeal}
          isExistingDealHeader = {isExistingDealHeader}
          isExistingSBLC = {isExistingSBLC}
          isFormSavedOrDeleted = {isDealHeaderFormSavedOrDeleted}
          selectedBusinessUnit = {selectedBusinessUnit}
          selectedPFI = {selectedPFI}
          selectedPFIToCopy = {selectedPFIToCopy}
          formData = {dealHeaderFormData}
          sellingLegFormData = {sellingLegFormData}
          sblcInitialFormData = {sblcInitialFormData}
          sblcFormData = {sblcFormData}
          expandedFormSections = {expandedFormSections}
          showDeleteModal = {showDeleteModal}
          setIsExistingDealHeader = {setIsExistingDealHeader}
          setIsExistingSBLC = {setIsExistingSBLC}
          setIsFormUpdated = {setIsDealHeaderFormUpdated}
          setIsSBLCFormUpdated = {setIsSBLCFormUpdated}
          setIsFormSavedOrDeleted = {setIsDealHeaderFormSavedOrDeleted}
          setFormData = {setDealHeaderFormData}
          setSellingLegFormData = {setSellingLegFormData}
          setSBLCFormData = {setSBLCFormData}
          setWarningDialogText = {setWarningDialogText}
          setShowWarningDialog = {setShowWarningDialog}
          setShowDeleteModal = {setShowDeleteModal}          
          setExpandedFormSections = {setExpandedFormSections}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          logOut = {logOut}
        />
      </Grid> 

      <Grid size = {{ xs: 6 }}> 
        <BuyingLeg
          ref = {buyingLegRef}
          isWritable = {isWritable}
          isListDataLoading = {isPreShipmentListDataLoading}
          isExistingBuyingLeg = {isExistingBuyingLeg}
          selectedBusinessUnit = {selectedBusinessUnit}
          selectedPFI = {selectedPFI}
          listData = {preShipmentListData}
          formData = {buyingLegFormData}
          dealHeaderFormData = {dealHeaderFormData}
          expandedFormSections = {expandedFormSections}
          setIsExistingBuyingLeg = {setIsExistingBuyingLeg}
          setIsUpdated = {setIsBuyingLegFormUpdated}
          setIsFormSavedOrDeleted = {setIsBuyingLegFormSavedOrDeleted}
          setFormData = {setBuyingLegFormData}
          setDealHeaderFormData = {setDealHeaderFormData}
          setExpandedFormSections = {setExpandedFormSections}     
          setShowDeleteModal = {setShowDeleteModal}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          logOut = {logOut}
        />
      </Grid>    
      
      <Grid size = {{ xs: 6 }}>
        <SellingLeg
          ref = {sellingLegRef}
          isWritable = {isWritable}
          isListDataLoading = {isPreShipmentListDataLoading}
          isExistingSellingLeg = {isExistingSellingLeg}
          selectedBusinessUnit = {selectedBusinessUnit}
          selectedPFI = {selectedPFI}
          listData = {preShipmentListData}
          formData = {sellingLegFormData}
          dealHeaderFormData = {dealHeaderFormData}
          expandedFormSections = {expandedFormSections}
          setIsExistingSellingLeg = {setIsExistingSellingLeg}
          setIsUpdated = {setIsSellingLegFormUpdated}
          setIsFormSavedOrDeleted = {setIsSellingLegFormSavedOrDeleted}
          setFormData = {setSellingLegFormData}
          setDealHeaderFormData = {setDealHeaderFormData}
          setExpandedFormSections = {setExpandedFormSections}
          setShowDeleteModal = {setShowDeleteModal}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          logOut = {logOut}
        />
      </Grid>

      <div className = "d-flex gap-2 pt-3 submit-buttons">
        <button 
          type = "button"
          disabled = {!isWritable || isLoading}
          className = "btn btn-secondary custom-btn"
          onClick = {handleSubmitWrapper}
        >
          {isExistingDealHeader || isExistingBuyingLeg || isExistingSellingLeg ? "Update" : "Save"}
        </button>

        {(isExistingDealHeader || isExistingBuyingLeg || isExistingSellingLeg) && (
          <button
            type = "button"
            disabled = {!isWritable || isLoading}
            className = "btn custom-btn btn-danger"
            onClick = {() => setShowDeleteModal(true)}
          >
            Delete
          </button>
        )}
      </div>

      <Dialog open = {showWarningDialog} onClose = {handleCloseDialog}>
        <DialogContent>
          <DialogContentText className = "text-center">
            {warningDialogText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isSBLCDeal ? (
            <button
              type = "button"
              className = "btn btn-primary custom-btn"
              onClick = {() => ref?.current?.handleSubmit()}
            >
              Yes
            </button>
          ) : (
            <button
              type = "button"
              className = "btn btn-primary custom-btn"
              onClick = {handleCloseDialog}
            >
              Ok
            </button>
          )}
        </DialogActions>
      </Dialog>

      <Modal show = {showDeleteModal} onHide = {handleCloseModal} centered className = "delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Do you confirm that you want to delete the form details?
            {isSBLCDeal && (
              <>
                <span>{" "}Deleting this will also remove the selected product grades.</span>
                <br />
              </>
            )}
            This action cannot be undone.  
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Grid>
  );
});

export default memo(PreShipment);