import { createContext, useState, useMemo } from 'react';

const TabContext = createContext();

const TabProvider = ({ children }) => 
{
    /* TAB VARIABLES */
    const [activeTab, setActiveTab] = useState("Deals");
    const [selectedTab, setSelectedTab] = useState(null);

    /* DEAL VARIABLES */
    const [selectedDeal, setSelectedDeal] = useState(null);
    const [selectedPFI, setSelectedPFI] = useState(null);

    /* TRANCHE VARIABLES */
    const [selectedTranche, setSelectedTranche] = useState(null);

    const contextValue = useMemo(() => (
    { 
        activeTab, 
        selectedTab,
        selectedDeal, 
        selectedPFI,
        selectedTranche,
        setActiveTab, 
        setSelectedTab,
        setSelectedDeal,
        setSelectedPFI,
        setSelectedTranche
    }), [activeTab, selectedTab, selectedDeal, selectedPFI, selectedTranche]);

    return (
        <TabContext.Provider value = {contextValue}>
            {children}
        </TabContext.Provider>
    );
}

export { TabContext, TabProvider };