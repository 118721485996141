import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { roles } from '../../../utils/roles';
import { getPermissions } from '../../../lib/accessControl';
import { abortController } from '../../../utils/abortController';
import useUser from '../../../hooks/useUser';
import useSessionExpire from '../../../hooks/useSessionExpire';
import Alert from '../../../components/Alert';
import MUIDataTable from '../../../components/MUIDataTable';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import Modal from 'react-bootstrap/Modal';
import SBLCDetails from './SBLCDetails';
import SBLCForm from './SBLCForm';
import Loader from '../../../components/Loader';
import withAuth from '../../../lib/withAuth';
import axios from 'axios';

const SBLC = ({ logOut = () => {} }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const { pathname } = useLocation();

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* AUTHORIZATION VARIABLES */
  const { isWritable = false } = getPermissions(currentUser?.role, pathname);

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();

  /* DATATABLE VARIABLES */
  const [columnData, setColumnData] = useState([]);
  const [columnDataTypes, setColumnDataTypes] = useState({});
  const [columnWidths, setColumnWidths] = useState({});
  const [rows, setRows] = useState([]);
  const [toggleDisplayData, setToggleDisplayData] = useState(false);

  /* SBLC VARIABLES */
  const [selectedSBLC, setSelectedSBLC] = useState(null);
  const [clickedSBLC, setClickedSBLC] = useState(null);
  const [showSBLCForm, setShowSBLCForm] = useState(false);

  /* STATUS VARIABLES */
  const [rowId, setRowId] = useState(null);
  const [rowStatus, setRowStatus] = useState(null);

  /* MODAL VARIABLES */
  const [showDisableModal, setShowDisableModal] = useState(false);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
      setRowId(null);
      setRowStatus(null);

      await axios({
        method: "get",
        url: "/api/GetSBLC",
        signal: abortController.signal
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const sblcData = data?.sblc_data || {};
          const { rows: rowData = [], columns = [], data_types: dataTypes = {}, max_column_lengths: widths = {} } = sblcData;
          const columnData = columns.filter((column) => !(column === "id" || column === "isPaid" || column === "User_Id" || column === "isDeleted")) || [];

          setColumnData(columnData);
          setColumnDataTypes(dataTypes);
          setColumnWidths(widths);
          setRows(rowData);
        } 
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      { 
        console.log("Get SBLC Api: ", error);
        setIsLoading(false);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    fetchData();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [toggleDisplayData]);

  const handleRecordStatusChangeWrapper = (id, status) => 
  {
    setRowId(id);
    setRowStatus(status);

    if (status === 1) 
    {
      setShowDisableModal(true);
    } 
    else 
    {
      handleRecordStatusChange(id, status);
    }
  }

  const handleRecordStatusChange = useCallback(async (id, status) => 
  {
    setShowDisableModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/api/SetSBLCStatus",
      data: {
        id: rowId || id,
        status: rowStatus || status,
        user_id: currentUser?.id
      },
      signal: abortController.signal
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        setToggleDisplayData(!toggleDisplayData);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    { 
      console.log("Set SBLC Status Api: ", error);
      setIsLoading(false);

      if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
      {
        return;
      }
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [rowId, rowStatus, toggleDisplayData]);

  const handleCloseModal = () => 
  {
    setShowDisableModal(false);
  }

  const handleAddSBLC = () => 
  {
    setSelectedSBLC(null);
    setShowSBLCForm(true);
  }

  const handleEditSBLC = (sblc) => 
  {
    setSelectedSBLC(sblc);
    setShowSBLCForm(true);
  }

  const handleCellClick = (sblc) => 
  {
    setClickedSBLC(sblc);
  }

  return (
    <div className = "px-4 content-container d-flex flex-column container entities sblc">
      <Alert
        show = {showAlert}
        message = {alertMessage}
        type = {alertType}
        setShow = {setShowAlert}
      />
      
      <Slide direction = "right" appear = {false} in = {!clickedSBLC && !showSBLCForm} mountOnEnter unmountOnExit>
        <div>
          <h4 className = "page-heading m-0">
            SBLC
          </h4>

          <div className = "mt-3">
            <Backdrop
              sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open = {isLoading}
              >
              <Loader/>
            </Backdrop>
            
            <MUIDataTable
              title = "SBLC"
              isWritable = {isWritable}
              pathname = {pathname}
              columnData = {columnData}
              columnDataTypes = {columnDataTypes}
              columnWidths = {columnWidths}
              rows = {rows}
              files = {[]}
              isEditable = {true}
              setIsLoading = {setIsLoading}
              setShowAlert = {setShowAlert}
              setAlertMessage = {setAlertMessage}
              setAlertType = {setAlertType}
              handleEditSBLC = {handleEditSBLC}
              handleAddSBLC = {handleAddSBLC}
              handleRecordStatusChange = {handleRecordStatusChangeWrapper}
              handleCellClick = {handleCellClick}
              logOut = {logOut}
            />
          </div>

          <Modal show = {showDisableModal} onHide = {handleCloseModal} centered>
            <Modal.Header closeButton>
              <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <span>
                Disabling the SBLC will remove it from the list of available SBLC.
              </span>
            </Modal.Body>
            <Modal.Footer className = "gap-2">
              <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
                Cancel
              </Button>
              <Button
                variant = "contained"
                size = "small"
                onClick = {handleRecordStatusChange}
              >
                Disable
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </Slide>

      <Slide direction = "left" in = {showSBLCForm ? true : false} mountOnEnter unmountOnExit>
        <div>
          <SBLCForm 
            selectedSBLC = {selectedSBLC}
            isLoading = {isLoading}
            toggleDisplayData = {toggleDisplayData}
            setIsLoading = {setIsLoading}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
            setShowSBLCForm = {setShowSBLCForm}
            setSelectedSBLC = {setSelectedSBLC}
            setToggleDisplayData = {setToggleDisplayData}
            logOut = {logOut}
          />
        </div>
      </Slide>

      <Slide direction = "left" in = {clickedSBLC ? true : false} mountOnEnter unmountOnExit>
        <div>
          <SBLCDetails 
            selectedSBLC = {clickedSBLC} 
            toggleDisplayData = {toggleDisplayData}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
            setSelectedSBLC = {setClickedSBLC}
            setToggleDisplayData = {setToggleDisplayData}
            logOut = {logOut}
          />
        </div>
      </Slide>
    </div>
  );
}

export default withAuth(SBLC)([
  roles[2],
  roles[13], 
  roles[14], 
  roles[16], 
  roles[17], 
  roles[19]
]);