import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getPermissions } from '../../lib/accessControl';
import { fetchPostShipmentLists } from '../../store/slices/dealDropdownOptions';
import { fetchCertificatesOfOrigin, fetchUploadedDocuments, fetchDocumentRevisionList } from '../../store/slices/dealUploadedDocuments';
import { formatDateFields } from '../../utils/dateUtils';
import { abortController } from '../../utils/abortController';
import useSessionExpire from '../../hooks/useSessionExpire';
import useUser from '../../hooks/useUser';
import Documentation from './Documentation';
import ShipmentDetails from './ShipmentDetails';
import Modal from 'react-bootstrap/Modal';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import dayjs from 'dayjs';
import axios from 'axios';

const PostShipment = forwardRef(({ isWritable = false, isLoading = false, isListDataLoading = false, isFoodsOrPolymersDeal = false, isFoodsPolymersOrSBLCDeal = false, isSBLCDeal = false, isUpdated = false, selectedBusinessUnit = null, selectedPFI = null, selectedTranche = null, setIsUpdated = () => {}, setIsDocumentToBeGenerated = () => {}, setSelectedTranche = () => {}, setShowConfirmationDialog = () => {}, setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, logOut = () => {} },  ref) => 
{
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* AUTHORIZATION VARIABLES */
  const { isWritable: isDocumentWritable = false } = getPermissions(currentUser?.role, null, "documents");
  const documentPermissions = getPermissions(currentUser?.role, null, "approvableDocuments");

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();
  
  /* LIST VARIABLES */
  const postShipmentListData = useSelector((state) => state.dealDropdownOptions.postShipment);
  const [isPostShipmentListDataLoading, setIsPostShipmentListDataLoading] = useState(false); 
  const [togglePostShipmentListData, setTogglePostShipmentListData] = useState(false);
  const [tranches, setTranches] = useState([]);

  /* SELECTED LIST VARIABLES */
  const [selectionType, setSelectionType] = useState(null);
  const [stagedTranche, setStagedTranche] = useState(null);

  /* AUTO-POPULATE DATA VARIABLES */
  const [tranchesForCopy, setTranchesForCopy] = useState([]);
  const [selectedTrancheToCopy, setSelectedTrancheToCopy] = useState(null);
  const [stagedTrancheToCopy, setStagedTrancheToCopy] = useState(null);

  /* FORM VARIABLES */
  const [expandedFormSections, setExpandedFormSections] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
  const formRef = useRef(null);

  /* Post Shipment Variables */
  const postShipmentDataSchema = {
    Tranche_Id: null,
    Tranche_Name: "",
    Shipping_Line: null,
    Vessel_Name: "",
    Voyage_No: "",
    Split_Bill_of_Lading_No: "",
    Master_Bill_of_Lading_No: "",
    Shipment_Date: null,
    Demurrage_Free_Days_at_Disport: null,
    Estimated_Arrival_to_POD: null,
    NN_Docs_Supplier: null,
    NN_Docs_Buyer: null,
    Documents_Status_to_Customer: "",
    Documents_Status_to_Supplier: "",
    Delivery_Date: null,
    Commercial_Invoice_No: "",
    Commercial_Invoice_Date: null,
    Gross_Weight_KGs: null,
    Net_Weight_KGs: null,
    Insurance_Cover_No: "",
    SI_Date: null,
    Insurance_Company_Details: "",
    Shipper: "",
    Notify_Bank: "",
    Notify_Customer: "",
    Final_Destination: "",
    Partial_Shipment: "",
    Transhipment: "",
    Port_Of_Loading_Id: null,
    Port_Of_Discharge_Id: null,
    Consignee: "",
    Shipment_Advice_Days: null,
    Certificates_of_Origin: [],
    tranche_products_selected: [],
    tranche_product_grades_selected: [],
    AWB_from_Supplier: "",
    Advance_Payment_Date: null,
    Supplier_Document_Receiving_Date: null,
    Disc_Acceptance: "",
    Special_Requirement: "",
    Special_Requirement_SI: "",
    Special_Requirement_CI: "",
    Special_Requirement_SA: "",
    Special_Requirement_PL: "",
    Special_Requirement_BE: "",
    Advance_Payment_Receive_Date: null,
    Final_Payment_Receive_Date: null,
    AWB_To_Customer: null, 
    Final_Payment_Date: null,
    Dispatch_Date: null,
    Discounting_Required: null,
    Supplier_Transaction_Status: "",
    Supplier_Status: null,
    Supplier_Discrepancy_Reason: "",
    Supplier_Discrepancy_Acceptance_Swift: null,
    Customer_Transaction_Status: "",
    Customer_Status: null,
    Customer_Discrepancy_Reason: "",
    Customer_Discrepancy_Acceptance_Swift: null,
    Target_Customer_PFI_Issuance_Date: null,
    Target_Supplier_LC_Issuance_Date: null,
    Target_Customer_NN_Documents: null,
    Target_Supplier_NN_Documents: null,
    Target_Supplier_Original_Documents: null,
    Target_Customer_Original_Documents: null,
    Customer_LC_Deadline: null,
    Advance_Amount: null,
    Discount: null,
    Freight: null,
    FOB_Value: null,
    Label: "",
    Value: null,
    Transaction_Type: null            
  };
  const sblcTrancheProductGradeDataSchema = {
    Deal_PFI_Id: null,
    Tranche_Id: null,
    SBLC_Id: null,
    PFIs: [],
    PFI_Id: []
  };
  const [isExistingPostShipment, setIsExistingPostShipment] = useState(false);
  const [isPostShipmentFormUpdated, setIsPostShipmentFormUpdated] = useState(false);
  const [isSBLCFormUpdated, setIsSBLCFormUpdated] = useState(false);
  const [isPostShipmentFormSavedOrDeleted, setIsPostShipmentFormSavedOrDeleted] = useState(false);
  const [postShipmentInitialFormData, setPostShipmentInitialFormData] = useState(structuredClone(postShipmentDataSchema));
  const [postShipmentFormData, setPostShipmentFormData] = useState(structuredClone(postShipmentDataSchema));
  const [sblcInitialFormData, setSBLCInitialFormData] = useState(structuredClone(sblcTrancheProductGradeDataSchema));
  const [sblcFormData, setSBLCFormData] = useState(structuredClone(sblcTrancheProductGradeDataSchema));

  /* DOCUMENT VARIABLES */
  const documentData = useSelector((state) => state.dealUploadedDocuments);
  const [areDocumentsLoading, setAreDocumentsLoading] = useState(false);
  const [toggleDocumentList, setToggleDocumentList] = useState(false);

  /* FILE VARIABLES */
  const [areFilesLoading, setAreFilesLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [toggleFileList, setToggleFileList] = useState(false);

  /* MODAL VARIABLES */
  const [showSelectModal, setShowSelectModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  /* REFERENCE VARIBLES */
  const shipmentDetailsRef = useRef(null);
  const documentationRef = useRef(null);

  useEffect(() => 
  {
    const fetchLists = async () => 
    {
      setIsLoading(true);
      setIsPostShipmentListDataLoading(true);
  
      try 
      {
        const response = await axios.post("/api/GetTrancheList", {
          pathname,
          Business_Unit_Id: selectedBusinessUnit,
          Deal_PFI_Id: selectedPFI
        }, { signal: abortController.signal });
  
        if (response.status === 200) 
        {
          const tranches = response.data?.tranches || [];

          setTranches(tranches);
          setTranchesForCopy(tranches.filter(tranche => tranche.id !== tranches?.[0]?.id));
          setSelectedTranche(tranches?.[0]);
        } 
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
  
        await dispatch(fetchPostShipmentLists({
          pathname,
          businessUnitId: selectedBusinessUnit,
          selectedPFI,
          isFoodsOrPolymersDeal,
          isFoodsPolymersOrSBLCDeal
        }))
          .unwrap()
          .catch(error => console.error("Failed to fetch post-shipment lists:", error))
          .finally(() => setIsPostShipmentListDataLoading(false));
  
      } 
      catch (error)
      {
        console.log("Get Tranche List Api: ", error);
        handleApiError(error);
      }
    }
  
    if (selectedPFI) 
    {
      fetchLists();
    }
  
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedPFI, togglePostShipmentListData]);
    
  useEffect(() => 
  {    
    const getPostShipmentDealData = async (selectedTrancheId) => 
    {  
      try 
      {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
  
        const promises = [
          axios.post(`${isFoodsPolymersOrSBLCDeal ? "/api/GetPostShipmentFoodsPolymersAndSBLC" : "/api/GetPostShipmentFertilizersAndPetroChemicals"}`, {
            Business_Unit_Id: selectedBusinessUnit,
            Deal_PFI_Id: selectedPFI,
            Tranche_Id: selectedTrancheId
          })
        ];
  
        if (isSBLCDeal)
        {
          promises.push(axios.post("/api/GetDealTrancheSBLCProductGrades", { Deal_PFI_Id: selectedPFI, Tranche_Id: selectedTrancheId }));
        }
  
        const [rawPostShipmentData, rawSBLCTrancheProductGradeData] = await Promise.all(promises);
        const isEmpty = (obj) => !obj || Object.keys(obj).length === 0;
  
        const combinedPostShipmentData = {
          ...(rawPostShipmentData?.data?.shipment_details || {}),
          ...(rawPostShipmentData?.data?.documentation || {})
        };
        const postShipmentData = isEmpty(combinedPostShipmentData) ? postShipmentDataSchema : combinedPostShipmentData;
        const sblcTrancheProductGradeData = isEmpty(rawSBLCTrancheProductGradeData?.data) ? sblcTrancheProductGradeDataSchema : rawSBLCTrancheProductGradeData?.data;
        const formattedPostShipmentData = formatDateFields(postShipmentData, [
          "Shipment_Date",
          "Estimated_Arrival_to_POD",
          "NN_Docs_Supplier",
          "NN_Docs_Buyer",
          "Delivery_Date",
          "Commercial_Invoice_Date",
          "SI_Date",
          "Advance_Payment_Date",
          "Advance_Payment_Receive_Date",
          "Final_Payment_Date",
          "Final_Payment_Receive_Date",
          "Advance_Receiving_Date",
          "Remaining_Balance_Receiving_Date",
          "Supplier_Document_Receiving_Date",
          "Dispatch_Date",
          "Target_Customer_PFI_Issuance_Date",
          "Target_Supplier_LC_Issuance_Date",
          "Target_Customer_NN_Documents",
          "Target_Supplier_NN_Documents",
          "Target_Supplier_Original_Documents",
          "Target_Customer_Original_Documents",
          "Customer_LC_Deadline"
        ]);
        const totalAmount = Number(formattedPostShipmentData?.Total_Amount) || 0;
        const freight = Number(formattedPostShipmentData?.Freight) || 0;
        const fobValue = Number(formattedPostShipmentData?.FOB_Value) || 0;
        
        if (totalAmount !== (freight + fobValue)) 
        {
          formattedPostShipmentData.Freight = null;
          formattedPostShipmentData.FOB_Value = null;
        }
  
        setPostShipmentInitialFormData(structuredClone(formattedPostShipmentData));
        setPostShipmentFormData(structuredClone(formattedPostShipmentData));
        setSBLCInitialFormData(structuredClone(sblcTrancheProductGradeData));
        setSBLCFormData(structuredClone(sblcTrancheProductGradeData));
      } 
      catch (error) 
      {
        console.log("Get Post-Shipment Deal Data Api: ", error);
        handleApiError(error);
      } 
      finally 
      {
        setIsLoading(false);
      }
    };

    if (tranches.length > 0)
    {
      const selectedTrancheId = selectedTrancheToCopy?.id || selectedTranche?.id;
      getPostShipmentDealData(selectedTrancheId);
    }
    else
    {
      setIsLoading(false);
      setPostShipmentInitialFormData(structuredClone(postShipmentDataSchema));
      setPostShipmentFormData(structuredClone(postShipmentDataSchema));
      setSBLCInitialFormData(structuredClone(sblcTrancheProductGradeDataSchema));
      setSBLCFormData(structuredClone(sblcTrancheProductGradeDataSchema));
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedTranche, selectedTrancheToCopy]);
  
  useEffect(() => 
  {
    const fetchDocumentData = async () => 
    {
      setAreDocumentsLoading(true);

      try 
      {
        if (selectedTrancheToCopy || selectedTranche)
        {
          await dispatch(fetchCertificatesOfOrigin({ 
            selectedBusinessUnit, 
            selectedTranche: selectedTrancheToCopy || selectedTranche 
          })).unwrap();
        }
  
        await dispatch(fetchDocumentRevisionList({ 
          selectedBusinessUnit, 
          selectedPFI, 
          selectedTranche: selectedTrancheToCopy || selectedTranche 
        })).unwrap();
      } 
      catch (error) 
      {
        handleApiError(error);
      } 
      finally 
      {
        setAreDocumentsLoading(false);
      }
    }
  
    fetchDocumentData();
  
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedTranche, selectedTrancheToCopy, toggleDocumentList]);

  useEffect(() => 
  {
    const fetchFileData = async () => 
    {  
      setAreFilesLoading(true);

      try 
      {
        await dispatch(fetchUploadedDocuments({ 
          selectedPFI, 
          selectedTranche: selectedTrancheToCopy || selectedTranche 
        })).unwrap();
      } 
      catch (error) 
      {
        handleApiError(error);
      } 
      finally 
      {
        setAreFilesLoading(false);
      }
    }

    fetchFileData();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedTranche, selectedTrancheToCopy, toggleFileList]);

  useEffect(() =>
  {
    setIsUpdated(isPostShipmentFormUpdated || isSBLCFormUpdated);
    
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isPostShipmentFormUpdated, isSBLCFormUpdated]);
  
  useEffect(() => 
  {
    // Check if the 'formData' is empty
    const isEmpty = typeof postShipmentFormData === "object" && !postShipmentFormData?.Tranche_Id;

    if (!isEmpty && !selectedTrancheToCopy) 
    {
      setIsExistingPostShipment(true);
    } 
    else 
    {
      setIsExistingPostShipment(false);
    }

    if (!isPostShipmentFormSavedOrDeleted && !selectedTrancheToCopy)
    {
      const isPostShipmentFormUpdated = checkIfFormUpdated(postShipmentFormData, postShipmentInitialFormData);
      setIsPostShipmentFormUpdated(isPostShipmentFormUpdated);
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [postShipmentFormData, selectedTrancheToCopy]);
  
  useEffect(() => 
  {
    const filteredFiles = documentData?.files?.filter(file => 
      (file.file.dealPFIId === selectedPFI || file.file.trancheId === (selectedTrancheToCopy?.id || selectedTranche?.id))
    ) || [];    
    
    setFiles(filteredFiles);
  }, [selectedPFI, selectedTranche, selectedTrancheToCopy, documentData]);

  useImperativeHandle(ref, () => (
  {
    handleSubmit: async () => 
    {
      setIsLoading(true);
    
      try 
      {
        if (formRef.current?.reportValidity())
        {
          const validationResults = await Promise.all([
            shipmentDetailsRef.current?.isValid() ?? false,
            documentationRef.current?.isValid() ?? false
          ]);
    
          if (validationResults.includes(false)) 
          {
            return;
          }
    
          await shipmentDetailsRef.current?.handleSubmit();         
        }
      }
      catch
      {
        console.log("Add/Update Post-Shipment data api due to unknown reasons.");
      }
      finally 
      {
        setIsLoading(false);
      }
    }
  }));

  const checkIfFormUpdated = (formData, initialFormData, dateKeys = []) => 
  {
    // Check non-date fields
    const isNonDateUpdated = Object.keys(initialFormData).some((key) => 
    {
      const currentValue = formData[key];
      const initialValue = initialFormData[key];
    
      return JSON.stringify(currentValue) !== JSON.stringify(initialValue);
    });

    // Check date fields separately
    const isDateUpdated = dateKeys.some((dateKey) => 
    {
      const currentDate = formData[dateKey] ? dayjs(formData[dateKey]) : formData[dateKey];
      const initialDate = initialFormData[dateKey] ? dayjs(initialFormData[dateKey]) : initialFormData[dateKey];
    
      return JSON.stringify(currentDate) !== JSON.stringify(initialDate);
    });

    return isNonDateUpdated || isDateUpdated;
  }

  const handleApiError = (error) => 
  {
    const status = error?.response?.status;
    const data = error?.response?.data;

    switch (status) 
    {
      case 400:
        setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
        break;
      case 403:
        triggerSessionExpire();
        break;
      case 401:
        setAlertMessage("Unauthorized access. You do not have the required permissions to perform this action.");
        setAlertType("error");
        setShowAlert(true);
        break;
      case 429:
        setAlertMessage("Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
        setTimeout(logOut, 3000);
        break;
      default:
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
        break;
    }
  }

  const handleSelectTrancheWrapper = (selectionType, newValue) => 
  {
    const isEmpty = Object.entries(postShipmentFormData)?.every(([key, value]) => value === "" || value === null || value === undefined) && uploadedFiles.length === 0;

    if (!isEmpty && !isPostShipmentFormSavedOrDeleted && (!isExistingPostShipment || isUpdated)) 
    {
      setSelectionType(selectionType);

      if (newValue)
      {
        if (selectionType === "Copy") 
        {
          setStagedTrancheToCopy(newValue);
        } 
        else if (selectionType === "Select") 
        {
          setStagedTranche(newValue);
        }

        setShowSelectModal(true);
      }
    } 
    else
    {
      handleSelectTranche(selectionType, newValue);
    }
  }

  const handleSelectTranche = (selectionType, newValue) => 
  {
    setShowSelectModal(false);

    if (selectionType === "Copy") 
    {
      setSelectedTrancheToCopy(newValue);
    }
    else if (selectionType === "Select")
    {
      setSelectedTranche(newValue);
      setSelectedTrancheToCopy(null);
    }

    setIsUpdated(false);
  }

  const handleConfirmTrancheSelection = () =>
  {    
    if (selectionType === "Copy") 
    {
      handleSelectTranche("Copy", stagedTrancheToCopy);
    } 
    else if (selectionType === "Select") 
    {
      handleSelectTranche("Select", stagedTranche);
    }
  }

  const handleChange = (field, value, isDate = false) => 
  {
    let newValue = value;

    if (isDate && newValue) 
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }

    if (["Gross_Weight_KGs", "Net_Weight_KGs"].includes(field)) 
    {
      // Remove any non-numeric characters except the decimal point
      newValue = value.replace(/[^0-9.]/g, "");

      // Ensure the value has at most 3 decimal places
      if (newValue.includes(".")) 
      {
        const [integerPart, decimalPart] = newValue.split(".");
        newValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
      }
    }

    setPostShipmentFormData((previousFormData) => ({
      ...previousFormData,
      [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null
    }));

    setIsPostShipmentFormSavedOrDeleted(false);
  }

  const handleAddTrancheWrapper = () => 
  {
    shipmentDetailsRef.current?.handleAddTrancheWrapper();
  }

  const handleExpandAll = () => 
  {
    setExpandedFormSections([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
  }

  const handleCollapseAll = () => 
  {
    setExpandedFormSections([]);
  }
  
  const handleDelete = () => 
  {
    shipmentDetailsRef.current?.handleDelete();
  }

  const handleCloseModal = () => 
  {
    setShowSelectModal(false);
    setShowDeleteModal(false);
    shipmentDetailsRef.current?.handleCloseModal();
    documentationRef.current?.handleCloseModal();
  }

  return (
    <Grid
      container
      spacing = {2}
      className = "input-fields align-items-start autocomplete"
    >
      <div className = "w-100 d-flex justify-content-between align-items-center mt-2 flex-wrap row-gap-3">
        <div className = "d-flex align-items-center gap-2 form-section-button">
          <Button 
            variant = "outlined" 
            size = "small" 
            startIcon = {<UnfoldMoreIcon />}
            disabled = {expandedFormSections.length === 5}
            onClick = {handleExpandAll}
          >
            Expand All
          </Button>

          <Button 
            variant = "outlined" 
            size = "small" 
            startIcon = {<UnfoldLessIcon />}
            disabled = {expandedFormSections.length === 0}
            onClick = {handleCollapseAll}
          >
            Collapse All
          </Button>
        </div>

        <div className = "copy-tranche autocomplete flex-wrap">
          <VirtualizedAutocomplete
            isMultiple = {false}
            isObject = {true}
            isWritable = {isWritable}
            filterOn = "Select Tranche to Auto Populate"
            options = {tranchesForCopy}
            selectedOptions = {selectedTrancheToCopy}
            handleSelectChange = {(filterOn, newValue) => handleSelectTrancheWrapper("Copy", newValue)}
          />

          <VirtualizedAutocomplete
            isLoading = {isListDataLoading}
            isMultiple = {false}
            isObject = {true}
            isWritable = {true}
            filterOn = "Select Tranche Name"
            options = {tranches}
            selectedOptions = {selectedTranche}
            handleSelectChange = {(filterOn, newValue) => handleSelectTrancheWrapper("Select", newValue)}
          />

          <IconButton
            className = "add-icon-button"
            color = "primary"
            aria-label = "add"
            disabled = {!isWritable}
            onClick = {handleAddTrancheWrapper}
          >
            <AddIcon />
          </IconButton>
        </div>
      </div>

      <form 
        ref = {formRef} 
        className = "w-100"
        onSubmit = {(event) => event.preventDefault()}
      >
        <Grid size = {{ xs: 4 }} className = "m-auto mt-3">
          <TextField
            label = "Tranche Name"
            id = "Tranche_Name"
            value = {postShipmentFormData?.Tranche_Name || ""}
            required
            onChange = {(event) => handleChange("Tranche_Name", event.target.value)}
            slotProps = {{
              input: {
                readOnly: !isWritable
              }
            }}
          />
        </Grid>
      </form>

      <Grid size = {{ xs: 12 }} className = "mt-4">
        <ShipmentDetails
          ref = {shipmentDetailsRef}
          isWritable = {isWritable}
          isListDataLoading = {isPostShipmentListDataLoading}
          isDocumentWritable = {isDocumentWritable}
          isExistingShipmentDetails = {isExistingPostShipment}
          isFormSavedOrDeleted = {isPostShipmentFormSavedOrDeleted}
          isFoodsOrPolymersDeal = {isFoodsOrPolymersDeal}
          isFoodsPolymersOrSBLCDeal = {isFoodsPolymersOrSBLCDeal}
          isSBLCDeal = {isSBLCDeal}
          isUpdated = {isUpdated}
          selectedBusinessUnit = {selectedBusinessUnit}
          selectedPFI = {selectedPFI}
          selectedTranche = {selectedTranche}
          selectedTrancheToCopy = {selectedTrancheToCopy}
          listData = {{...postShipmentListData, tranches}}  
          formData = {postShipmentFormData}
          sblcInitialFormData = {sblcInitialFormData}
          sblcFormData = {sblcFormData}
          files = {files}
          uploadedFiles = {uploadedFiles}
          toggleListData = {togglePostShipmentListData}
          toggleDocumentList = {toggleDocumentList}
          toggleFileList = {toggleFileList}
          expandedFormSections = {expandedFormSections}
          setIsExistingShipmentDetails = {setIsExistingPostShipment}
          setIsFormUpdated = {setIsPostShipmentFormUpdated}
          setIsSBLCFormUpdated = {setIsSBLCFormUpdated}
          setIsFormSavedOrDeleted = {setIsPostShipmentFormSavedOrDeleted}
          setTranches = {setTranches}
          setSelectedTranche = {setSelectedTranche}
          setSelectedTrancheToCopy = {setSelectedTrancheToCopy}
          setFormData = {setPostShipmentFormData}
          setSBLCFormData = {setSBLCFormData}
          setFiles = {setFiles}
          setUploadedFiles = {setUploadedFiles}
          setToggleListData = {setTogglePostShipmentListData}
          setToggleDocumentList = {setToggleDocumentList}
          setToggleFileList = {setToggleFileList}
          setExpandedFormSections = {setExpandedFormSections}
          setShowDeleteModal = {setShowDeleteModal}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          handleChange = {handleChange}
          logOut = {logOut}
        />
      </Grid>

      <Grid size = {{ xs: 12 }} className = "mt-3">
        <Documentation
          ref = {documentationRef}
          isWritable = {isWritable}
          isDocumentWritable = {isDocumentWritable}
          areDocumentsLoading = {areDocumentsLoading}
          areFilesLoading = {areFilesLoading}
          isExistingDocumentation = {isExistingPostShipment}
          isFoodsOrPolymersDeal = {isFoodsOrPolymersDeal}
          isSBLCDeal = {isSBLCDeal}
          isUpdated = {isUpdated}
          selectedBusinessUnit = {selectedBusinessUnit}
          selectedPFI = {selectedPFI}
          selectedTranche = {selectedTranche}
          listData = {postShipmentListData}
          documentPermissions = {documentPermissions}
          formData = {postShipmentFormData}
          files = {files}
          uploadedFiles = {uploadedFiles}
          toggleDocumentList = {toggleDocumentList}
          expandedFormSections = {expandedFormSections}
          setSelectedTranche = {setSelectedTranche}
          setFormData = {setPostShipmentFormData}
          setFiles = {setFiles}
          setUploadedFiles = {setUploadedFiles}
          setExpandedFormSections = {setExpandedFormSections}
          setToggleDocumentList = {setToggleDocumentList}
          setIsDocumentToBeGenerated = {setIsDocumentToBeGenerated}
          setShowConfirmationDialog = {setShowConfirmationDialog}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          logOut = {logOut}
        />
      </Grid>

      <div className = "d-flex gap-2 justify-content-end submit-buttons">
        <button
          type = "button"
          disabled = {isLoading || (!isWritable && !isDocumentWritable)}
          className = "btn btn-secondary custom-btn" 
          onClick = {() => ref.current?.handleSubmit()}
        >
          {isExistingPostShipment ? "Update" : "Save"}
        </button>

        {isExistingPostShipment && (
          <button
            type = "button"
            disabled = {isLoading || (!isWritable && !isDocumentWritable)}
            className = "btn custom-btn btn-danger"
            onClick = {() => setShowDeleteModal(true)}
          >
            Delete
          </button>
        )}
      </div>

      <Modal show = {showSelectModal} onHide = {handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Switching forms will discard any unsaved changes. Do you want to continue?
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleConfirmTrancheSelection}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show = {showDeleteModal} onHide = {handleCloseModal} centered className = "delete-modal">
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Do you really want to delete the form details?
            This process cannot be undone.
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleDelete}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </Grid>
  );
});

export default memo(PostShipment);