import dayjs from 'dayjs';

export const formatDateFields = (data, dateKeys) => 
{
    const formattedData = { ...data };

    // Iterate over the dateKeys and format the corresponding fields
    dateKeys.forEach((key) => 
    {
        if (key === "Deal_Sheet_Approval_Date")
        {
            formattedData[key] = formattedData[key] ? dayjs(formattedData[key]).toISOString() : formattedData[key];
        }
        else
        {
            formattedData[key] = formattedData[key] ? dayjs(formattedData[key]).format("MM/DD/YYYY") : formattedData[key];
        }
    });

    return formattedData;
};