import { motion } from 'framer-motion';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

const MetricTile = ({ isLoading = false, menuItem = {}, handleNavigation = () => {} }) => 
{
  const { label, caption, icon, keyMetrics, disabled, color, hoverColor = color } = menuItem;

  return (
    <Grid size = {{ xs: 6, sm: 4, md: 3 }}>
      <Tooltip title = {disabled ? "Please contact the system administrator for access." : ""} arrow>
        <Box
          sx = {{
            perspective: '1000px',
            cursor: disabled ? 'default' : 'pointer',
            width: '100%',
            height: '170px'
          }}
        >
          <motion.div
            whileHover = {disabled ? {} : {
              y: -5
            }}
            transition = {{ duration: 0.3 }}
            className = "motion-div"
          >
            <Card
              sx = {{
                position: 'absolute',
                width: '100%',
                height: '100%',
                backfaceVisibility: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                backgroundColor: disabled ? '#C1C1C1' : color,
                color: disabled ? '#787878' : '#ffffff',
                borderRadius: '12px',
                transition: 'background-color 0.3s ease',
                '&:hover': disabled ? {} : { backgroundColor: hoverColor }
              }}
              onClick = {!disabled ? () => handleNavigation(menuItem) : undefined}
            >
              <CardContent 
                sx = {{ 
                  textAlign: 'center', 
                  p: 2,
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  width: '100%'
                }}
              >
                <Box
                  sx = {{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 50,
                    height: 50,
                    borderRadius: '50%',
                    border: `2px solid ${disabled ? '#787878' : '#ffffff'}`,
                    padding: 1,
                    marginBottom: 1
                  }}
                >
                  {icon}
                </Box>
                <Typography fontWeight = "bold" sx = {{ fontSize: '1.2rem' }}>
                  <Tooltip title = {!disabled && caption ? caption : ""} arrow>
                    {label}
                  </Tooltip>
                </Typography>

                {keyMetrics && !disabled && (
                  <Box sx = {{ display: 'flex', justifyContent: 'space-evenly', width: '100%', mt: 1 }}>
                    {keyMetrics.map((metric, index) => (
                      <Box key = {index} sx = {{ display: 'flex', alignItems: 'center', flex: 1 }}>
                        <Box sx = {{ textAlign: 'center', flex: 1 }}>
                          {isLoading ? (
                            <div className = "dots-loader"></div>
                          ) : (
                            <Typography fontWeight = "bold" sx = {{ fontSize: '1rem' }}>
                              {metric.value}
                            </Typography>
                          )}
                          
                          <Typography variant = "body2" sx = {{ fontSize: '0.75rem' }}>
                            {metric.label}
                          </Typography>
                        </Box>

                        {(index < keyMetrics.length - 1) && (
                          <Divider 
                            orientation = "vertical" 
                            flexItem 
                            sx = {{ 
                              height: '100%',
                              backgroundColor: 'white' 
                            }}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                )}
              </CardContent>
            </Card>
          </motion.div>
        </Box>
      </Tooltip>
    </Grid>
  );
}

export default MetricTile;