import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissions } from '../../lib/accessControl';
import { roles } from '../../utils/roles';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { fetchPostShipmentLists, fetchPreShipmentLists } from '../../store/slices/dealDropdownOptions';
import { abortController } from '../../utils/abortController';
import { produce } from 'immer';
import { BiReset } from 'react-icons/bi';
import { countries } from '../../static/CountryList';
import withAuth from '../../lib/withAuth';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import Button from '@mui/material/Button';
import EditableMaterialReactDataTable from '../../components/EditableMaterialReactDataTable';
import Backdrop from '@mui/material/Backdrop';
import Grid from '@mui/material/Grid2';
import ButtonGroup from '@mui/material/ButtonGroup';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import FileUploader from '../../components/FileUploader';
import Loader from '../../components/Loader';
import Alert from '../../components/Alert';
import Modal from 'react-bootstrap/Modal';
import dayjs from 'dayjs';
import axios from 'axios';

const MassUpdate = ({ logOut = () => {} }) => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const location = useLocation();
  const dispatch = useDispatch();

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* AUTHORIZATION VARIABLES */
  const businessUnits = getPermissions(currentUser?.role, null, "businessUnits");
  const isDocumentTeamRole = currentUser?.role === roles[2];

  /* LIST VARIABLES */
  const [deals, setDeals] = useState([]);
  const preShipmentListData = useSelector((state) => state.dealDropdownOptions.preShipment);  
  const postShipmentListData = useSelector((state) => state.dealDropdownOptions.postShipment);
  const listData = { ...preShipmentListData, ...postShipmentListData };
  const listOptions = useMemo(() => ({
    orderStatus: ["In-Progress", "Cancelled", "Closed"],
    transactionTypes: ["Addition", "Subtraction"],
    buyingPaymentInstrumentStatus: ["Pending", "Transmitted"],
    countries: countries,
    sellingPaymentInstrumentStatus: ["Pending", "Received"],
    validityDays: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
    partialShipments: ["Allowed", "Not Allowed"],
    discountingOptions: ["Yes", "No"]
  }), []);
  const listKeys = [
    "banks",
    "tenors",
    "paymentTerms",
    "traders",
    "incoterms",
    "seaPorts",
    "currencies",
    "packagingTypes",
    "customerCounterParties",
    "supplierCounterParties",
    "shippingLines",
    "productGrades",
    "products",
    "seaPorts",
    "buyingLegFileTypes",
    "sellingLegFileTypes"
  ];
  const [listDataMap, setListDataMap] = useState(new Map([
    ...listKeys.map((key) => [key, listData?.[key]]),
    ...Object.entries(listOptions)
  ]));

  /* SELECTED LIST VARIABLES */
  const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null);
  const [selectedDeals, setSelectedDeals] = useState([]);

  /* DATE VARIABLES */
  const [startDate, setStartDate] = useState(dayjs().startOf("month").format("MM/DD/YYYY"));
  const [endDate, setEndDate] = useState(dayjs().format("MM/DD/YYYY"));

  /* CONDITIONAL FILTER VARIABLES */
  const [isFilterable, setIsFilterable] = useState(true);
  const [filter, setFilter] = useState(true);
  const [isFiltered, setIsFiltered] = useState(true);

  /* FILE VARIABLES */
  const [selectedDealForUpload, setSelectedDealForUpload] = useState(null);
  const [selectedTranche, setSelectedTranche] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const filteredFiles = useMemo(() => 
  {  
    return uploadedFiles.reduce((accumulator, { dealMetaData, files }) => 
    {
      if (dealMetaData.deal_pfi_id === selectedDealForUpload?.id) 
      {
        accumulator.push(...files);
      }
      return accumulator;
    }, []);
  }, [uploadedFiles, selectedDealForUpload]);  
  const formRef = useRef(null);
  
  /* DATATABLE VARIABLES */
  const [dealData, setDealData] = useState([]);
  const [dealInputFieldData, setDealInputFieldData] = useState([]);
  const [editedDealData, setEditedDealData] = useState([]);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [toggleDisplayData, setToggleDisplayData] = useState(false);
  const tableRef = useRef();

  /* DATATABLE GLOBAL FILTER VARIABLES */
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  /* DIALOG VARIABLES */
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  
  /* MODAL VARIABLES */  
  const [showFileModal, setShowFileModal] = useState(false);

  useEffect(() => 
  {
    const fetchLists = async () => 
    {
      axios({
        method: "post",
        url: "/api/GetDealList",
        data: { 
          pathname: location?.pathname 
        },
        signal: abortController.signal
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const deals = data?.deals || [];
          setDeals(deals);
        }
      })
      .catch((error) => 
      {
        console.log("Get Deal List Api: ", error);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });

      axios({
        method: "post",
        url: "/api/GetTrancheList",
        data: { pathname },
        signal: abortController.signal
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const tranches = data?.tranches || [];

          setListDataMap((previousListDataMap) => 
          {
            const updatedMap = new Map(previousListDataMap);
            updatedMap.set("tranches", tranches);
            
            return updatedMap;
          });
        }
      })
      .catch((error) => 
      {
        console.log("Get Tranche List Api: ", error);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    const pathname = location?.pathname;

    dispatch(fetchPreShipmentLists({ pathname }));
    dispatch(fetchPostShipmentLists({ pathname }));
    fetchLists();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() =>
  {
    setSelectedTranche(listDataMap.get("tranches")?.filter(tranche => tranche.deal_pfi_id === selectedDealForUpload?.id)?.[0] || null || []);
  }, [listDataMap, selectedDealForUpload]);

  useEffect(() => 
  {
    if (selectedBusinessUnit || selectedDeals?.length > 0 || startDate || endDate)
    {
      setIsFilterable(true);
    }
    else
    {
      setIsFilterable(false);
    }

  }, [selectedBusinessUnit, selectedDeals, startDate, endDate]);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setIsLoading(true);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      await axios({
        method: "post",
        url: "/api/GetAllDealData",
        data: {
          business_unit: selectedBusinessUnit ? selectedBusinessUnit.id : null,
          deals: selectedDeals?.map(({ id }) => id) || [],
          start_date: startDate,
          end_date: endDate,
          filter_value: globalFilterValue
        },
        signal: abortController.signal
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const { deal_data: dealData = [], deal_inputs: dealInputData = [], tranches = [] } = data || {};

          if (filter && dealData.length === 0)
          {
            setAlertMessage("No data found that matches the selected filter options.");
            setAlertType("info");
            setShowAlert(true);
          }

          setDealData(dealData);
          setDealInputFieldData(dealInputData);
          setListDataMap((previousListDataMap) => new Map(previousListDataMap).set("tranches", tranches));
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.error("Get All Deal Data Api: ", error);
        setIsLoading(false);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        }
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);

          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    fetchData();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [filter, toggleDisplayData]);

  const convertToDayJsObject = (date) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
    return convertedDate;
  }

  const handleDateChange = (newDate, type) => 
  {
    const date = newDate ? dayjs(newDate).format("MM/DD/YYYY") : newDate;
    type === "Start Date" ? setStartDate(date) : setEndDate(date);
  }

  const handleFilter = () => 
  {
    if (isDataChanged)
    {
      setShowWarningDialog(true);
    }
    else
    {
      setIsFiltered(true);
      setFilter(true);
      setToggleDisplayData(!toggleDisplayData);
    }
  }

  const handleReset = () => 
  {
    setSelectedBusinessUnit(null);
    setSelectedDeals([]);
    setStartDate(null);
    setEndDate(null);
    setIsFilterable(false);
    setFilter(false);
    setIsFiltered(false);
    setToggleDisplayData(!toggleDisplayData);
  }

  const handleGlobalFilter = async (rowData) => 
  {
    if (isDataChanged)
    {
      setShowWarningDialog(true);
      return;
    }

    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/api/FilterTableData",
      data: {
        pathname: location?.pathname,
        table_data: rowData,
        filter_value: globalFilterValue
      },
      signal: abortController.signal
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        tableRef?.current?.setFilteredData(data || []);
      }
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Filter Table Data Api: ", error);
      setIsLoading(false);

      if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
      {
        return;
      }

      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      }
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);

        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleUploadFiles = (pfiId, pfiValue) => 
  {
    setSelectedDealForUpload({ id: pfiId, value: pfiValue });
    setShowFileModal(true);
  }

  const handleFileChange = useCallback((newFiles) => 
  {
    setUploadedFiles((previousUploadedFiles) =>
      produce(previousUploadedFiles, (draft) => 
      {    
        const dealPFIID = selectedDealForUpload.id;
        const existingFileIndex = draft.findIndex(
          (file) => file.dealMetaData.deal_pfi_id === dealPFIID
        );

        const updatedFiles = 
        {
          files: newFiles,
          formattedFiles: newFiles.map((file) => ({
            DealPFIId: dealPFIID,
            TrancheID: selectedTranche?.id,
            fileName: file.name,
            docType: `Documentation - ${dealPFIID}`,
            fileExtension: file.name.split('.').pop(),
            tabType: file.tabType,
            fileType: file.docType,
            markForApproval: file.markForApproval
          })),
          dealMetaData: { deal_pfi_id: dealPFIID }
        };
    
        if (existingFileIndex !== -1) 
        {
          draft[existingFileIndex] = updatedFiles;
        } 
        else 
        {
          draft.push(updatedFiles);
        }
      })
    );    
    setIsDataChanged(true);  
    
  }, [selectedDealForUpload, selectedTranche]);

  const handleSaveChanges = async () => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const formData = new FormData();
    const updatedEditedDealData = editedDealData.map(({ value, ...rest }) => ({
      ...rest,
      value: value?.hasOwnProperty("value") ? value.id : value
    }));    
    const uploadedFilesMetadata = [];

    uploadedFiles.forEach((fileGroup) => 
    {
      fileGroup.formattedFiles.forEach((fileMetadata, index) => 
      {
        uploadedFilesMetadata.push(fileMetadata);

        const file = fileGroup.files[index];
        formData.append("files", file);
      });
    });

    formData.append("EditedRecords", JSON.stringify(updatedEditedDealData));
    formData.append("UploadedFiles", JSON.stringify(uploadedFilesMetadata));

    await axios({
      method: "post",
      url: "/api/MassDealUpdate",
      header: { "Content-Type": "multipart/form-data" },
      data: formData,
      signal: abortController.signal
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {        
        setEditedDealData([]);
        setUploadedFiles([]);
        setIsDataChanged(false);
        setAlertMessage(data?.data);
        setAlertType("success");
        setShowAlert(true);
        setTimeout(() => setToggleDisplayData(!toggleDisplayData), 3000);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Mass Deal Update Api: ", error);
      setIsLoading(false);

      if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
      {
        return;
      }

      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);

        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleCloseDialog = () => 
  {
    setShowWarningDialog(false);
  }

  const handleCloseFileModal = () =>
  {
    if (formRef.current?.reportValidity())
    {
      handleCloseModal();
    }
  }

  const handleCloseModal = () => 
  {   
    setSelectedDealForUpload(null);
    setShowFileModal(false);
  }

  return (
    <div className = "content-container d-flex flex-column container mass-update position-relative">
      <Alert
        show = {showAlert}
        message = {alertMessage}
        type = {alertType}
        setShow = {setShowAlert}
      />

      <Backdrop
        sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open = {isLoading}
      >
        <Loader/>
      </Backdrop>

      <h4 className = "page-heading m-0">
        Mass Update
      </h4>

      <Grid container spacing = {1} justifyContent = "center" alignItems = "center">
        {isDocumentTeamRole && (
          <Grid size = {{ xs: 2 }} className = "mass-update-filter autocomplete">
            <VirtualizedAutocomplete
              isMultiple = {false}
              isObject = {true}
              isWritable = {true}
              filterOn = "Business Unit"
              options = {businessUnits}
              selectedOptions = {selectedBusinessUnit}
              handleSelectChange = {(filterOn, newValue) => setSelectedBusinessUnit(newValue)}
            />
          </Grid>
        )}

        <Grid size = {{ xs: 2 }} className = "mass-update-filter autocomplete">
          <VirtualizedAutocomplete
            isMultiple = {true}
            isObject = {true}
            isWritable = {true}
            filterOn = "Deals"
            options = {selectedBusinessUnit ? deals.filter(deal => deal.Business_Unit_Id === selectedBusinessUnit.id) : deals}
            selectedOptions = {selectedDeals || []}
            handleSelectChange = {(filterOn, newValue) => setSelectedDeals(newValue)}
          />
        </Grid>

        <Grid size = {{ xs: 2 }}>
          <LocalizationProvider dateAdapter = {AdapterDayjs}>
            <DesktopDatePicker
              inputFormat = "DD-MMM-YYYY"
              label = "Start Date"
              className = "date-picker w-100"
              maxDate = {convertToDayJsObject(endDate)}
              value = {convertToDayJsObject(startDate)}
              onChange = {(newDate) => handleDateChange(newDate, "Start Date")}
              onAccept = {(newDate) => handleDateChange(newDate, "Start Date")}
              slotProps = {{
                actionBar: {
                  actions: ["clear"]
                },
                textField: {
                  InputProps: {
                    size: "small",
                    disabled: true
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid size = {{ xs: 2 }}>
          <LocalizationProvider dateAdapter = {AdapterDayjs}>
            <DesktopDatePicker
              inputFormat = "DD-MMM-YYYY"
              label = "End Date"
              className = "date-picker w-100"
              minDate = {convertToDayJsObject(startDate)}
              value = {convertToDayJsObject(endDate)}
              onChange = {(newDate) => handleDateChange(newDate, "End Date")}
              onAccept = {(newDate) => handleDateChange(newDate, "End Date")}
              slotProps = {{
                actionBar: {
                  actions: ["clear"]
                },
                textField: {
                  InputProps: {
                    size: "small",
                    disabled: true
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid size = {{ xs: 2 }}>
          <ButtonGroup variant = "contained" disableElevation = {true}>
            <Button type = "submit" className = "filter-button" color = "primary" disabled = {!isFilterable} onClick = {handleFilter}>Filter</Button>
            <Button className = "reset-button" color = "info" disabled = {!isFiltered} startIcon = {<BiReset />} onClick = {handleReset}></Button>
          </ButtonGroup>
        </Grid>
      </Grid>

      <LocalizationProvider dateAdapter = {AdapterDayjs}>
        <EditableMaterialReactDataTable
          ref = {tableRef}
          isLoading = {isLoading}
          isDataChanged = {isDataChanged}
          globalFilterValue = {globalFilterValue}
          tableData = {dealData}
          inputFieldData = {dealInputFieldData}
          listDataMap = {listDataMap}
          convertToDayJsObject = {convertToDayJsObject}
          setGlobalFilterValue = {setGlobalFilterValue}
          setEditedData = {setEditedDealData}
          setIsDataChanged = {setIsDataChanged}
          handleGlobalFilter = {handleGlobalFilter}
          handleUploadFiles = {handleUploadFiles}
          handleSaveChanges = {handleSaveChanges}
        />
      </LocalizationProvider>

      <Dialog open = {showWarningDialog} onClose = {handleCloseDialog}>
        <DialogContent>
          <DialogContentText className = "text-center">
            Please save your changes before applying the filter to avoid losing any unsaved data.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            type = "button"
            variant = "contained"
            className = "btn btn-primary custom-btn"
            onClick = {handleCloseDialog}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>

      <Modal className = {`mass-update-modal autocomplete ${filteredFiles.length > 0 ? 'show-table' : ''}`} show = {showFileModal} onHide = {handleCloseFileModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Upload Files for {selectedDealForUpload?.value}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form ref = {formRef} onSubmit = {(event) => { event.preventDefault(); }}>
            {listDataMap.get("tranches")?.filter(tranche => tranche.deal_pfi_id === selectedDealForUpload?.id)?.length === 0 ? (
              <span>There is no tranche available for document upload.</span>
            ) : (
              <>
                <div className = {`${filteredFiles.length === 0 ? 'w-50' : 'w-32'} mb-3`}>
                  <VirtualizedAutocomplete
                    isMultiple = {false}
                    isObject = {true}
                    isWritable = {true}
                    filterOn = "Select Tranche Name"
                    options = {listDataMap.get("tranches")?.filter(tranche => tranche.deal_pfi_id === selectedDealForUpload?.id) || []}
                    selectedOptions = {selectedTranche || null}
                    handleSelectChange = {(filterOn, newValue) => setSelectedTranche(newValue)}
                  />
                </div>

                {filteredFiles.length === 0 && (
                  <span className = "d-block mb-2">Please select files to upload.</span>
                )}
                
                <FileUploader
                  isWritable = {true}
                  isLoading = {isLoading}
                  pathname = {location?.pathname}
                  uploadedFiles = {filteredFiles}
                  allowFileTypeSelection = {true}
                  buyingLegFileTypes = {listDataMap.get("buyingLegFileTypes")}
                  sellingLegFileTypes = {listDataMap.get("sellingLegFileTypes")}
                  setIsLoading = {setIsLoading}
                  setShowAlert = {setShowAlert}
                  setAlertMessage = {setAlertMessage}
                  setAlertType = {setAlertType}
                  setUploadedFiles = {handleFileChange}
                  triggerSessionExpire = {triggerSessionExpire}
                  logOut = {logOut}
                />
              </>
            )}
          </form>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseFileModal}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default withAuth(MassUpdate)([
  roles[2],
  roles[4],
  roles[5],
  roles[10],
  roles[11],
  roles[13],
  roles[14],
  roles[16],
  roles[17]
]);