import { forwardRef, memo, useImperativeHandle, useMemo, useRef } from 'react';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { abortController } from '../../utils/abortController';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import axios from 'axios';

const BuyingLeg = forwardRef(({ isWritable = false, isListDataLoading = false, isExistingBuyingLeg = false, selectedBusinessUnit = null, selectedPFI = null, listData = {}, formData = {}, dealHeaderFormData = {}, expandedFormSections = [], setIsExistingBuyingLeg = () => {}, setFormData = () => {}, setDealHeaderFormData = () => {}, setExpandedFormSections = () => {}, setShowDeleteModal = () => {}, setIsUpdated = () => {}, setIsFormSavedOrDeleted = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, logOut = () => {} }, ref) => 
{
  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();

  /* LIST VARIABLES */
  const { 
    banks = [],
    incoterms = [],
    traders = [],
    seaPorts = [],
    currencies = [],
    tenors = [],
    paymentTerms = [] 
  } = listData;
  const paymentInstrumentStatus = useMemo(() => ["Pending", "Transmitted"], []);

  /* FORM VARIABLES */
  const formRef = useRef(null);
  
  useImperativeHandle(ref, () => ({
    isValid,
    handleSubmit: handleSubmit,
    handleDelete: handleDelete
  }));

  const convertToDayJsObject = (date) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
    return convertedDate;
  }

  const isValid = () => 
  {
    return formRef.current?.reportValidity();
  }

  const handleFormSectionAccordionChange = (section) => (_, isExpanded) => 
  {
    setExpandedFormSections(isExpanded ? [...expandedFormSections, section] : expandedFormSections.filter(id => id !== section));
  }

  const handleChange = (field, value, isDate = false) => 
  {
    let newValue = value;

    if (isDate && newValue) 
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }

    if (["Buying_Tenor_Id", "Buying_Payment_Terms_Id"].includes(field))
    {
      setDealHeaderFormData((previousDealHeaderFormData) => ({
        ...previousDealHeaderFormData,
        [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null
      }));
    }
    else
    {
      setFormData((previousFormData) => ({
        ...previousFormData,
        [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null
      }));
    }
  }
  
  const handleSubmit = (event) => 
  {
    event?.preventDefault();

    if (!isValid()) 
    {
      return false;
    }

    return new Promise((resolve, reject) => 
    {
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
  
      let updatedFormData = {
        ...formData,
        Business_Unit_Id: selectedBusinessUnit,
        Deal_PFI_Id: selectedPFI,
        User_Id: currentUser?.id
      };
  
      const apiUrl = isExistingBuyingLeg ? "/api/UpdateBuyingLeg" : "/api/AddBuyingLeg";
  
      updatedFormData = Object.fromEntries(Object.entries(updatedFormData).map(([key, value]) => [
        key,
        typeof value === "object" && value?.hasOwnProperty("value") ? value.id : value
      ]));
  
      axios({
        method: "post",
        url: apiUrl,
        data: updatedFormData,
        signal: abortController.signal
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          setIsUpdated(false);
          setIsFormSavedOrDeleted(true);
          resolve(true);
        } 
        else if (status === 400) 
        {
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
          reject(false);
        } 
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
          reject(false);
        }
      })
      .catch((error) => 
      {
        console.log("Add/Update Buying Leg Data Api: ", error);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);

          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }          
        }

        reject(false);
      });
    });
  }
  
  const handleDelete = async () => 
  {
    return new Promise((resolve, reject) => 
    {
      setShowDeleteModal(false);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      axios({
        method: "post",
        url: "/api/DeleteBuyingLeg",
        data: { 
          Business_Unit_Id: selectedBusinessUnit,
          Deal_PFI_Id: selectedPFI,
          User_Id: currentUser?.id
        },
        signal: abortController.signal
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          formRef?.current?.reset();

          setIsExistingBuyingLeg(false);
          setIsUpdated(false);
          setIsFormSavedOrDeleted(false);
          resolve(true);
        } 
        else if (status === 400)
        {
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
          reject(false);
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
          reject(false);
        }
      })
      .catch((error) => 
      {
        console.log("Delete Buying Leg Data Api: ", error);    
        
        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }

        reject(false);
      });
    });
  }

  return (
    <Accordion 
      sx = {{ marginRight: '0.1em !important' }}
      expanded = {expandedFormSections.includes(4)} 
      onChange = {handleFormSectionAccordionChange(4)}
    >
      <AccordionSummary
        id = "panel-header"
        aria-controls = "panel-content"
        expandIcon = {<ExpandMoreIcon />}
        slot = "div"
      >
        <small className = "page-heading title">
          Buying Leg Information
        </small>
      </AccordionSummary>
      <AccordionDetails>
        <form
          ref = {formRef}
          className = "deal-form mt-1"
          onSubmit = {handleSubmit}
        >
          <div className = "d-flex flex-column gap-3">
            <Grid
              container
              spacing = {2}
              className = "align-items-start autocomplete"
            >
              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Buying Payment Terms *"
                  options = {paymentTerms}
                  selectedOptions = {dealHeaderFormData?.Buying_Payment_Terms_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Buying_Payment_Terms_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = {`Buying Tenor ${dealHeaderFormData?.Buying_Payment_Terms_Id?.value === "LC" ? "*" : ""}`}
                  options = {tenors}
                  selectedOptions = {dealHeaderFormData?.Buying_Tenor_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Buying_Tenor_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <TextField
                  label = "Supplier PFI No."
                  id = "Supplier_PFI_No"
                  value = {formData.Supplier_PFI_No || ""}
                  onChange = {(event) => handleChange("Supplier_PFI_No", event.target.value)}
                  slotProps = {{ 
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Supplier Bank"
                  options = {banks}
                  selectedOptions = {formData?.Supplier_Bank_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Supplier_Bank_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Latest Date of Shipment"
                    className = "date-picker w-100"
                    disableMaskedInput
                    value = {convertToDayJsObject(formData?.Latest_Shipment_Date)}
                    readOnly = {!isWritable}
                    onChange = {(newDate) => handleChange("Latest_Shipment_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Latest_Shipment_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Buying Incoterm *"
                  options = {incoterms}
                  selectedOptions = {formData?.Incoterm_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Incoterm_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  isWritable = {isWritable}
                  filterOn = "FZE LC Status"
                  options = {paymentInstrumentStatus}
                  selectedOptions = {formData?.Payment_Instrument_Status || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Payment_Instrument_Status", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <TextField
                  label = "FZE LC Document Presentation Days"
                  type = "number"
                  id = "Document_Presentation_Days"
                  value = {formData?.Document_Presentation_Days || ""}
                  onChange = {(event) => 
                  {
                    const value = event.target.value;

                    if (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 100)) 
                    {
                      handleChange("Document_Presentation_Days", value);
                    }
                  }}
                  slotProps = {{
                    input: { 
                      readOnly: !isWritable,
                      min: 1, 
                      max: 100 
                    } 
                  }}
                  onKeyDown = {(event) => 
                  {
                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                    {
                      event.preventDefault();
                    }
                  }}
                  onInput = {(event) => 
                  {
                    const value = event.target.value;
                    event.target.value = value.replace(/[^0-9]/g, "");
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "FZE LC Expiry Date"
                    disableMaskedInput
                    className = "date-picker w-100"
                    value = {convertToDayJsObject(formData?.LC_Expiry_Date)}
                    readOnly = {!isWritable}
                    onChange = {(newDate) => handleChange("LC_Expiry_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("LC_Expiry_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "FZE LC Confirming Bank"
                  options = {banks}
                  selectedOptions = {formData?.LC_Confirming_Bank_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("LC_Confirming_Bank_Id", newValue)}
                />
              </Grid>
              
              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <TextField
                  label = "PO No."
                  id = "PO_No"
                  value = {formData.PO_No || ""}
                  onChange = {(event) => handleChange("PO_No", event.target.value)}
                  slotProps = {{ 
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Supplier CI Date"
                    disableMaskedInput
                    className = "date-picker w-100"
                    value = {convertToDayJsObject(formData?.Supplier_CI_Date)}
                    readOnly = {!isWritable}
                    onChange = {(newDate) => handleChange("Supplier_CI_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Supplier_CI_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Supplier PFI Date"
                    disableMaskedInput
                    className = "date-picker w-100"
                    value = {convertToDayJsObject(formData?.Supplier_PFI_Date)}
                    readOnly = {!isWritable}
                    onChange = {(newDate) => handleChange("Supplier_PFI_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Supplier_PFI_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Port of Loading *"
                  options = {seaPorts}
                  selectedOptions = {formData?.Port_of_Loading_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Port_of_Loading_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "FZE Bank *"
                  options = {banks}
                  selectedOptions = {formData?.FZE_Bank_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("FZE_Bank_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <TextField
                  label = "FZE LC No."
                  id = "LC_No"
                  value = {formData?.LC_No || ""}
                  onChange = {(event) => handleChange("LC_No", event.target.value)}
                  slotProps = {{ 
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Buying Currency *"
                  options = {currencies}
                  selectedOptions = {formData?.Buying_Currency_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Buying_Currency_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <TextField
                  label = "Advise Through Bank"
                  id = "Advise_Through_Bank"
                  value = {formData.Advise_Through_Bank || ""}
                  onChange = {(event) => handleChange("Advise_Through_Bank", event.target.value)}
                  slotProps = {{ 
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "FZE LC Issue Date"
                    disableMaskedInput
                    className = "date-picker w-100"
                    value = {convertToDayJsObject(formData?.LC_Issue_Date)}
                    readOnly = {!isWritable}
                    onChange = {(newDate) => handleChange("LC_Issue_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("LC_Issue_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "PO Date"
                    disableMaskedInput
                    className = "date-picker w-100"
                    value = {convertToDayJsObject(formData?.PO_Date)}
                    readOnly = {!isWritable}
                    onChange = {(newDate) => handleChange("PO_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("PO_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Trader Name"
                  options = {traders}
                  selectedOptions = {formData?.Trader_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Trader_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <TextField
                  label = "Remaining Balance Payment Days"
                  type = "number"
                  id = "Remaining_Balance_Payment_Days"
                  value = {formData?.Remaining_Balance_Payment_Days || ""}
                  onChange = {(event) => handleChange("Remaining_Balance_Payment_Days", event.target.value)}
                  slotProps = {{
                    input: { 
                      readOnly: !isWritable,
                      min: 0, 
                      max: 2147483647 
                    } 
                  }}
                  onKeyDown = {(event) => 
                  {
                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                    {
                      event.preventDefault();
                    }
                  }}
                  onInput = {(event) => 
                  {
                    const value = event.target.value;
                    event.target.value = value.replace(/[^0-9]/g, "");
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <TextField
                  label = "Supplier CI No."
                  id = "Supplier_CI_No"
                  value = {formData.Supplier_CI_No || ""}
                  onChange = {(event) => handleChange("Supplier_CI_No", event.target.value)}
                  slotProps = {{ 
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 12 }} className = "form-text-field">
                <TextField
                  label = "Special Requirement"
                  id = "Special_Requirement"
                  multiline
                  rows = {3}
                  value = {formData?.Special_Requirement || ""}
                  onChange = {(event) => handleChange("Special_Requirement", event.target.value)}
                  slotProps = {{ 
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </AccordionDetails>
    </Accordion>
  );
});

export default memo(BuyingLeg);