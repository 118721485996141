import { forwardRef, memo, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { abortController } from '../../utils/abortController';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import FileUploader from '../../components/FileUploader';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import ReportDownloader from '../../components/ReportDownloader';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Modal from 'react-bootstrap/Modal';
import ButtonGroup from '@mui/material/ButtonGroup';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import dayjs from 'dayjs';

const Documentation = forwardRef(({ isWritable = false, isDocumentWritable = false, areDocumentsLoading = false, areFilesLoading = false, isExistingDocumentation = false, isFoodsOrPolymersDeal = false, isSBLCDeal = false, isUpdated = false, selectedBusinessUnit = null, selectedPFI = null, selectedTranche = null, listData = {}, documentPermissions = {}, formData = {}, files = [], uploadedFiles = [], toggleDocumentList = false, expandedFormSections = [], setFormData = () => {}, setFiles = () => {}, setUploadedFiles = () => {}, setToggleDocumentList = () => {}, setExpandedFormSections = () => {}, setIsDocumentToBeGenerated = () => {}, setShowConfirmationDialog = () => {}, setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, logOut = () => {} }, ref) => 
{
  const { pathname } = useLocation();

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();
  
  /* LIST VARIABLES */
  const {
    buyingLegFileTypes = [],
    sellingLegFileTypes = []
  } = listData;
  const discountingOptions = useMemo(() => ["Yes", "No"], []);
  const discrepancyAcceptanceSwifts = useMemo(() => ["Yes", "No"], []);
  const transactionTypes = useMemo(() => ["Addition", "Subtraction"], []);
  const buyerDocumentStatus = useMemo(() => ["Received - Clean", "Received - Discrepant"], []);
  const sellerDocumentStatus = useMemo(() => ["Dispatched - Clean", "Dispatched - Discrepant"], []);

  /* FORM VARIABLES */
  const formRef = useRef(null);

  /* SELECTED LIST VARIABLES */
  const [selectedCOO, setSelectedCOO] = useState(null);
  const [selectedDocumentRevisionNumber, setSelectedDocumentRevisionNumber] = useState(null);

  /* DOCUMENT VARIABLES */
  const coos = useSelector(state => state.dealUploadedDocuments.certificatesOfOrigin) || [];
  const documentRevisionNumbers = useSelector(state => state.dealUploadedDocuments.documentRevisionNumbers) || [];
  const documentGenerationData = useSelector(state => state.dealUploadedDocuments.documentGenerationData) || {};
  const [draftDocuments, setDraftDocuments] = useState([]);
  const [approvedDocuments, setApprovedDocuments] = useState([]);
  const [documentType, setDocumentType] = useState(null);
  const [addTermsAndConditionsPage, setAddTermsAndConditionsPage] = useState(false);
  
  /* DOCUMENT BUTTON VARIABLES */
  const [isPFIDocumentGenerating, setIsPFIDocumentGenerating] = useState(false);
  const [isSIDocumentGenerating, setIsSIDocumentGenerating] = useState(false);
  const [isCIDocumentGenerating, setIsCIDocumentGenerating] = useState(false);
  const [isSADocumentGenerating, setIsSADocumentGenerating] = useState(false);
  const [isPLDocumentGenerating, setIsPLDocumentGenerating] = useState(false);
  const [isBEDocumentGenerating, setIsBEDocumentGenerating] = useState(false);
  const [isCOODocumentGenerating, setIsCOODocumentGenerating] = useState(false);
  const pfiDocumentDownloadButtonRef = useRef(null);
  const siDocumentDownloadButtonRef = useRef(null);
  const ciDocumentDownloadButtonRef = useRef(null);
  const saDocumentDownloadButtonRef = useRef(null);
  const plDocumentDownloadButtonRef = useRef(null);
  const beDocumentDownloadButtonRef = useRef(null);
  const cooDocumentDownloadButtonRef = useRef(null);
  const [selectedDocumentGenerationButtonRef, setSelectedDocumentGenerationButtonRef] = useState(null);

  /* INPUT REFERENCE VARIABLES */
  const freightInputRef = useRef(null);
  const fobValueInputRef = useRef(null);

  /* TAB VARIABLES */
  const [selectedTab, setSelectedTab] = useState(0);
  
  /* MODAL VARIABLES */
  const [showDocumentModal, setShowDocumentModal] = useState(false);

  useImperativeHandle(ref, () => (
  {
    isValid,
    handleCloseModal: handleCloseModal
  }));
  
  const convertToDayJsObject = (date) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
    return convertedDate;
  }

  const calculateMaturityDate = (counterPartyType) =>
  {
    const shipmentDate = formData?.Shipment_Date ? dayjs(formData.Shipment_Date) : null;
    const tenorDays = formData?.[counterPartyType === "Customer" ? "Selling_Tenor_Days" : "Buying_Tenor_Days"] || 0;
    const maturityDate = shipmentDate ? shipmentDate.add(tenorDays, 'day') : null;

    return maturityDate;
  }

  const isValid = () => 
  {
    return formRef.current?.reportValidity();
  }

  const handleSectionAccordionChange = (section) => (_, isExpanded) => 
  {
    setExpandedFormSections(isExpanded ? [...expandedFormSections, section] : expandedFormSections.filter(id => id !== section));
  }

  const handleChange = (event, field, value, isDate = false) => 
  {
    let newValue = value;

    if (isDate && newValue)
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }

    if (["Advance_Amount", "Discount", "Freight", "FOB_Value", "Value"].includes(field))
    {
      // Remove any non-numeric characters except the decimal point
      newValue = value.replace(/[^0-9.]/g, "");

      // Ensure the value has at most 2 decimal places
      if (newValue.includes(".")) 
      {
        const [integerPart, decimalPart] = newValue.split(".");
        newValue = `${integerPart}.${decimalPart.substring(0, 2)}`;
      }
    }

    if (field === "Freight" || field === "FOB_Value")
    {
      freightInputRef?.current?.setCustomValidity("");
      fobValueInputRef?.current?.setCustomValidity("");

      let freight = parseInt(formData["Freight"] || 0);
      let fobValue = parseInt(formData["FOB_Value"] || 0);

      if (field === "Freight")
      {
        freight = parseInt(newValue || 0);
      }
      else if (field === "FOB_Value")
      {
        fobValue = parseInt(newValue || 0);
      }

      const calculatedTotalAmount = freight + fobValue;
      const actualTotalAmount = formData["Total_Amount"] || 0;

      if (calculatedTotalAmount > actualTotalAmount)
      {
        event?.target?.setCustomValidity("The calculated total is more than the actual total amount.");
      }
      else if (calculatedTotalAmount < actualTotalAmount)
      {
        event?.target?.setCustomValidity("The calculated total is less than the actual total amount.");
      }
    }

    setFormData((previousFormData) => ({
      ...previousFormData,
      [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null
    }));
  }

  const handleTabChange = (tab) =>
  {
    setSelectedDocumentRevisionNumber(null);
    setSelectedCOO(null);
    setSelectedTab(tab);
  }

  const handleViewDocument = async () =>
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/api/ViewDocument",
      data: { 
        pathname: pathname,
        Deal_PFI_Id: selectedPFI,
        Tranche_Id: selectedTranche?.id,
        Document_Type: documentType,
        COO_ID: selectedCOO?.id,
        Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
        is_approved_document: selectedTab === 1,
        view_document: true
      },
      signal: abortController.signal
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        window.open(data, '_blank', 'noopener, noreferrer');
      }
      else if (status === 202)
      {
        setAlertMessage(data?.data);
        setAlertType("info");
        setShowAlert(true);
      }
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("View Document Api: ", error);
      setIsLoading(false);

      if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
      {
        return;
      }

      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleShowDocumentModal = (documentType, downloadButtonRef) =>
  {
    if (isUpdated)
    {
      setIsDocumentToBeGenerated(true);
      setShowConfirmationDialog(true);

      return;
    }

    setToggleDocumentList(!toggleDocumentList);
    setIsDocumentToBeGenerated(false);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");
    setDocumentType(documentType);
    
    if (documentType !== "COO")
    {
      const selectedDocumentRevisionNumbers = [
        ...new Set(
          documentRevisionNumbers
            .filter(documentRevisionNumber => 
              documentRevisionNumber.Document_Type === documentType &&
              (documentType === "PFI" || documentRevisionNumber.Tranche_Id === selectedTranche?.id)
            )
        )
      ] || [];
      const { 
        is_document_generated: isDocumentGenerated = false, 
        is_document_approved: isDocumentApproved = false 
      } = documentGenerationData[documentType] || {};

      if (selectedDocumentRevisionNumbers.length > 0 || isDocumentGenerated)
      {
        const approvedDocumentRevisionNumbers = selectedDocumentRevisionNumbers.filter(documentRevisionNumber => documentRevisionNumber.isApproved);  
        
        if (isDocumentGenerated)
        {
          selectedDocumentRevisionNumbers.unshift({ id: "Default", value: "Default" });

          if (isDocumentApproved)
          {
            approvedDocumentRevisionNumbers.unshift({ id: "Default", value: "Default" });
          }
        }

        setDraftDocuments(selectedDocumentRevisionNumbers);
        setApprovedDocuments(approvedDocumentRevisionNumbers);
      }

      setSelectedDocumentGenerationButtonRef(downloadButtonRef);
      setShowDocumentModal(true);
    }
    else
    {
      const approvedCertificateDocuments = coos.filter(coo => coo.isApproved);
        
      setDraftDocuments(coos);
      setApprovedDocuments(approvedCertificateDocuments);
      setSelectedDocumentGenerationButtonRef(downloadButtonRef);
      setShowDocumentModal(true);
    }
  }

  const handleGenerateDocument = () =>
  {
    selectedDocumentGenerationButtonRef?.current?.click();
    handleCloseModal();
  }

  const handleCloseModal = () => 
  {
    setDraftDocuments([]);
    setApprovedDocuments([]);
    setSelectedDocumentRevisionNumber(null);
    setAddTermsAndConditionsPage(false);
    setSelectedTab(0);
    setSelectedCOO(null);
    setShowDocumentModal(false);
  }

  const formSectionsHTML = [
    {
      id: 5,
      columns: 12,
      title: "Special Requirements",
      content: (
        <Grid
          container
          spacing = {1}
          className = "align-items-start"
        >
          <Grid size = {{ xs: 4 }} className = "form-text-field">
            <TextField
              label = "SI Special Requirement"
              id = "Special_Requirement_SI"
              multiline
              rows = {3}
              value = {formData?.Special_Requirement_SI || ""}
              onChange = {(event) => handleChange(event, "Special_Requirement_SI", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 4 }} className = "form-text-field">
            <TextField
              label = "PL Special Requirement"
              id = "Special_Requirement_PL"
              multiline
              rows = {3}
              value = {formData?.Special_Requirement_PL || ""}
              onChange = {(event) => handleChange(event, "Special_Requirement_PL", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 4 }} className = "form-text-field">
            <TextField
              label = "CI Special Requirement"
              id = "Special_Requirement_CI"
              multiline
              rows = {3}
              value = {formData?.Special_Requirement_CI || ""}
              onChange = {(event) => handleChange(event, "Special_Requirement_CI", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 4 }} className = "form-text-field">
            <TextField
              label = "BE Special Requirement"
              id = "Special_Requirement_BE"
              multiline
              rows = {3}
              value = {formData?.Special_Requirement_BE || ""}
              onChange = {(event) => handleChange(event, "Special_Requirement_BE", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 4 }} className = "form-text-field">
            <TextField
              label = "SA Special Requirement"
              id = "Special_Requirement_SA"
              multiline
              rows = {3}
              value = {formData?.Special_Requirement_SA || ""}
              onChange = {(event) => handleChange(event, "Special_Requirement_SA", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>
        </Grid>
      )
    },
    {
      id: 6,
      columns: 12,
      title: "CI Freight And Discount Adjustments",
      content: (
        <Grid
          container
          spacing = {2}
          className = "align-items-start"
        >
          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              label = "Advance Amount"
              id = "Advance_Amount"
              type = "number"
              value = {formData?.Advance_Amount || ""}
              onChange = {(event) => handleChange(event, "Advance_Amount", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              label = "Total Amount"
              id = "Total_Amount"
              type = "number"
              value = {formData?.Total_Amount || ""}
              disabled
              slotProps = {{
                input: {
                  readOnly: !isWritable
                },
                inputLabel: { 
                  shrink: formData?.Total_Amount 
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              inputRef = {fobValueInputRef}
              label = "FOB Value"
              id = "FOB_Value"
              type = "number"
              value = {formData?.FOB_Value || ""}
              onChange = {(event) => handleChange(event, "FOB_Value", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              inputRef = {freightInputRef}
              label = "Freight"
              id = "Freight"
              type = "number"
              value = {formData?.Freight || ""}
              onChange = {(event) => handleChange(event, "Freight", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              label = "Discount"
              id = "Discount"
              type = "number"
              value = {formData?.Discount || ""}
              onChange = {(event) => handleChange(event, "Discount", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              label = "Label"
              id = "Label"
              value = {formData?.Label || ""}
              onChange = {(event) => handleChange(event, "Label", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              label = "Value"
              id = "Value"
              type = "number"
              value = {formData?.Value || ""}
              onChange = {(event) => handleChange(event, "Value", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 3 }}>
            <VirtualizedAutocomplete
              isMultiple = {false}
              isObject = {false}
              isWritable = {isWritable}
              filterOn = "Transaction Type"
              options = {transactionTypes}
              selectedOptions = {formData?.Transaction_Type || null}
              handleSelectChange = {(filterOn, newValue) => handleChange(null, "Transaction_Type", newValue)}
            />
          </Grid>
        </Grid>
      )
    },
    {
      id: 7,
      columns: 12,
      title: "Documentation Details",
      content: (
        <Grid
          container
          spacing = {2}
          className = "align-items-start"
        >
          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              label = "AWB from Supplier"
              id = "AWB_from_Supplier"
              value = {formData?.AWB_from_Supplier || ""}
              onChange = {(event) => handleChange(event, "AWB_from_Supplier", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Supplier Document Receiving Date"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Supplier_Document_Receiving_Date)}
                onChange = {(newDate) => handleChange(null, "Supplier_Document_Receiving_Date", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Supplier_Document_Receiving_Date", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              label = "AWB to Customer"
              id = "AWB_To_Customer"
              value = {formData?.AWB_To_Customer || ""}
              onChange = {(event) => handleChange(event, "AWB_To_Customer", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Dispatch Date"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Dispatch_Date)}
                onChange = {(newDate) => handleChange(null, "Dispatch_Date", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Dispatch_Date", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              label = "Disc. Acceptance"
              id = "Disc_Acceptance"
              value = {formData?.Disc_Acceptance || ""}
              onChange = {(event) => handleChange(event, "Disc_Acceptance", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Advance Payment Date"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Advance_Payment_Date)}
                onChange = {(newDate) => handleChange(null, "Advance_Payment_Date", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Advance_Payment_Date", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Advance Payment Receive Date"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Advance_Payment_Receive_Date)}
                onChange = {(newDate) => handleChange(null, "Advance_Payment_Receive_Date", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Advance_Payment_Receive_Date", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Final Payment Date"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Final_Payment_Date)}
                onChange = {(newDate) => handleChange(null, "Final_Payment_Date", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Final_Payment_Date", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Final Payment Receive Date"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Final_Payment_Receive_Date)}
                onChange = {(newDate) => handleChange(null, "Final_Payment_Receive_Date", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Final_Payment_Receive_Date", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          {(formData?.Selling_Payment_Term === "LC" && formData?.Selling_Tenor !== "Sight") && (
            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat = "MM/DD/YYYY"
                  label = "Maturity Date for Customer"
                  disableMaskedInput
                  className = "date-picker w-100"
                  disabled = {true}
                  value = {calculateMaturityDate("Customer")}
                  slotProps = {{
                    textField: {
                      InputProps: {
                        size: "small"
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
          )}

          {(formData?.Buying_Payment_Term === "LC" && formData?.Buying_Tenor !== "Sight") && (
            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat = "MM/DD/YYYY"
                  label = "Maturity Date for Supplier"
                  disableMaskedInput
                  className = "date-picker w-100"
                  disabled = {true}
                  value = {calculateMaturityDate("Supplier")}
                  slotProps = {{
                    textField: {
                      InputProps: {
                        size: "small"
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>
          )}

          <Grid size = {{ xs: 3 }}>
            <VirtualizedAutocomplete
              isMultiple = {false}
              isObject = {false}
              isWritable = {isWritable}
              filterOn = "Discounting Required"
              options = {discountingOptions}
              selectedOptions = {formData?.Discounting_Required || null}
              handleSelectChange = {(filterOn, newValue) => handleChange(null, "Discounting_Required", newValue)}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              label = "Customer Transaction Status"
              id = "Customer_Transaction_Status"
              value = {formData?.Customer_Transaction_Status || ""}
              onChange = {(event) => handleChange(event, "Customer_Transaction_Status", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <TextField
              label = "Supplier Transaction Status"
              id = "Supplier_Transaction_Status"
              value = {formData?.Supplier_Transaction_Status || ""}
              onChange = {(event) => handleChange(event, "Supplier_Transaction_Status", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>

          <Grid size = {{ xs: 4 }} className = "form-text-field">
            <TextField
              label = "Special Requirement"
              id = "Special_Requirement"
              multiline
              rows = {3}
              value = {formData?.Special_Requirement || ""}
              onChange = {(event) => handleChange(event, "Special_Requirement", event.target.value)}
              slotProps = {{
                input: {
                  readOnly: !isWritable
                }
              }}
            />
          </Grid>
        </Grid>
      )
    },
    {
      id: 8,
      columns: 12,
      title: "Target Dates",
      content: (
        <Grid
          container
          spacing = {2}
          className = "align-items-start autocomplete"
        >
          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Target Customer PFI Issuance Date"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Target_Customer_PFI_Issuance_Date)}
                onChange = {(newDate) => handleChange(null, "Target_Customer_PFI_Issuance_Date", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Target_Customer_PFI_Issuance_Date", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Target Customer NN Documents"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Target_Customer_NN_Documents)}
                onChange = {(newDate) => handleChange(null, "Target_Customer_NN_Documents", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Target_Customer_NN_Documents", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
              inputFormat = "MM/DD/YYYY"
              label = "Target Supplier Original Documents"
              disableMaskedInput
              readOnly = {!isWritable}
              className = "date-picker w-100"
              value = {convertToDayJsObject(formData?.Target_Supplier_Original_Documents)}
              onChange = {(newDate) => handleChange(null, "Target_Supplier_Original_Documents", newDate, true)}
              onAccept = {(newDate) => handleChange(null, "Target_Supplier_Original_Documents", newDate, true)}
              slotProps = {{
                actionBar: {
                  actions: ["clear"]
                },
                textField: {
                  InputProps: {
                  size: "small",
                  disabled: true
                  }
                }
              }}
            />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Customer LC Deadline"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Customer_LC_Deadline)}
                onChange = {(newDate) => handleChange(null, "Customer_LC_Deadline", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Customer_LC_Deadline", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Target Supplier LC Issuance Date"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Target_Supplier_LC_Issuance_Date)}
                onChange = {(newDate) => handleChange(null, "Target_Supplier_LC_Issuance_Date", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Target_Supplier_LC_Issuance_Date", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Target Supplier NN Documents"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Target_Supplier_NN_Documents)}
                onChange = {(newDate) => handleChange(null, "Target_Supplier_NN_Documents", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Target_Supplier_NN_Documents", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>

          <Grid size = {{ xs: 3 }} className = "form-text-field">
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
              <DesktopDatePicker
                inputFormat = "MM/DD/YYYY"
                label = "Target Customer Original Documents"
                disableMaskedInput
                readOnly = {!isWritable}
                className = "date-picker w-100"
                value = {convertToDayJsObject(formData?.Target_Customer_Original_Documents)}
                onChange = {(newDate) => handleChange(null, "Target_Customer_Original_Documents", newDate, true)}
                onAccept = {(newDate) => handleChange(null, "Target_Customer_Original_Documents", newDate, true)}
                slotProps = {{
                  actionBar: {
                    actions: ["clear"]
                  },
                  textField: {
                    InputProps: {
                      size: "small",
                      disabled: true
                    }
                  }
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      )
    },
    {
      id: 9,
      columns: 6,
      title: "Document Creation",
      content: (
        <>
          <Grid
            container
            spacing = {2}
            className = "align-items-center autocomplete justify-content-center column-gap-2 document-generation-buttons"
          >
            <Grid size = {{ xs: 6 }} className = "d-flex form-text-field justify-content-center">
              {documentPermissions["PFI"]?.isReadable && (
                <ButtonGroup variant = "contained">
                  <Button sx = {{ cursor: 'default !important', width: '170px' }}>Generate PFI</Button>

                  <IconButton 
                    color = "primary" 
                    disabled = {!selectedPFI || isPFIDocumentGenerating}
                    onClick = {() => handleShowDocumentModal("PFI", pfiDocumentDownloadButtonRef)}
                    sx = {{
                      margin: '5px !important' 
                    }}
                  >
                    {isPFIDocumentGenerating ? (
                      <CircularProgress color = "inherit" />
                    ) : (
                      <FileDownloadIcon fontSize = "small" />
                    )}
                  </IconButton>
                </ButtonGroup>
              )}
            </Grid>
            
            <Grid size = {{ xs: 6 }} className = "d-flex form-text-field justify-content-center">
              {documentPermissions["SI"]?.isReadable && (
                <ButtonGroup variant = "contained" >
                  <Button sx = {{ cursor: 'default !important', width: '170px' }}>Generate SI</Button>

                  <IconButton 
                    color = "primary" 
                    disabled = {!selectedTranche || isSIDocumentGenerating}
                    onClick = {() => handleShowDocumentModal("SI", siDocumentDownloadButtonRef)}
                    sx = {{
                      margin: '5px !important' 
                    }}
                  >
                    {isSIDocumentGenerating ? (
                      <CircularProgress color = "inherit" />
                    ) : (
                      <FileDownloadIcon fontSize = "small" />
                    )}
                  </IconButton>
                </ButtonGroup>
              )}
            </Grid>
            
            <Grid size = {{ xs: 6 }} className = "d-flex form-text-field justify-content-center">
              {documentPermissions["CI"]?.isReadable && (
                <ButtonGroup variant = "contained" >
                  <Button sx = {{ cursor: 'default !important', width: '170px' }}>Generate CI</Button>

                  <IconButton 
                    color = "primary" 
                    disabled = {!selectedTranche || isCIDocumentGenerating}
                    onClick = {() => handleShowDocumentModal("CI", ciDocumentDownloadButtonRef)}
                    sx = {{
                      margin: '5px !important' 
                    }}
                  >
                    {isCIDocumentGenerating ? (
                      <CircularProgress color = "inherit" />
                    ) : (
                      <FileDownloadIcon fontSize = "small" />
                    )}
                  </IconButton>
                </ButtonGroup>
              )}
            </Grid>
            
            <Grid size = {{ xs: 6 }} className = "d-flex form-text-field justify-content-center">
              {documentPermissions["SA"]?.isReadable && (
                <ButtonGroup variant = "contained" >
                  <Button sx = {{ cursor: 'default !important', width: '170px' }}>Generate SA</Button>

                  <IconButton 
                    color = "primary" 
                    disabled = {!selectedTranche || isSADocumentGenerating}
                    onClick = {() => handleShowDocumentModal("SA", saDocumentDownloadButtonRef)}
                    sx = {{
                      margin: '5px !important' 
                    }}
                  >
                    {isSADocumentGenerating ? (
                      <CircularProgress color = "inherit" />
                    ) : (
                      <FileDownloadIcon fontSize = "small" />
                    )}
                  </IconButton>
                </ButtonGroup>
              )}
            </Grid>
            
            <Grid size = {{ xs: 6 }} className = "d-flex form-text-field justify-content-center">
              {documentPermissions["PL"]?.isReadable && (
                <ButtonGroup variant = "contained" >
                  <Button sx = {{ cursor: 'default !important', width: '170px' }}>Generate PL</Button>

                  <IconButton 
                    color = "primary" 
                    disabled = {!selectedTranche || isPLDocumentGenerating}
                    onClick = {() => handleShowDocumentModal("PL", plDocumentDownloadButtonRef)}
                    sx = {{
                      margin: '5px !important' 
                    }}
                  >
                    {isPLDocumentGenerating ? (
                      <CircularProgress color = "inherit" />
                    ) : (
                      <FileDownloadIcon fontSize = "small" />
                    )}
                  </IconButton>
                </ButtonGroup>
              )}
            </Grid>
            
            <Grid size = {{ xs: 6 }} className = "d-flex form-text-field justify-content-center">
              {documentPermissions["BE"]?.isReadable && (
                <ButtonGroup variant = "contained" >
                  <Button sx = {{ cursor: 'default !important', width: '170px' }}>Generate BE</Button>

                  <IconButton 
                    color = "primary" 
                    disabled = {!selectedTranche || isBEDocumentGenerating}
                    onClick = {() => handleShowDocumentModal("BE", beDocumentDownloadButtonRef)}
                    sx = {{
                      margin: '5px !important' 
                    }}
                  >
                    {isBEDocumentGenerating ? (
                      <CircularProgress color = "inherit" />
                    ) : (
                      <FileDownloadIcon fontSize = "small" />
                    )}
                  </IconButton>
                </ButtonGroup>
              )}
            </Grid>

            <Grid size = {{ xs: 6 }} className = "d-flex form-text-field justify-content-center">
              {documentPermissions["COO"]?.isReadable && (
                <ButtonGroup variant = "contained" >
                  <Button sx = {{ cursor: 'default !important', width : '170px' }}>Other Certificates</Button>

                  <IconButton 
                    color = "primary" 
                    disabled = {!selectedTranche || isCOODocumentGenerating}
                    onClick = {() => handleShowDocumentModal("COO", cooDocumentDownloadButtonRef)}
                    sx = {{
                      margin: '5px !important' 
                    }}
                  >
                    {isCOODocumentGenerating ? (
                      <CircularProgress color = "inherit" />
                    ) : (
                      <FileDownloadIcon fontSize = "small" />
                    )}
                  </IconButton>
                </ButtonGroup>
              )}
            </Grid>
          </Grid>

          <ReportDownloader
            ref = {pfiDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/api/GeneratePFI"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit,
              Deal_PFI_Id: selectedPFI,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              add_terms_and_conditions_page: addTermsAndConditionsPage,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            fileName = "PFI"
            buttonText = "Generate PFI"
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsPFIDocumentGenerating}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            ref = {siDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/api/GenerateSI"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            fileName = "SI"
            buttonText = "Generate SI"
            isDisabled =  {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsSIDocumentGenerating}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            ref = {ciDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/api/GenerateCI"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate CI"
            fileName = "CI"
            isDisabled = {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsCIDocumentGenerating}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            ref = {saDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/api/GenerateSA"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate SA"
            fileName = "SA"
            isDisabled = {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsSADocumentGenerating}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            ref = {plDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/api/GeneratePL"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate PL"
            fileName = "PL"
            isDisabled = {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsPLDocumentGenerating}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            ref = {beDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/api/GenerateBE"
            apiData = {{
              Business_Unit_Id: selectedBusinessUnit,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              Revision_Number: selectedDocumentRevisionNumber?.value === "Default" ? "Default" : isNaN(selectedDocumentRevisionNumber?.value?.slice(1)) ? null : parseInt(selectedDocumentRevisionNumber?.value?.slice(1), 10),
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate BE"
            fileName = "BE"
            isDisabled = {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsBEDocumentGenerating}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />

          <ReportDownloader
            ref = {cooDocumentDownloadButtonRef}
            apiMethod = "post"
            api = "/api/GenerateOtherCertificates"
            apiData = {{ 
              Business_Unit_Id: selectedBusinessUnit,
              Deal_PFI_Id: selectedPFI,
              Tranche_Id: selectedTranche?.id,
              COO_ID: selectedCOO?.id,
              User_Name: currentUser?.name,
              User_Id: currentUser?.id,
              is_approved_document: selectedTab === 1,
              is_foods_and_polymers_deal: isFoodsOrPolymersDeal,
              is_sblc_deal: isSBLCDeal
            }}
            buttonText = "Generate Other Certificate"
            fileName = "COO"
            isDisabled =  {!selectedTranche}
            toggleDocumentList = {toggleDocumentList}
            setToggleDocumentList = {setToggleDocumentList}
            handleResetModal = {handleCloseModal}
            setIsLoading = {setIsCOODocumentGenerating}
            setShowAlert = {setShowAlert}
            setAlertMessage = {setAlertMessage}
            setAlertType = {setAlertType}
          />
        </>
      )
    },
    {
      id: 10,
      columns: 6,
      title: "Document Status",
      content: (
        <>
          <Grid
            container
            spacing = {2}
            className = "align-items-start autocomplete mb-3"
          >
            <Grid size = {{ xs: 6 }}>
              <VirtualizedAutocomplete
                isMultiple = {false}
                isObject = {false}
                isWritable = {isWritable}
                filterOn = "Supplier Status"
                options = {buyerDocumentStatus}
                selectedOptions = {formData?.Supplier_Status || null}
                handleSelectChange = {(filterOn, newValue) => handleChange(null, "Supplier_Status", newValue)}
              />
            </Grid>

            {formData?.Supplier_Status?.includes("Discrepant") && (
              <>
                <Grid size = {{ xs: 6 }}>
                  <VirtualizedAutocomplete
                    isMultiple = {false}
                    isObject = {false}
                    isWritable = {isWritable}
                    filterOn = "Supplier Discrepancy Acceptance Swift"
                    options = {discrepancyAcceptanceSwifts}
                    selectedOptions = {formData?.Supplier_Discrepancy_Acceptance_Swift || null}
                    handleSelectChange = {(filterOn, newValue) => handleChange(null, "Supplier_Discrepancy_Acceptance_Swift", newValue)}
                  />
                </Grid>

                <Grid size = {{ xs: 12 }} className = "form-text-field">
                  <TextField
                    label = "Supplier Discrepancy Reason"
                    id = "Supplier_Discrepancy_Reason"
                    multiline
                    rows = {3}
                    value = {formData?.Supplier_Discrepancy_Reason || ""}
                    onChange = {(event) => handleChange(event, "Supplier_Discrepancy_Reason", event.target.value)}
                    slotProps = {{
                      input: {
                        readOnly: !isWritable
                      }
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>

          <Grid
            container
            spacing = {2}
            className = "align-items-start autocomplete"
          >
            <Grid size = {{ xs: 6 }}>
              <VirtualizedAutocomplete
                isMultiple = {false}
                isObject = {false}
                isWritable = {isWritable}
                filterOn = "Customer Status"
                options = {sellerDocumentStatus}
                selectedOptions = {formData?.Customer_Status || null}
                handleSelectChange = {(filterOn, newValue) => handleChange(null, "Customer_Status", newValue)}
              />
            </Grid>

            {formData?.Customer_Status?.includes("Discrepant") && (
              <>
                <Grid size = {{ xs: 6 }}>
                  <VirtualizedAutocomplete
                    isMultiple = {false}
                    isObject = {false}
                    isWritable = {isWritable}
                    filterOn = "Customer Discrepancy Acceptance Swift"
                    options = {discrepancyAcceptanceSwifts}
                    selectedOptions = {formData?.Customer_Discrepancy_Acceptance_Swift || null}
                    handleSelectChange = {(filterOn, newValue) => handleChange(null, "Customer_Discrepancy_Acceptance_Swift", newValue)}
                  />
                </Grid>

                <Grid size = {{ xs: 12 }} className = "form-text-field">
                  <TextField
                    label = "Customer Discrepancy Reason"
                    id = "Customer_Discrepancy_Reason"
                    multiline
                    rows = {3}
                    value = {formData?.Customer_Discrepancy_Reason || ""}
                    onChange = {(event) => handleChange(event, "Customer_Discrepancy_Reason", event.target.value)}
                    slotProps = {{
                      input: {
                        readOnly: !isWritable
                      }
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </>
      )
    },
    {
      id: 11,
      columns: 12,
      title: "Document Library",
      content: (
        <FileUploader
          isWritable = {isWritable ? isDocumentWritable : isExistingDocumentation ? isDocumentWritable : false}
          isLoading = {areFilesLoading}
          pathname = {pathname}
          files = {files}
          uploadedFiles = {uploadedFiles}
          allowFileTypeSelection = {true}
          buyingLegFileTypes = {buyingLegFileTypes}
          sellingLegFileTypes = {sellingLegFileTypes}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          setFiles = {setFiles}
          setUploadedFiles = {setUploadedFiles}
          logOut = {logOut}
        />
      )
    }
  ];

  return (
    <>
      <form ref = {formRef} className = "deal-form multi-form-container">
        <div className = "d-flex flex-wrap gap-2">
          {formSectionsHTML.map((section, index) => (
            <Grid 
              key = {index}
              size = {{ xs: section?.columns }} 
              className = "form-text-field"
            >
              <Accordion
                key = {section.id}
                expanded = {expandedFormSections.includes(section.id)}
                onChange = {handleSectionAccordionChange(section.id)}
              >
                <AccordionSummary
                  id = "panel-header"
                  aria-controls = "panel-content"
                  expandIcon = {<ExpandMoreIcon />}  
                  slot = "div"                  
                >
                  <small className = "page-heading title">
                    {section.title}
                  </small>

                  {section.title === "Target Dates" && (                    
                    <Tooltip arrow title = "The target dates will automatically populate upon saving the form.">
                      <IconButton size = "small" onClick = {(event) => event.stopPropagation()}>
                        <InfoIcon sx = {{ color: '#aab2b6' }} />
                      </IconButton>
                    </Tooltip>
                  )}
                </AccordionSummary>
                <AccordionDetails>
                  {section.content}
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </div>
      </form>

      <Modal show = {showDocumentModal} onHide = {handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>{documentType === "COO" ? "Certificate" : documentType} Document</Modal.Title>
        </Modal.Header>
        <Modal.Body className = "pt-0 px-0">
          <Tabs 
            centered
            value = {selectedTab} 
            onChange = {(event, newValue) => handleTabChange(newValue)} 
            sx = {{ 
              minHeight: '38px !important',
              marginBottom: '1em',
              backgroundColor: '#eef2ff',
              '& .MuiTab-root': {
                minHeight: '38px !important'
              }
            }}
          >
            <Tab label = "Draft Documents" />
            <Tab label = "Approved Documents" />
          </Tabs>

          <div className = "px-3">
            <span>
              {selectedTab === 0 
                ? documentType === "COO" 
                  ? 'Select a draft document to generate or to view.'
                  : "Select a previous document to apply changes, view a document, or click 'Generate Draft Document' to create a new one."
                : 'Select an approved document to view.'
              }
            </span>            

            <div className = "mt-2">
              <VirtualizedAutocomplete
                isLoading = {areDocumentsLoading}
                isMultiple = {false}
                isObject = {true}
                isWritable = {true}
                filterOn = {documentType === "COO" ? "Select Certificate" : "Select Document Revision Number"}
                options = {selectedTab === 0 ? draftDocuments : approvedDocuments}
                selectedOptions = {documentType === "COO" ? selectedCOO : selectedDocumentRevisionNumber || null}
                handleSelectChange = {(filterOn, newValue) => documentType === "COO" ? setSelectedCOO(newValue) : setSelectedDocumentRevisionNumber(newValue)}
              />
            </div>

            {(documentType === "PFI" && selectedTab === 0) && (
              <div className = "d-flex align-items-center gap-1">
                <Checkbox 
                  size = "small"
                  className = "p-0"
                  disabled = {!isWritable}
                  onChange = {(event) => setAddTermsAndConditionsPage(event.target.checked)}
                />

                <Typography fontSize = "small">Include <b>Terms & Conditions</b> with the document?</Typography>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button 
            variant = "contained" 
            size = "small" 
            disabled = {documentType === "COO" ? (!selectedCOO || (selectedTab === 0 && !selectedCOO.isGenerated)) : !selectedDocumentRevisionNumber}
            onClick = {handleViewDocument}
          >
            View Document
          </Button>
          {selectedTab === 0 && (
            <Button 
              variant = "contained" 
              size = "small" 
              disabled = {!isWritable || (selectedTab === 1 && documentType !== "COO" && !selectedDocumentRevisionNumber) || (documentType === "BE" && !documentGenerationData?.["CI"]?.is_document_generated) || (documentType === "COO" && !selectedCOO)}
              onClick = {handleGenerateDocument}
            >
              Generate Draft Document
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default memo(Documentation);