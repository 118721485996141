import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BiReset } from 'react-icons/bi';
import { roles } from '../../utils/roles';
import { getPermissions } from '../../lib/accessControl';
import { abortController } from '../../utils/abortController';
import withAuth from '../../lib/withAuth';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Modal from 'react-bootstrap/Modal';
import Backdrop from '@mui/material/Backdrop';
import Alert from '../../components/Alert';
import Loader from '../../components/Loader';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import MaterialReactDataTable from '../../components/MaterialReactDataTable';
import dayjs from 'dayjs';
import axios from 'axios';

const FinanceAuditTrail = ({ logOut = () => {} }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const { pathname } = useLocation();
  
    /* AUTHENTICATION VARIABLES */
    const currentUser = useUser();
  
    /* AUTHORIZATION VARAIBLES */
    const businessUnits = getPermissions(currentUser?.role, null, "businessUnits");

    /* SESSION EXPIRY VARIABLES */
    const triggerSessionExpire = useSessionExpire();

    /* LIST VARIABLES */
    const [deals, setDeals] = useState([]);

    /* SELECTED LIST VARIABLES */
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null);
    const [selectedDeals, setSelectedDeals] = useState([]);

    /* DATE VARIABLES */
    const [startDate, setStartDate] = useState(dayjs().startOf("month").format("MM/DD/YYYY"));
    const [endDate, setEndDate] = useState(dayjs().format("MM/DD/YYYY"));

    /* DATA VARIABLES */
    const [financeAuditTrailData, setFinanceAuditTrailData] = useState({});
    const [toggleDisplayData, setToggleDisplayData] = useState(false);
    const [isDataChanged, setIsDataChanged] = useState(false);

    /* DATATABLE GLOBAL FILTER VARIABLES */
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    /* CONDITIONAL FILTER VARIABLES */
    const [isFilterable, setIsFilterable] = useState(true);
    const [filter, setFilter] = useState(true);
    const [isFiltered, setIsFiltered] = useState(true);

    /* DATATABLE VARIABLES */
    const tableRef = useRef();

    /* MODAL VARIABLES */  
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);

    useEffect(() =>
    {
        const fetchLists = async () => 
        {
            axios({
                method: "post",
                url: "/api/GetDealList",
                data: { pathname },
                signal: abortController.signal
            })
            .then((response) => 
            {
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const deals = data?.deals || [];
                    setDeals(deals);
                }
            })
            .catch((error) => 
            {
                console.log("Get Deal List Api: ", error);

                if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
                {
                    return;
                }

                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }

        fetchLists();

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() =>
    {
        const fetchData = async () =>
        {
            setIsLoading(true);
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");

            await axios({
                method: "post",
                url: "/api/GetFinanceAuditTrail",
                data: {
                    business_unit: selectedBusinessUnit?.id || null,
                    deals: selectedDeals?.map(({ id }) => id) || [],
                    start_date: startDate,
                    end_date: endDate,
                    filter_value: globalFilterValue
                },
                signal: abortController.signal
            })
            .then((response) => 
            {
                setIsLoading(false);
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const financeAuditTrailData = data?.finance_audit_trail_data || {};
                    const { rows = [] } = financeAuditTrailData;

                    if (filter && rows.length === 0)
                    {
                        setAlertMessage("No data found that matches the selected filter options.");
                        setAlertType("info");
                        setShowAlert(true);
                    }

                    setFinanceAuditTrailData(financeAuditTrailData);
                }
                else
                {
                    setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => 
            {
                console.log("Get Finance Audit Trail Api: ", error);
                setIsLoading(false);

                if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
                {
                    return;
                }

                const status = error?.response?.status;

                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }

        fetchData();

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [filter, toggleDisplayData]);
    
    useEffect(() => 
    {
        if (selectedBusinessUnit || selectedDeals?.length > 0 || startDate || endDate)
        {
            setIsFilterable(true);
        }
        else
        {
            setIsFilterable(false);
        }

    }, [selectedBusinessUnit, selectedDeals, startDate, endDate]);
    
    const convertToDayJsObject = (date) => 
    {
        const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
        return convertedDate;
    }

    const handleDateChange = (newDate, type) => 
    {
        const date = newDate ? dayjs(newDate).format("MM/DD/YYYY") : newDate;
        type === "Start Date" ? setStartDate(date) : setEndDate(date);
    }

    const handleFilter = () => 
    {
        setIsFiltered(true);
        setFilter(true);
        setToggleDisplayData(!toggleDisplayData);
    }

    const handleReset = () =>
    {
        setSelectedBusinessUnit(null);
        setSelectedDeals([]);
        setStartDate(null);
        setEndDate(null);
        setFinanceAuditTrailData({});
        setIsFilterable(false);
        setFilter(false);
        setIsFiltered(false);
        setToggleDisplayData(!toggleDisplayData);
    }

    const handleGlobalFilter = async (rowData) =>
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
        
        await axios({
            method: "post",
            url: "/api/FilterTableData",
            data: { 
                pathname: pathname,
                business_unit_id: selectedBusinessUnit?.id,
                table_data: rowData,
                filter_value: globalFilterValue
            },
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
    
            if (status === 200) 
            {
                tableRef?.current?.setFilteredData(data || []);
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Filter Table Data Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
            
            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleOpenModal = () =>
    {
        setShowDisclaimerModal(true);
    }

    const handleCloseModal = () => 
    {   
        setShowDisclaimerModal(false);
    }

    return (
        <div className = "content-container d-flex flex-column container audit-trail gap-2">
            <Alert
                show = {showAlert}
                message = {alertMessage}
                type = {alertType}
                setShow = {setShowAlert}
            />

            <Backdrop
                sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open = {isLoading}
            >
                <Loader/>
            </Backdrop>

            <h4 className = "page-heading mt-0 mb-2">
                Finance Audit Trail
            </h4>

            <form className = "d-flex flex-column gap-5 mb-2" onSubmit = {(event) => event.preventDefault()}>
                <Grid container spacing = {1} justifyContent = "center" alignItems = "center">
                    <Grid size = {{ xs: 2 }} className = "audit-trail-filter autocomplete">
                        <VirtualizedAutocomplete
                            isMultiple = {false}
                            isObject = {true}
                            isWritable = {true}
                            filterOn = "Business Unit"
                            options = {businessUnits?.filter(businessUnit => businessUnit.value !== "SBLC")}
                            selectedOptions = {selectedBusinessUnit}
                            handleSelectChange = {(filterOn, newValue) => setSelectedBusinessUnit(newValue)}
                        />
                    </Grid>

                    <Grid size = {{ xs: 2 }} className = "audit-trail-filter autocomplete">
                        <VirtualizedAutocomplete
                            isMultiple = {true}
                            isObject = {true}
                            isWritable = {true}
                            filterOn = "Deals"
                            options = {selectedBusinessUnit ? deals.filter(deal => deal.Business_Unit_Id === selectedBusinessUnit.id) : deals}
                            selectedOptions = {selectedDeals || []}
                            handleSelectChange = {(filterOn, newValue) => setSelectedDeals(newValue)}
                        />
                    </Grid>

                    <Grid size = {{ xs: 2 }}>
                        <LocalizationProvider dateAdapter = {AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat = "DD-MMM-YYYY"
                                label = "Start Date"
                                className = "date-picker w-100"
                                maxDate = {convertToDayJsObject(endDate)}
                                value = {convertToDayJsObject(startDate)}
                                onChange = {(newDate) => handleDateChange(newDate, "Start Date")}
                                onAccept = {(newDate) => handleDateChange(newDate, "Start Date")}
                                slotProps = {{
                                    actionBar: {
                                        actions: ["clear"]
                                    },
                                    textField: {
                                        InputProps: {
                                            size: "small",
                                            disabled: true
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid size = {{ xs: 2 }}>
                        <LocalizationProvider dateAdapter = {AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat = "DD-MMM-YYYY"
                                label = "End Date"
                                className = "date-picker w-100"
                                minDate = {convertToDayJsObject(startDate)}
                                value = {convertToDayJsObject(endDate)}
                                onChange = {(newDate) => handleDateChange(newDate, "End Date")}
                                onAccept = {(newDate) => handleDateChange(newDate, "End Date")}
                                slotProps = {{
                                    actionBar: {
                                        actions: ["clear"]
                                    },
                                    textField: {
                                        InputProps: {
                                            size: "small",
                                            disabled: true
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid size = {{ xs: 2 }}>
                        <ButtonGroup variant = "contained" disableElevation = {true} size = "small">
                            <Button type = "submit" className = "filter-button" color = "primary" disabled = {!isFilterable} onClick = {handleFilter}>Filter</Button>
                            <Button className = "reset-button" color = "info" disabled = {!isFiltered} startIcon = {<BiReset />} onClick = {handleReset}></Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </form>
            
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <MaterialReactDataTable
                    ref = {tableRef}
                    title = "Finance Audit Trail"
                    isWritable = {true}
                    isLoading = {isLoading} 
                    isBusinessUnitSelected = {selectedBusinessUnit && isFiltered}
                    isDataChanged = {isDataChanged}
                    showActions = {false}
                    businessUnitId = {selectedBusinessUnit?.id}
                    globalFilterValue = {globalFilterValue}
                    tableData = {financeAuditTrailData}
                    setGlobalFilterValue = {setGlobalFilterValue}
                    setIsDataChanged = {setIsDataChanged}
                    handleGlobalFilter = {handleGlobalFilter}
                    handleOpenAuditTrailModal = {handleOpenModal}
                />
            </LocalizationProvider>

            <Modal show = {showDisclaimerModal} onHide = {handleCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Finance Audit Trail Disclaimer</Modal.Title>
                </Modal.Header>
                <Modal.Body className = "audit-trail-disclaimer">
                    <span>
                        This audit trail is maintained solely for the following purposes:
                    </span>

                    <ul className = "mt-2">
                        <li>Creating new deals and deal PFIs</li>
                        <li>Adding, updating, and deleting pre-shipment data</li>
                        <li>Adding, updating, and deleting post-shipment data</li>
                        <li>
                            Managing products, product grades, and SBLC product grades based on the 
                            selected business unit, including additions, updates, and deletions
                        </li>
                    </ul>

                    <b>Tracked Fields</b>
                    <ul className = "mt-2">
                        <li><strong>Pre-Shipment</strong>
                            <ul>
                                <li>Customer Name</li>
                                <li>Supplier Name</li>
                                <li>Buying Payment Terms</li>
                                <li>Selling Payment Terms</li>
                                <li>Buying Tenor</li>
                                <li>Selling Tenor</li>
                                <li>Buying Incoterm</li>
                                <li>Selling Incoterm</li>
                                <li>Buying Currency</li>
                                <li>Selling Currency</li>
                                <li>Port of Loading</li>
                                <li>Port of Discharge</li>
                                <li>FZE Bank</li>
                                <li>Customer Bank</li>
                                <li>Supplier Bank</li>
                            </ul>
                        </li>
                        <li><strong>Post-Shipment</strong>
                            <ul>
                                <li>Port of Loading</li>
                                <li>Port of Discharge</li>
                                <li>Shipment Date</li>
                                <li>Shipped Quantity</li>
                            </ul>
                        </li>
                    </ul>
                    
                    <p>
                        All recorded activities are intended for security, compliance, and operational 
                        oversight. Unauthorized access, modification, or misuse of this information is 
                        strictly prohibited. The organization reserves the right to monitor and review 
                        audit logs as necessary to ensure compliance with applicable policies and 
                        regulations.
                    </p>
                </Modal.Body>
                <Modal.Footer className = "gap-2">
                    <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default withAuth(FinanceAuditTrail)([
    roles[20]
]);