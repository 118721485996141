import { useState } from 'react';
import { roles } from '../../utils/roles';
import { getPermissions } from '../../lib/accessControl';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BiReset } from 'react-icons/bi';
import withAuth from '../../lib/withAuth';
import useUser from '../../hooks/useUser';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import ReportDownloader from '../../components/ReportDownloader';
import Backdrop from '@mui/material/Backdrop';
import Alert from '../../components/Alert';
import Loader from '../../components/Loader';
import dayjs from 'dayjs';

const DealReport = ({ logOut = () => {} }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");

    /* AUTHENTICATION VARIABLES */
    const currentUser = useUser();

    /* AUTHORIZATION VARAIBLES */
    let businessUnits = getPermissions(currentUser?.role, null, "businessUnits");
    businessUnits = [...businessUnits, { id: "All", value: "All" }];

    /* SELECTED LIST VARIABLES */
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null);

    /* DATE VARIABLES */
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const convertToDayJsObject = (date) => 
    {
        const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
        return convertedDate;
    }

    const handleDateChange = (newDate, type) => 
    {
        const date = newDate ? dayjs(newDate).format("MM/DD/YYYY") : newDate;
        type === "Start Date" ? setStartDate(date) : setEndDate(date);
    }

    const handleReset = () =>
    {
        setSelectedBusinessUnit(null);
        setStartDate(null);
        setEndDate(null);
    }

    return (
        <div className = "content-container d-flex flex-column container reports gap-2">
            <Alert
                show = {showAlert}
                message = {alertMessage}
                type = {alertType}
                setShow = {setShowAlert}
            />

            <Backdrop
                sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open = {isLoading}
            >
                <Loader/>
            </Backdrop>

            <h4 className = "page-heading mt-0 mb-1">
                Deal Report
            </h4>

            <form className = "d-flex flex-column gap-4 mb-3">
                <Grid container spacing = {1} justifyContent = "center" alignItems = "center">
                    <Grid size = {{ xs: 2 }} className = "report-filter autocomplete">
                        <VirtualizedAutocomplete
                            isMultiple = {false}
                            isObject = {true}
                            isRequired = {true}
                            isWritable = {true}
                            filterOn = "Business Unit"
                            options = {businessUnits}
                            selectedOptions = {selectedBusinessUnit}
                            handleSelectChange = {(filterOn, newValue) => setSelectedBusinessUnit(newValue)}
                        />
                    </Grid>

                    <Grid size = {{ xs: 2 }}>
                        <LocalizationProvider dateAdapter = {AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat = "DD-MMM-YYYY"
                                label = "Start Date"
                                className = "date-picker w-100"
                                maxDate = {convertToDayJsObject(endDate)}
                                value = {convertToDayJsObject(startDate)}
                                onChange = {(newDate) => handleDateChange(newDate, "Start Date")}
                                onAccept = {(newDate) => handleDateChange(newDate, "Start Date")}
                                slotProps = {{
                                    actionBar: {
                                        actions: ["clear"]
                                    },
                                    textField: {
                                        InputProps: {
                                            size: "small",
                                            disabled: true
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid size = {{ xs: 2 }}>
                        <LocalizationProvider dateAdapter = {AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat = "DD-MMM-YYYY"
                                label = "End Date"
                                className = "date-picker w-100"
                                minDate = {convertToDayJsObject(startDate)}
                                value = {convertToDayJsObject(endDate)}
                                onChange = {(newDate) => handleDateChange(newDate, "End Date")}
                                onAccept = {(newDate) => handleDateChange(newDate, "End Date")}
                                slotProps = {{
                                    actionBar: {
                                        actions: ["clear"]
                                    },
                                    textField: {
                                        InputProps: {
                                            size: "small",
                                            disabled: true
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid size = {{ xs: 2 }}>
                        <ButtonGroup 
                            variant = "contained" 
                            disableElevation = {true} 
                            size = "small"
                        >
                            <Button
                                className = "reset-button" 
                                color = "info" 
                                disabled = {!(selectedBusinessUnit || startDate || endDate)} 
                                startIcon = {<BiReset />} 
                                onClick = {handleReset}
                            />
                        </ButtonGroup>
                    </Grid>
                </Grid>

                <Grid 
                    container 
                    spacing = {2} 
                    flexDirection = "column" 
                    justifyContent = "center" 
                    paddingX = {2} 
                    gap = {1}
                >
                    <Grid 
                        size = {{ xs: 12 }} 
                        className = "d-flex align-items-center pt-0" 
                        justifyContent ="space-between"
                    >
                        <span>
                            Download the data for all deals and their relevant details in the system.
                        </span>

                        <div className = "report-button">
                            <ReportDownloader
                                isBusinessUnitRequired = {true}
                                apiMethod = "post"
                                api = "/api/GenerateDealReport"
                                apiData = {{ 
                                    businessUnit: selectedBusinessUnit?.id || null, 
                                    startDate, 
                                    endDate
                                }}
                                fileName = "Deal Report"
                                buttonType = "submit"
                                buttonText = "Deal Report"
                                setIsLoading = {setIsLoading}
                                setShowAlert = {setShowAlert}
                                setAlertMessage = {setAlertMessage}
                                setAlertType = {setAlertType}
                                logOut = {logOut}
                            />
                        </div>
                    </Grid>
                </Grid>
            </form>
        </div>
    );
}

export default withAuth(DealReport)([
    roles[4], 
    roles[5], 
    roles[6], 
    roles[7], 
    roles[8], 
    roles[10], 
    roles[11], 
    roles[12], 
    roles[13], 
    roles[14], 
    roles[15], 
    roles[16], 
    roles[17], 
    roles[18], 
    roles[19]
]);