import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { BiReset } from 'react-icons/bi';
import { roles } from '../../utils/roles';
import { getPermissions } from '../../lib/accessControl';
import { abortController } from '../../utils/abortController';
import withAuth from '../../lib/withAuth';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import Grid from '@mui/material/Grid2';
import Popover from '@mui/material/Popover';
import Github from '@uiw/react-color-github';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import MaterialReactDataTable from '../../components/MaterialReactDataTable';
import ReportDownloader from '../../components/ReportDownloader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Backdrop from '@mui/material/Backdrop';
import Alert from '../../components/Alert';
import Loader from '../../components/Loader';
import dayjs from 'dayjs';
import axios from 'axios';

const MorningReport = ({ logOut = () => {} }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const { pathname } = useLocation();

    /* AUTHENTICATION VARIABLES */
    const currentUser = useUser();

    /* AUTHORIZATION VARIABLES */
    const { isWritable = false } = getPermissions(currentUser?.role, pathname);
    let businessUnits = getPermissions(currentUser?.role, null, "businessUnits");
    businessUnits = [...businessUnits, { id: "All", value: "All" }];
    const isFoodsPolymersOrSBLCDeal = businessUnits.some(businessUnit => [1, 4, 6].includes(businessUnit.id));

    /* SESSION EXPIRY VARIABLES */
    const triggerSessionExpire = useSessionExpire();

    /* LIST VARIABLES */
    const [deals, setDeals] = useState([]);

    /* SELECTED LIST VARIABLES */
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState(null);
    const [selectedDeals, setSelectedDeals] = useState([]);

    /* DATE VARIABLES */
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    /* API VARIABLES */
    const [fetchApiUrl, setFetchApiURL] = useState(null);
    const [exportApiUrl, setExportApiURL] = useState(null);

    /* DATA VARIABLES */
    const [morningReportData, setMorningReportData] = useState({});
    const [morningReportRows, setMorningReportRows] = useState([]);
    const [toggleDisplayData, setToggleDisplayData] = useState(false);
    const [isDataChanged, setIsDataChanged] = useState(false);

    /* DATATABLE GLOBAL FILTER VARIABLES */
    const [globalFilterValue, setGlobalFilterValue] = useState("");

    /* DATATABLE ROW COLOR VARIABLES */
    const [rowColor, setRowColor] = useState(null);
    
    /* CONDITIONAL FILTER VARIABLES */
    const [isFilterable, setIsFilterable] = useState(false);
    const [filter, setFilter] = useState(false);
    const [isFiltered, setIsFiltered] = useState(false);

    /* DATATABLE VARIABLES */
    const tableRef = useRef();

    /* BUTTON VARIABLES */
    const downloadButtonRef = useRef(null);

    /* COLOR PICKER VARIABLES */
    const [anchorEl, setAnchorEl] = useState(null);
    const showColorPicker = Boolean(anchorEl);

    /* DIALOG VARIABLES */
    const [showWarningDialog, setShowWarningDialog] = useState(false);  

    useEffect(() =>
    {
        const fetchLists = async () => 
        {
            axios({
                method: "post",
                url: "/api/GetDealList",
                data: { pathname },
                signal: abortController.signal
            })
            .then((response) => 
            {
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const deals = data?.deals || [];
                    setDeals(deals);
                }
            })
            .catch((error) => 
            {
                console.log("Get Deal List Api: ", error);

                if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
                {
                    return;
                }

                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }

        fetchLists();

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, []);

    useEffect(() =>
    {
        const fetchData = async () =>
        {
            setIsLoading(true);
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");

            await axios({
                method: "post",
                url: fetchApiUrl,
                data: {
                    business_unit: selectedBusinessUnit?.id,
                    deals: selectedDeals?.map(({ id }) => id) || [],
                    start_date: startDate,
                    end_date: endDate,
                    row_color: rowColor,
                    filter_value: globalFilterValue
                },
                signal: abortController.signal
            })
            .then((response) => 
            {
                setIsLoading(false);
                const { status, data } = response;
        
                if (status === 200) 
                {
                    const morningReportData = data?.morning_report_data || {};
                    const { rows = [] } = morningReportData;

                    if (filter && rows.length === 0)
                    {
                        setAlertMessage("No data found that matches the selected filter options.");
                        setAlertType("info");
                        setShowAlert(true);
                    }

                    setMorningReportData(morningReportData);
                }
                else
                {
                    setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => 
            {
                console.log("Get Morning Report Data Api: ", error);
                setIsLoading(false);

                if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
                {
                    return;
                }

                const status = error?.response?.status;

                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }

        if (selectedBusinessUnit)
        {
            fetchData();
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [filter, toggleDisplayData]);
    
    useEffect(() => 
    {
        if (selectedBusinessUnit || selectedDeals?.length > 0 || startDate || endDate || rowColor)
        {
            setIsFilterable(true);
        }
        else
        {
            setIsFilterable(false);
        }

    }, [selectedBusinessUnit, selectedDeals, startDate, endDate, rowColor]);

    useEffect(() =>
    {
        let selectedBusinessUnitId = selectedBusinessUnit?.id || null;

        if (selectedBusinessUnitId === "All") 
        {
            selectedBusinessUnitId = businessUnits.filter(businessUnit => businessUnit.id !== 5)?.[0]?.id;
        }

        if ([2, 3, 5].includes(selectedBusinessUnitId))
        {
            setFetchApiURL("/api/GetMorningReportFertilizersAndPetroChemicals");
            setExportApiURL("/api/GenerateMorningReportFertilizersAndPetroChemicals");
        }
        else if ([1, 4, 6].includes(selectedBusinessUnitId))
        {
            setFetchApiURL("/api/GetMorningReportFoodsPolymersAndSBLC");
            setExportApiURL("/api/GenerateMorningReportFoodsPolymersAndSBLC");
        }
        
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [selectedBusinessUnit]);
    
    useEffect(() => 
    {
        if (morningReportRows.length > 0) 
        {
            downloadButtonRef?.current?.click();
        }
    }, [morningReportRows]);
    
    const convertToDayJsObject = (date) => 
    {
        const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
        return convertedDate;
    }

    const extractTableSubRows = (rows) => 
    {
        const selectedBusinessUnitId = selectedBusinessUnit?.id || null;
        const firstColumn = [2, 3, 5].includes(selectedBusinessUnitId) ? "Deal No." : "Supplier Contract";

        return rows.flatMap(row => 
            row.original?.["Supplier Contract"]?.includes("Total") 
            ? [{ 
                ...row.original,  // Spread the main row data
                subRows: []       // Set subRows to empty if needed
            }] 
            : (row.subRows || []).map(subRow => ({
                ...subRow.original,
                [firstColumn]: row.original[firstColumn]  // Assign the value from the main row
            }))
        );
    }
    
    const handleDateChange = (newDate, type) => 
    {
        const date = newDate ? dayjs(newDate).format("MM/DD/YYYY") : newDate;
        type === "Start Date" ? setStartDate(date) : setEndDate(date);
    }

    const handleFilter = () => 
    {
        if (isDataChanged)
        {
            setShowWarningDialog(true);
        }
        else
        {
            setIsFiltered(true);
            setFilter(true);
            setToggleDisplayData(!toggleDisplayData);
        }
    }

    const handleReset = () =>
    {
        setSelectedBusinessUnit(null);
        setSelectedDeals([]);
        setStartDate(null);
        setEndDate(null);
        setRowColor(null);
        setMorningReportData({});
        setMorningReportRows([]);
        setFetchApiURL(null);
        setExportApiURL(null);
        setIsFilterable(false);
        setFilter(false);
        setIsFiltered(false);
        setToggleDisplayData(!toggleDisplayData);
    }

    const handleGlobalFilter = async (rowData) =>
    {
        if (isDataChanged)
        {
            setShowWarningDialog(true);
            return;
        }

        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
        
        await axios({
            method: "post",
            url: "/api/FilterTableData",
            data: { 
                pathname: pathname,
                business_unit_id: selectedBusinessUnit?.id,
                table_data: rowData,
                filter_value: globalFilterValue
            },
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
    
            if (status === 200) 
            {
                tableRef?.current?.setFilteredData(data || []);
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Filter Table Data Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
            
            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleExportReport = (rows) =>
    {
        const tableRows = extractTableSubRows(rows);
        setMorningReportRows(tableRows);
    }

    const handleSaveChanges = async (rows) =>
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        const tableRows = extractTableSubRows(rows);
        
        await axios({
            method: "post",
            url: "/api/SetReportRowColor",
            data: { updated_report_data: tableRows },
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status } = response;
    
            if (status === 200) 
            {
                setAlertMessage("Row colors have been updated successfully.");
                setAlertType("success");
                setShowAlert(true);
                setTimeout(() => setToggleDisplayData(!toggleDisplayData), 3000);
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Save Changes Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
            
            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleCloseDialog = () => 
    {
        setShowWarningDialog(false);
    }

    return (
        <div className = "content-container d-flex flex-column container reports gap-2">
            <Alert
                show = {showAlert}
                message = {alertMessage}
                type = {alertType}
                setShow = {setShowAlert}
            />

            <Backdrop
                sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open = {isLoading}
            >
                <Loader/>
            </Backdrop>

            <h4 className = "page-heading mt-0 mb-2">
                Morning Report
            </h4>
        
            <form className = "d-flex flex-column gap-5 mb-2" onSubmit = {(event) => event.preventDefault()}>
                <Grid container spacing = {1} justifyContent = "center" alignItems = "center">
                    <Grid size = {{ xs: 2 }} className = "report-filter autocomplete">
                        <VirtualizedAutocomplete
                            isMultiple = {false}
                            isObject = {true}
                            isRequired = {true}
                            isWritable = {true}
                            filterOn = "Business Unit"
                            options = {isFoodsPolymersOrSBLCDeal ? businessUnits?.filter(businessUnit => !["Miscellaneous", "SBLC"].includes(businessUnit.value)) : businessUnits}
                            selectedOptions = {selectedBusinessUnit}
                            handleSelectChange = {(filterOn, newValue) => setSelectedBusinessUnit(newValue)}
                        />
                    </Grid>

                    <Grid size = {{ xs: 2 }} className = "report-filter autocomplete">
                        <VirtualizedAutocomplete
                            isMultiple = {true}
                            isObject = {true}
                            isWritable = {true}
                            filterOn = "Deals"
                            options = {selectedBusinessUnit ? deals.filter(deal => deal.Business_Unit_Id === selectedBusinessUnit.id) : deals}
                            selectedOptions = {selectedDeals || []}
                            handleSelectChange = {(filterOn, newValue) => setSelectedDeals(newValue)}
                        />
                    </Grid>

                    <Grid size = {{ xs: 2 }}>
                        <LocalizationProvider dateAdapter = {AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat = "DD-MMM-YYYY"
                                label = "Start Date"
                                className = "date-picker w-100"
                                maxDate = {convertToDayJsObject(endDate)}
                                value = {convertToDayJsObject(startDate)}
                                onChange = {(newDate) => handleDateChange(newDate, "Start Date")}
                                onAccept = {(newDate) => handleDateChange(newDate, "Start Date")}
                                slotProps = {{
                                    actionBar: {
                                        actions: ["clear"]
                                    },
                                    textField: {
                                        InputProps: {
                                            size: "small",
                                            disabled: true
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid size = {{ xs: 2 }}>
                        <LocalizationProvider dateAdapter = {AdapterDayjs}>
                            <DesktopDatePicker
                                inputFormat = "DD-MMM-YYYY"
                                label = "End Date"
                                className = "date-picker w-100"
                                minDate = {convertToDayJsObject(startDate)}
                                value = {convertToDayJsObject(endDate)}
                                onChange = {(newDate) => handleDateChange(newDate, "End Date")}
                                onAccept = {(newDate) => handleDateChange(newDate, "End Date")}
                                slotProps = {{
                                    actionBar: {
                                        actions: ["clear"]
                                    },
                                    textField: {
                                        InputProps: {
                                            size: "small",
                                            disabled: true
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>

                    <Grid size = {{ xs: 2 }}>
                        <TextField
                            size = "small" 
                            label = "Color"
                            className = "color-filter"
                            value = {rowColor || ""}
                            onChange = {() => {}}
                            slotProps = {{ 
                                input: {
                                    readOnly: true,
                                    endAdornment: (
                                        <>
                                            <IconButton 
                                                color = "primary" 
                                                size = "small"
                                                onClick = {(event) => setAnchorEl(event.currentTarget)}
                                            >
                                                <FormatColorFillIcon 
                                                    fontSize = "small" 
                                                    sx = {{ color: '#0000008a' }}
                                                />
                                            </IconButton>
                
                                            <Popover 
                                                open = {showColorPicker}
                                                anchorEl = {anchorEl}
                                                anchorOrigin = {{
                                                    vertical: 'top',
                                                    horizontal: 'center'
                                                }}
                                                transformOrigin = {{
                                                    vertical: 'bottom',
                                                    horizontal: 'center'
                                                }}
                                                onClose = {() => setAnchorEl(null)}
                                            >
                                                <Github
                                                    color = {rowColor}
                                                    colors = {['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB', '#FFFFFF']}
                                                    onChange = {(color) => setRowColor(color.hex)}
                                                />
                                            </Popover>
                                        </>
                                    )
                                }
                            }}
                        />
                    </Grid>

                    <Grid size = {{ xs: 2 }} sx ={{ width: 'unset' }}>
                        <ButtonGroup variant = "contained" disableElevation = {true} size = "small">
                            <Button type = "submit" className = "filter-button" color = "primary" disabled = {!isFilterable} onClick = {handleFilter}>Filter</Button>
                            <Button className = "reset-button" color = "info" disabled = {!isFiltered} startIcon = {<BiReset />} onClick = {handleReset}></Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
            </form>
            
            <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <MaterialReactDataTable
                    ref = {tableRef}
                    title = "Morning Report"
                    isWritable = {isWritable}
                    isLoading = {isLoading} 
                    isBusinessUnitSelected = {selectedBusinessUnit && isFiltered}
                    isReportExportable = {true}
                    isDataChanged = {isDataChanged}
                    businessUnitId = {selectedBusinessUnit?.id}
                    globalFilterValue = {globalFilterValue}
                    tableData = {morningReportData}
                    setGlobalFilterValue = {setGlobalFilterValue}
                    setIsDataChanged = {setIsDataChanged}
                    handleGlobalFilter = {handleGlobalFilter}
                    handleExportRows = {handleExportReport} 
                    handleSaveChanges = {handleSaveChanges}
                />
            </LocalizationProvider>

            <ReportDownloader
                ref = {downloadButtonRef}
                apiMethod = "post"
                api = {exportApiUrl}
                apiData = {{
                    rowData: morningReportRows,  
                    business_unit: selectedBusinessUnit?.id || null
                }}
                fileName = {`${selectedBusinessUnit?.value} Morning Report`}
                buttonType = "submit"
                buttonText = "Morning Report"
                setIsLoading = {setIsLoading}
                setShowAlert = {setShowAlert}
                setAlertMessage = {setAlertMessage}
                setAlertType = {setAlertType}
                logOut = {logOut}
            />

            <Dialog open = {showWarningDialog} onClose = {handleCloseDialog}>
                <DialogContent>
                    <DialogContentText className = "text-center">
                        Please save your changes before applying the filter to avoid losing any unsaved data.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button
                        type = "button"
                        variant = "contained"
                        className = "btn btn-primary custom-btn"
                        onClick = {handleCloseDialog}
                    >
                        Ok
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default withAuth(MorningReport)([
    roles[4], 
    roles[5], 
    roles[6], 
    roles[7], 
    roles[8], 
    roles[10], 
    roles[11], 
    roles[12], 
    roles[13], 
    roles[14], 
    roles[15], 
    roles[16], 
    roles[17], 
    roles[18], 
    roles[19]
]);