import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton } from 'material-react-table';
import { RenderCustomCell } from './DataTableCustomCell';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker, DesktopDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { produce } from 'immer';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Slide from '@mui/material/Slide';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import VirtualizedAutocomplete from './Autocomplete';
import dayjs from 'dayjs';

const EditableMaterialReactDataTable = forwardRef(({ isLoading = false, isDataChanged = false, globalFilterValue = "", tableData = {}, inputFieldData = {}, listDataMap = null, setGlobalFilterValue = () => {}, setEditedData = () => {}, setIsDataChanged = () => {}, convertToDayJsObject = () => {}, handleGlobalFilter = () => {}, handleUploadFiles = () => {}, handleSaveChanges = () => {} }, ref) => 
{
    /* DATATABLE VARIABLES */
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const resetButtonRef = useRef(null);

    /* DATATABLE GLOBAL FILTER VARIABLES */
    const [showGlobalFilterTextField, setShowGlobalFilterTextField] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const searchInputRef = useRef(null);
    const searchButtonRef = useRef(null);

    /* DATATABLE VARIABLE */
    const table = useMaterialReactTable({
        columns,
        data: filteredData,
        enableFacetedValues: true,
        enableColumnResizing: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnVirtualization: true,
        enableRowVirtualization: true,
        enableColumnActions: false,
        enableExpanding: true,
        enableStickyHeader: true,
        enableRowActions: isLoading ? false : columns?.length > 0,
        filterFromLeafRows: true,
        paginateExpandedRows: true,
        enableFilterMatchHighlighting: true,
        enableEditing: isLoading ? false : columns?.length > 0 ? (row) => row.depth > 0 : false,
        editDisplayMode: 'row',
        positionToolbarAlertBanner: 'bottom',
        positionActionsColumn: 'last',
        columnFilterDisplayMode: 'popover',
        autoResetAll: false,
        columnVirtualizerOptions: { overscan: columns.length - 1 },
        initialState: { 
            density: 'compact',
            expanded: false,
            pagination: { 
                pageIndex: 0, 
                pageSize: 20 
            },
            columnPinning: { 
                left: [
                    'mrt-row-expand'
                ] 
            }
        },
        getSubRows: (row) => row.subRows,
        renderTopToolbarCustomActions: ({ table }) => (
            <div>
                <Button 
                    ref = {resetButtonRef} 
                    className = "hidden" 
                    onClick = {() => handleResetTable(table)}
                ></Button>

                <Button
                    startIcon = {<SaveIcon sx = {{ marginTop: '-3px', marginRight: '5px', color: '#0C969C' }} />}
                    size = "small"
                    disabled = {!isDataChanged}
                    onClick = {() => handleSaveChanges(table.getPrePaginationRowModel().rows)}
                >
                    Save Changes
                </Button>
            </div>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <>
                <Slide
                    direction = "left"
                    in = {showGlobalFilterTextField}
                    mountOnEnter
                    unmountOnExit
                >
                    <TextField
                        inputRef = {searchInputRef}
                        placeholder = "Search..."
                        className = "global-search mr-1"
                        value = {globalFilterValue || ""}
                        disabled = {data?.length === 0}
                        onChange = {handleGlobalFilterValueChange}
                        onKeyDown = {handleEnterPress}
                        slotProps = {{
                            inputLabel: {
                                shrink: false
                            },
                            input: {
                                endAdornment: (
                                    <>
                                        <IconButton
                                            title = "Clear"
                                            onClick = {handleClearGlobalFilter}
                                        >
                                            <ClearIcon 
                                                fontSize = "small"
                                                sx = {{ color: '#0C969C' }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            ref = {searchButtonRef}
                                            title = "Search"
                                            onClick = {() => handleGlobalFilter(data)}
                                        >
                                            <SearchIcon 
                                                fontSize = "small"
                                                sx = {{ color: '#0C969C' }}
                                            />
                                        </IconButton>
                                    </>
                                )
                            }
                        }}
                    />
                </Slide>

                <IconButton
                    title = "Search"
                    disabled = {table.getPrePaginationRowModel().rows.length === 0}
                    onClick = {handleShowGlobalFilterTextField}
                >
                    {showGlobalFilterTextField ? (
                        <SearchOffIcon 
                            fontSize = "small"
                            sx = {{ color: '#0C969C' }}
                        /> 
                    ) : ( 
                        <SearchIcon 
                            fontSize = "small"
                            sx = {{ color: '#0C969C' }}
                        />
                    )}
                </IconButton>

                <IconButton
                    title = "Clear Filters"
                    disabled = {table.getPrePaginationRowModel().rows.length === 0}
                    onClick = {() => table.resetColumnFilters()}
                >
                    <FilterAltOffIcon 
                        fontSize = "small"
                        sx = {{ color: '#0C969C' }}
                    />
                </IconButton>

                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <MRT_ShowHideColumnsButton 
                    table = {table}
                    sx = {{ 
                        '> svg': {
                            fill: '#0C969C'
                        } 
                    }}
                />
            </>
        ),
        renderEmptyRowsFallback: () => (
            <p className = "empty-table">
                <small>{!isLoading ? "No records to display." : ""}</small>
            </p>
        ),
        renderRowActions: ({ table, row }) => {
            let rowData = row.original || {};
            const isSubRow = row.depth > 0;

            return (
                <div className = "mx-auto">
                    <Box className = "d-flex justify-content-center align-items-center w-100 gap-2 h-full">
                        {isSubRow && (  
                            <>
                                <Tooltip title = "Edit">
                                    <IconButton 
                                        color = "primary" 
                                        size = "small"
                                        onClick = {() => table.setEditingRow(row)}
                                    >
                                        <EditIcon 
                                            fontSize = "small" 
                                            sx = {{ color: '#0C969C'}} 
                                        />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title = "Upload Files">
                                    <IconButton 
                                        color = "primary" 
                                        size = "small"
                                        onClick = {() => handleUploadFiles(rowData?.deal_pfi_id, rowData?.["PFI No."])}
                                    >
                                        <UploadFileIcon 
                                            fontSize = "small" 
                                            sx = {{ color: '#0C969C'}} 
                                        />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                    </Box>
                </div>
            );
        },
        onEditingRowSave: ({ table, row, values }) => handleSaveRowChanges(table, row, values),
        onEditingRowCancel: ({ table }) => handleDiscardRowChanges(table),
        displayColumnDefOptions: {
            'mrt-row-expand': {
                size: 45,
                grow: false,
                visibleInShowHideMenu: false,
                muiTableBodyCellProps: {
                    sx: {
                        backgroundColor: 'white',
                        border: '1px solid #949495'
                    }
                }
            },
            'mrt-row-actions': {
                size: 100,
                grow: false,
                visibleInShowHideMenu: false
            }
        },
        filterFns: {
            multiSelect: (row, columnId, filterValue) => 
            {
                if (!filterValue || filterValue.length === 0) 
                {
                    return true;
                }
                
                return filterValue.includes(row.getValue(columnId));
            }
        },
        muiTableProps: {
            sx: {
                caption: {
                    captionSide: 'top'
                }
            }
        },
        muiTableContainerProps: {
            sx: { 
                height: 'calc(100vh - 192px)'
            }
        },
        muiTableBodyProps: {
            sx: { 
                height: 'calc(100vh - 192px)',
                '& .MuiTableCell-root': {
                    borderColor :'#e7e7e7'
                },
                '& .MuiTableCell-root:last-of-type': {
                    border: 'none'
                }
            }
        },
        muiTableHeadProps: {
            sx: {
                zIndex: 1,
                '& .MuiTableCell-head:nth-last-of-type(2) > div': {
                    justifyContent: 'center'
                },
                '& .MuiTableCell-head': {
                    paddingTop: 0
                },
                '& .MuiTableCell-head:last-of-type': {
                    boxShadow: 'none',
                    border: 'none'
                }
            }
        },
        muiTableHeadCellProps: {
            sx: {
                backgroundColor: '#0C969C', 
                color:'#ffffff',
                border: '1px solid rgba(81, 81, 81, .5)',
                fontSize: '12px',
                fontWeight:'regular',
                paddingTop: 0,
                paddingBottom: 0,
                '& .Mui-TableHeadCell-Content-Wrapper': {
                    whiteSpace: 'unset'
                },
                '& .MuiSvgIcon-root': {
                    color: '#ffffff !important'
                }
            }
        },
        muiTableBodyRowProps: ({ table, row }) => {
            const isExpanded = row.getCanExpand() && row.getIsExpanded();

            return {
                sx: {
                    height: '50px',
                    fontSize: '12px',
                    backgroundColor: isExpanded ? "#d7d7d8" : "transparent",
                    '&:hover': {
                        backgroundColor: '#ddf3ff'
                    }
                },
                onBlur: (event) =>
                {
                    const relatedTarget = event.relatedTarget;

                    if (!relatedTarget)
                    {
                        handleSaveRowChanges(table, row, row.original)
                    }
                }
            };
        },
        muiTableBodyCellProps: ({ table, column, row }) => {
            const isSubRow = row.depth > 0;
            const isEditing = table.getState().editingRow?.id === row.id;
            let color = "black";
            let backgroundColor = "transparent";
            let fontWeight = "normal";
    
            if (isSubRow && column.id === "Deal No.")
            {
                color = "transparent";
            } 
            else if (["mrt-row-expand", "mrt-row-actions"].includes(column.id)) 
            {
                backgroundColor = "white";
            }
        
            return {
                sx: {
                    border: '1px solid rgba(81, 81, 81, .5)',
                    opacity: 1,
                    overflow: 'visible',
                    whiteSpace: 'wrap',
                    justifyContent: 'center',
                    padding: isEditing
                        ? (["Tab", "Tranche", "Field", "Value"].includes(column.id) ? '0' : '0.5rem') 
                        : '0.5rem',
                    '& > div': {
                        flexDirection: isEditing && column.id === "mrt-row-actions" ? 'row-reverse' : 'row',
                        '& > button:nth-of-type(2) svg': {
                            color: '#0C969C'
                        },
                        '& svg': {
                            fontSize: '17.5px'
                        },
                        '& input': {
                            padding: '0 8px !important',
                            fontSize: '12px !important'
                        },
                        '& fieldset': {
                            border: 'none'
                        }
                    },
                    color,
                    backgroundColor,
                    fontWeight
                }
            };
        },
        muiFilterAutocompleteProps: {
            sx: {
                '& .MuiAutocomplete-input': {
                    width: '100% !important'
                }
            }
        },
        muiLinearProgressProps: {
            sx: {
                color: 'secondary',
                variant: 'indeterminate'
            }
        },
        muiPaginationProps: {
            showRowsPerPage: false,
            showFirstButton: true,
            showLastButton: true
        },
        muiTablePaperProps: {
            sx: {
                '& > .MuiBox-root:last-child': {
                    minHeight: '3em'
                },
                '& > .MuiBox-root:last-child .MuiTablePagination-root': {
                    padding: '10px'
                },
                '& > .MuiBox-root:last-child .MuiTablePagination-root > span': {
                    fontSize: '14px'
                }
            }
        }
    });

    useEffect(() => 
    {
        const defineColumns = () => 
        {
            const excludedColumns = ["id", "deal_id", "deal_pfi_id", "tranche_id", "uuid", "isDeleted"];
            const tableColumns = tableData?.columns?.filter(column => !excludedColumns.includes(column)) || [];
    
            return tableColumns.map(column => 
            {
                const dataType = tableData?.data_types?.[column] || "string";
                const width = 115;
    
                if (column === "Tab") 
                {
                    return createTabColumnObject(width);
                }
                else if (column === "Tranche") 
                {
                    return createTrancheColumnObject(width);
                }
                else if (column === "Field") 
                {
                    return createFieldColumnObject(width);
                }
                else if (column === "Value") 
                {
                    return createValueColumnObject(width);
                }
    
                switch (true) 
                {
                    case dataType.includes("object"):
                        return createColumnObject(column, width, "multi-select", "multiSelect", false);
                    case dataType.includes("int") || dataType.includes("float"):
                        return createColumnObject(column, width, "range", "between", true);
                    case dataType.includes("datetime"):
                        return createColumnObject(column, width, "date-range", "betweenInclusive", false, true);
                    default:
                        return null;
                }
            }).filter(Boolean);
        }
    
        const newColumns = defineColumns();
        const newData = tableData.rows || [];
        const newFilteredData = tableData.filtered_rows || [];

        if (JSON.stringify(newColumns) !== JSON.stringify(columns)) 
        {
            setColumns(newColumns);
        }

        if (JSON.stringify(newData) !== JSON.stringify(data)) 
        {
            setData(newData);
        }

        if (JSON.stringify(newFilteredData) !== JSON.stringify(filteredData)) 
        {
            setFilteredData(newFilteredData);
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [tableData]);
    
    useImperativeHandle(ref, () => ({
        filteredData,
        setFilteredData
    }));
    
    const isSimpleArray = (array) => 
    {
        if (!Array.isArray(array) || array.length === 0) return false;
        return array.every(item => typeof item !== "object" || item === null);
    }

    const createColumnObject = (column, width, filterVariant, filterFn, isNumber, isDate = false) => (
    {
        accessorFn: (row) => isDate ? new Date(row[column]) : row[column],
        id: column,
        header: column === "Deal No" ? "Deal No." : column,
        size: width,
        filterVariant,
        filterFn,
        enableEditing: false,
        Header: ({ column }) => (
            <Tooltip title = {column.columnDef.header} arrow>
                <div>{column.columnDef.header}</div>
            </Tooltip>
        ),
        Cell: ({ column: col, row }) => 
        {
            const value = row.original?.[column];
            
            return (
                <RenderCustomCell
                    value = {isDate ? value?.toLocaleDateString() : value}
                    width = {col.getSize()}
                    isNumber = {isNumber}
                />
            );
        }
    })
    
    const createTabColumnObject = (width) => (
    {
        accessorFn: (row) => row["Tab"],
        id: "Tab",
        header: "Tab",
        size: width,
        Header: ({ column }) => (
            <Tooltip title = {column.columnDef.header} arrow>
                <div>{column.columnDef.header}</div>
            </Tooltip>
        ),
        Cell: ({ column, row }) => 
        {
            const value = row.original?.["Tab"];
            
            return (
                <RenderCustomCell
                    value = {value}
                    width = {column.getSize()}
                />
            );
        },
        Edit: ({ row }) => (
            <div className = "w-100 autocomplete table-autocomplete">
                <VirtualizedAutocomplete
                    isMultiple = {false}
                    isObject = {false}
                    isPlaceHolder = {true}
                    isWritable = {true}
                    filterOn = "Select Tab"
                    options = {["Pre-Shipment", "Post-Shipment"]}
                    selectedOptions = {row.original?.["Tab"] || "Post-Shipment"}
                    handleSelectChange = {(filterOn, newValue) => handleEditCell(row, "Tab", null, newValue)}
                />
            </div>
        )
    });

    const createTrancheColumnObject = (width) => (
    {
        accessorFn: (row) => row["Tranche"],
        id: "Tranche",
        header: "Tranche",
        size: width,
        enableEditing: (row) => row.original?.["Tab"] === "Post-Shipment" || !row.original?.["Tab"],
        Cell: ({ column, row }) => 
        {
            const value = row.original?.["Tranche"];
            
            return (
                <RenderCustomCell
                    value = {value?.value}
                    width = {column.getSize()}
                />
            );
        },
        Edit: ({ row }) => 
        {
            const tranches = listDataMap.get("tranches")?.filter(
                tranche => tranche.deal_pfi_id === row.original.deal_pfi_id
            ) || [];              

            return (
                <div className = "w-100 autocomplete table-autocomplete">
                    <VirtualizedAutocomplete
                        isMultiple = {false}
                        isObject = {true}
                        isPlaceHolder = {true}
                        isWritable = {true}
                        filterOn = "Select Tranche"
                        options = {tranches}
                        selectedOptions = {row.original?.["Tranche"] || tranches?.[0]}
                        handleSelectChange = {(filterOn, newValue) => handleEditCell(row, "Tranche", null, newValue)}
                    />
                </div>
            );
        }
    });

    const createFieldColumnObject = (width) => (
    {
        accessorFn: (row) => row["Field"],
        id: "Field",
        header: "Field",
        size: width,
        enableEditing: (row) => (row.original?.["Tab"] === "Post-Shipment" || !row.original?.["Tab"]) 
            ? (listDataMap.get("tranches")?.length > 0) 
            : row.original?.["Tab"] === "Pre-Shipment",
        Cell: ({ column, row }) => 
        {
            const value = row.original?.["Field"];
            
            return (
                <RenderCustomCell
                    value = {value}
                    width = {column.getSize()}
                />
            );
        },
        Edit: ({ row }) =>  (
            <div className = "w-100 autocomplete table-autocomplete">
                <VirtualizedAutocomplete
                    isMultiple = {false}
                    isObject = {false}
                    isPlaceHolder = {true}
                    isWritable = {true}
                    filterOn = "Select Field"
                    options = {Object.keys(inputFieldData[row.original["Tab"]] || inputFieldData["Post-Shipment"] || {})}
                    selectedOptions = {row.original?.["Field"]}
                    handleSelectChange = {(filterOn, newValue) => handleEditCell(row, "Field", null, newValue)}
                />
            </div>
        )
    });

    const createValueColumnObject = (width) => (
    {
        accessorFn: (row) => row["Value"],
        id: "Value",
        header: "Value",
        size: width,
        Cell: ({ column, row }) => 
        {
            const value = row.original?.["Value"];
            
            return (
                <RenderCustomCell
                    value = {value ? (typeof value === "object" && !Array.isArray(value)) ? value.value : value : value}
                    width = {column.getSize()}
                    isNumber = {typeof value === "number" && !isNaN(value)}
                />
            );
        },
        Edit: ({ row }) => 
        {
            const { Tab: tab, Field: field } = row.original;
            const fieldConfig = inputFieldData[tab]?.[field];
    
            if (!fieldConfig) return null;
    
            switch (fieldConfig.type) 
            {
                case "single-select dropdown":
                    const options = listDataMap.get(fieldConfig.dropdown) || [];

                    return (
                        <div className = "w-100 autocomplete table-autocomplete">
                            <VirtualizedAutocomplete
                                isMultiple = {false}
                                isObject = {!isSimpleArray(options)}
                                isPlaceHolder = {true}
                                isWritable = {true}
                                filterOn = {`Select ${field}`}
                                options = {options}
                                selectedOptions = {row.original?.[field]}
                                handleSelectChange = {(filterOn, newValue) => handleEditCell(row, "Value", field, newValue)}
                            />
                        </div>
                    );
                case "number input":
                    return (
                        <TextField
                            type = "number"
                            variant = "outlined"
                            size = "small"
                            fullWidth
                            placeholder = {`Enter ${field}`}
                            value = {row.original?.[field] || ""}
                            onChange = {(event) => handleEditCell(row, "Value", field, event.target.value)}
                            slotProps = {{
                                input: { 
                                    min: 0, 
                                    max: 2147483647,
                                    step: ["Quantity", "Shipped Quantity", "Gross Weight KGs", "Net Weight KGs"].includes(field) ? "0.001" : "0.01"
                                } 
                            }}
                            onKeyDown = {(event) => 
                            {
                                if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                {
                                    event.preventDefault();
                                }
                            }}
                        />
                    );
                case "string input":
                    return (
                        <TextField
                            variant = "outlined"
                            size = "small"
                            fullWidth
                            placeholder = {`Enter ${field}`}
                            value = {row.original?.[field] || ""}
                            onChange = {(event) => handleEditCell(row, "Value", field, event.target.value)}
                        />
                    );
                case "date":
                    return (
                        <LocalizationProvider dateAdapter = {AdapterDayjs}>
                            <DesktopDatePicker
                                className = "date-picker w-100"
                                placeholder = {`Enter ${field}`}
                                inputFormat = "MM/DD/YYYY"
                                disableMaskedInput
                                value = {convertToDayJsObject(row.original?.[field])}
                                onChange = {(newDate) => handleEditCell(row, "Value", field, newDate, true)}
                                onAccept = {(newDate) => handleEditCell(row, "Value", field, newDate, true)}
                                slotProps = {{
                                    actionBar: {
                                        actions: ["clear"]
                                    },
                                    textField: {
                                        InputProps: {
                                            size: "small",
                                            disabled: true
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    );
                case "datetime":
                    return (
                        <LocalizationProvider dateAdapter = {AdapterDayjs}>
                            <DesktopDateTimePicker
                                inputFormat = "MM/DD/YYYY hh:mm A"
                                className = "datetime-picker w-100"
                                placeholder = {`Enter ${field}`}
                                disableMaskedInput
                                value = {row.original?.[field] ? dayjs(row.original?.[field]) : null}
                                onChange = {(newDateTime) => handleEditCell(row, "Value", field, newDateTime, true)}
                                onAccept = {(newDateTime) => handleEditCell(row, "Value", field, newDateTime, true)}
                                slotProps = {{
                                    actionBar: {
                                        actions: ["clear"]
                                    },
                                    textField: {
                                        InputProps: {
                                            size: "small",
                                            disabled: true
                                        }
                                    }
                                }}
                            />
                        </LocalizationProvider>
                    );
                default:
                    return (
                        <TextField
                            variant = "outlined"
                            size = "small"
                            fullWidth
                            placeholder = {`Enter ${field}`}
                            value = {row.original?.[field] || ""}
                            onChange = {(event) => handleEditCell(row, "Value", field, event.target.value)}
                        />
                    );
            }
        }
    });

    const handleEditCell = (row, field, subField, newValue, isDate = false) => 
    {
        if (newValue) 
        {
            if (isDate) 
            {
                newValue = dayjs(newValue).format("MM/DD/YYYY");
            } 
            else if (["Quantity", "Shipped Quantity", "Buying Price", "Selling Price", "Advance Amount", "Discount", "Value", "Gross Weight KGs", "Net Weight KGs"].includes(subField)) 
            {
                newValue = newValue.replace(/[^0-9.]/g, "");
                
                if (newValue.includes(".")) 
                {
                    const [integerPart, decimalPart] = newValue.split(".");
                    
                    if (["Quantity", "Shipped Quantity", "Gross Weight KGs", "Net Weight KGs"].includes(field)) 
                    {
                        newValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
                    } 
                    else 
                    {
                        newValue = `${integerPart}.${decimalPart.substring(0, 2)}`;
                    }
                }
            }
        }

        setFilteredData((previousFilteredData) => 
            produce(previousFilteredData, (draft) => 
            {
                const parentRow = draft.find(draftRow => parseInt(draftRow.deal_id) === parseInt(row.original.deal_id));

                if (parentRow) 
                {
                    const subRow = parentRow.subRows.find(subRow => parseInt(subRow.deal_pfi_id) === parseInt(row.original.deal_pfi_id));

                    if (subRow) 
                    {
                        const tranches = listDataMap.get("tranches")?.filter(
                            tranche => tranche.deal_pfi_id === row.original.deal_pfi_id
                        ) || [];

                        if (field === "Tab") 
                        {
                            subRow["Tranche"] = newValue === "Post-Shipment" ? tranches?.[0] : null;
                            subRow["Field"] = null;
                            subRow["Value"] = null;
                        } 
                        else if (field === "Field") 
                        {
                            subRow["Tab"] ||= "Post-Shipment";
                            subRow["Tranche"] = subRow["Tab"] === "Post-Shipment" ? subRow["Tranche"] ? subRow["Tranche"] : tranches?.[0] : null;
                            subRow["Value"] = null;
                        }
                        else if (field === "Value" && subField) 
                        {
                            subRow[subField] = newValue || "";
                        }

                        subRow[field] = newValue;
                    }
                }
            })
        );
    }

    const handleSaveRowChanges = (table, row, values) =>
    {
        const { Tab: tab, Field: field } = values;
        const fieldConfig = inputFieldData[tab]?.[field];

        if (fieldConfig) 
        {    
            setEditedData((previousEditedData) =>
                produce(previousEditedData, (draft) => 
                {
                    const existingRow = draft.find(item => item.deal_pfi_id === row.original.deal_pfi_id);
              
                    if (existingRow) 
                    {
                        existingRow.tab = values.Tab;
                        existingRow.section = fieldConfig.section;
                        existingRow.tranche_id = values.Tab === "Post-Shipment" ? values.Tranche?.id : null;
                        existingRow.field = fieldConfig.field;
                        existingRow.value = values.Value;
                    } 
                    else 
                    {
                        draft.push({
                            deal_id: row.original.deal_id,
                            deal_pfi_id: row.original.deal_pfi_id,
                            tab: values.Tab,
                            section: fieldConfig.section,
                            tranche_id: values.Tab === "Post-Shipment" ? values.Tranche?.id : null,
                            field: fieldConfig.field,
                            value: values.Value
                        });
                    }
                })
            );  
            
            setIsDataChanged(true);
        }

        table.setEditingRow(null);
    }

    const handleDiscardRowChanges = (table) =>
    {
        table.setEditingRow(null);
    }

    const handleEnterPress = (event) => 
    {
        if (event.key === "Enter") 
        {
            event.preventDefault();
            searchButtonRef?.current?.click();
        }
    }

    const handleShowGlobalFilterTextField = () => 
    {
        if (showGlobalFilterTextField) 
        {
            setShowGlobalFilterTextField(false);
            handleClearGlobalFilter();
        }
        else 
        {
            setShowGlobalFilterTextField(true);
            setTimeout(() => searchInputRef.current.focus(), 0);
        }
    }
    
    const handleGlobalFilterValueChange = (event) => 
    {
        const { value } = event.target;
        setGlobalFilterValue(value);

        if (!value) 
        {
            setFilteredData(data);
        }
    }

    const handleClearGlobalFilter = () => 
    {
        setGlobalFilterValue("");
        setFilteredData(data);
    }

    const handleResetTable = (table) =>
    {
        table.resetColumnFilters();
        table.resetExpanded();
        table.resetRowSelection();
    }

    return (
        <div className = "h-100">
            <MaterialReactTable table = {table} />
        </div>
    );
});

export default memo(EditableMaterialReactDataTable);