import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { roles } from '../../utils/roles';
import { getPermissions } from '../../lib/accessControl';
import useUser from '../../hooks/useUser';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import withAuth from '../../lib/withAuth';
import Currency from './Currency';
import Inconterm from './Incoterm';
import PackagingType from './PackagingType';
import PaymentTerm from './PaymentTerm';
import ProductGrade from './ProductGrade';
import SeaPort from './SeaPort';
import ShippingLine from './ShippingLine';
import UnitofMeasurement from './UnitofMeasurement';

const Dropdowns = ({ logOut = () => {} }) => 
{
  const { pathname } = useLocation();

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* AUTHORIZATION VARIABLES */
  const { isWritable = false } = getPermissions(currentUser?.role, pathname);

  /* DROPDOWN VARIABLES */
  const dropdowns = useMemo(() => ["Currency", "Incoterm", "Packaging Type", "Payment Term", "Product Grade", "Sea Port", "Shipping Line", "Unit of Measurement"], []);
  const [selectedDropdown, setSelectedDropdown] = useState(null);

  return (
    <div className = "content-container d-flex flex-column container entities dropdown">
      <div className = "autocomplete mt-1">
        <VirtualizedAutocomplete
          isMultiple = {false}
          isObject = {false}
          isWritable = {true}
          filterOn = "Select Dropdown"
          options = {dropdowns}
          selectedOptions = {selectedDropdown}
          handleSelectChange = {(filterOn, newValue) => setSelectedDropdown(newValue)}
        />
      </div>

      {selectedDropdown === "Currency" ? (
        <Currency 
          isWritable = {isWritable}
          pathname = {pathname}
          logOut = {logOut}
        />
      ) : selectedDropdown === "Incoterm" ? (
        <Inconterm 
          isWritable = {isWritable}
          pathname = {pathname}
          logOut = {logOut}
        />
      ) : selectedDropdown === "Packaging Type" ? (
        <PackagingType
          isWritable = {isWritable}
          pathname = {pathname}
          logOut = {logOut}
        />
      ) : selectedDropdown === "Payment Term" ? (
        <PaymentTerm 
          isWritable = {isWritable}
          pathname = {pathname}
          logOut = {logOut}
        />
      ) : selectedDropdown === "Product Grade" ? (
        <ProductGrade 
          isWritable = {isWritable}
          pathname = {pathname}
          logOut = {logOut}
        />
      ) : selectedDropdown === "Sea Port" ? (
        <SeaPort 
          isWritable = {isWritable}
          pathname = {pathname}
          logOut = {logOut}
        />
      ) : selectedDropdown === "Shipping Line" ? (
        <ShippingLine 
          isWritable = {isWritable}
          pathname = {pathname}
          logOut = {logOut}
        />
      ) : selectedDropdown === "Unit of Measurement" && (
        <UnitofMeasurement 
          isWritable = {isWritable}
          pathname = {pathname}
          logOut = {logOut}
        />
      )}
    </div>
  );
}

export default withAuth(Dropdowns)([
  roles[9],
  roles[19]
]);