import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { abortController } from '../../utils/abortController';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DeleteIcon from '@mui/icons-material/Delete';
import Modal from 'react-bootstrap/Modal';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import IconButton from '@mui/material/IconButton';
import AddBoxIcon from '@mui/icons-material/AddBox';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DealSBLC from './DealSBLC';
import dayjs from 'dayjs';
import axios from 'axios';

dayjs.extend(isSameOrBefore);

const ShipmentDetails = forwardRef(({ isWritable = false, isDocumentWritable = false, isListDataLoading = false, isExistingShipmentDetails = false, isFormSavedOrDeleted = false, isFoodsOrPolymersDeal = false, isFoodsPolymersOrSBLCDeal = false, isSBLCDeal = false, isUpdated = false, selectedBusinessUnit = null, selectedPFI = null, selectedTranche = null, selectedTrancheToCopy = null, listData = {}, formData = {}, sblcInitialFormData = {}, sblcFormData = {}, files = [], uploadedFiles = [], toggleListData = false, toggleDocumentList = false, toggleFileList = false, expandedFormSections = [], setIsExistingShipmentDetails = () => {}, setIsFormUpdated = () => {}, setIsSBLCFormUpdated = () => {}, setIsFormSavedOrDeleted = () => {}, setTranches = () => {}, setSelectedTranche = () => {}, setSelectedTrancheToCopy = () => {}, setFormData = () => {}, setSBLCFormData = () => {}, setFiles = () => {}, setUploadedFiles = () => {}, setToggleListData = () => {}, setToggleDocumentList = () => {}, setToggleFileList = () => {}, setExpandedFormSections = () => {}, setShowDeleteModal = () => {}, setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, handleChange = () => {}, logOut = () => {} }, ref) => 
{
  const { pathname } = useLocation();

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();
  
  /* LIST VARIABLES */
  const {
    tranches = [],
    products = [],
    productGrades = [],
    seaPorts = [],
    shippingLines = []
  } = listData;
  const partialShipments = useMemo(() => ["Allowed", "Not Allowed"], []);

  /* FORM VARIABLES */
  const formRef = useRef(null);

  /* SBLC VARIABLES */
  const [isExistingSBLC, setIsExistingSBLC] = useState(false);

  /* ACCORDION VARIABLES */
  const [isProductOrProductGradeAccordionExpanded, setIsProductOrProductGradeAccordionExpanded] = useState(null);
  const [isPFIAccordionExpanded, setIsPFIAccordionExpanded] = useState(null);
  const [isProductGradeAccordionExpanded, setIsProductGradeAccordionExpanded] = useState(null);
  const [isCertificateAccodionExpanded, setIsCertificateAccodionExpanded] = useState(null);
  const productOrProductGradeAccordionRefs = useRef([]);
  const pfiAccordionRefs = useRef([]);
  const productGradeAccordionRefs = useRef([]);
  const certificateAccordionRefs = useRef([]);

  /* DIALOG VARIABLES */
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  /* MODAL VARIABLES */
  const [showAddModal, setShowAddModal] = useState(false);
 
  useEffect(() =>
  {
    if (isFoodsOrPolymersDeal)
    {
      setIsProductOrProductGradeAccordionExpanded((formData?.tranche_product_grades_selected?.length ?? 0) - 1);
    }
    else
    {
      setIsProductOrProductGradeAccordionExpanded((formData?.tranche_products_selected?.length ?? 0) - 1);
    }
  }, [isFoodsOrPolymersDeal, formData?.tranche_products_selected, formData?.tranche_product_grades_selected]);

  useEffect(() =>
  {
    setIsCertificateAccodionExpanded((formData?.Certificates_of_Origin?.length ?? 0) - 1);
  }, [formData?.Certificates_of_Origin]);

  useEffect(() => 
  {   
    if (isSBLCDeal)
    {
      // Check if the 'sblcFormData' is empty
      const isEmpty = typeof sblcFormData === "object" && !sblcFormData?.Deal_PFI_Id;

      // Set existing deal flags
      if (!isEmpty && !selectedTrancheToCopy) 
      {
        setIsExistingSBLC(true);
      }
      else
      {
        setIsExistingSBLC(false);
      }
    
      if (!isFormSavedOrDeleted && !selectedTrancheToCopy)
      {
        const isSBLCFormUpdated = checkIfFormUpdated(sblcFormData, sblcInitialFormData);
        setIsSBLCFormUpdated(isSBLCFormUpdated);
      }
    }
    
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isSBLCDeal, sblcFormData, selectedTrancheToCopy]);

  useImperativeHandle(ref, () => ({
    isValid,
    handleSubmit: handleSubmit,
    handleDelete: handleDelete,
    handleAddTrancheWrapper: handleAddTrancheWrapper,
    handleCloseModal: handleCloseModal
  }));

  const checkIfFormUpdated = (formData, initialFormData, dateKeys = []) => 
  {
    // Check non-date fields
    const isNonDateUpdated = Object.keys(initialFormData).some((key) => 
    {
      const currentValue = formData[key];
      const initialValue = initialFormData[key];
    
      return JSON.stringify(currentValue) !== JSON.stringify(initialValue);
    });

    // Check date fields separately
    const isDateUpdated = dateKeys.some((dateKey) => 
    {
      const currentDate = formData[dateKey] ? dayjs(formData[dateKey]) : formData[dateKey];
      const initialDate = initialFormData[dateKey] ? dayjs(initialFormData[dateKey]) : initialFormData[dateKey];
    
      return JSON.stringify(currentDate) !== JSON.stringify(initialDate);
    });

    return isNonDateUpdated || isDateUpdated;
  }

  const convertToDayJsObject = (date) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
    return convertedDate;
  }

  const isValid = () => 
  {
    return formRef.current?.reportValidity();
  }

  const resetPostShipmentData = () =>
  {
    const updatedTranches = tranches.filter(tranche => tranche.id !== selectedTranche?.id) || [];
    formRef?.current?.reset();

    if (updatedTranches.length > 0) 
    {
      setSelectedTranche(updatedTranches[0]);
    } 
    else 
    {
      setSelectedTranche(null);
    }

    setTranches(updatedTranches);
    setFiles([]);
    setUploadedFiles([]);
    setIsExistingShipmentDetails(updatedTranches.length > 0);
    setIsFormSavedOrDeleted(false);
    setIsFormUpdated(false);
    setToggleListData(!toggleListData);
    setToggleDocumentList(!toggleDocumentList);
    setToggleFileList(!toggleFileList);
    setAlertMessage("Post-shipment data deleted successfully.");
    setAlertType("success");
    setShowAlert(true);
  }
  
  const handleSectionAccordionChange = (section) => (_, isExpanded) => 
  {
    setExpandedFormSections(isExpanded ? [...expandedFormSections, section] : expandedFormSections.filter(id => id !== section));
  }

  const handleAccordionChange = (index, flag) => (_, isExpanded) =>
  {
    if (flag === "Certificates") 
    {
      setIsCertificateAccodionExpanded(isExpanded ? index : null);
    }
    else
    {
      setIsProductOrProductGradeAccordionExpanded(isExpanded ? index : null);
    }
  }

  const handleAddTrancheWrapper = () => 
  {
    const isEmpty = Object.entries(formData)?.every(([key, value]) => value === "" || value === null || value === undefined) && uploadedFiles.length === 0;
    const isAnyFormFieldFilled = Object.values(formData).some((value) => 
    {
      if (Array.isArray(value)) 
      {
        return value.some((nestedObject) =>
          Object.values(nestedObject).some((nestedValue) =>
            Boolean(nestedValue)
          )
        );
      }

      return Boolean(value);
    });

    if (!isAnyFormFieldFilled) 
    {
      setShowWarningDialog(true);
    } 
    else if (!isEmpty && !isFormSavedOrDeleted && (!isExistingShipmentDetails || isUpdated)) 
    {
      setShowAddModal(true);
    } 
    else 
    {
      handleAddTranche();
    }
  }

  const handleAddTranche = () => 
  {
    setShowAddModal(false);

    formRef?.current?.reset();
    
    setIsExistingShipmentDetails(false);
    setIsExistingSBLC(false);
    setIsFormUpdated(false);
    setIsPFIAccordionExpanded(null);
    setIsProductGradeAccordionExpanded(null);
    setSelectedTranche(null);
    setFiles([]);
    setUploadedFiles([]);
  }

  const handleCertificateChange = (index, field, value) => 
  {
    const newCertificates = [...formData?.Certificates_of_Origin];

    newCertificates[index] = { ...newCertificates[index], [field]: value };
    setFormData((previousFormData) => ({
      ...previousFormData,
      Certificates_of_Origin: newCertificates
    }));
  }

  const handleAddCertificate = (event) => 
  {
    event.stopPropagation();    

    const certificates = [...formData?.Certificates_of_Origin || []];
    const isAnyCertificateFieldFilled =
      certificates?.length > 0
        ? certificates.every((certificate) =>
            Object.values(certificate).some((value) => Boolean(value))
          )
        : true;

    if (isAnyCertificateFieldFilled) 
    {
      const newCertificateData = {
        COO_Title: "",
        COO_Content: ""
      };
      const updatedCertificatesOfOrigin = [...certificates, newCertificateData];

      setFormData((previousFormData) => ({
        ...previousFormData,
        Certificates_of_Origin: updatedCertificatesOfOrigin
      }));
    } 
    else 
    {
      setShowWarningDialog(true);
    }
  }

  const handleDeleteCertificate = (event, index) => 
  {
    event.stopPropagation();
    const newCertificates = [...formData?.Certificates_of_Origin];

    newCertificates.splice(index, 1);
    setFormData((previousFormData) => ({
      ...previousFormData,
      Certificates_of_Origin: newCertificates
    }));
  }

  const handleProductChange = (index, field, value) => 
  {
    let formattedValue = value;

    if (field === "Shipped_Quantity") 
    {
      if (formattedValue) 
      {
        // Remove any non-numeric characters except the decimal point
        formattedValue = value.replace(/[^0-9.]/g, "");

        // Ensure the value has at most 3 decimal places
        if (formattedValue.includes(".")) 
        {
          const [integerPart, decimalPart] = formattedValue.split(".");
          formattedValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
        }
      } 
      else 
      {
        formattedValue = null;
      }
    }

    const data = { ...formData };
    const newProducts = [...data.tranche_products_selected];
    const updatedProduct = { ...newProducts[index] };

    // Get old quantity & price before update
    const oldQuantity = parseInt(newProducts[index]?.Shipped_Quantity || 0);
    const oldPrice = parseInt(newProducts[index]?.Deal_Product_Id?.Selling_Price || 0);
    const oldAmount = oldQuantity * oldPrice;

    updatedProduct[field] = formattedValue;    
    newProducts[index] = updatedProduct;

    // Get new quantity & price after update
    const newQuantity = parseInt(updatedProduct?.Shipped_Quantity || 0);
    const newPrice = parseInt(updatedProduct?.Deal_Product_Id?.Selling_Price || 0);
    const newAmount = newQuantity * newPrice;

    // Adjust total by subtracting old and adding new
    const totalAmount = (data["Total_Amount"] || 0) - oldAmount + newAmount;
    
    setFormData((previousFormData) => ({
      ...previousFormData,
      tranche_products_selected: newProducts,
      Total_Amount: totalAmount
    }));
  }

  const handleAddProduct = (event) => 
  {
    event.stopPropagation();

    const tranche_products_selected = [...formData?.tranche_products_selected];
    const isAnyProductFieldFilled =
      tranche_products_selected.length > 0
        ? tranche_products_selected.every((product) =>
            Object.values(product)?.some((value) =>
              Array.isArray(value) ? value.length > 0 : Boolean(value)
            )
          )
        : true;

    if (isAnyProductFieldFilled) 
    {
      const newProductData = {
        Deal_Product_Id: null,
        Shipped_Quantity: null
      };

      setFormData((previousFormData) => ({
        ...previousFormData,
        tranche_products_selected: [
          ...(previousFormData.tranche_products_selected || []),
          newProductData
        ]
      }));
    } 
    else 
    {
      setShowWarningDialog(true);
    }
  }

  const handleDeleteProduct = (event, index) => 
  {
    event.stopPropagation();
    setFormData((previousFormData) =>
    {
      const productToDelete = previousFormData.tranche_products_selected[index];  
      const deletedAmount = (parseInt(productToDelete?.Shipped_Quantity || 0) * parseInt(productToDelete?.Deal_Product_Id?.Selling_Price || 0)) || 0;
    
      return {
        ...previousFormData,
        tranche_products_selected: previousFormData.tranche_products_selected.filter((_, i) => i !== index),
        Total_Amount: (previousFormData.Total_Amount || 0) - deletedAmount
      };
    });
  }

  const handleProductGradeChange = (index, field, value) => 
  {
    let formattedValue = value;

    if (field === "Shipped_Quantity") 
    {
      if (formattedValue) 
      {
        // Remove any non-numeric characters except the decimal point
        formattedValue = value.replace(/[^0-9.]/g, "");

        // Ensure the value has at most 3 decimal places
        if (formattedValue.includes(".")) 
        {
          const [integerPart, decimalPart] = formattedValue.split(".");
          formattedValue = `${integerPart}.${decimalPart.substring(0, 3)}`;
        }
      } 
      else 
      {
        formattedValue = null;
      }
    }

    const data = { ...formData };
    const newProductGrades = [...data.tranche_product_grades_selected];
    const updatedProductGrade = { ...newProductGrades[index] };

    // Get old quantity & price before update
    const oldQuantity = parseInt(newProductGrades[index]?.Shipped_Quantity || 0);
    const oldPrice = parseInt(newProductGrades[index]?.Deal_Product_Grade_Id?.Selling_Price || 0);
    const oldAmount = oldQuantity * oldPrice;

    updatedProductGrade[field] = formattedValue;
    newProductGrades[index] = updatedProductGrade;

    // Get new quantity & price after update
    const newQuantity = parseInt(updatedProductGrade?.Shipped_Quantity || 0);
    const newPrice = parseInt(updatedProductGrade?.Deal_Product_Grade_Id?.Selling_Price || 0);
    const newAmount = newQuantity * newPrice;

    // Adjust total by subtracting old and adding new
    const totalAmount = (data["Total_Amount"] || 0) - oldAmount + newAmount;

    setFormData((previousFormData) => ({
      ...previousFormData,
      tranche_product_grades_selected: newProductGrades,
      Total_Amount: totalAmount
    }));
  }

  const handleAddProductGrade = (event) => 
  {
    event.stopPropagation();

    const tranche_product_grades_selected = [...formData?.tranche_product_grades_selected] || [];
    const isAnyProductGradeFieldFilled =
      tranche_product_grades_selected.length > 0
        ? tranche_product_grades_selected.every((product) =>
            Object.values(product)?.some((value) =>
              Array.isArray(value) ? value.length > 0 : Boolean(value)
            )
          )
        : true;

    if (isAnyProductGradeFieldFilled) 
    {
      const newProductGradeData = {
        Deal_Product_Grade_Id: null,
        Shipped_Quantity: null
      };

      setFormData((previousFormData) => ({
        ...previousFormData,
        tranche_product_grades_selected: [
          ...(previousFormData.tranche_product_grades_selected || []),
          newProductGradeData
        ]
      }));
    } 
    else 
    {
      setShowWarningDialog(true);
    }
  }

  const handleDeleteProductGrade = (event, index) => 
  {
    event.stopPropagation();
    setFormData((previousFormData) => 
    {
      const productGradeToDelete = previousFormData.tranche_product_grades_selected[index];  
      const deletedAmount = (parseInt(productGradeToDelete?.Shipped_Quantity || 0) * parseInt(productGradeToDelete?.Deal_Product_Grade_Id?.Selling_Price || 0)) || 0;

      return {
        ...previousFormData,
        tranche_product_grades_selected: previousFormData.tranche_product_grades_selected.filter((_, i) => i !== index),
        Total_Amount: (previousFormData.Total_Amount || 0) - deletedAmount
      };
    });
  }

  const handleUpload = async (trancheId, resolve, reject) => 
  {
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileFormData = new FormData();
    const TrancheID = trancheId || selectedTranche?.value;

    uploadedFiles.forEach((file, index) => 
    {
      const fileObj = {
        DealPFIId: selectedPFI,
        TrancheID: TrancheID || selectedTranche?.id,
        fileName: file.name,
        docType: `Documentation - ${TrancheID}`,
        fileExtension: file.name.split(".").pop(),
        tabType: file.tabType,
        fileType: file.docType,
        markForApproval: file.markForApproval
      };
    
      fileFormData.append(`file${index}`, JSON.stringify(fileObj));
      fileFormData.append(`file${index}`, file);
    });    

    fileFormData.append("pathname", pathname);

    await axios({
      method: "post",
      url: "/api/UploadDocument",
      data: fileFormData,
      signal: abortController.signal
    })
    .then((response) => 
    {
      const { status, data } = response;

      if (status === 200) 
      {
        setAlertMessage("Post-shipment data saved successfully.");
        setAlertType("success");
        setShowAlert(true);
        setToggleFileList(!toggleFileList);

        if (isDocumentWritable)
        {
          resolve(true);
        }
      } 
      else if (status === 202)
      {
        setAlertMessage(data?.data || "An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);     
        reject(false);   
      }
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
        reject(false);
      }
    })
    .catch((error) => 
    {
      console.log("Upload Documentation Documents Api: ", error);

      if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
      {
        return;
      }
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }

      reject(false);
    });
  }

  const handleDeleteFiles = async (fileIds) =>
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "post",
      url: "/api/DeleteDocument",
      data: { 
        pathname: pathname,
        fileIds: fileIds
      },
      signal: abortController.signal
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status } = response;

      if (status === 200) 
      {
        resetPostShipmentData();
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Delete Documents Api: ", error);
      setIsLoading(false);

      if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
      {
        return;
      }

      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleSubmit = async (event) => 
  {
    event?.preventDefault();
    
    if (!isValid()) 
    {
      return false;
    }

    return new Promise((resolve, reject) => 
    {
      if (isExistingShipmentDetails && !isWritable && isDocumentWritable && uploadedFiles.length > 0)
      {
        handleUpload(selectedTranche?.id, resolve, reject);
      }
      else
      {
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
        
        let updatedFormData = {
          ...formData,
          Business_Unit_Id: selectedBusinessUnit,
          Deal_PFI_Id: selectedPFI,
          User_Id: currentUser?.id,
          ...(isExistingShipmentDetails && { Tranche_Id: selectedTranche?.id })
        };
    
        const apiUrl = isExistingShipmentDetails
          ? isFoodsPolymersOrSBLCDeal
            ? "/api/UpdatePostShipmentFoodsPolymersAndSBLC"
            : "/api/UpdatePostShipmentFertilizersAndPetroChemicals"
          : isFoodsPolymersOrSBLCDeal
            ? "/api/AddPostShipmentFoodsPolymersAndSBLC"
            : "/api/AddPostShipmentFertilizersAndPetroChemicals";
        let isSBLCSaved = Promise.resolve();
    
        updatedFormData = Object.fromEntries(Object.entries(updatedFormData).map(([key, value]) => [
          key,
          typeof value === "object" && value?.hasOwnProperty("value") ? value.id : value
        ]));
    
        if (isSBLCDeal) 
        {
          let updatedSBLCFormData = {
            ...sblcFormData,
            Deal_PFI_Id: selectedPFI,
            Tranche_Name: formData?.Tranche_Name,
            User_Id: currentUser?.id,
            ...(isExistingShipmentDetails && { Tranche_Id: selectedTranche?.id })
          };
          const sblcApiUrl = isExistingSBLC ? "/api/UpdateDealTrancheSBLCProductGrades" : "/api/AddDealTrancheSBLCProductGrades";
    
          const transformObject = (obj, seen = new Set()) => 
          {
            if (seen.has(obj)) return obj;
            if (typeof obj !== "object" || obj === null) return obj;
            
            seen.add(obj);
    
            if (Array.isArray(obj)) 
            {
              return obj.map(item =>
                typeof item === "object" && item !== null
                  ? item.hasOwnProperty("value")
                    ? item.id
                    : transformObject(item, seen)
                  : item
              );
            }
    
            return Object.fromEntries(
              Object.entries(obj).map(([key, value]) => [
                key,
                typeof value === "object" && value !== null
                  ? Array.isArray(value)
                    ? transformObject(value, seen)
                    : value.hasOwnProperty("value")
                      ? value.id
                      : transformObject(value, seen)
                  : value
              ])
            );
          };
    
          updatedSBLCFormData = transformObject(updatedSBLCFormData);

          isSBLCSaved = axios({
            method: "post",
            url: sblcApiUrl,
            data: updatedSBLCFormData,
            signal: abortController.signal
          })
          .then((response) => 
          {
            const { status, data } = response;
    
            if (status === 200) 
            {
              if (!isExistingShipmentDetails)
              {
                updatedFormData = { ...updatedFormData, Tranche_Id: data?.tranche_id };
              }

              setIsSBLCFormUpdated(false);
            } 
            else if (status === 400) 
            {
              setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
              setAlertType("error");
              setShowAlert(true);
              reject(false);
            } 
            else 
            {
              setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
              setAlertType("error");
              setShowAlert(true);
              reject(false);
            }
          })
          .catch((error) => 
          {
            console.log("Add/Update Shipment Details SBLC Data Api: ", error);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
              return;
            }

            const status = error?.response?.status;
    
            if (status === 403) 
            {
              triggerSessionExpire();
            } 
            else 
            {
              setAlertMessage(
                status === 401
                  ? "Unauthorized access. You do not have the required permissions to perform this action."
                  : status === 429
                  ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                  : "An error occurred while processing your request. Please try again later or contact the site administrator."
              );
              setAlertType("error");
              setShowAlert(true);
    
              if (status === 429) 
              {
                setTimeout(logOut, 3000);
              }
            }

            reject(false);
          });
        }
    
        isSBLCSaved
        .then(() => 
          axios.post(apiUrl, updatedFormData, { signal: abortController.signal })
          .then(async (response) => 
          {
            const { status, data } = response;

            if (status === 200) 
            {
              const Tranche_Id = data?.Tranche_Id || selectedTranche?.id;

              if (uploadedFiles.length > 0 && Tranche_Id) 
              {
                await handleUpload(Tranche_Id, resolve, reject);
              }

              setSelectedTrancheToCopy(null);
              setUploadedFiles([]);
              setIsExistingShipmentDetails(true);
              setIsFormSavedOrDeleted(true);
              setIsFormUpdated(false);  
              setToggleListData(!toggleListData);
              setToggleDocumentList(!toggleDocumentList);
              resolve(true);
            } 
            else 
            {
              const errorMessage = status === 400 
                ? (data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.")
                : "An error occurred while processing your request. Please try again later or contact the site administrator.";

              setAlertMessage(errorMessage);
              setAlertType("error");
              setShowAlert(true);
              reject(false);
            }
          })
        )
        .catch((error) => 
        {
          console.log("Add/Update Deal Details Api: ", error);

          if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
          {
            return;
          }

          const status = error?.response?.status;

          if (status === 403) 
          {
            triggerSessionExpire();
          } 
          else 
          {
            setAlertMessage(
              status === 401 ? "Unauthorized access. You do not have the required permissions." :
              status === 429 ? "Request limit exceeded. Your account is temporarily disabled." :
              "An error occurred while processing your request. Please try again later or contact the site administrator."
            );
            setAlertType("error");
            setShowAlert(true);

            if (status === 429) 
            {
              setTimeout(logOut, 3000);
            }
          }
          
          reject(false);
        });
      }
    });
  }
  
  const handleDelete = async () => 
  {
    setShowDeleteModal(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const fileIds = files.flatMap((file) => file?.file?.id ? [file.file.id] : []);

    if (isExistingShipmentDetails && !isWritable && isDocumentWritable && fileIds.length > 0)
    {
      handleDeleteFiles(fileIds);
    }
    else
    {
      let isSBLCDeleted = true;

      if (isSBLCDeal)
      {
        await axios({
          method: "post",
          url: "/api/DeleteDealTrancheSBLCProductGrades",
          data: { 
            Deal_PFI_Id: selectedPFI,
            Tranche_Id: selectedTranche?.id,
            SBLC_Id: sblcFormData?.SBLC_Id?.id
          },
          signal: abortController.signal
        })
        .then((response) => 
        {
          const { status, data } = response;

          if (status === 200) 
          {
            isSBLCDeleted = true;

            setIsExistingSBLC(false);
            setIsSBLCFormUpdated(false);
            setIsPFIAccordionExpanded(null);
            setIsProductGradeAccordionExpanded(null);
          } 
          else if (status === 400)
          {
            setIsLoading(false);
            setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
          }
          else 
          {
            setIsLoading(false);
            setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
          }
        })
        .catch((error) => 
        {
          console.log("Delete Shipment Details SBLC Api: ", error);
          setIsLoading(false);

          if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
          {
            return;
          }
          
          const status = error?.response?.status;

          if (status === 403) 
          {
            triggerSessionExpire();
          } 
          else 
          {
            setAlertMessage(
              status === 401
                ? "Unauthorized access. You do not have the required permissions to perform this action."
                : status === 429
                ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                : "An error occurred while processing your request. Please try again later or contact the site administrator."
            );
            setAlertType("error");
            setShowAlert(true);
        
            if (status === 429) 
            {
              setTimeout(logOut, 3000);
            }
          }
        });
      }

      if (isSBLCDeleted)
      {
        await axios({
          method: "post",
          url: "/api/DeletePostShipment",
          data: {
            Business_Unit_Id: selectedBusinessUnit,
            Deal_PFI_Id: selectedPFI,
            Tranche_Id: selectedTranche?.id,
            User_Id: currentUser?.id
          },
          signal: abortController.signal
        })
        .then(async (response) => 
        {
          const { status, data } = response;

          if (status === 200) 
          {
            if (fileIds.length > 0)
            {
              handleDeleteFiles(fileIds);
            }
            else
            {
              setIsLoading(false);
              resetPostShipmentData();
            }
          } 
          else if (status === 400)
          {
            setIsLoading(false);
            setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
            setAlertType("error");
            setShowAlert(true);
          }
          else 
          {
            setIsLoading(false);
            setAlertMessage(data?.data);
            setAlertType("error");
            setShowAlert(true);
          }
        })
        .catch((error) => 
        {
          console.log("Delete Shipment Details Data Api: ", error);
          setIsLoading(false);
  
          if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
          {
            return;
          }
          
          const status = error?.response?.status;
  
          if (status === 403) 
          {
            triggerSessionExpire();
          } 
          else 
          {
            setAlertMessage(
              status === 401
                ? "Unauthorized access. You do not have the required permissions to perform this action."
                : status === 429
                ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                : "An error occurred while processing your request. Please try again later or contact the site administrator."
            );
            setAlertType("error");
            setShowAlert(true);
        
            if (status === 429) 
            {
              setTimeout(logOut, 3000);
            }
          }
        });
      }
    }
  }

  const handleCloseDialog = () => 
  {
    setShowWarningDialog(false);
  }

  const handleCloseModal = () => 
  {
    setShowDeleteModal(false);
    setShowAddModal(false);
  }

  const formSectionsHTML = [
    {
      id: 1,
      title: isFoodsPolymersOrSBLCDeal ? "Product Grades" : "Products",
      content: (
        <Grid size = {{ xs: 12 }}>
          <Grid
            container
            spacing = {3}
            className = "align-items-start autocomplete"
          >
            {isSBLCDeal ? (
              <Grid size = {{ xs: 12 }}>             
                <DealSBLC 
                  isWritable = {isWritable}
                  isListDataLoading = {isListDataLoading}
                  isPFIAccordionExpanded = {isPFIAccordionExpanded}
                  isProductGradeAccordionExpanded = {isProductGradeAccordionExpanded}
                  selectedTab = "Shipment Details"
                  selectedPFI = {selectedPFI}
                  sblcFormData = {sblcFormData}
                  pfiAccordionRefs = {pfiAccordionRefs}
                  productGradeAccordionRefs = {productGradeAccordionRefs}
                  setShowAlert = {setShowAlert}
                  setAlertMessage = {setAlertMessage}
                  setAlertType = {setAlertType}
                  setIsPFIAccordionExpanded = {setIsPFIAccordionExpanded}
                  setIsProductGradeAccordionExpanded = {setIsProductGradeAccordionExpanded}
                  setSBLCFormData = {setSBLCFormData}
                />
              </Grid>
            ) : (
              <Grid size = {{ xs: 12 }}>
                {isFoodsOrPolymersDeal ? (
                  <>
                    {formData?.tranche_product_grades_selected?.map((productGrade, index) => {
                      const productGradeValue = productGrade?.Deal_Product_Grade_Id?.value || `Product Grade ${index + 1}`;

                      return (
                        <Accordion
                          ref = {(element) => (productOrProductGradeAccordionRefs.current[index] = element)}
                          key = {index}
                          expanded = {isProductOrProductGradeAccordionExpanded === index}
                          onChange = {handleAccordionChange(index, "Tranche Product Grades")}
                        >
                          <AccordionSummary
                            id = {`product-grade-header-${index}`}
                            aria-controls = {`product-grade-content-${index}`}
                            expandIcon = {<ExpandMoreIcon />}
                            slot = "div"
                          >
                            <small className = "page-heading fw-bold item">
                              {productGradeValue}
                            </small>
                            
                            <IconButton
                              color = "error"
                              size = "small"
                              className = "pt-0"
                              disabled = {!isWritable}
                              onClick = {(event) => handleDeleteProductGrade(event, index)}
                            >
                              <DeleteIcon fontSize = "inherit" />
                            </IconButton>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid
                              container
                              spacing = {2}
                              className = "align-items-start autocomplete"
                            >
                              <Grid size = {{ xs: 3 }}>
                                <VirtualizedAutocomplete
                                  isLoading = {isListDataLoading}
                                  isMultiple = {false}
                                  isObject = {true}
                                  isRequired = {true}
                                  isWritable = {isWritable}
                                  filterOn = "Product Grade"
                                  options = {productGrades}
                                  selectedOptions = {productGrade.Deal_Product_Grade_Id || null}
                                  handleSelectChange = {(filterOn, newValue) => handleProductGradeChange(index, "Deal_Product_Grade_Id", newValue)}
                                />
                              </Grid>

                              <Grid size = {{ xs: 3 }} className = "form-text-field">
                                <TextField
                                  label = "Shipped Quantity"
                                  id = "Shipped_Quantity"
                                  type = "number"
                                  value = {productGrade.Shipped_Quantity || ""}
                                  onChange = {(event) => handleProductGradeChange(index, "Shipped_Quantity", event.target.value)}
                                  slotProps = {{ 
                                    input: { 
                                      readOnly: !isWritable,
                                      min: 0, 
                                      max: 2147483647, 
                                      step: "0.001"
                                    } 
                                  }}
                                  onKeyDown = {(event) => 
                                  {
                                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                    {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </>
                ) : (
                  <>
                    {formData?.tranche_products_selected?.map((product, index) => {
                      const productValue = product?.Deal_Product_Id?.value || `Product (${index + 1})`;
                    
                      return (
                        <Accordion
                          ref = {(element) => (productOrProductGradeAccordionRefs.current[index] = element)}
                          key = {index}
                          expanded = {isProductOrProductGradeAccordionExpanded === index}
                          onChange = {handleAccordionChange(index, "Tranche Products")}
                        >
                          <AccordionSummary
                            id = {`product-header-${index}`}
                            aria-controls = {`product-content-${index}`}
                            expandIcon = {<ExpandMoreIcon />}
                            slot = "div"
                          >
                            <small className = "page-heading fw-bold item">
                              {productValue}
                            </small>
                            
                            <IconButton
                              color = "error"
                              size = "small"
                              className = "pt-0"
                              disabled = {!isWritable}
                              onClick = {(event) => handleDeleteProduct(event, index)}
                            >
                              <DeleteIcon fontSize = "inherit" />
                            </IconButton>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid
                              container
                              spacing = {2}
                              className = "align-items-start autocomplete"
                            >
                              <Grid size = {{ xs: 3 }}>
                                <VirtualizedAutocomplete
                                  isLoading = {isListDataLoading}
                                  isMultiple = {false}
                                  isObject = {true}
                                  isRequired = {true}
                                  isWritable = {isWritable}
                                  filterOn = "Product"
                                  options = {products}
                                  selectedOptions = {product.Deal_Product_Id || null}
                                  handleSelectChange = {(filterOn, newValue) => handleProductChange(index, "Deal_Product_Id", newValue)}
                                />
                              </Grid>

                              <Grid size = {{ xs: 3 }} className = "form-text-field">
                                <TextField
                                  label = "Shipped Quantity"
                                  id = "Shipped_Quantity"
                                  type = "number"
                                  value = {product.Shipped_Quantity || ""}
                                  onChange = {(event) => handleProductChange(index, "Shipped_Quantity", event.target.value)}
                                  slotProps = {{ 
                                    input: { 
                                      readOnly: !isWritable,
                                      min: 0, 
                                      max: 2147483647, 
                                      step: "0.001" 
                                    } 
                                  }}
                                  onKeyDown = {(event) => 
                                  {
                                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                                    {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                  </>
                )}
              </Grid>
            )}      
          </Grid>
        </Grid>
      )
    },
    {
      id: 2,
      title: "Basic Information",
      content: (
        <Grid size = {{ xs: 12 }}>
          <Grid
            container
            spacing = {2}
            className = "align-items-start autocomplete"
          >
            <Grid size = {{ xs: 3 }}>
              <VirtualizedAutocomplete
                isLoading = {isListDataLoading}
                isMultiple = {false}
                isObject = {true}
                isWritable = {isWritable}
                filterOn = "Port of Loading"
                options = {seaPorts}
                selectedOptions = {formData?.Port_Of_Loading_Id || null}
                handleSelectChange = {(filterOn, newValue) => handleChange("Port_Of_Loading_Id", newValue)}
              />
            </Grid>

            <Grid size = {{ xs: 3 }}>
              <VirtualizedAutocomplete
                isLoading = {isListDataLoading}
                isMultiple = {false}
                isObject = {true}
                isWritable = {isWritable}
                filterOn = "Port of Discharge"
                options = {seaPorts}
                selectedOptions = {formData?.Port_Of_Discharge_Id || null}
                handleSelectChange = {(filterOn, newValue) => handleChange("Port_Of_Discharge_Id", newValue)}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Commercial Invoice No."
                id = "Commercial_Invoice_No"
                disabled
                value = {formData?.Commercial_Invoice_No || ""}
                onChange = {(event) => handleChange("Commercial_Invoice_No", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat = "MM/DD/YYYY"
                  label = "Document Creation Date"
                  disableMaskedInput
                  readOnly = {!isWritable}
                  className = "date-picker w-100"
                  value = {convertToDayJsObject(formData?.Commercial_Invoice_Date)}
                  onChange = {(newDate) => handleChange("Commercial_Invoice_Date", newDate, true)}
                  onAccept = {(newDate) => handleChange("Commercial_Invoice_Date", newDate, true)}
                  slotProps = {{
                    actionBar: {
                      actions: ["clear"]
                    },
                    textField: {
                      InputProps: {
                        size: "small",
                        disabled: true
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid size = {{ xs: 3 }}>
              <VirtualizedAutocomplete
                isLoading = {isListDataLoading}
                isMultiple = {false}
                isObject = {true}
                isWritable = {isWritable}
                filterOn = "Shipping Line"
                options = {shippingLines}
                selectedOptions = {formData?.Shipping_Line || null}
                handleSelectChange = {(filterOn, newValue) => handleChange("Shipping_Line", newValue)}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Vessel Name"
                id = "Vessel_Name"
                value = {formData?.Vessel_Name || ""}
                onChange = {(event) => handleChange("Vessel_Name", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat = "MM/DD/YYYY"
                  label = "NN Docs Supplier"
                  disableMaskedInput
                  readOnly = {!isWritable}
                  className = "date-picker w-100"
                  value = {convertToDayJsObject(formData?.NN_Docs_Supplier)}
                  onChange = {(newDate) => handleChange("NN_Docs_Supplier", newDate, true)}
                  onAccept = {(newDate) => handleChange("NN_Docs_Supplier", newDate, true)}
                  slotProps = {{
                    actionBar: {
                      actions: ["clear"]
                    },
                    textField: {
                      InputProps: {
                        size: "small",
                        disabled: true
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat = "MM/DD/YYYY"
                  label = "NN Docs Buyer"
                  className = "date-picker w-100"
                  disableMaskedInput
                  readOnly = {!isWritable}
                  value = {convertToDayJsObject(formData?.NN_Docs_Buyer)}
                  onChange = {(newDate) => handleChange("NN_Docs_Buyer", newDate, true)}
                  onAccept = {(newDate) => handleChange("NN_Docs_Buyer", newDate, true)}
                  slotProps = {{
                    actionBar: {
                      actions: ["clear"]
                    },
                    textField: {
                      InputProps: {
                        size: "small",
                        disabled: true
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Voyage No."
                id = "Voyage_No"
                value = {formData?.Voyage_No || ""}
                onChange = {(event) => handleChange("Voyage_No", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Master BL Number."
                id = "Master_Bill_of_Lading_No"
                value = {formData?.Master_Bill_of_Lading_No || ""}
                onChange = {(event) => handleChange("Master_Bill_of_Lading_No", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "NN Documents Status - Customer"
                id = "Documents_Status_to_Customer"
                value = {formData?.Documents_Status_to_Customer || ""}
                onChange = {(event) => handleChange("Documents_Status_to_Customer", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "NN Documents Status - Supplier"
                id = "Documents_Status_to_Supplier"
                value = {formData?.Documents_Status_to_Supplier || ""}
                onChange = {(event) => handleChange("Documents_Status_to_Supplier", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Split BL Number."
                id = "Split_Bill_of_Lading_No"
                value = {formData?.Split_Bill_of_Lading_No || ""}
                onChange = {(event) => handleChange("Split_Bill_of_Lading_No", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat = "MM/DD/YYYY"
                  label = "Shipment Date"
                  disableMaskedInput
                  readOnly = {!isWritable}
                  className = "date-picker w-100"
                  value = {convertToDayJsObject(formData?.Shipment_Date)}
                  onChange = {(newDate) => handleChange("Shipment_Date", newDate, true)}
                  onAccept = {(newDate) => handleChange("Shipment_Date", newDate, true)}
                  slotProps = {{
                    actionBar: {
                      actions: ["clear"]
                    },
                    textField: {
                      InputProps: {
                        size: "small",
                        disabled: true
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat = "MM/DD/YYYY"
                  label = "Delivery Date"
                  disableMaskedInput
                  readOnly = {!isWritable}
                  className = "date-picker w-100"
                  value = {convertToDayJsObject(formData?.Delivery_Date)}
                  onChange = {(newDate) => handleChange("Delivery_Date", newDate, true)}
                  onAccept = {(newDate) => handleChange("Delivery_Date", newDate, true)}
                  slotProps = {{
                    actionBar: {
                      actions: ["clear"]
                    },
                    textField: {
                      InputProps: {
                        size: "small",
                        disabled: true
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Insurance Ref No."
                id = "Insurance_Cover_No"
                value = {formData?.Insurance_Cover_No || ""}
                onChange = {(event) => handleChange("Insurance_Cover_No", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Demurrage Free Days at Disport"
                type = "number"
                id = "Demurrage_Free_Days_at_Disport"
                value = {formData?.Demurrage_Free_Days_at_Disport || ""}
                onChange = {(event) => handleChange("Demurrage_Free_Days_at_Disport", event.target.value)}
                slotProps = {{ 
                  input: { 
                    readOnly: !isWritable,
                    min: 0, 
                    max: 2147483647 
                  } 
                }}
                onKeyDown = {(event) => 
                {
                  if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                  {
                    event.preventDefault();
                  }
                }}
                onInput = {(event) => 
                {
                  const value = event.target.value;
                  event.target.value = value.replace(/[^0-9]/g, "");
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat = "MM/DD/YYYY"
                  label = "Estimated Arrival to POD"
                  disableMaskedInput
                  readOnly = {!isWritable}
                  className = "date-picker w-100"
                  value = {convertToDayJsObject(formData?.Estimated_Arrival_to_POD)}
                  onChange = {(newDate) => handleChange("Estimated_Arrival_to_POD", newDate, true)}
                  onAccept = {(newDate) => handleChange("Estimated_Arrival_to_POD", newDate, true)}
                  slotProps = {{
                    actionBar: {
                      actions: ["clear"]
                    },
                    textField: {
                      InputProps: {
                        size: "small",
                        disabled: true
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <LocalizationProvider dateAdapter = {AdapterDayjs}>
                <DesktopDatePicker
                  inputFormat = "MM/DD/YYYY"
                  label = "SI Date"
                  disableMaskedInput
                  readOnly = {!isWritable}
                  className = "date-picker w-100"
                  value = {convertToDayJsObject(formData?.SI_Date)}
                  onChange = {(newDate) => handleChange("SI_Date", newDate, true)}
                  onAccept = {(newDate) => handleChange("SI_Date", newDate, true)}
                  slotProps = {{
                    actionBar: {
                      actions: ["clear"]
                    },
                    textField: {
                      InputProps: {
                        size: "small",
                        disabled: true
                      }
                    }
                  }}
                />
              </LocalizationProvider>
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Shipment Advice Days"
                id = "Shipment_Advice_Days"
                value = {formData?.Shipment_Advice_Days || ""}
                onChange = {(event) => handleChange("Shipment_Advice_Days", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Gross Weight KGs"
                type = "number"
                id = "Gross_Weight_KGs"
                value = {formData?.Gross_Weight_KGs || ""}
                onChange = {(event) => handleChange("Gross_Weight_KGs", event.target.value)}
                slotProps = {{ 
                  input: { 
                    readOnly: !isWritable,
                    min: 0, max: 2147483647, 
                    step: "0.001" 
                  } 
                }}
                onKeyDown = {(event) => 
                {
                  if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                  {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Net Weight KGs"
                type = "number"
                id = "Net_Weight_KGs"
                value = {formData?.Net_Weight_KGs || ""}
                onChange = {(event) => handleChange("Net_Weight_KGs", event.target.value)}
                slotProps = {{ 
                  input: { 
                    readOnly: !isWritable,
                    min: 0, max: 2147483647, 
                    step: "0.001" 
                  } 
                }}
                onKeyDown = {(event) => 
                {
                  if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                  {
                    event.preventDefault();
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 4 }} className = "form-text-field">
              <TextField
                label = "Insurance Company Details"
                id = "Insurance_Company_Details"
                multiline
                rows = {3}
                value = {formData?.Insurance_Company_Details || ""}
                onChange = {(event) => handleChange("Insurance_Company_Details", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>  
      )
    },
    {
      id: 3,
      title: "SI Creation",
      content: (
        <Grid size = {{ xs: 12 }}>
          <Grid
            container
            spacing = {2}
            className = "align-items-start autocomplete"
          >
            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Shipper"
                id = "Shipper"
                value = {formData?.Shipper || ""}
                onChange = {(event) => handleChange("Shipper", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Notify Customer"
                id = "Notify_Customer"
                value = {formData?.Notify_Customer || ""}
                onChange = {(event) => handleChange("Notify_Customer", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Final Destination"
                id = "Final_Destination"
                value = {formData?.Final_Destination || ""}
                onChange = {(event) => handleChange("Final_Destination", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }}>
              <VirtualizedAutocomplete
                isMultiple = {false}
                isObject = {false}
                isWritable = {isWritable}
                filterOn = "Transhipment"
                options = {partialShipments}
                selectedOptions = {formData?.Transhipment || null}
                handleSelectChange = {(filterOn, newValue) => handleChange("Transhipment", newValue)}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Notify Bank"
                id = "Notify_Bank"
                value = {formData?.Notify_Bank || ""}
                onChange = {(event) => handleChange("Notify_Bank", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }} className = "form-text-field">
              <TextField
                label = "Consignee"
                id = "Consignee"
                value = {formData?.Consignee || ""}
                onChange = {(event) => handleChange("Consignee", event.target.value)}
                slotProps = {{
                  input: {
                    readOnly: !isWritable
                  }
                }}
              />
            </Grid>

            <Grid size = {{ xs: 3 }}>
              <VirtualizedAutocomplete
                isMultiple = {false}
                isObject = {false}
                isWritable = {isWritable}
                filterOn = "Partial Shipment"
                options = {partialShipments}
                selectedOptions = {formData?.Partial_Shipment || null}
                handleSelectChange = {(filterOn, newValue) => handleChange("Partial_Shipment", newValue)}
              />
            </Grid>
          </Grid>
        </Grid>
      )
    },
    {
      id: 4,
      title: "Other Certificates",
      content: (
        <Grid size = {{ xs: 12 }}>
          <Grid
            container
            spacing = {2}
            className = "align-items-start autocomplete"
          >
            <Grid size = {{ xs: 12 }} className = "mb-3">
              {formData?.Certificates_of_Origin?.map((certificate, index) => (
                <Accordion
                  ref = {(element) => (certificateAccordionRefs.current[index] = element)}
                  key = {index}
                  expanded = {isCertificateAccodionExpanded === index}
                  onChange = {handleAccordionChange(index, "Certificates")}
                >
                  <AccordionSummary
                    id = {`product-header-${index}`}
                    aria-controls = {`product-content-${index}`}
                    expandIcon = {<ExpandMoreIcon />}
                    slot = "div"
                  >
                    <small className = "page-heading fw-bold item">Other Certificate {index + 1}</small>
                    <IconButton
                      color = "error"
                      size = "small"
                      className = "pt-0"
                      disabled = {!isWritable}
                      onClick = {(event) => handleDeleteCertificate(event, index)}
                    >
                      <DeleteIcon fontSize = "inherit" />
                    </IconButton>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className = "row w-100 flex-column align-items-start gap-3">
                      <div className = "col-3 form-text-field p-0">
                        <TextField
                          label = "Title"
                          id = "COO_Title"
                          value = {certificate.COO_Title || ""}
                          onChange = {(event) => handleCertificateChange(index, "COO_Title", event.target.value)}
                          slotProps = {{
                            input: {
                              readOnly: !isWritable
                            }
                          }}
                        />
                      </div>

                      <div className = "col-6 form-text-field p-0">
                        <TextField
                          label = "Content"
                          id = "COO_Content"
                          multiline
                          rows = {3}
                          value = {certificate.COO_Content || ""}
                          onChange = {(event) => handleCertificateChange(index, "COO_Content", event.target.value)}
                          slotProps = {{
                            input: {
                              readOnly: !isWritable
                            }
                          }}
                        />
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
          </Grid>
        </Grid>
      )
    }
  ];

  return (
    <>
      <form
        ref = {formRef}
        className = "deal-form multi-form-container"
        onSubmit = {handleSubmit}
      >
        <div className = "d-flex flex-column gap-2">
          {formSectionsHTML.map((section) => (
            <Accordion
              key = {section.id}
              expanded = {expandedFormSections.includes(section.id)}
              onChange = {handleSectionAccordionChange(section.id)}
            >
              <AccordionSummary
                id = "panel-header"
                aria-controls = "panel-content"
                expandIcon = {<ExpandMoreIcon />}
                slot = "div"
              >
                <small className = "page-heading title">
                  {section.title.includes("Product") ? `Product${isFoodsOrPolymersDeal ? " Grade" : ""}s` : section.title}
                </small>

                {expandedFormSections.includes(section.id) && (
                  <>
                    {(section.title.includes("Product") && !isSBLCDeal) ? (
                      <IconButton
                        color = "primary"
                        disabled = {!isWritable}
                        onClick = {isFoodsOrPolymersDeal ? handleAddProductGrade : handleAddProduct}
                      >
                        <AddBoxIcon 
                          fontSize = "large"
                          sx = {{ color: '#0C969C' }}
                        />
                      </IconButton>
                    ) : section.title === "Other Certificates" && (
                      <IconButton
                        color = "primary"
                        disabled = {!isWritable}
                        onClick = {handleAddCertificate}
                      >
                        <AddBoxIcon 
                          fontSize = "large"
                          sx = {{ color: '#0C969C' }}
                        />
                      </IconButton>
                    )}
                  </>
                )}
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  spacing = {2}
                  className = "w-100 align-items-start autocomplete"
                >
                  {section.content}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      </form>

      <Dialog open = {showWarningDialog} onClose = {handleCloseDialog}>
        <DialogContent>
          <DialogContentText className = "text-center">
            Please fill in at least one field in the form before adding a new entry.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            type = "button"
            className = "btn btn-primary custom-btn"
            onClick = {handleCloseDialog}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>

      <Modal show = {showAddModal} onHide = {handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <span>
            Adding a new form will discard your unsaved changes. Proceed
            anyway?
          </span>
        </Modal.Body>
        <Modal.Footer className = "gap-2">
          <Button variant = "outlined" size = "small" onClick = {handleCloseModal}>
            Cancel
          </Button>
          <Button variant = "contained" size = "small" onClick = {handleAddTranche}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
});

export default memo(ShipmentDetails);