import { combineReducers } from '@reduxjs/toolkit';
import userReducer from '../store/slices/user';
import dealDropdownOptions from '../store/slices/dealDropdownOptions';
import dealUploadedDocuments from '../store/slices/dealUploadedDocuments';

const rootReducer = combineReducers({
  user: userReducer,
  dealDropdownOptions: dealDropdownOptions,
  dealUploadedDocuments: dealUploadedDocuments
});

export default rootReducer;