import { useMemo, useRef } from 'react';
import { abortController } from '../utils/abortController';
import Grid from '@mui/material/Grid2';
import VirtualizedAutocomplete from './Autocomplete';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';

const FileUploader = ({ isWritable = false, isLoading = false, isMultiple = true, isKYC = false, uploadText = "", pathname = null, files = [], uploadedFiles = [], allowFileTypeSelection = false, kycFileTypes = [], buyingLegFileTypes = [], sellingLegFileTypes = [], setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, setFiles = () => {}, setUploadedFiles = () => {}, setDeletedRowFileIds = () => {}, triggerSessionExpire = () => {}, logOut = () => {} }) => 
{
    /* FILE VARIABLES */
    const maxFileSize = 5 * 1024 * 1024;
    const fileInputRef = useRef(null);
    const fileTypes = useMemo(() => ["pdf", "doc", "docx", "xlsx", "csv", "jpg", "jpeg", "png"], []);

    const handleChange = (event) => 
    {
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        const newFiles = Object.values(event.target.files) || [];
        const isValidFileType = newFiles.every(file => fileTypes.includes(file.name.split(".").pop()?.toLowerCase()));
        const isMultipleFiles = files.length === 1 || uploadedFiles.length === 1;

        if (!isMultiple && isMultipleFiles) 
        {
            setAlertMessage("Please upload one file only.");
            setAlertType("error");
            setShowAlert(true);
        } 
        else if (!isValidFileType) 
        {
            setAlertMessage(`Please upload files with the following extensions: ${fileTypes.join(", ")}.`);
            setAlertType("error");
            setShowAlert(true);
        } 
        else 
        {
            const uniqueFiles = new Set(uploadedFiles.map(file => file.name + file.size));
            const newUploadedFiles = newFiles.filter(file => !uniqueFiles.has(file.name + file.size));
            const allFiles = [...uploadedFiles, ...newUploadedFiles];
            const isMaxFileSizeExceeded = allFiles.reduce((total, file) => total + file.size, 0) > maxFileSize;

            if (isMaxFileSizeExceeded)
            {
                setAlertMessage(`The total file size exceeds the maximum allowed limit of ${maxFileSize / (1024 * 1024)} MB. Please reduce the file size or remove some files and try again.`);
                setAlertType("error");
                setShowAlert(true);
            }
            else
            {
                setUploadedFiles(allFiles);
            }
        }

        if (fileInputRef?.current?.value) 
        {
            fileInputRef.current.value = null;
        }
    }

    const handleDeleteExistingFile = async (index, id) => 
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        await axios({
            method: "post",
            url: "/api/DeleteDocument",
            data: { 
                pathname: pathname,
                fileIds: [id] 
            },
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status } = response;

            if (status === 200) 
            {
                const updatedFiles = [...files];
                updatedFiles.splice(index, 1);

                setFiles(updatedFiles);
                setDeletedRowFileIds((previousDeletedFileIds) => [...previousDeletedFileIds, id]);
                setAlertMessage("File Deleted Successfully.");
                setAlertType("success");
                setShowAlert(true);
            } 
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Get Documents Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }

            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    const handleDeleteNewFile = (index) => 
    {
        const newUploadedFiles = [...uploadedFiles];
    
        newUploadedFiles.splice(index, 1);
        setUploadedFiles(newUploadedFiles);
    }

    const handleTabTypeChange = (index, value) =>
    {
        const updatedUploadedFiles = [...uploadedFiles];

        updatedUploadedFiles[index].tabType = value;
        updatedUploadedFiles[index].docType = null;
        
        setUploadedFiles(updatedUploadedFiles);
    }

    const handleSetMarkForApproval = (index, value) =>
    {
        const updatedUploadedFiles = [...uploadedFiles];

        updatedUploadedFiles[index].markForApproval = value;
        setUploadedFiles(updatedUploadedFiles);
    }

    const handleUploadedFileChange = (index, value) => 
    {
        const updatedUploadedFiles = [...uploadedFiles];

        updatedUploadedFiles[index].docType = value;
        setUploadedFiles(updatedUploadedFiles);
    }

    const handleViewFile = async (event, file) =>
    {
        event.preventDefault();

        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        const { 
            dealPFIId,
            trancheId,
            cooId,
            name: fileName, 
            location: fileBlobName,
            fileType,
            fileSource,
            status: fileStatus 
        } = file.file;

        const fileRevisionNumber = isNaN(fileName?.value?.slice(1)) ? null : parseInt(fileName?.value?.slice(1), 10);

        await axios({
            method: "post",
            url: fileSource === "System Generated" ? "/api/ViewDocument" : "/api/AccessDocument",
            data: { 
                ...(fileSource === "System Generated"
                    ? {
                        pathname: pathname,
                        Deal_PFI_Id: dealPFIId,
                        Tranche_Id: trancheId,
                        COO_ID: cooId,
                        Document_Type: fileType,
                        Revision_Number: fileRevisionNumber,
                        is_approved_document: fileStatus === "Approved",
                        view_document: true
                    } 
                    : {
                        pathname: pathname,
                        FileBlobName: fileBlobName,
                        view_document: true
                    }
                )
            },
            signal: abortController.signal  
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;

            if (status === 200) 
            {
                window.open(data, "_blank", "noopener, noreferrer");
            } 
            else if (status === 202)
            {
                setAlertMessage(data?.data);
                setAlertType("info");
                setShowAlert(true);
            }
            else 
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("Download Document Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
            
            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    return (
        <div className = "upload-container my-3 w-100">
            <Grid 
                container 
                spacing = {2} 
                className = {`w-100 ${isKYC ? 'align-items-center' : ''}`}
            >
                {isKYC && (
                    <Grid size = {{ xs: 5 }} className = "form-text-field w-fit">
                        <small className = "mb-1">
                            {uploadText}
                        </small>
                    </Grid>
                )}

                <Grid size = {{ xs: 4 }} className = "pt-0">
                    <div className = "form-group">
                        <input
                            ref = {fileInputRef}
                            className = "form-control"
                            type = "file"
                            name = "documents"
                            multiple = {isMultiple}
                            disabled = {!isWritable}
                            placeholder = "Choose File"
                            accept = {fileTypes.map(type => `.${type}`).join(", ")}
                            onChange = {handleChange}
                        />
                    </div>
                </Grid>

                {isLoading && (
                    <Grid size = {{ xs: 1 }} className = "d-flex align-items-center">
                        <CircularProgress 
                            color = "inherit" 
                            sx = {{
                                width: '1.6em !important',
                                height: '1.6em !important'
                            }}
                        />
                    </Grid>
                )}
            </Grid>

            {(files?.length > 0 || uploadedFiles?.length > 0) && (
                <>
                    {allowFileTypeSelection && (
                        <>
                            <Grid container className = "file-table-header">
                                <Grid size = {{ xs: isKYC ? 5 : 2 }}>
                                    <span>Document Name</span>
                                </Grid>

                                {!isKYC && (
                                    <>
                                        <Grid size = {{ xs: 2 }}>
                                            <span>File Source</span>
                                        </Grid>

                                        <Grid size = {{ xs: 2 }}>
                                            <span>Tab</span>
                                        </Grid>
                                    </>
                                )}
                                
                                <Grid size = {{ xs: isKYC ? 5 : 3 }}>
                                    <span>File Type</span>
                                </Grid>

                                {!isKYC && (
                                    <Grid size = {{ xs: 2 }}>
                                        <span>Status</span>
                                    </Grid>
                                )}

                                <Grid size = {{ xs: isKYC ? 2 : 1 }}>
                                    <span>Actions</span>
                                </Grid>
                            </Grid>

                            {isLoading && <LinearProgress />}
                        </>
                    )}
                
                    <div className = "file-table-content-container">
                        {files?.map((file, index) => (
                            <Grid 
                                key = {index} 
                                container 
                                className = {`${allowFileTypeSelection ? 'file-table-content': 'mt-2'}`}
                            >
                                <Grid size = {{ xs: isKYC ? 5 : 2 }}>
                                    <a href = "#/" onClick = {(event) => handleViewFile(event, file)}>
                                        <span className = "uploaded-file cursor-pointer">
                                            {file.file.name}
                                        </span>
                                    </a>
                                </Grid>
                                
                                {allowFileTypeSelection && (
                                    <>
                                        {!isKYC && (
                                            <>
                                                <Grid size = {{ xs: 2 }}>
                                                    <span>{file.file.fileSource}</span>
                                                </Grid>

                                                <Grid size = {{ xs: 2 }}>
                                                    <span>{file.file.tabType}</span>
                                                </Grid>
                                            </>
                                        )}

                                        <Grid size = {{ xs: isKYC ? 5 : 3 }}>
                                            <span>{file.file.fileType}</span>
                                        </Grid>

                                        {!isKYC && (
                                            <Grid 
                                                size = {{ xs: 2 }}
                                                sx = {{
                                                    justifyContent: 'center',
                                                    color: file.file.fileSource === "System Generated"
                                                        ? file.file.status === "Approved"
                                                            ? '#00b242'
                                                            : '#fe0000'
                                                        : file.file.markedForApproval
                                                            ? file.file.status
                                                                ? '#00b242'
                                                                : '#fe0000'
                                                            : 'transparent'
                                                }}
                                            >
                                                <span>
                                                    {file.file.markedForApproval ? (
                                                        file.file.status ? "Approved" : "Not Approved"
                                                    ) : (
                                                        file.file.fileSource === "System Generated" 
                                                            ? file.file.status 
                                                            : "Approval Not Required"
                                                    )}
                                                </span>
                                            </Grid>
                                        )}
                                    </>
                                )}

                                <Grid size = {{ xs: isKYC ? 2 : 1 }}>
                                    {file.file.fileSource === "Uploaded File" && (
                                        <IconButton 
                                            color = "error" 
                                            size = "small"
                                            disabled = {!isWritable}
                                            onClick = {() => handleDeleteExistingFile(index, file.file.id)}
                                        >
                                            <DeleteIcon fontSize = "small" />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        ))}

                        {uploadedFiles?.map((file, index) => (
                            <Grid 
                                key = {index} 
                                container 
                                className = {`${allowFileTypeSelection ? 'file-table-content': 'mt-2'}`}
                            >
                                <Grid size = {{ xs: isKYC ? 5 : 2 }}>
                                    <span className = "uploaded-file cursor-pointer">
                                        {file.name}
                                    </span>
                                </Grid>

                                {allowFileTypeSelection && (
                                        isKYC ? (
                                            <Grid size = {{ xs: isKYC ? 5 : 4 }}>
                                                <VirtualizedAutocomplete
                                                    isMultiple = {false}
                                                    isObject = {true}
                                                    isRequired = {true}
                                                    isWritable = {true}
                                                    filterOn = "File Type"
                                                    options = {kycFileTypes || []}
                                                    selectedOptions = {file.docType || null}
                                                    handleSelectChange = {(filterOn, newValue) => handleUploadedFileChange(index, newValue)}
                                                />
                                            </Grid>
                                        ) : (
                                            <>
                                                <Grid size = {{ xs: 2 }}>
                                                    <span>Uploaded File</span>
                                                </Grid>

                                                <Grid size = {{ xs: 2 }}>
                                                    <VirtualizedAutocomplete
                                                        isMultiple = {false}
                                                        isObject = {false}
                                                        isRequired = {true}
                                                        isWritable = {true}
                                                        filterOn = "Tab"
                                                        options = {["Buying Leg", "Selling Leg"]}
                                                        selectedOptions = {file.tabType || null}
                                                        handleSelectChange = {(filterOn, newValue) => handleTabTypeChange(index, newValue)}
                                                    />
                                                </Grid>
        
                                                <Grid size = {{ xs: 3 }}>
                                                    <VirtualizedAutocomplete
                                                        isMultiple = {false}
                                                        isObject = {true}
                                                        isDisabled = {!file.tabType}
                                                        isRequired = {true}
                                                        isWritable = {true}
                                                        filterOn = "File Type"
                                                        options = {file.tabType === "Buying Leg" ? buyingLegFileTypes : file.tabType === "Selling Leg" ? sellingLegFileTypes : []}
                                                        selectedOptions = {file.docType || null}
                                                        handleSelectChange = {(filterOn, newValue) => handleUploadedFileChange(index, newValue)}
                                                    />
                                                </Grid>
        
                                                <Grid size = {{ xs: 2 }}>
                                                    <FormControlLabel
                                                        label = "Mark for Approval"
                                                        sx = {{
                                                            margin: 'auto',
                                                            gap: '3px'
                                                        }}
                                                        control = {
                                                            <Checkbox 
                                                                size = "small"
                                                                sx = {{
                                                                    padding: 0,
                                                                    marginTop: '-1px'
                                                                }}
                                                                checked = {file?.markForApproval || false}
                                                                onChange = {(event) => handleSetMarkForApproval(index, event.target.checked)}
                                                            />
                                                        }
                                                    />
                                                </Grid>
                                            </>
                                        )
                                )}

                                <Grid size = {{ xs: isKYC ? 2 : 1 }}>
                                    <IconButton 
                                        color = "error" 
                                        size = "small"
                                        disabled = {!isWritable}
                                        onClick = {() => handleDeleteNewFile(index)}
                                    >
                                        <DeleteIcon fontSize = "inherit" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
}

export default FileUploader;