import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { abortController } from '../../utils/abortController';
import axios from 'axios';

const STATUS = Object.freeze({
  IDLE: "idle",
  LOADING: "loading",
  SUCCEEDED: "succeeded",
  FAILED: "failed"
});

const initialState = {
  preShipment: {
    banks: [],
    tenors: [],
    paymentTerms: [],
    traders: [],
    incoterms: [],
    seaPorts: [],
    currencies: [],
    packagingTypes: [],
    customerCounterParties: [],
    supplierCounterParties: [],
    status: STATUS.IDLE,
    error: null
  },
  postShipment: {
    shippingLines: [],
    productGrades: [],
    products: [],
    seaPorts: [],
    buyingLegFileTypes: [],
    sellingLegFileTypes: [],
    selectedTranche: null,
    status: STATUS.IDLE,
    error: null
  }
};

const fetchData = async (endpoints, payload) => 
{
  const responses = await Promise.all(
    endpoints.map((endpoint) => axios.post(endpoint, payload, { signal: abortController.signal }))
  );

  return responses.map((response) => response.data);
};

export const fetchPreShipmentLists = createAsyncThunk("dealDropdownOptions/fetchPreShipmentLists", async ({ pathname, businessUnitId }, { rejectWithValue }) => 
{
  try 
  {
    const endpoints = [
      "/api/GetTenorList",
      "/api/GetPaymentTermList"
    ];

    if (pathname !== "/mass-update") 
    {
      endpoints.push(...[
        "/api/GetBankList",
        "/api/GetTraderList",
        "/api/GetIncotermList",
        "/api/GetSeaPortList",
        "/api/GetCurrencyList",
        "/api/GetPackagingTypeList",
        "/api/GetCounterPartyList",
        "/api/GetDealPFIList"
      ]);
    }

    const [
      tenors,
      paymentTerms,
      banks,
      traders,
      incoterms,
      seaPorts,
      currencies,
      packagingTypes,
      counterParties,
      pfis
    ] = await fetchData(endpoints, { 
      pathname, 
      Business_Unit_Id: businessUnitId
    });

    return {
      tenors: tenors?.tenors || [],
      paymentTerms: paymentTerms?.payment_terms || [],
      banks: banks?.banks || [],
      traders: traders?.traders || [],
      incoterms: incoterms?.incoterms || [],
      seaPorts: seaPorts?.sea_ports || [],
      currencies: currencies?.currencies || [],
      packagingTypes: packagingTypes?.packaging_types || [],
      customerCounterParties: counterParties?.customer_vendors || [], 
      supplierCounterParties: counterParties?.supplier_vendors || [],
      pfis: pfis?.pfis || []
    };
  } 
  catch (error) 
  {
    return rejectWithValue(error.response?.data || "Failed to fetch pre-shipment lists");
  }
});

export const fetchPostShipmentLists = createAsyncThunk("dealDropdownOptions/fetchPostShipmentLists", async ({ pathname, businessUnitId, selectedPFI, isFoodsOrPolymersDeal, isFoodsPolymersOrSBLCDeal }, { rejectWithValue }) => 
{
  try 
  {
    const endpoints = [
      "/api/GetShippingLineList",
      "/api/GetSeaPortList",
      "/api/GetExternalFileTypeList"
    ];
    
    if (pathname !== "/mass-update") 
    {
      if (isFoodsOrPolymersDeal) 
      {
        endpoints.push("/api/GetDealProductGrades");
      }
      else if (!isFoodsPolymersOrSBLCDeal) 
      {
        endpoints.push("/api/GetDealProducts");
      }
    }
    
    const [
      shippingLines,
      seaPorts,
      externalFileTypes,
      productGradesOrProducts,
    ] = await fetchData(endpoints, {
      pathname, 
      Business_Unit_Id: businessUnitId, 
      Deal_PFI_Id: selectedPFI
    });    

    const { buying_leg_file_types: buyingLegFileTypes = [], selling_leg_file_types: sellingLegFileTypes = [] } = externalFileTypes || {};
    const productGrades = isFoodsOrPolymersDeal ? productGradesOrProducts : [];
    const products = !isFoodsPolymersOrSBLCDeal ? productGradesOrProducts : [];

    return {
      shippingLines: shippingLines?.shipping_lines || [],
      seaPorts: seaPorts?.sea_ports || [],
      buyingLegFileTypes,
      sellingLegFileTypes,
      productGrades: productGrades?.deal_product_grades || [],
      products: products?.deal_products || []
    };
  } 
  catch (error) 
  {
    return rejectWithValue(error.response?.data || "Failed to fetch post-shipment lists");
  }
});

const dealDropdownOptions = createSlice({
  name: "dealDropdownOptions",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchPreShipmentLists.pending, (state) => {
        state.preShipment.status = STATUS.LOADING;
      })
      .addCase(fetchPreShipmentLists.fulfilled, (state, action) => {
        Object.assign(state.preShipment, {
          ...action.payload,
          status: STATUS.SUCCEEDED,
          error: null
        });
      })
      .addCase(fetchPreShipmentLists.rejected, (state, action) => {
        state.preShipment.status = STATUS.FAILED;
        state.preShipment.error = action.payload;
      })
      .addCase(fetchPostShipmentLists.pending, (state) => {
        state.postShipment.status = STATUS.LOADING;
      })
      .addCase(fetchPostShipmentLists.fulfilled, (state, action) => {
        Object.assign(state.postShipment, {
          ...action.payload,
          status: STATUS.SUCCEEDED,
          error: null
        });
      })
      .addCase(fetchPostShipmentLists.rejected, (state, action) => {
        state.postShipment.status = STATUS.FAILED;
        state.postShipment.error = action.payload;
      });
  }
});

export default dealDropdownOptions.reducer;