import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { abortController } from '../../utils/abortController';
import useSessionExpire from '../../hooks/useSessionExpire';
import Logo from '../../images/logo-full.png';
import Backdrop from '@mui/material/Backdrop';
import Alert from '../../components/Alert';
import Loader from '../../components/Loader';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Button from 'react-bootstrap/Button';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DownloadIcon from '@mui/icons-material/Download';
import axios from 'axios';

const ReportDocuments = ({ logOut = () => {} }) => 
{
    const [isLoading, setIsLoading] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertType, setAlertType] = useState("");
    const { pathname } = useLocation();
    const navigate = useNavigate();

    /* SEARCH PARAMETER VARIABLES */
    const params = useParams();
    const { trancheId = null, fileType = null } = params || {};

    /* SESSION EXPIRY VARIABLES */
    const triggerSessionExpire = useSessionExpire();

    /* DOCUMENT VARIABLES */
    const [documents, setDocuments] = useState([]);

    useEffect(() =>
    {
        const fetchDocuments = async () =>
        {
            setIsLoading(true);
            setShowAlert(false);
            setAlertMessage("");
            setAlertType("");

            await axios({
                method: "post",
                url: "/api/GetReportDocuments",
                data: {
                    Tranche_Id: trancheId,
                    File_Type: fileType
                },
                signal: abortController.signal
            })
            .then((response) => 
            {
                setIsLoading(false);
                const { status, data } = response;
        
                if (status === 200) 
                {
                    setDocuments(data || []);
                }
                else
                {
                    setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                    setAlertType("error");
                    setShowAlert(true);
                }
            })
            .catch((error) => 
            {
                console.log("Get Report Documents Api: ", error);
                setIsLoading(false);

                if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
                {
                    return;
                }
        
                const status = error?.response?.status;
        
                if (status === 403) 
                {
                    triggerSessionExpire();
                } 
                else 
                {
                    setAlertMessage(
                        status === 401
                            ? "Unauthorized access. You do not have the required permissions to perform this action."
                            : status === 429
                            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                            : "An error occurred while processing your request. Please try again later or contact the site administrator."
                    );
                    setAlertType("error");
                    setShowAlert(true);
                
                    if (status === 429) 
                    {
                        setTimeout(logOut, 3000);
                    }
                }
            });
        }

        if (trancheId && fileType)
        {
            fetchDocuments();
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [trancheId, fileType]);

    const handleDocumentAction = async (actionType, document) =>
    {
        setIsLoading(true);
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");

        const { 
            Deal_PFI_Id,
            id,
            Is_Approved_CI
        } = document;
    
        await axios({
            method: "post",
            url: fileType === "Customer CI" ? "/api/ViewDocument" : "/api/AccessDocument",
            data: {
                ...(fileType === "Customer CI"
                    ? {
                        pathname: pathname,
                        Deal_PFI_Id,
                        Tranche_Id: id,
                        Document_Type: "CI",
                        is_approved_document: Is_Approved_CI,
                        view_document: actionType === "View"
                    } 
                    : {
                        pathname: pathname,
                        FileBlobName: document.File_Location,
                        view_document: actionType === "View"
                    }
                )
            },
            signal: abortController.signal
        })
        .then((response) => 
        {
            setIsLoading(false);
            const { status, data } = response;
        
            if (status === 200) 
            {
                window.open(data, "_blank", "noopener, noreferrer");
            }
            else if (status === 202)
            {
                setAlertMessage(data?.data);
                setAlertType("info");
                setShowAlert(true);
            }
            else
            {
                setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
                setAlertType("error");
                setShowAlert(true);
            }
        })
        .catch((error) => 
        {
            console.log("View Document Api: ", error);
            setIsLoading(false);

            if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
            {
                return;
            }
        
            const status = error?.response?.status;

            if (status === 403) 
            {
                triggerSessionExpire();
            } 
            else 
            {
                setAlertMessage(
                    status === 401
                        ? "Unauthorized access. You do not have the required permissions to perform this action."
                        : status === 429
                        ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
                        : "An error occurred while processing your request. Please try again later or contact the site administrator."
                );
                setAlertType("error");
                setShowAlert(true);
            
                if (status === 429) 
                {
                    setTimeout(logOut, 3000);
                }
            }
        });
    }

    return (
        <div className = "report-document-background">
            <Alert
                show = {showAlert}
                message = {alertMessage}
                type = {alertType}
                setShow = {setShowAlert}
            />

            <Backdrop
                sx = {{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open = {isLoading}
            >
                <Loader/>
            </Backdrop>

            <Box className = "report-document-container">
                <div>
                    <img src = {Logo} alt = "Engro" className = "login-logo" />
                    <h6>Report Documents</h6>

                    <div className = "list-container">
                        {(isLoading && documents.length === 0) ? (
                            <>
                                <Skeleton animation = "wave" />
                                <Skeleton animation = "wave" />
                                <Skeleton animation = "wave" />
                                <Skeleton animation = "wave" />
                            </>
                        ) : (
                            <>
                                {documents?.length > 0 ? (
                                    <List 
                                        sx = {{ 
                                            width: '100%', 
                                            bgcolor: 'background.paper',
                                            paddingTop: 0,
                                            paddingBottom: 0
                                        }}
                                    >
                                        {documents.map((document, index) => (
                                            <ListItem
                                                key = {index}
                                                disableGutters
                                                secondaryAction = {
                                                    <div className = "d-flex align-items-center gap-3">
                                                        <Tooltip title = "View">
                                                            <IconButton 
                                                                color = "primary" 
                                                                size = "small"
                                                                onClick = {() => handleDocumentAction("View", document)}
                                                            >
                                                                <VisibilityIcon 
                                                                    fontSize = "inherit"
                                                                    sx = {{ color: '#0C969C' }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>

                                                        <Tooltip title = "Download">
                                                            <IconButton 
                                                                color = "primary" 
                                                                size = "small"
                                                                onClick = {() => handleDocumentAction("Download", document)}
                                                            >
                                                                <DownloadIcon 
                                                                    fontSize = "inherit"
                                                                    sx = {{ color: '#0C969C' }}
                                                                />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </div>
                                                }
                                                sx = {{
                                                    paddingTop: 0,
                                                    paddingBottom: 0
                                                }}
                                            >
                                                <ListItemText 
                                                    primary = {fileType === "Customer CI" ? `${document.Commercial_Invoice}.pdf` : document.File_Name} 
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                ) : (
                                    <span>No documents found for this report.</span>
                                )}
                            </>
                        )}

                        <Button
                            disabled = {isLoading}
                            className = {`homepage-button btn-secondary`}
                            onClick = {() => navigate("/")}
                        >
                            Go to Homepage
                        </Button>
                    </div>
                </div>
            </Box>
        </div>
    );
}

export default ReportDocuments;