import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { abortController } from '../../utils/abortController';
import axios from 'axios';

const initialState = {
    certificatesOfOrigin: [],
    documentRevisionNumbers: [],
    documentGenerationData: {},
    files: []
};

export const fetchCertificatesOfOrigin = createAsyncThunk("uploadedDocuments/fetchCertificatesOfOrigin", async ({ selectedBusinessUnit, selectedTranche }, { dispatch, rejectWithValue }) => 
{
    try 
    {
        const response = await axios.post("/api/GetCertificatesOfOrigin", {
            Business_Unit_Id: selectedBusinessUnit,
            Tranche_Id: selectedTranche?.id
        }, { 
            signal: abortController.signal 
        });

        if (response.status === 200) 
        {
            dispatch(setCertificatesOfOrigin(response.data || []));
        }
    } 
    catch (error) 
    {
        return rejectWithValue(error.response?.data || "Failed to fetch COO list");
    }
});

export const fetchDocumentRevisionList = createAsyncThunk("uploadedDocuments/fetchDocumentRevisionList", async ({ selectedBusinessUnit, selectedPFI, selectedTranche }, { dispatch, rejectWithValue }) => 
{
    try 
    {
        const response = await axios.post("/api/GetDocumentRevisions", {
            Business_Unit_Id: selectedBusinessUnit,
            Deal_PFI_Id: selectedPFI,
            Tranche_Id: selectedTranche?.id
        }, { 
            signal: abortController.signal 
        });

        if (response.status === 200) 
        {
            dispatch(setDocumentRevisionData(response.data || {}));
        }
    } 
    catch (error) 
    {
        return rejectWithValue(error.response?.data || "Failed to fetch document revision list");
    }
});

export const fetchUploadedDocuments = createAsyncThunk("uploadedDocuments/fetchUploadedDocuments", async ({ selectedPFI, selectedTranche }, { dispatch, rejectWithValue }) => 
{
    try 
    {
        const response = await axios.post("/api/GetAllDocuments", {
            pathname: window.location?.pathname,
            Deal_PFI_Id: selectedPFI,
            TrancheID: selectedTranche?.id,
        }, { 
            signal: abortController.signal 
        });

        if (response.status === 200) 
        {
            const files = response.data?.map(({
                Deal_PFI_Id: dealPFIId,
                TrancheID: trancheId,
                COO_Id: cooId,
                File_Name: name,
                File_Id: id,
                File_Location: location,
                File_Source: fileSource,
                Tab_Type: tabType,
                File_Type: fileType,
                Doc_Type: docName,
                isApproved: status,
                Approve: markedForApproval
            }) => ({
                file: { dealPFIId, trancheId, cooId, name, id, location, fileSource, tabType, fileType, status, markedForApproval },
                docName
            })) || [];

            dispatch(setFiles(files));
        }
    } 
    catch (error) 
    {
        return rejectWithValue(error.response?.data || "Failed to fetch uploaded documents");
    }
});

const dealUploadedDocuments = createSlice({
    name: "dealUploadedDocuments",
    initialState,
    reducers: {
        setCertificatesOfOrigin: (state, action) => {
            state.certificatesOfOrigin = action.payload;
        },
        setDocumentRevisionData: (state, action) => {
            const { document_revision_data, document_generation_data } = action.payload;
            state.documentRevisionNumbers = document_revision_data;
            state.documentGenerationData = document_generation_data;
        },
        setFiles: (state, action) => {
            state.files = action.payload;
        },
        resetDocumentData: (state) => {
            Object.assign(state, initialState);
        }
    }
});

export const {
    setCertificatesOfOrigin,
    setFiles,
    setDocumentRevisionData,
    resetDocumentData
} = dealUploadedDocuments.actions;

export default dealUploadedDocuments.reducer;