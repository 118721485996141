import { useState } from 'react';
import { Link } from 'react-router-dom';
import { roles } from '../../utils/roles';
import withAuth from '../../lib/withAuth';
import Button from 'react-bootstrap/Button';
import Box from '@mui/material/Box';
import Alert from 'react-bootstrap/Alert';
import Logo from '../../images/logo-full.png';
import axios from 'axios';
import '../../styles/authentication.css';

const ResetPassword = () => 
{
  const [isLoading, setIsLoading] = useState(false);
  const [alertSuccess, setAlertSuccess] = useState(null);
  const [alertError, setAlertError] = useState(null)

  /* EMAIL VARIABLES */
  const [email, setEmail] = useState(null);

  const fetchGeolocation = () => 
  {
    return new Promise((resolve) => 
    {
      if (navigator.geolocation) 
      {
        navigator.geolocation.getCurrentPosition(
          (position) => 
          {
            resolve({
              lat: position.coords.latitude,
              lon: position.coords.longitude
            });
          },
          (error) => 
          {
            console.error("Get Geolocation Error: ", error);
            resolve(null); 
          }
        );
      } 
      else 
      {
        resolve(null); 
      }
    });
  }

  const handleChange = (event) => 
  {
    if (typeof event.target.value !== "undefined" && event.target.value !== "") 
    {
      const emailPattern = new RegExp(
        /^(('[\w\s-]+')|([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );

      if (!emailPattern.test(event.target.value)) 
      {
        event.target.setCustomValidity("Please enter a valid email address.");
      }
      else
      {
        event.target.setCustomValidity("");
      }

      setEmail(event.target.value);
    }
  }

  const handleSubmit = async (event) => 
  {
    event.preventDefault();

    setIsLoading(true);
    setAlertSuccess(null);
    setAlertError(null);

    const geolocation = await fetchGeolocation(); 

    await axios({
      method: "post",
      url: "/api/ForgotPassword",
      data: { 
        email,
        geolocation
      }
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200 || status === 202) 
      {
        setAlertSuccess(data?.data);
        setTimeout(() => setAlertSuccess(null), 6000);
      } 
      else 
      {
        setAlertError("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setTimeout(() => setAlertError(null), 6000);
      }
    })
    .catch((error) => 
    {
      console.log("Forgot Password Api: ", error);
      setIsLoading(false);
      setAlertError(
        error?.response?.status === 429
          ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
          : "An error occurred while processing your request. Please try again later or contact the site administrator."
      );
      setTimeout(() => setAlertError(null), 6000);
    });
  }

  return (
    <div className = "login-background">
      <Box className = "login-container">
        <div>
          <img src = {Logo} alt = "Engro" className = "login-logo" />
          <h6>Reset Password</h6>

          <div className = "login-form reset-password">
            <form 
              className = "d-flex flex-column gap-3"
              onSubmit = {handleSubmit}
            >
              <div className = "sub-title">
                Enter your email to change password.
              </div>

              {alertSuccess && <Alert variant = "success">{alertSuccess}</Alert>}
              {alertError && <Alert variant = "danger">{alertError}</Alert>}

              <div className = "form-group">
                <input
                  type = "email"
                  id = "email"
                  name = "email"
                  className = "form-control"
                  placeholder = "Enter Email"
                  onChange = {handleChange}
                  required />
              </div>

              <div className = "d-flex justify-content-start">
                <Link to = "/login" className = "login-link">
                  <i>Back To Login</i>
                </Link>
              </div>

              <Button
                type = "submit"
                disabled = {isLoading}
                className = "login-button btn-secondary"
              >
                {isLoading ? (
                  <div className = "spinner-border text-info" role = "status" />
                ) : (
                  'Submit'
                )}
              </Button>
            </form>
          </div>
        </div>
      </Box>
    </div>
  );
}

export default withAuth(ResetPassword)([
  roles[1], 
  roles[2], 
  roles[3], 
  roles[4], 
  roles[5], 
  roles[6], 
  roles[7], 
  roles[8], 
  roles[9], 
  roles[10], 
  roles[11], 
  roles[12], 
  roles[13], 
  roles[14], 
  roles[15], 
  roles[16], 
  roles[17], 
  roles[18], 
  roles[19]
]);