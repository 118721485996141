import { forwardRef, memo, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable, MRT_ShowHideColumnsButton } from 'material-react-table';
import { RenderCustomCell } from './DataTableCustomCell';
import ApprovalIcon from '@mui/icons-material/Approval';
import Github from '@uiw/react-color-github';
import Popover from '@mui/material/Popover';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import LockResetIcon from '@mui/icons-material/LockReset';
import WarningIcon from '@mui/icons-material/Warning';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import DownloadIcon from '@mui/icons-material/Download';
import SearchIcon from '@mui/icons-material/Search';
import InsertCommentIcon from '@mui/icons-material/InsertComment';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import AddIcon from '@mui/icons-material/Add';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import dayjs from 'dayjs';

const MaterialReactDataTable = forwardRef(({ title = "", isWritable = false, isLoading = false, isTabChanged = false, isBusinessUnitSelected = false, isReportExportable = false, isDataChanged = false, showActions = true, allowBulkActions = false, businessUnitId = null, currentUserId = null, newDeal = {}, globalFilterValue = "", counterPartyTypes = [], tableData = {}, setIsTabChanged = () => {}, setGlobalFilterValue = () => {}, setIsDataChanged = () => {}, handleGlobalFilter = () => {}, handleExportRows = () => {}, handleAddOrEditComment = () => {}, handleViewDocument = () => {}, handleRecordStatusChange = () => {}, handleBulkViewOrDownload = () => {}, handleBulkApproval = () => {}, handleAddDeal = () => {}, handleAddPFI = () => {}, handleEditDeal = () => {}, handleEditKYCRequest = () => {}, handleSaveChanges = () => {}, handleResetPassword = () => {}, handleUserStatusChange = () => {}, handleOpenAuditTrailModal = () => {} }, ref) => 
{
    /* DATA VARIABLES */
    const [columns, setColumns] = useState([]);
    const [data, setData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});

    /* DATATABLE GLOBAL FILTER VARIABLES */
    const [showGlobalFilterTextField, setShowGlobalFilterTextField] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const searchInputRef = useRef(null);
    const searchButtonRef = useRef(null);

    /* DATATABLE COLOR PICKER VARIABLES */
    const [anchorEl, setAnchorEl] = useState(null);
    const showColorPicker = Boolean(anchorEl);

    /* DATATABLE ROW VARIALES  */ 
    const [rowSelection, setRowSelection] = useState({});
    
    /* DATATABLE VARIABLES */
    const table = useMaterialReactTable({
        columns,
        data: filteredData,
        enableFacetedValues: true,
        enableColumnResizing: true,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableColumnVirtualization: true,
        enableRowVirtualization: true,
        enableColumnActions: false,
        enableExpanding: !["Counter Party List", "User Management", "User Audit Trail"].includes(title),
        enableStickyHeader: true,
        enableRowActions: showActions && columns?.length > 0,
        enableRowSelection: allowBulkActions && data?.length > 0,
        enableSubRowSelection: allowBulkActions && data?.length > 0,
        filterFromLeafRows: true,
        paginateExpandedRows: true,
        enableFilterMatchHighlighting: true,
        positionToolbarAlertBanner: 'bottom',
        positionActionsColumn: 'last',
        columnFilterDisplayMode: 'popover',
        autoResetAll: title === "Approve Documents" ? isTabChanged : false,
        columnVirtualizerOptions: { overscan: columns.length - 1 },
        state: { rowSelection },
        initialState: { 
            density: 'compact',
            expanded: false,
            pagination: { 
                pageIndex: 0, 
                pageSize: 20 
            },
            columnPinning: { 
                left: [
                    'mrt-row-expand', 
                    'mrt-row-select'
                ] 
            }
        },
        getSubRows: (row) => row.subRows,
        renderTopToolbarCustomActions: ({ table }) => (
            <>
                {title === "Deals" ? (
                    <Button
                        startIcon = {<AddIcon sx = {{ marginTop: '-3px', color: '#0C969C' }} />}
                        sx = {{ color: '#0C969C' }}
                        size = "small"
                        disabled = {!isWritable}
                        onClick = {handleAddDeal}
                    >
                        Add New Deal
                    </Button>
                ) : (title === "Approve Documents" && allowBulkActions) ? (
                    <div className = "d-flex align-items-center gap-2 bulk-actions">
                        <Button
                            startIcon = {<VisibilityIcon sx = {{ marginTop: '-3px', color: '#0C969C' }} />}
                            sx = {{ color: '#0C969C' }}
                            size = "small"
                            disabled = {!(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected())}
                            onClick = {() => handleBulkViewOrDownload(
                                table.getSelectedRowModel().flatRows
                                    .filter(({ depth }) => depth > 0)
                                    .map(({ original }) => original), 
                                    "view"
                                )
                            }
                        >
                            View Selected
                        </Button>

                        <Button
                            startIcon = {<DownloadIcon sx = {{ marginTop: '-3px', color: '#0C969C' }} />}
                            sx = {{ color: '#0C969C' }}
                            size = "small"
                            disabled = {!(table.getIsSomeRowsSelected() || table.getIsAllRowsSelected())}
                            onClick = {() => handleBulkViewOrDownload(
                                table.getSelectedRowModel().flatRows
                                    .filter(({ depth }) => depth > 0)
                                    .map(({ original }) => original), 
                                    "download"
                                )
                            }
                        >
                            Download Selected
                        </Button>

                        <Button
                            startIcon = {<ApprovalIcon sx = {{ marginTop: '-3px', color: '#0C969C' }} />}
                            sx = {{ color: '#0C969C' }}
                            size = "small"
                            disabled = {
                                !isWritable || (
                                    (table.getIsSomeRowsSelected() || table.getIsAllRowsSelected())
                                        ? !table.getSelectedRowModel().flatRows.some(({ original, depth }) => depth > 0 && !original.isApproved)
                                        : true
                                )
                            }
                            onClick = {() => handleBulkApproval(
                                table.getSelectedRowModel().flatRows
                                    .filter(({ original, depth }) => depth > 0 && !original.isApproved)
                                    .map(({ original }) => original)
                                )
                            }
                        >
                            Approve Selected
                        </Button>
                    </div>
                ) : title.includes("Audit") && (
                    <Button
                        startIcon = {<WarningIcon sx = {{ marginTop: '-3px', color: '#0C969C' }} />}
                        sx = {{ color: '#0C969C' }}
                        size = "small"
                        onClick = {handleOpenAuditTrailModal}
                    >
                        Disclaimer
                    </Button>
                )}

                <div>
                    <Button 
                        ref = {resetButtonRef} 
                        className = "hidden" 
                        onClick = {() => handleResetTable(table)}
                    />

                    {isReportExportable && (
                        <Button
                            startIcon = {<FileDownloadIcon sx = {{ marginTop: '-1px', marginRight: '5px', color: '#0C969C' }} />}
                            sx = {{ color: '#0C969C' }}
                            size = "small"
                            disabled = {table.getPrePaginationRowModel().rows.length === 0}
                            onClick = {() => handleExportRows(table.getPrePaginationRowModel().rows)}
                        >
                            Export {title}
                        </Button>
                    )}

                    {title === "Morning Report" && (
                        <Button
                            startIcon = {<SaveIcon sx = {{ marginTop: '-1px', marginRight: '5px', color: '#0C969C' }} />}
                            sx = {{ color: '#0C969C' }}
                            title = "Save Changes"
                            disabled = {!isWritable || !isDataChanged}
                            onClick = {() => handleSaveChanges(table.getPrePaginationRowModel().rows)}
                        >
                            Save Changes
                        </Button>
                    )}
                </div>
            </>
        ),
        renderToolbarInternalActions: ({ table }) => (
            <>
                <Slide
                    direction = "left"
                    in = {showGlobalFilterTextField}
                    mountOnEnter
                    unmountOnExit
                >
                    <TextField
                        inputRef = {searchInputRef}
                        placeholder = "Search..."
                        className = "global-search mr-1"
                        value = {globalFilterValue || ""}
                        disabled = {data?.length === 0}
                        onChange = {handleGlobalFilterValueChange}
                        onKeyDown = {handleEnterPress}
                        slotProps = {{
                            inputLabel: {
                                shrink: false
                            },
                            input: {
                                endAdornment: (
                                    <>
                                        <IconButton
                                            title = "Clear"
                                            size = "small"
                                            onClick = {handleClearGlobalFilter}
                                        >
                                            <ClearIcon 
                                                fontSize = "small"
                                                sx = {{ color: '#0C969C' }}
                                            />
                                        </IconButton>

                                        <IconButton
                                            ref = {searchButtonRef}
                                            title = "Search"
                                            size = "small"
                                            onClick = {() => title === "Approve Documents" 
                                                ? handleGlobalFilter(table, data)
                                                : handleGlobalFilter(data)
                                            }
                                        >
                                            <SearchIcon 
                                                fontSize = "small"
                                                sx = {{ color: '#0C969C' }}
                                            />
                                        </IconButton>
                                    </>
                                )
                            }
                        }}
                    />
                </Slide>

                <IconButton
                    title = "Search"
                    disabled = {table.getPrePaginationRowModel().rows.length === 0}
                    onClick = {handleShowGlobalFilterTextField}
                >
                    {showGlobalFilterTextField ? (
                        <SearchOffIcon 
                            fontSize = "small"
                            sx = {{ color: '#0C969C' }}
                        /> 
                    ) : ( 
                        <SearchIcon 
                            fontSize = "small"
                            sx = {{ color: '#0C969C' }}
                        />
                    )}
                </IconButton>

                <IconButton
                    title = "Clear Filters"
                    disabled = {table.getPrePaginationRowModel().rows.length === 0}
                    onClick = {() => table.resetColumnFilters()}
                >
                    <FilterAltOffIcon 
                        fontSize = "small"
                        sx = {{ color: '#0C969C' }}
                    />
                </IconButton>

                {/* eslint-disable-next-line react/jsx-pascal-case */}
                <MRT_ShowHideColumnsButton 
                    table = {table}
                    sx = {{ 
                        '> svg': {
                            fill: '#0C969C'
                        } 
                    }}
                />
            </>
        ),
        renderEmptyRowsFallback: () => (
            <p className = "empty-table">
                {!isLoading &&
                    (!isBusinessUnitSelected && title !== "Finance Audit Trail"
                    ? "Please select a business unit to view data."
                    : "No records to display.")}
            </p>
        ),
        renderRowActions: ({ row }) => {
            let rowData = row.original || {};
            const isSubRow = row.depth > 0;

            if (title === "Deals" && isSubRow)
            {
                const parentRowData = row.getParentRow().original;
                const { deal_id, "Deal No.": dealNumber } = parentRowData;

                rowData = { ...rowData, "Deal No.": dealNumber, deal_id };                
            }

            return (
                <div className = {`${title !== "Approve Documents" ? 'mx-auto' : ''}`}>
                    <Box className = "d-flex justify-content-center align-items-center w-100 gap-2 h-full">
                        {isSubRow ? (  
                            <>
                                {title === "Deals" ? (
                                    <>
                                        <Tooltip title = "Pre-Shipment">
                                            <IconButton 
                                                color = "primary" 
                                                size = "small"
                                                onClick = {() => handleEditDeal(rowData, "Pre-Shipment")}
                                            >
                                                <DescriptionIcon 
                                                    fontSize = "inherit" 
                                                    sx = {{ color: '#0C969C'}} 
                                                />
                                            </IconButton>
                                        </Tooltip>
                                        
                                        <Tooltip title = "Post-Shipment">
                                            <IconButton 
                                                color = "primary" 
                                                size = "small"
                                                onClick = {() => handleEditDeal(rowData, "Post-Shipment")}
                                            >
                                                <LocalShippingIcon 
                                                    fontSize = "inherit" 
                                                    sx = {{ color: '#0C969C' }} 
                                                />
                                            </IconButton>
                                        </Tooltip>
                                    </>
                                ) : title === "Morning Report" ? (
                                    <>
                                        <Tooltip title = "Color Picker">
                                            <span className = "m-auto">
                                                <IconButton 
                                                    color = "primary" 
                                                    size = "small"
                                                    disabled = {!isWritable || !(rowData?.deal_product_grade_id || rowData?.tranche_product_id)}
                                                    onClick = {(event) => handleShowColorPicker(event, row)}
                                                >
                                                    <FormatColorFillIcon 
                                                        fontSize = "inherit"
                                                        sx = {{ color: '#0C969C' }} 
                                                    />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
            
                                        <Popover 
                                            open = {showColorPicker}
                                            anchorEl = {anchorEl}
                                            anchorOrigin = {{
                                                vertical: 'top',
                                                horizontal: 'center'
                                            }}
                                            transformOrigin = {{
                                                vertical: 'bottom',
                                                horizontal: 'center'
                                            }}
                                            onClose = {handleCloseColorPicker}
                                        >
                                            <Github
                                                color = {rowData?.rowColor}
                                                colors = {['#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB', '#FFFFFF']}
                                                onChange = {(color) => handleChangeRowColor(color.hex)}
                                            />
                                        </Popover>
                                    </>
                                ) : title === "Approve Documents" && (
                                    <div className = "grid-container icon-buttons">
                                        <Tooltip title = "Comments">
                                            <span className = "m-auto">
                                                <IconButton 
                                                    color = "primary" 
                                                    size = "small"
                                                    disabled = {!isWritable}
                                                    onClick = {() => handleAddOrEditComment(rowData)}
                                                >
                                                    <InsertCommentIcon 
                                                        fontSize = "inherit"
                                                        sx = {{ color: '#0C969C' }}
                                                    />
                                                </IconButton>
                                            </span>
                                        </Tooltip>

                                        <Tooltip title = "View">
                                            <IconButton 
                                                color = "primary" 
                                                size = "small"
                                                onClick = {() => handleViewDocument(rowData)}
                                            >
                                                <VisibilityIcon 
                                                    fontSize = "inherit"
                                                    sx = {{ color: '#0C969C' }}
                                                />
                                            </IconButton>
                                        </Tooltip>

                                        <Tooltip title = "Delete">
                                            <span className = "m-auto">
                                                <IconButton 
                                                    color = "error" 
                                                    size = "small"
                                                    disabled = {!isWritable}
                                                    onClick = {() => handleDeleteDocument(rowData)}
                                                >
                                                    <DeleteIcon fontSize = "inherit" />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        
                                        <Button
                                            className = "capitalize mr-1"
                                            color = {rowData?.isApproved ? 'error' : 'success'}
                                            disabled = {!isWritable}
                                            size = "small"
                                            onClick = {() => handleSetDocumentStatus(rowData)}
                                        >
                                            <strong>
                                                {rowData?.isApproved ? 'Disapprove' : 'Approve'}
                                            </strong>
                                        </Button>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {title === "Deals" ? (
                                    <Tooltip title = "Add New PFI">
                                        <span className = "m-auto">
                                            <IconButton 
                                                color = "primary" 
                                                size = "small"
                                                disabled = {!isWritable}
                                                onClick = {() => handleAddPFI(rowData)}
                                            >
                                                <AddIcon 
                                                    fontSize = "inherit"
                                                    sx = {{ color: '#0C969C' }}
                                                />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                ) : title === "Requests" ? (
                                    <Tooltip title = "Edit KYC Request">
                                        <span className = "m-auto">
                                            <IconButton 
                                                color = "primary" 
                                                size = "small"
                                                disabled = {calculateIsRequestDisabled(rowData)}
                                                onClick = {() => handleEditKYCRequest(rowData?.uuid)}
                                            >
                                                <EditIcon 
                                                    fontSize = "inherit"
                                                    sx = {{ color: '#0C969C' }}
                                                />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                ) : title === "Counter Party List" ? (
                                    <Tooltip title = "Edit KYC Request">
                                        <span className = "m-auto">
                                            <IconButton 
                                                color = "primary" 
                                                size = "small"
                                                disabled = {!isWritable || !rowData?.["Counter Party Type"]}
                                                onClick = {() => handleEditKYCRequest(rowData?.uuid)}
                                            >
                                                <EditIcon 
                                                    fontSize = "inherit"
                                                    sx = {{ color: '#0C969C' }}
                                                />
                                            </IconButton>
                                        </span>
                                    </Tooltip>
                                ) : title === "User Management" && (
                                    <>
                                        <Tooltip title = "Reset Password">
                                            <span className = "m-auto">
                                                <IconButton 
                                                    color = "primary" 
                                                    size = "small"
                                                    onClick = {() => handleResetPassword(rowData)}
                                                >
                                                    <LockResetIcon 
                                                        fontSize = "inherit"
                                                        sx = {{ color: '#065886' }}
                                                    />
                                                </IconButton>
                                            </span>
                                        </Tooltip>

                                        {currentUserId !== rowData?.id && (
                                            <Button
                                                className = "capitalize"
                                                color = {rowData?.Status === "Inactive" ? "success" : "error"}
                                                disabled = {!isWritable}
                                                size = "small"
                                                onClick = {() => handleUserStatusChange(rowData?.id, rowData?.Status === "Inactive" ? 1 : 0)}
                                                sx = {{
                                                    minWidth: 'fit-content'
                                                }}
                                            >
                                                <strong>
                                                    {rowData?.Status === "Inactive" ? "Enable" : "Disable"}
                                                </strong>
                                            </Button>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </Box>
                </div>
            );
        },
        onRowSelectionChange: setRowSelection,
        displayColumnDefOptions: {
            'mrt-row-expand': {
                size: 45,
                grow: false,
                visibleInShowHideMenu: false,
                muiTableBodyCellProps: {
                    sx: {
                        backgroundColor: 'white',
                        border: '1px solid #949495'
                    }
                }
            },
            'mrt-row-select': {
                size: 45,
                grow: false,
                visibleInShowHideMenu: false,
                muiTableBodyCellProps: {
                    sx: {
                        backgroundColor: 'white',
                        border: '1px solid #949495'
                    }
                }
            },
            'mrt-row-actions': {
                size: title === "Approve Documents" ? 210 : 100,
                grow: false,
                visibleInShowHideMenu: false
            }
        },
        filterFns: {
            multiSelect: (row, columnId, filterValue) => 
            {
                if (!filterValue || filterValue.length === 0) 
                {
                    return true;
                }
                
                return filterValue.includes(row.getValue(columnId));
            }
        },
        muiTableProps: {
            sx: {
                caption: {
                    captionSide: 'top'
                }
            }
        },
        muiTableContainerProps: {
            sx: { 
                height: 
                    title === "Deals" 
                        ? 'calc(100vh - 150px)'
                        : title === "Morning Report" 
                            ? 'calc(100vh - 207px)' 
                            : title.includes("Audit Trail")
                                ? 'calc(100vh - 204px)'
                                : title === "Approve Documents" 
                                    ? (!isBusinessUnitSelected ? 'calc(100vh - 145px)' : 'calc(100vh - 196px)')
                                    : ["Requests", "Counter Party List"].includes(title)
                                        ? 'calc(100vh - 145px)'
                                        : 'calc(100vh - 151px)'
            }
        },
        muiTableBodyProps: {
            sx: { 
                height: 
                    title === "Deals" 
                        ? 'calc(100vh - 150px)'
                        : title === "Morning Report" 
                            ? 'calc(100vh - 207px)' 
                            : title.includes("Audit Trail")
                                ? 'calc(100vh - 204px)'
                                : title === "Approve Documents" 
                                    ? (!isBusinessUnitSelected ? 'calc(100vh - 145px)' : 'calc(100vh - 196px)')
                                    : title === "Requests" 
                                        ? 'calc(100vh - 145px)'
                                        : 'calc(100vh - 151px)',
                '& .MuiTableCell-root': {
                    borderColor :'#e7e7e7'
                },
                '& .MuiTableCell-root:last-of-type': {
                    border: 'none'
                }
            }
        },
        muiTableHeadProps: {
            sx: {
                zIndex: 1,
                '& .MuiTableCell-head:nth-last-of-type(2) > div': {
                    justifyContent: 'center'
                },
                '& .MuiTableCell-head': {
                    paddingTop: 0
                },
                '& .MuiTableCell-head:last-of-type': {
                    boxShadow: 'none',
                    border: 'none'
                }
            }
        },
        muiTableHeadCellProps: {
            sx: {
                backgroundColor: '#0C969C',  
                color:'#ffffff',
                border: '1px solid rgba(81, 81, 81, .5)',
                fontSize: '12px',
                fontWeight:'regular',
                paddingTop: 0,
                paddingBottom: 0,
                '& .Mui-TableHeadCell-Content-Wrapper': {
                    whiteSpace: 'unset'
                },
                '& .MuiSvgIcon-root': {
                    color:'#ffffff !important'
                }
            }
        },
        muiTableBodyRowProps: ({ row }) => {
            const isTotalRow = row.original["Supplier Contract"]?.includes("Total");
            const isExpanded = row.getCanExpand() && row.getIsExpanded();
            const isSubRow = row.depth > 0;
            const backgroundColor = (() => 
            {
                if (title === "Morning Report" && isSubRow) return row.original?.rowColor || "transparent";
                if (newDeal?.id && row.original?.deal_id === newDeal.id) return "#c7dcf5";
                if (isExpanded) return "#d7d7d8";
                return isTotalRow ? "#d7e5ef" : "transparent";
            })();

            return {
                sx: {
                    height: '50px',
                    fontSize: '12px',
                    backgroundColor,
                    '&:hover': {
                        backgroundColor: '#ddf3ff'
                    }
                }
            };
        },
        muiTableBodyCellProps: ({ column, row }) => {
            const { 
                "Transaction Status": transactionStatusRaw = null, 
                "TAT Cust. PFI": tatCustPFI = null, 
                "Status Cust. LC": statusCustLCRaw = null,
                "Aging/TAT Cust. LC": agingTATCustLC = null,
                "Status Sup. LC": statusSupLCRaw = null,
                "Aging/TAT Sup. LC": agingTATSupLC = null,
                "Aging/TAT Sup. Docs (Copy)": agingTATSupDocsCopy = null,
                "Aging/TAT Cust. Docs (Copy)": agingTATCustDocsCopy = null,
                "Aging/TAT Sup. Docs (Original)": agingTATSupDocsOriginal = null,
                "Aging/TAT Cust. Docs (Original)": agingTATCustDocsOriginal = null,
                "Supplier Contract": supplierContract = null
            } = row.original || {};    
            const statusCustLC = statusCustLCRaw?.toLowerCase();
            const statusSupLC = statusSupLCRaw?.toLowerCase();
            const transactionStatus = transactionStatusRaw?.toLowerCase();
            const isSubRow = row.depth > 0;

            let color = "black";
            let backgroundColor = "transparent";
            let fontWeight = "normal";
        
            if (title === "Morning Report") 
            {
                switch (column.id) 
                {
                    case "Transaction Status":
                        if (transactionStatus === "transaction completed") 
                        {
                            color = "white";
                            backgroundColor = "#92D050";
                        }
                        
                        break;
                    case "Status Cust. LC":
                    case "Status Sup. LC":
                        if (statusCustLC === "pending" || statusSupLC === "pending") 
                        {
                            color = "#9C0006";
                            backgroundColor = "#FFC7CE";
                        } 
                        else if (statusCustLC === "received" || statusSupLC === "transmitted") 
                        {
                            color = "#006100";
                            backgroundColor = "#C6EFCE";
                        }
                        
                        break;
                    default:
                        if (
                            (column.id === "TAT Cust. PFI" && tatCustPFI > 1) ||
                            (column.id === "Aging/TAT Cust. LC" && agingTATCustLC > 10) ||
                            (column.id === "Aging/TAT Sup. LC" && agingTATSupLC > 3) ||
                            (column.id === "Aging/TAT Sup. Docs (Copy)" && agingTATSupDocsCopy > 10) ||
                            (column.id === "Aging/TAT Cust. Docs (Copy)" && agingTATCustDocsCopy > 11) ||
                            (column.id === "Aging/TAT Sup. Docs (Original)" && agingTATSupDocsOriginal > 21) ||
                            (column.id === "Aging/TAT Cust. Docs (Original)" && agingTATCustDocsOriginal > 30)
                        ) 
                        {
                            color = "white";
                            backgroundColor = "red";
                        } 
                        else if (supplierContract?.includes("Total")) 
                        {
                            fontWeight = "600";
                        }
                        
                        break;
                }
            } 
            else if (title === "Approve Documents" && isSubRow && column.id === "Status") 
            {
                const status = row.original?.Status;
                color = status === "Approved" ? "#00b242" : "#fe0000";
            } 
            else if (isSubRow && column.id === "Deal No.") 
            {
                color = "transparent";
            } 
            else if (title === "Requests" || title === "Counter Party List") 
            {
                if (column.id === "Counter Party Status") 
                {
                    const status = row.original?.["Counter Party Status"];
                    const counterPartyStatusColors = {
                        "Proceed with Caution": "#B8860B",
                        "Future Transaction Blocked": "red",
                        "On-Boarded and Available to Use": "green"
                    };

                    color = counterPartyStatusColors[status] || "black";
                }
                else if (column.id === "Next Review Status")
                {
                    const status = row.original?.["Next Review Status"];
                    color = status === "Pending" ? "#fe0000" : "black";
                }
                else if (column.id === "Counter Party Status") 
                {
                    const status = row.original?.["Counter Party Status"];
                    color = status === "Approved" ? "#00b242" : "#fe0000";
                }
            }
            else if (["mrt-row-expand", "mrt-row-select", "mrt-row-actions"].includes(column.id)) 
            {
                backgroundColor = "white";
            }
        
            return {
                sx: {
                    border: '1px solid rgba(81, 81, 81, .5)',
                    opacity: 1,
                    overflow: 'visible',
                    whiteSpace: 'wrap',
                    color,
                    backgroundColor,
                    fontWeight
                }
            };
        },
        muiFilterAutocompleteProps: {
            sx: {
                '& .MuiAutocomplete-input': {
                    width: '100% !important'
                }
            }
        },
        muiLinearProgressProps: {
            sx: {
                color: 'secondary',
                variant: 'indeterminate'
            }
        },
        muiPaginationProps: {
            showRowsPerPage: false,
            showFirstButton: true,
            showLastButton: true
        },
        muiTablePaperProps: {
            sx: {
                '& > .MuiBox-root:last-child': {
                    minHeight: '3em'
                },
                '& > .MuiBox-root:last-child .MuiTablePagination-root': {
                    padding: '10px'
                },
                '& > .MuiBox-root:last-child .MuiTablePagination-root > span': {
                    fontSize: '14px'
                }
            }
        }
    });
    const resetButtonRef = useRef(null);

    useEffect(() =>
    {
        let tableColumns = tableData?.columns || [];
        const { rows: tableRows = [], filtered_rows: filteredTableRows = [], data_types: tableColumnDataTypes = {} } = tableData;
        const tempColumns = [];

        tableColumns = tableColumns.filter(column => !["id", "deal_id", "deal_pfi_id", "tranche_id", "coo_id", "external_file_id", "revision_number", "deal_product_grade_id", "tranche_product_id", "uuid", "is_external_document", "counter_party_id", "vendor_id", "rowColor", "isApproved", "isDeleted"].includes(column)) || [];

        if (title === "Deals" && [2, 3, 5].includes(businessUnitId))
        {
            tableColumns = tableColumns.filter(column => column !== "Product Grade");
        }

        for (let i = 0; i < tableColumns.length; i++)
        {
            const column = tableColumns[i];
            const dataType = tableColumnDataTypes[column];
            const width = 150;

            if (dataType.includes("object"))
            {
                tempColumns.push({
                    accessorFn: (originalRow) => originalRow[column],
                    id: column,
                    header: column === "Deal No" ? "Deal No." : column,
                    size: width,
                    filterVariant: 'multi-select',
                    filterFn: 'multiSelect',
                    visibleInShowHideMenu: !(title === "Deals" && [2, 3, 5].includes(businessUnitId) && column === "Product Grade"),
                    Header: ({ column }) => (
                        <Tooltip title = {column.columnDef.header} arrow>
                            <div>{column.columnDef.header}</div>
                        </Tooltip>
                    ),
                    Cell: ({ column: col, row }) => {
                        const string = row.original?.[column];

                        return (
                            <RenderCustomCell 
                                value = {string}  
                                width = {col.getSize()} 
                                isNumber = {false} 
                            />
                        );
                    }
                });
            }
            else if (dataType.includes("int") || dataType.includes("float"))
            {
                tempColumns.push({
                    accessorFn: (originalRow) => originalRow[column],
                    id: column,
                    header: column,
                    size: width,
                    filterVariant: 'range',
                    filterFn: 'between',
                    Header: ({ column }) => (
                        <Tooltip title = {column.columnDef.header} arrow>
                            <div>{column.columnDef.header}</div>
                        </Tooltip>
                    ),
                    Cell: ({ column: col, row }) => {
                        const number = isNaN(parseFloat(row.original?.[column])) ? null : parseFloat(row.original?.[column]);

                        return (
                            <RenderCustomCell 
                                value = {number}  
                                width = {col.getSize()} 
                                isNumber = {true} 
                            />
                        );
                    }
                });
            }
            else if (dataType.includes("datetime"))
            {
                tempColumns.push({
                    accessorFn: (originalRow) => new Date(originalRow[column]),
                    id: column,
                    header: column,
                    size: width,
                    filterVariant: 'date-range',
                    filterFn: 'betweenInclusive',
                    Header: ({ column }) => (
                        <Tooltip title = {column.columnDef.header} arrow>
                            <div>{column.columnDef.header}</div>
                        </Tooltip>
                    ),
                    Cell: ({ column: col, row }) => {
                        let date = row.original[column]; 

                        if (column === "Shipment Month") 
                        {
                            date = dayjs(date).isValid() ? dayjs(date).format("MMMM") : date;
                        }                        
                        
                        return (
                            <RenderCustomCell 
                                value = {date}  
                                width = {col.getSize()} 
                                isNumber = {false} 
                            />
                        );
                    }
                });
            }
        }

        if (title === "Approve Documents" && isTabChanged)
        {
            resetButtonRef?.current?.click();
        }

        setColumns(tempColumns);
        setData(tableRows);
        setFilteredData(filteredTableRows);
        setSelectedRow({});
        setIsDataChanged(false);

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [tableData]);

    useEffect(() => 
    {
        const selectedRows = table.getSelectedRowModel().rows;
        const selectedParentRows = selectedRows.filter(row => row.depth === 0);
        
        selectedParentRows.forEach(row => 
        {
            // If no sub-row is selected, deselect the parent row
            if (!row.getIsAllSubRowsSelected() && !row.getIsSomeSelected()) 
            {
                row.toggleSelected(false);
            }
        });  
    }, [rowSelection, table]);

    useImperativeHandle(ref, () => (
    {
        setFilteredData
    }));

    const calculateIsRequestDisabled = (rowData) => 
    {
        const subRows = rowData.subRows;
    
        // Check if there's any "Rejected" or "Reverted Back" execution for any counterparty type
        const hasRejectedOrRevertedBack = subRows.some(subrow => 
            counterPartyTypes.includes(subrow["Reviewed Party Type"]) && ["Rejected", "Reverted Back"].includes(subrow.Execution)
        );
    
        if (hasRejectedOrRevertedBack) 
        {
            return false; // Enable if any "Rejected" or "Reverted Back" exists
        }

        // If all subrows for the counterparty types are approved, return disabled
        const allApproved = counterPartyTypes.every(type =>
            subRows.every(subrow => 
                subrow["Reviewed Party Type"] === type && subrow.Execution === "Approved"
            )
        );
    
        return allApproved ? true : false; // Disable if all are "Approved", otherwise enable
    }

    const handleEnterPress = (event) =>
    {
        if (event.key === "Enter")
        {
            event.preventDefault();
            searchButtonRef?.current?.click();
        }
    }

    const handleShowGlobalFilterTextField = () =>
    {
        if (showGlobalFilterTextField)   
        {
            setShowGlobalFilterTextField(false);
            handleClearGlobalFilter();
        }
        else
        {
            setShowGlobalFilterTextField(true);
            setTimeout(() => searchInputRef.current.focus(), 0);
        }
    }

    const handleGlobalFilterValueChange = (event) =>
    {
        const { value } = event.target;
        setGlobalFilterValue(value);

        if (!value)
        {
            setFilteredData(data);
        }
    }

    const handleClearGlobalFilter = () =>
    {
        setGlobalFilterValue("");
        setFilteredData(data);
    }
    
    const handleChangeRowColor = (color) =>
    {
        setData((previousData) => 
        {
            const updatedData = [...previousData];
            const updatedSubRows = [...updatedData[selectedRow.parentId]?.subRows];
            const currentRow = updatedSubRows[selectedRow.index];
            const previousRowColor = currentRow?.previousRowColor || currentRow.rowColor;
            const isUpdated = previousRowColor?.toLowerCase() !== color?.toLowerCase();
        
            updatedSubRows[selectedRow.index] = {
                ...currentRow,
                rowColor: color,
                previousRowColor,
                isUpdated
            };
            updatedData[selectedRow.parentId] = {
                ...updatedData[selectedRow.parentId],
                subRows: updatedSubRows
            };
            setIsDataChanged(isUpdated);
        
            return updatedData;
        }); 
        
        setFilteredData((previousFilteredData) => 
        {
            const updatedFilteredData = [...previousFilteredData];
            const updatedSubRows = [...updatedFilteredData[selectedRow.parentId]?.subRows];
            const currentRow = updatedSubRows[selectedRow.index];
            const previousRowColor = currentRow?.previousRowColor || currentRow.rowColor;
            const isUpdated = previousRowColor?.toLowerCase() !== color?.toLowerCase();
        
            updatedSubRows[selectedRow.index] = {
                ...currentRow,
                rowColor: color,
                previousRowColor,
                isUpdated
            };
            updatedFilteredData[selectedRow.parentId] = {
                ...updatedFilteredData[selectedRow.parentId],
                subRows: updatedSubRows
            };
            setIsDataChanged(isUpdated);
        
            return updatedFilteredData;
        }); 
    }

    const handleShowColorPicker = (event, row) => 
    {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    }
    
    const handleCloseColorPicker = () => 
    {
        setAnchorEl(null);
        setSelectedRow({});
    }

    const handleSetDocumentStatus = (rowData) =>
    {
        const updatedRowData = { ...rowData };
        updatedRowData["Reviewed By"] = currentUserId;

        handleRecordStatusChange(updatedRowData);
        setIsTabChanged(false);
    }

    const handleDeleteDocument = (rowData) =>
    {
        rowData.isDeleted = 1;
        handleRecordStatusChange(rowData);
    }
    
    const handleResetTable = (table) =>
    {
        table.resetColumnFilters();
        table.resetExpanded();
        table.resetRowSelection();
        setAnchorEl(null);
    }
      
    return (
        <div style = {{ height: '100%' }}>
            <MaterialReactTable table = {table} />
        </div>
    );
});

export default memo(MaterialReactDataTable);