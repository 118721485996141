import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getPermissions } from '../../lib/accessControl';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { abortController } from '../../utils/abortController';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import MaterialReactDataTable from '../../components/MaterialReactDataTable';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import axios from 'axios';

const Deals = ({ isLoading = false, selectedBusinessUnit = null, setActiveTab = () => {}, setSelectedDeal = () => {}, setSelectedPFI = () => {}, setIsLoading = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, logOut = () => {} }) => 
{
  const location = useLocation();

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* AUTHORIZATION VARIABLES */
  const { isWritable = false } = getPermissions(currentUser?.role, location?.pathname);

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();

  /* DEAL VARIABLES */
  const [dealData, setDealData] = useState([]);
  const [newDeal, setNewDeal] = useState({});
  const [toggleDisplayData, setToggleDisplayData] = useState(false);
  const tableRef = useRef();

  /* DATATABLE ROW VARIABLES */
  const [rowData, setRowData] = useState({});

  /* DATATABLE GLOBAL FILTER VARIABLES */
  const [globalFilterValue, setGlobalFilterValue] = useState("");

  /* DIALOG VARIABLES */
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  useEffect(() => 
  {
    const fetchData = async () => 
    {
      setIsLoading(true);

      if (Object.keys(newDeal).length === 0)
      {
        setShowAlert(false);
        setAlertMessage("");
        setAlertType("");
      }

      await axios({
        method: "post",
        url: "/api/GetDealData",
        data: {
          business_unit_id: selectedBusinessUnit,
          filter_value: globalFilterValue
        },
        signal: abortController.signal
      })
      .then((response) => 
      {
        setIsLoading(false);
        const { status, data } = response;

        if (status === 200) 
        {
          const dealData = data?.deal_data || [];
          setDealData(dealData);
        } 
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
        }
      })
      .catch((error) => 
      {
        console.error("Get Deal Data Api: ", error);
        setIsLoading(false);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    if (selectedBusinessUnit) 
    {
      fetchData();
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [selectedBusinessUnit, toggleDisplayData]);

  const handleAddDeal = async () => 
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    await axios({
      method: "POST",
      url: "/api/GenerateDealNo",
      data: {
        business_unit_id: selectedBusinessUnit,
        user_id: currentUser?.id
      },
      signal: abortController.signal
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        setNewDeal(data);
        setAlertMessage(`New deal created successfully: ${data?.value}`);
        setAlertType("success");
        setShowAlert(true);
        setToggleDisplayData(!toggleDisplayData);
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.error("Generate Deal No. Api: ", error);
      setIsLoading(false);

      if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
      {
        return;
      }
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleAddDealPFIWrapper = (rowData) =>
  {
    setRowData(rowData);
    setShowConfirmationDialog(true);
  }

  const handleAddDealPFI = async () => 
  {
    setShowConfirmationDialog(false);
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");

    const { deal_id, "Deal No.": dealNo } = rowData;
    const selectedDeal = { id: deal_id, value: dealNo };

    await axios({
      method: "POST",
      url: "/api/AddDealPFI",
      data: {
        business_unit_id: selectedBusinessUnit,
        deal_no: selectedDeal?.id,
        user_id: currentUser?.id
      },
      signal: abortController.signal
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        setActiveTab("Pre-Shipment");
        setSelectedDeal(selectedDeal);
        setSelectedPFI(data);
        setNewDeal({});
      } 
      else 
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    { 
      console.error("Generate PFI Api: ", error);
      setIsLoading(false);

      if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
      {
        return;
      }
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleEditDeal = (rowData, tab) =>
  {
    const { deal_id: dealId, deal_pfi_id: dealPFIId, "Deal No.": dealNo, "PFI No.": pfiNo } = rowData;
    
    setSelectedDeal({ id: dealId, value: dealNo });
    setSelectedPFI({ id: dealPFIId, value: pfiNo.slice(dealNo.length) });
    setActiveTab(tab);
  }

  const handleGlobalFilter = async (rowData) =>
  {
    setIsLoading(true);
    setShowAlert(false);
    setAlertMessage("");
    setAlertType("");
    
    await axios({
      method: "post",
      url: "/api/FilterTableData",
      data: { 
        pathname: location?.pathname,
        business_unit_id: selectedBusinessUnit,
        table_data: rowData,
        filter_value: globalFilterValue
      },
      signal: abortController.signal
    })
    .then((response) => 
    {
      setIsLoading(false);
      const { status, data } = response;

      if (status === 200) 
      {
        tableRef?.current?.setFilteredData(data || []);
      }
      else
      {
        setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
        setAlertType("error");
        setShowAlert(true);
      }
    })
    .catch((error) => 
    {
      console.log("Filter Table Data Api: ", error);
      setIsLoading(false);

      if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
      {
        return;
      }
      
      const status = error?.response?.status;

      if (status === 403) 
      {
        triggerSessionExpire();
      } 
      else 
      {
        setAlertMessage(
          status === 401
            ? "Unauthorized access. You do not have the required permissions to perform this action."
            : status === 429
            ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
            : "An error occurred while processing your request. Please try again later or contact the site administrator."
        );
        setAlertType("error");
        setShowAlert(true);
    
        if (status === 429) 
        {
          setTimeout(logOut, 3000);
        }
      }
    });
  }

  const handleCloseConfirmationDialog = () =>
  {
    setShowConfirmationDialog(false);
  }

  return (
    <div className = "d-flex w-100 flex-column gap-2 justify-content-center px-2">
      <Dialog open = {showConfirmationDialog} onClose = {handleCloseConfirmationDialog}>
        <DialogContent>
          <DialogContentText className = "text-center">
            Are you sure you want to proceed with adding a new PFI?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            type = "button"
            className = "btn btn-secondary custom-btn"
            onClick = {handleAddDealPFI}
          >
            Add
          </button>

          <button
            type = "button"
            className = "btn btn-danger custom-btn"
            onClick = {handleCloseConfirmationDialog}
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>

      <LocalizationProvider dateAdapter = {AdapterDayjs}>
        <MaterialReactDataTable
          ref = {tableRef}
          title = "Deals"
          isWritable = {isWritable}
          isLoading = {isLoading}
          isBusinessUnitSelected = {true}
          businessUnitId = {selectedBusinessUnit}
          newDeal = {newDeal} 
          globalFilterValue = {globalFilterValue}
          tableData = {dealData}
          setIsLoading = {setIsLoading}
          setShowAlert = {setShowAlert}
          setAlertMessage = {setAlertMessage}
          setAlertType = {setAlertType}
          setGlobalFilterValue = {setGlobalFilterValue}
          handleGlobalFilter = {handleGlobalFilter}
          handleAddDeal = {handleAddDeal}
          handleAddPFI = {handleAddDealPFIWrapper}
          handleEditDeal = {handleEditDeal}
        />
      </LocalizationProvider>
    </div>
  );
}

export default Deals;