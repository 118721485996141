import { forwardRef, memo, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { countries } from '../../static/CountryList';
import { abortController } from '../../utils/abortController';
import useUser from '../../hooks/useUser';
import useSessionExpire from '../../hooks/useSessionExpire';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid2';
import VirtualizedAutocomplete from '../../components/Autocomplete';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs';
import axios from 'axios';

const SellingLeg = forwardRef(({ isWritable = false, isListDataLoading = false, isExistingSellingLeg = false, selectedBusinessUnit = null, selectedPFI = null, listData = {}, formData = {}, dealHeaderFormData = {}, expandedFormSections = [], setIsExistingSellingLeg = () => {}, setFormData = () => {}, setDealHeaderFormData = () => {}, setExpandedFormSections = () => {}, setShowDeleteModal = () => {}, setIsUpdated = () => {}, setIsFormSavedOrDeleted = () => {}, setShowAlert = () => {}, setAlertMessage = () => {}, setAlertType = () => {}, logOut = () => {} }, ref) => 
{
  const { pathname } = useLocation();

  /* AUTHENTICATION VARIABLES */
  const currentUser = useUser();

  /* SESSION EXPIRY VARIABLES */
  const triggerSessionExpire = useSessionExpire();

  /* LIST VARIABLES */
  const [packagingTypes, setPackagingTypes] = useState([]);
  const paymentInstrumentStatus = useMemo(() => ["Pending", "Received"], []);
  const validityDays = useMemo(() => Array.from({ length: 60 }, (_, i) => i + 1), []);
  const { 
    banks = [],
    incoterms = [],
    traders = [],
    seaPorts = [],
    currencies = [],
    tenors = [],
    paymentTerms = [] 
  } = listData;

  /* FORM VARIABLES */
  const formRef = useRef(null);

  useEffect(() => 
  {
    const fetchLists = async () => 
    {
      axios({
        method: "post",
        url: "/api/GetPackagingTypeList",
        data: { 
          pathname: pathname,
          Business_Unit_Id: selectedBusinessUnit
        },
        signal: abortController.signal
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          const packagingTypes = data?.packaging_types || [];
          setPackagingTypes(packagingTypes);
        }
      })
      .catch((error) => 
      {
        console.log("Get Packaging Type Api: ", error);

        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }

        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }
      });
    }

    fetchLists();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => 
  {
    if (formData?.PFI_Issuance_Date) 
    {
      const orderDate = dayjs(formData?.PFI_Issuance_Date);
      const validityDays = formData?.Validity_Days || 0;
      const expiryDate = orderDate.add(validityDays, "days").format("MM/DD/YYYY");

      setFormData((previousFormData) => ({
        ...previousFormData,
        PFI_Expiry_Date: expiryDate
      }));
    }

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [formData?.PFI_Issuance_Date, formData?.Validity_Days]);
  
  useImperativeHandle(ref, () => ({
    isValid,
    handleSubmit: handleSubmit,
    handleDelete: handleDelete
  }));

  const convertToDayJsObject = (date) => 
  {
    const convertedDate = date ? dayjs(date, "MM/DD/YYYY") : null;
    return convertedDate;
  }

  const isValid = () => 
  {
    return formRef.current?.reportValidity();
  }

  const handleFormSectionAccordionChange = (section) => (_, isExpanded) => 
  {
    setExpandedFormSections(isExpanded ? [...expandedFormSections, section] : expandedFormSections.filter(id => id !== section));
  }

  const handleChange = (field, value, isDate = false) => 
  {
    let newValue = value;
    let dataKey = null;
    let totalAmount = 0; 
    
    if (isDate && newValue)
    {
      newValue = dayjs(newValue).format("MM/DD/YYYY");
    }

    if (["Selling_Tenor_Id", "Selling_Payment_Terms_Id"].includes(field))
    {
      setDealHeaderFormData((previousDealHeaderFormData) => ({
        ...previousDealHeaderFormData,
        [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null
      }));
    }
    else
    {
      if (field === "Customer_Bank_Id")
      {
        setFormData((prevData) => ({
          ...prevData,
          Customer_Bank_Name: newValue?.Name || null
        })); 
      }
        
      setFormData((prevData) => ({
        ...prevData,
        [field]: typeof newValue === "string" ? newValue.trim() === "" ? null : newValue : newValue ? newValue : null,
        [dataKey]: totalAmount
      }));
    } 
  }

  const handleSubmit = async (event) => 
  {
    event?.preventDefault();

    if (!isValid()) 
    {
      return false;
    }

    return new Promise((resolve, reject) => 
    {
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");
    
      let updatedFormData = {
        ...formData,
        Business_Unit_Id: selectedBusinessUnit,
        Deal_PFI_Id: selectedPFI,
        User_Id: currentUser?.id
      };
    
      updatedFormData = Object.fromEntries(Object.entries(updatedFormData).map(([key, value]) => [
        key,
        typeof value === "object" && value?.hasOwnProperty("value") ? value.id : value
      ]));
    
      const apiUrl = isExistingSellingLeg ? "/api/UpdateSellingLeg" : "/api/AddSellingLeg";
  
      axios({
        method: "post",
        url: apiUrl,
        data: updatedFormData,
        signal: abortController.signal
      })
      .then((response) => 
      {
        const { status, data } = response;
  
        if (status === 200) 
        {          
          setIsUpdated(false);
          setIsFormSavedOrDeleted(true);
          resolve(true);
        } 
        else if (status === 400) 
        {
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
          reject(false);
        } 
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
          reject(false);
        }
      })
      .catch((error) => 
      {
        console.log("Add/Update Selling Leg Data Api: ", error);
        
        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }

        const status = error?.response?.status;
  
        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
        }

        reject(false);
      });
    });
  }

  const handleDelete = async () => 
  {
    return new Promise((resolve, reject) => 
    {
      setShowDeleteModal(false);
      setShowAlert(false);
      setAlertMessage("");
      setAlertType("");

      axios({
        method: "post",
        url: "/api/DeleteSellingLeg",
        data: { 
          Business_Unit_Id: selectedBusinessUnit,
          Deal_PFI_Id: selectedPFI,
          User_Id: currentUser?.id
        },
        signal: abortController.signal
      })
      .then((response) => 
      {
        const { status, data } = response;

        if (status === 200) 
        {
          formRef?.current?.reset();

          setIsExistingSellingLeg(false);
          setIsUpdated(false);
          setIsFormSavedOrDeleted(true);
          resolve(true);
        } 
        else if (status === 400)
        {
          setAlertMessage(data?.error || "An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
          reject(false);
        }
        else 
        {
          setAlertMessage("An error occurred while processing your request. Please try again later or contact the site administrator.");
          setAlertType("error");
          setShowAlert(true);
          reject(false);
        }
      })
      .catch((error) => 
      {
        console.log("Delete Selling Leg Data Api: ", error);       
        
        if (axios.isCancel(error) || error.code === "ERR_CANCELED") 
        {
          return;
        }
        
        const status = error?.response?.status;

        if (status === 403) 
        {
          triggerSessionExpire();
        } 
        else 
        {
          setAlertMessage(
            status === 401
              ? "Unauthorized access. You do not have the required permissions to perform this action."
              : status === 429
              ? "Request limit exceeded. Your account is temporarily disabled. Please contact the site administrator."
              : "An error occurred while processing your request. Please try again later or contact the site administrator."
          );
          setAlertType("error");
          setShowAlert(true);
      
          if (status === 429) 
          {
            setTimeout(logOut, 3000);
          }
        }

        reject(false);
      });
    });
  }

  return (
    <Accordion 
      sx = {{ marginLeft: '0.1em !important' }}
      expanded = {expandedFormSections.includes(5)} 
      onChange = {handleFormSectionAccordionChange(5)}
    >
      <AccordionSummary
        id = "panel-header" 
        aria-controls = "panel-content"
        expandIcon = {<ExpandMoreIcon />}
        slot = "div"
      >
        <small className = "page-heading title">
          Selling Leg Information
        </small>
      </AccordionSummary>
      <AccordionDetails>
        <form
          ref = {formRef}
          className = "deal-form mt-1"
          onSubmit = {handleSubmit}
        >
          <div className = "d-flex flex-column gap-3">
            <Grid
              container
              spacing = {2}
              className = "align-items-start autocomplete"
            >
              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Selling Payment Terms *"
                  options = {paymentTerms}
                  selectedOptions = {dealHeaderFormData?.Selling_Payment_Terms_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Selling_Payment_Terms_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = {`Selling Tenor ${dealHeaderFormData?.Selling_Payment_Terms_Id?.value === "LC" ? "*" : ""}`}
                  options = {tenors}
                  selectedOptions = {dealHeaderFormData?.Selling_Tenor_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Selling_Tenor_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Latest Date of Shipment *"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-100"
                    value = {convertToDayJsObject(formData?.Last_Shipment_Date)}
                    onChange = {(newDate) => handleChange("Last_Shipment_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Last_Shipment_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Selling Incoterm *"
                  options = {incoterms}
                  selectedOptions = {formData?.Incoterm_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Incoterm_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  isWritable = {isWritable}
                  filterOn = "Customer LC Status"
                  options = {paymentInstrumentStatus}
                  selectedOptions = {formData?.Payment_Instrument_Status || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Payment_Instrument_Status", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <TextField
                  label = "Customer Document Presentation Days"
                  type = "number"
                  id = "Document_Presentation_Days"
                  value = {formData?.Document_Presentation_Days || ""}
                  onChange = {(event) =>
                  {
                    const value = event.target.value;

                    if (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 100)) 
                    {
                      handleChange("Document_Presentation_Days", value);
                    }
                  }}
                  slotProps = {{ 
                    input: { 
                      readOnly: !isWritable,
                      min: 0, 
                      max: 2147483647 
                    } 
                  }}
                  onKeyDown = {(event) => 
                  {
                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                    {
                      event.preventDefault();
                    }
                  }}
                  onInput = {(event) => 
                  {
                    const value = event.target.value;
                    event.target.value = value.replace(/[^0-9]/g, "");
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "LC Expiry Date"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-100"
                    value = {convertToDayJsObject(formData?.LC_Expiry_Date)}
                    onChange = {(newDate) => handleChange("LC_Expiry_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("LC_Expiry_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Customer Confirming Bank"
                  options = {banks}
                  selectedOptions = {formData?.LC_Confirming_Bank_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("LC_Confirming_Bank_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <TextField
                  label = "Remaining Balance Payment Days"
                  type = "number"
                  id = "Remaining_Balance_Payment_Days"
                  value = {formData?.Remaining_Balance_Payment_Days || ""}
                  onChange = {(event) => handleChange("Remaining_Balance_Payment_Days", event.target.value)}
                  slotProps = {{ 
                    input: { 
                      readOnly: !isWritable,
                      min: 0, 
                      max: 2147483647 
                    } 
                  }}
                  onKeyDown = {(event) => 
                  {
                    if (event?.key === "e" || event?.key === "E" || event?.key === "-" || event?.key === "+") 
                    {
                      event.preventDefault();
                    }
                  }}
                  onInput = {(event) => 
                  {
                    const value = event.target.value;
                    event.target.value = value.replace(/[^0-9]/g, "");
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  isWritable = {isWritable}
                  filterOn = "Country of Origin *"
                  options = {countries}
                  selectedOptions = {formData?.Country_Of_Origin || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Country_Of_Origin", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "PFI Issuance Date *"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-100"
                    value = {convertToDayJsObject(formData?.PFI_Issuance_Date)}
                    onChange = {(newDate) => handleChange("PFI_Issuance_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("PFI_Issuance_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>
              
              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Customer Bank"
                  options = {banks}
                  selectedOptions = {formData?.Customer_Bank_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Customer_Bank_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <TextField
                  label = "Customer Bank Name"
                  id = "Customer_Bank_Name"
                  value = {formData?.Customer_Bank_Name || ""}
                  onChange = {(event) => handleChange("Customer_Bank_Name", event.target.value)}  
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}                
                />
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Port of Discharge *"
                  options = {seaPorts}
                  selectedOptions = {formData?.Port_of_Discharge_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Port_of_Discharge_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Selling Currency *"
                  options = {currencies}
                  selectedOptions = {formData?.Selling_Currency_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Selling_Currency_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <TextField
                  label = "Customer LC No."
                  id = "LC_No"
                  value = {formData?.LC_No || ""}
                  onChange = {(event) => handleChange("LC_No", event.target.value)}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "Customer LC Issuance Date"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-100"
                    value = {convertToDayJsObject(formData?.Issuance_Date)}
                    onChange = {(newDate) => handleChange("Issuance_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("Issuance_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <TextField
                  label = "Drawee Bank"
                  id = "Drawee_Bank"
                  value = {formData?.Drawee_Bank || ""}
                  onChange = {(event) => handleChange("Drawee_Bank", event.target.value)}                  
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                />
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Trader Name"
                  options = {traders}
                  selectedOptions = {formData?.Trader_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Trader_Id", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isMultiple = {false}
                  isObject = {false}
                  isWritable = {isWritable}
                  filterOn = "Validity Days *"
                  options = {validityDays}
                  selectedOptions = {formData?.Validity_Days || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Validity_Days", newValue)}
                />
              </Grid>

              <Grid size = {{ xs: 6 }} className = "form-text-field">
                <LocalizationProvider dateAdapter = {AdapterDayjs}>
                  <DesktopDatePicker
                    inputFormat = "MM/DD/YYYY"
                    label = "PFI Expiry Date"
                    disableMaskedInput
                    readOnly = {!isWritable}
                    className = "date-picker w-100"
                    value = {convertToDayJsObject(formData?.PFI_Expiry_Date)}
                    onChange = {(newDate) => handleChange("PFI_Expiry_Date", newDate, true)}
                    onAccept = {(newDate) => handleChange("PFI_Expiry_Date", newDate, true)}
                    slotProps = {{
                      actionBar: {
                        actions: ["clear"]
                      },
                      textField: {
                        InputProps: {
                          size: "small",
                          disabled: true
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid size = {{ xs: 6 }}>
                <VirtualizedAutocomplete
                  isLoading = {isListDataLoading}
                  isMultiple = {false}
                  isObject = {true}
                  isWritable = {isWritable}
                  filterOn = "Type of Packaging *"
                  options = {packagingTypes}
                  selectedOptions = {formData?.Packaging_Type_Id || null}
                  handleSelectChange = {(filterOn, newValue) => handleChange("Packaging_Type_Id", newValue)}
                />
              </Grid>
              
              <Grid size = {{ xs: 12 }} className = "form-text-field">
                <TextField
                  label = "Terms & Conditions *"
                  id = "Terms_and_Conditions"
                  multiline
                  rows = {3}
                  value = {formData?.Terms_and_Conditions || ""}
                  slotProps = {{
                    input: {
                      readOnly: !isWritable
                    }
                  }}
                  onChange = {(event) => handleChange("Terms_and_Conditions", event.target.value)}
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </AccordionDetails>
    </Accordion>
  );
});

export default memo(SellingLeg);